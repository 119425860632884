import React from "react";

//mui import
import CssBaseline from "@mui/material/CssBaseline";
import Link from "@mui/material/Link";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

import { Step, StepLabel, Stepper } from "@mui/material";
import { Typography, Button, TextField, Box, Select, MenuItem, Checkbox } from "@mui/material";
import { InputLabel, Divider, InputAdornment } from "@mui/material";

//component import
import { CModal, ExtendableTextField, ColorlibConnector, ColorlibStepIconRoot } from "components";

//api import

import { CreateCompany, checkCompanyNameExist } from "api";

//REGEX
import EmailRegex from "utils/emailRegex";
// import PhoneNumberRegex from "../../utils/phoneRegex";

//ICON IMPORT
import {
  Person,
  BusinessCenter,
  Handshake,
  CreditCard,
  LocalPhone,
  Mail,
  AccountBalance,
  Shuffle,
  Business,
  Fax,
} from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { isValidBIC, isValidIBAN } from "ibantools";

function StepIcon(props) {
  const { active, completed, className } = props;

  const icons = {
    1: <Person />,
    2: <BusinessCenter />,
    3: <CreditCard />,
    4: <Handshake />,
  };

  return (
    <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}

const CreateCompanyModal = ({ isOpen, onRequestClose, companyId }) => {
  const steps = ["Benutzerdaten", "Unternehmensdaten", "Bankverbindung einrichten", "Vereinbarung"];
  const dispatch = useDispatch();

  const account = useSelector((state) => state.account);
  const contact_person = useSelector((state) => state?.company[0]?.contact_person);

  //Button Values

  const [buttonLeft, setButtonLeft] = React.useState("Schließen");
  const [buttonRight, setButtonRight] = React.useState("Weiter");

  const [open, setOpen] = React.useState(false);
  const [activeStep, setActiveStep] = React.useState(0);

  //Error handling

  const [openCompanyNameExist, setOpenCompanyNameExist] = React.useState(false);
  const [companyErrorMessage, setCompanyErrorMessage] = React.useState("");

  //PAGE UserData(0)
  const [gender, setGender] = React.useState("");
  const [first_name, setFirstName] = React.useState("");
  const [last_name, setLastName] = React.useState("");
  const [phone_number, setPhoneNumber] = React.useState("");
  const [faxNumber, setFaxNumber] = React.useState("");

  const [email, setEmail] = React.useState("");

  const [contactPersonUsed, setContactPersonUsed] = React.useState(false);

  //PAGE CompanyData(1)

  const [ik_number, setIKNumber] = React.useState("");
  const [company_name, setCompanyName] = React.useState("");
  const [extra, setExtra] = React.useState("");
  const [street, setStreet] = React.useState("");
  const [post_code, setPostCode] = React.useState("");
  const [city, setCity] = React.useState("");
  const [street_number, setStreetNumber] = React.useState("");

  //PAGE Vereinbarungen(2)
  const [lastSchrift, setLastSchrift] = React.useState(false);
  const [acceptDataSecurity, setAcceptDataSecurity] = React.useState(false);
  const [certification, setCertification] = React.useState(false);
  const [authorized, setAuthorized] = React.useState(false);

  //PAGE Payment(3)
  const [creditInstituteName, setCreditInstituteName] = React.useState("");
  const [IBAN, setIBAN] = React.useState("");
  const ibanRegex = /^[A-Z]{2}\d{2}[A-Z0-9]{1,30}$/;
  const [IBANError, setIBANError] = React.useState(false);
  const [BIC, setBIC] = React.useState("");
  const bicRegex = /^[A-Z]{6}[A-Z2-9]{2}[A-NP-Z0-9]{3}$/;
  const [BICError, setBICError] = React.useState(false);
  const [errorIndicator, setErrorIndicator] = React.useState(false);

  const [differentBillingAddress, setDifferentBillingAddress] = React.useState(false);
  const [billing_email, setBillingEmail] = React.useState("");
  const [billing_street, setBillingStreet] = React.useState("");
  const [billing_post_code, setBillingPostCode] = React.useState("");
  const [billing_city, setBillingCity] = React.useState("");
  const [billing_street_number, setBillingStreetNumber] = React.useState("");

  const [textFields, setTextFields] = React.useState([{ id: 1, value: "" }]);

  const handleClick = async (value) => {
    switch (activeStep) {
      case 0: {
        if (!value) {
          onRequestClose();
        } else {
          if (!!first_name && !!last_name && EmailRegex(email) && !!phone_number) {
            //Activate NextButton
            setButtonLeft("Zurück");
            setActiveStep(activeStep + 1);
            setErrorIndicator(false);
          } else {
            setErrorIndicator(true);
          }
        }
        break;
      }
      case 1: {
        if (!value) {
          //Error Message
          setActiveStep(activeStep - 1);
          setButtonLeft("Schliessen");
        } else {
          if (!!ik_number && !!company_name && !!street && !!street_number && !!post_code && !!city) {
            const nameInUse = await checkCompanyNameExist(company_name, ik_number);
            console.log(nameInUse);
            if (nameInUse.error) {
              setCompanyErrorMessage(nameInUse?.message);
              setOpenCompanyNameExist(true);
              break;
            } else {
              setActiveStep(activeStep + 1);
            }
          } else {
            setErrorIndicator(true);
          }
        }
        break;
      }
      case 2: {
        if (!value) {
          setActiveStep(activeStep - 1);
        } else {
          if (
            differentBillingAddress &&
            !billing_street &&
            !billing_post_code &&
            !billing_city &&
            !billing_street_number
          ) {
            setErrorIndicator(true);
          } else {
            setActiveStep(activeStep + 1);
            setButtonRight("Unternehmen Anlegen");
          }
        }
        break;
      }
      case 3: {
        if (!value) {
          setActiveStep(activeStep - 1);
          setButtonRight("Weiter");
        } else {
          if (!lastSchrift || !acceptDataSecurity || !certification || !authorized) {
            setOpen(true);
          } else {
            setActiveStep(activeStep + 1);
            handleSubmit();
          }
        }
        break;
      }
      default:
        break;
    }
  };

  //register
  const handleSubmit = () => {
    const Company = {
      name: company_name,
      status: 0,
      ik_number: ik_number,
      extraIKNumbers: textFields.map((field) => field.value).join(","),
      street: street,
      street_number: street_number,
      extra_information: extra,
      postcode: post_code,
      city: city,
      differentBillingAddress: differentBillingAddress,
      billing_city: billing_city,
      billing_email: billing_email,
      billing_post_code: billing_post_code,
      billing_street: billing_street,
      billing_street_number: billing_street_number,
      creditInstituteName: creditInstituteName,
      BIC: BIC.replace(/\s+/g, "").trim(),
      IBAN: IBAN.replace(/\s+/g, "").trim(),
      phone_number: phone_number,
      fax_number: faxNumber,
      email: email,
      first_name: first_name,
      last_name: last_name,
      gender: gender,
      useContactPerson: contactPersonUsed,
      contactPersonData: contactPersonUsed ? contact_person[0].id : null,
    };
    dispatch(CreateCompany(Company, account?.id));
    onRequestClose();

    // if (!response.error) navigate("/");
    // else alert("Fehler");
  };

  const createTemporaryIkNumber = () => {
    const randomValue = `t${Math.floor(Math.random() * 1000000000)}`;
    setIKNumber(randomValue);
  };

  function handleContactPerson() {
    setContactPersonUsed(true);
    setFirstName(contact_person[0].first_name);
    setLastName(contact_person[0].last_name);
    setPhoneNumber(contact_person[0].phone_number);
    setFaxNumber(contact_person[0].fax_number);
    setEmail(contact_person[0].email);
    setGender(contact_person[0].gender);
  }

  const handleBankDetails = () => {
    if (activeStep !== 2) return false;
    return activeStep === 2 && (IBAN === "" || BIC === "");
  };

  return (
    <CModal
      open={isOpen}
      modalLabel="Unternehmen anlegen"
      onRequestClose={onRequestClose}
      onButtonPressed={() => handleClick(true)}
      buttonLabel={buttonRight}
      buttonDisabled={handleBankDetails()}
      backButtonLabel={buttonLeft}
      onBackButtonPressed={() => handleClick(false)}
    >
      <CssBaseline />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          p: 2,
          justifyContent: "space-between",
        }}
      >
        <Stepper activeStep={activeStep} alternativeLabel connector={<ColorlibConnector />}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel StepIconComponent={StepIcon}>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        {activeStep === 0 && (
          <>
            <Button
              variant="contained"
              sx={{ mt: 5, alignSelf: "flex-start", mb: 1, textTransform: "none" }}
              onClick={() => handleContactPerson()}
            >
              Von Account übernehmen
            </Button>
            <Box
              sx={{
                mt: 5,
                width: "100%",
                display: "flex",
                flex: 1,
                flexDirection: "column",
                alignContent: "flex-start",
              }}
            >
              <Box
                sx={{
                  width: "15%",
                  display: "flex",
                  flex: 1,
                  flexDirection: "column",
                  alignContent: "flex-start",
                }}
              >
                <InputLabel id="demo-simple-select-label">Anrede</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={gender}
                  onChange={(e) => setGender(e.target.value)}
                  error={errorIndicator && !gender}
                  helperText={errorIndicator && !gender ? "Dieses Feld ist auszufüllen" : ""}
                >
                  <MenuItem value={0}>Herr</MenuItem>
                  <MenuItem value={1}>Frau</MenuItem>
                  <MenuItem value={2}>* (divers)</MenuItem>
                </Select>
              </Box>
              <Box sx={{ display: "flex", flexDirection: "row", flex: 1, justifyContent: "space-between" }}>
                <Box sx={{ flex: 0.5, mt: 2 }}>
                  <TextField
                    sx={{ width: "80%" }}
                    label="Vorname"
                    value={first_name}
                    onChange={(e) => setFirstName(e.target.value)}
                    error={errorIndicator && !first_name}
                    helperText={errorIndicator && !first_name ? "Dieses Feld ist auszufüllen" : ""}
                  />
                </Box>
                <Box sx={{ flex: 0.5, mt: 2 }}>
                  <TextField
                    sx={{ width: "80%" }}
                    label="Nachname"
                    value={last_name}
                    onChange={(e) => setLastName(e.target.value)}
                    error={errorIndicator && !last_name}
                    helperText={errorIndicator && !last_name ? "Dieses Feld ist auszufüllen" : ""}
                  />
                </Box>
              </Box>
              <TextField
                sx={{ mt: 2 }}
                label="Telefon"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LocalPhone />
                    </InputAdornment>
                  ),
                }}
                value={phone_number}
                onChange={(e) => setPhoneNumber(e.target.value)}
                error={errorIndicator && !phone_number}
                helperText={errorIndicator && !phone_number ? "Dieses Feld ist auszufüllen" : ""}
              />
              <TextField
                sx={{ mt: 2 }}
                label="Fax"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Fax />
                    </InputAdornment>
                  ),
                }}
                value={faxNumber}
                onChange={(e) => setFaxNumber(e.target.value)}
                error={errorIndicator && !faxNumber}
                helperText={errorIndicator && !faxNumber ? "Dieses Feld ist auszufüllen" : ""}
              />
              <TextField
                sx={{ mt: 2 }}
                label="E-Mail-Adresse"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Mail />
                    </InputAdornment>
                  ),
                }}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                error={errorIndicator && !email}
                helperText={errorIndicator && !email ? "Dieses Feld ist auszufüllen" : ""}
              />
            </Box>
          </>
        )}
        {activeStep === 1 && (
          <>
            <Divider />
            <Box
              sx={{
                mt: 5,
                width: "100%",
                display: "flex",
                flex: 1,
                flexDirection: "column",
                alignContent: "flex-start",
              }}
            >
              <Box sx={{ display: "flex", flexDirection: "row", flex: 1, justifyContent: "space-between" }}>
                <Box sx={{ flex: 0.5, mt: 2, mr: 0.04 }}>
                  <TextField
                    sx={{ mt: 2, width: "100%" }}
                    label="IK-Nummer"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <AccountBalance />
                        </InputAdornment>
                      ),
                    }}
                    value={ik_number}
                    onChange={(e) => setIKNumber(e.target.value)}
                    error={errorIndicator && !ik_number}
                    helperText={errorIndicator && !ik_number ? "Dieses Feld ist auszufüllen" : ""}
                  />
                </Box>
                <Box sx={{ flex: 0.5, mt: 2 }}>
                  <Button
                    sx={{ mt: 2, minHeight: 56, width: "100%" }}
                    variant="contained"
                    startIcon={<Shuffle />}
                    onClick={() => createTemporaryIkNumber()}
                  >
                    Temporäre IK-Nummer
                  </Button>
                </Box>
              </Box>
              <ExtendableTextField textFields={textFields} setTextFields={setTextFields} />
              <TextField
                sx={{ mt: 2 }}
                label="Name der Firma"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Business />
                    </InputAdornment>
                  ),
                }}
                value={company_name}
                onChange={(e) => setCompanyName(e.target.value)}
                error={errorIndicator && !company_name}
                helperText={errorIndicator && !company_name ? "Dieses Feld ist auszufüllen" : ""}
              />
              <TextField sx={{ mt: 2 }} label="Zusatz" value={extra} onChange={(e) => setExtra(e.target.value)} />
              <Box sx={{ display: "flex", flex: 1, justifyContent: "space-between" }}>
                <TextField
                  sx={{ mt: 2, display: "flex", flex: 0.6 }}
                  label="Straße"
                  value={street}
                  onChange={(e) => setStreet(e.target.value)}
                  error={errorIndicator && !street}
                  helperText={errorIndicator && !street ? "Dieses Feld ist auszufüllen" : ""}
                />
                <TextField
                  sx={{ mt: 2, display: "flex", flex: 0.3 }}
                  label="Hausnummer"
                  value={street_number}
                  onChange={(e) => setStreetNumber(e.target.value)}
                  error={errorIndicator && !street_number}
                  helperText={errorIndicator && !street_number ? "Dieses Feld ist auszufüllen" : ""}
                />
              </Box>
              <Box sx={{ display: "flex", flexDirection: "row", flex: 1, justifyContent: "space-between" }}>
                <TextField
                  sx={{ mt: 2, display: "flex", flex: 0.3 }}
                  label="Postleitzahl"
                  value={post_code}
                  onChange={(e) => setPostCode(e.target.value)}
                  error={errorIndicator && !post_code}
                  helperText={errorIndicator && !post_code ? "Dieses Feld ist auszufüllen" : ""}
                />
                <TextField
                  sx={{ mt: 2, display: "flex", flex: 0.6 }}
                  label="Ort"
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                  error={errorIndicator && !city}
                  helperText={errorIndicator && !city ? "Dieses Feld ist auszufüllen" : ""}
                />
              </Box>
            </Box>
          </>
        )}
        {activeStep === 2 && (
          <>
            <Divider />
            <Box sx={{ mt: 5, display: "flex", flexDirection: "column" }}>
              <TextField
                label="Name des Kreditinstitutes (Optional)"
                value={creditInstituteName}
                onChange={(e) => setCreditInstituteName(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Business />
                    </InputAdornment>
                  ),
                }}
              />
              <Box sx={{ display: "flex", flexDirection: "row", flex: 1, justifyContent: "space-between" }}>
                <Box sx={{ flex: 0.5, mt: 2 }}>
                  <TextField
                    sx={{ width: "90%" }}
                    label="IBAN"
                    value={IBAN}
                    onChange={(event) => {
                      const inputValue = event.target.value;
                      const trimmedValue = inputValue.replace(/\s+/g, "").trim();
                      setIBAN(inputValue.toUpperCase());
                      console.log(trimmedValue);
                      // Validate the IBAN against the regex pattern
                      setIBANError(!isValidIBAN(trimmedValue));
                    }}
                    error={IBANError}
                    helperText={IBANError ? "Bitte geben Sie eine gültige IBAN ein" : ""}
                    InputProps={{
                      inputProps: {
                        pattern: ibanRegex, // Assign the regex pattern
                      },
                    }}
                  />
                </Box>
                <Box sx={{ flex: 0.5, mt: 2 }}>
                  <TextField
                    label="BIC"
                    sx={{ width: "90%" }}
                    value={BIC}
                    onChange={(event) => {
                      const inputValue = event.target.value;
                      const trimmedValue = inputValue.replace(/\s+/g, "").trim();
                      setBIC(inputValue.toUpperCase());

                      // Validate the BIC against the regex pattern
                      setBICError(!isValidBIC(trimmedValue));
                    }}
                    error={BICError}
                    helperText={BICError ? "Bitte geben Sie eine gültige BIC ein" : ""}
                    InputProps={{
                      inputProps: {
                        pattern: bicRegex, // Assign the regex pattern
                      },
                    }}
                  />
                </Box>
              </Box>
            </Box>
            <Box sx={{ display: "flex", flex: 1, alignItems: "center", mt: 2 }}>
              <Checkbox
                color="primary"
                checked={differentBillingAddress}
                onClick={() => setDifferentBillingAddress(!differentBillingAddress)}
              />
              <Typography>Von der Betriebsanschrift abweichende Rechnungsadresse</Typography>
            </Box>
            {differentBillingAddress === true && (
              <>
                <Divider textAlign="left" sx={{ my: 2 }}>
                  <Typography fontWeight={"bold"}>Rechnungsaddresse</Typography>
                </Divider>
                <Box sx={{ display: "flex", flex: 1, justifyContent: "space-between" }}>
                  <TextField
                    sx={{ mt: 2, display: "flex", flex: 0.6 }}
                    label="Rechnungs-Email"
                    value={billing_email}
                    onChange={(e) => setBillingEmail(e.target.value)}
                  />
                </Box>
                <Box sx={{ display: "flex", flex: 1, justifyContent: "space-between" }}>
                  <TextField
                    sx={{ mt: 2, display: "flex", flex: 0.6 }}
                    label="Straße"
                    value={billing_street}
                    onChange={(e) => setBillingStreet(e.target.value)}
                    error={errorIndicator && !billing_street}
                    helperText={errorIndicator && !billing_street ? "Dieses Feld ist auszufüllen" : ""}
                  />
                  <TextField
                    sx={{ mt: 2, display: "flex", flex: 0.3 }}
                    label="Hausnummer"
                    value={billing_street_number}
                    onChange={(e) => setBillingStreetNumber(e.target.value)}
                    error={errorIndicator && !billing_street_number}
                    helperText={errorIndicator && !billing_street_number ? "This field is required" : ""}
                  />
                </Box>
                <Box sx={{ display: "flex", flexDirection: "row", flex: 1, justifyContent: "space-between" }}>
                  <TextField
                    sx={{ mt: 2, display: "flex", flex: 0.3 }}
                    label="Postleitzahl"
                    value={billing_post_code}
                    onChange={(e) => setBillingPostCode(e.target.value)}
                    error={errorIndicator && !billing_post_code}
                    helperText={errorIndicator && !billing_post_code ? "Dieses Feld ist auszufüllen" : ""}
                  />
                  <TextField
                    sx={{ mt: 2, display: "flex", flex: 0.6 }}
                    label="Ort"
                    value={billing_city}
                    onChange={(e) => setBillingCity(e.target.value)}
                    error={errorIndicator && !billing_city}
                    helperText={errorIndicator && !billing_city ? "Dieses Feld ist auszufüllen" : ""}
                  />
                </Box>
              </>
            )}
          </>
        )}
        {activeStep === 3 && (
          <>
            <Divider />
            <Box sx={{ mt: 5 }}>
              <Box sx={{ display: "flex", flexDirection: "row", mb: 2, alignItems: "center" }}>
                <Checkbox
                  name="lastschrift"
                  color="primary"
                  checked={lastSchrift}
                  onChange={() => setLastSchrift(!lastSchrift)}
                />
                <Typography>Ich stimme dem Lastschrifteinzugsverfahren zu.</Typography>
              </Box>
              <Box sx={{ display: "flex", flexDirection: "row", mb: 2, alignItems: "center" }}>
                <Checkbox
                  color="primary"
                  checked={acceptDataSecurity}
                  onClick={() => setAcceptDataSecurity(!acceptDataSecurity)}
                />
                <Typography>Ich akzeptiere die</Typography>
                <Link
                  href="https://www.dgp-gmbh.de/datenschutz/"
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={{ ml: 1 }}
                >
                  Datenschutzerklärung.
                </Link>
              </Box>
              <Box sx={{ display: "flex", flexDirection: "row", mb: 2, alignItems: "center" }}>
                <Checkbox color="primary" checked={certification} onClick={() => setCertification(!certification)} />
                <Typography>Ich akzeptiere die</Typography>
                <Link
                  href="https://www.dgp-gmbh.de/zertifizierungsvereinbarung/"
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={{ ml: 1 }}
                >
                  Zertifizierungsvereinbarung.
                </Link>
              </Box>
              <Box sx={{ display: "flex", flexDirection: "row", mb: 2, alignItems: "center" }}>
                <Checkbox color="primary" checked={authorized} onClick={() => setAuthorized(!authorized)} />
                <Typography> Ich bestätige, dass ich zum Zeitpunkt der Antragstellung bevollmächtigt bin.</Typography>
              </Box>
            </Box>
          </>
        )}
      </Box>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={open}
        autoHideDuration={2000}
        onClose={() => setOpen(false)}
      >
        <Alert variant="filled" severity="error">
          Bitte bestätigen Sie alle Felder um fortzufahren.
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={openCompanyNameExist}
        autoHideDuration={5000}
        onClose={() => setOpenCompanyNameExist(false)}
      >
        <Alert variant="filled" severity="error">
          {companyErrorMessage}
        </Alert>
      </Snackbar>
    </CModal>
  );
};

export default CreateCompanyModal;
