import { Box } from "@mui/material";
import moment from "moment";
import { StatusType } from "../../assets/type.ts";
const prequalificationColumns = [
  {
    field: "status",
    headerName: "Status",
    flex: 1,
    editable: false,
    valueGetter: (params) => {
      switch (params.row.status) {
        case StatusType.CREATED:
          return "in Bearbeitung";
        case StatusType.PENDING:
          return "in Prüfung";
        case StatusType.COMPLETED:
          return "abgeschlossen";
        case StatusType.REJECTED:
          return "abgelehnt";
        case StatusType.UNDER_REVIEW:
          return "zur Überprüfung freigegeben";
        default:
          return "in Bearbeitung";
      }
    },
  },
  {
    field: "ik_number2",
    headerName: "PQ Bestätigungsnummer",
    flex: 0.75,
    editable: false,
    valueGetter: (params) => `${params.row.consecutiveNumber}`,
  },
  {
    field: "ik_number",
    headerName: "IK-Nummer",
    flex: 0.75,
    editable: false,
    valueGetter: (params) => `${params.row.facility[0]?.ik_number}`,
  },
  {
    field: "name",
    headerName: "Name",
    flex: 1.5,
    editable: false,
    valueGetter: (params) => `${params.row.facility[0]?.name}`,
  },
  {
    field: "fullAddress",
    headerName: "Adresse",
    flex: 1,
    editable: false,
    valueGetter: (params) =>
      `${params.row.facility[0]?.city || ""} ${params.row.facility[0]?.postcode || ""} / ${
        params.row.facility[0]?.street || ""
      }, ${params.row.facility[0]?.street_number || ""}`,
  },
  {
    field: "date",
    headerName: "Datum",
    flex: 1,
    editable: false,
    renderCell: (params) => {
      return <Box>{`${moment(params.row.date).locale("de").format("DD.MM.YYYY")}`}</Box>;
    },
  },
  {
    field: "criteriaCatalogVersion",
    headerName: "Version",
    flex: 1,
    editable: false,
    valueGetter: (params) => `${params.row.criteriaCatalogVersion}`,
  },
];

export default prequalificationColumns;
