import { Box } from "@mui/material";
import { Check } from "@mui/icons-material";
import moment from "moment";
import { StatusType } from "../../assets/type.ts";

const actionListLayout = [
  {
    field: "status",
    headerName: "Status",
    type: "singleSelect",
    cellClassName: "status-menu",
    flex: 0.2,
    editable: true,
    valueOptions: [
      {
        value: StatusType.PENDING,
        label: (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              height: "100%",
            }}
          >
            in Bearbeitung
          </Box>
        ),
      },
      {
        value: StatusType.COMPLETED,
        label: (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              height: "100%",
            }}
          >
            <Check />
          </Box>
        ),
      },
    ],
    preProcessEditCellProps: (params) => {
      const isPaidProps = params.row.status;
      const hasError = isPaidProps.value && !params.props.value;
      return { ...params.props, error: hasError };
    },
    renderCell: (params) => {
      switch (params.row.status) {
        case StatusType.PENDING:
          return (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
                height: "100%",
              }}
              className="menu-status"
            ></Box>
          );
        case StatusType.COMPLETED:
          return (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
                height: "100%",
              }}
              className="menu-status"
            >
              <Check />
            </Box>
          );
        default:
          return <Box className="menu-status"></Box>;
      }
    },
  },
  {
    field: "message",
    headerName: "Beschreibung",
    flex: 1,
    editable: false,
  },
  {
    field: "date",
    headerName: "Datum",
    flex: 0.25,
    editable: false,
    renderCell: (params) => {
      return <Box>{`${moment(params.row.date).locale("de").format("DD.MM.YYYY")}`}</Box>;
    },
  },
  {
    field: "company",
    headerName: "Firma",
    flex: 0.75,
    editable: false,
    valueGetter: (params) => {
      if (params.row.company.length === 0) {
        return params.row.facility[0]?.name;
      } else {
        return params.row.company.name;
      }
    },
  },
];
export default actionListLayout;
