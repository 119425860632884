import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

//mui component
import {
  TextField,
  Box,
  Select,
  MenuItem,
  Divider,
  Typography,
  Checkbox,
  Button,
  Snackbar,
  Alert,
  Autocomplete,
  InputAdornment,
  Tooltip,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { InfoOutlined } from "@mui/icons-material";

//component import, Card
import { CompanyList, moveFacilityToCompany, updateCompany, updateFacility } from "api";
import { isValidIBAN, isValidBIC } from "ibantools";
import { CustomContainer } from "components";

/**
 * Renders the Company Facility Overview component.
 *
 * @param {Object} object - the object containing the Company or Facility Object - the object parameter
 * @return {JSX.Element} the JSX element representing the Company Facility Overview
 */
const CompanyFacilityOverview = ({ object, type }) => {
  console.log("object", object);
  // SPECIALIST LIST CREATION -----------------
  const dispatch = useDispatch();

  //PAGE CompanyData(1)

  const [ik_number, setIKNumber] = useState(object?.ik_number);
  const [extraIKNumbers, setExtraIKNumbers] = useState(object?.extraIKNumbers);
  const [company_name, setCompanyName] = useState(object?.name);
  const [extra, setExtra] = useState(object?.extra);
  const [street, setStreet] = useState(object?.street);
  const [post_code, setPostCode] = useState(object?.postcode);
  const [city, setCity] = useState(object?.city);
  const [street_number, setStreetNumber] = useState(object?.street_number);
  const [scope, setScope] = useState(object?.scope);
  const [majorCustomer, setIsMajorCustomer] = useState(object?.major_customer);
  const [majorCustomerName, setMajorCustomerName] = useState(object?.major_customer_name);

  //monitoring_dates

  const [monitoring_date_1, setMonitoringDate1] = useState(object?.monitoring_date_1);
  const [monitoring_date_2, setMonitoringDate2] = useState(object?.monitoring_date_2);

  //PAGE Payment(3)
  const [creditInstituteName, setCreditInstituteName] = useState(object?.creditInstituteName);
  const [IBAN, setIBAN] = useState(object?.IBAN);
  const ibanRegex = /^[A-Z]{2}\d{2}[A-Z0-9]{1,30}$/;
  const [IBANError, setIBANError] = useState(false);
  const [BIC, setBIC] = useState(object?.BIC);
  const bicRegex = /^[A-Z]{6}[A-Z2-9]{2}[A-NP-Z0-9]{3}$/;
  const [BICError, setBICError] = useState(false);
  const [differentBillingAddress, setDifferentBillingAddress] = useState(object.differentBillingAddress);
  const [billing_email, setBillingEmail] = useState(object?.billing_email);
  const [billing_street, setBillingStreet] = useState(object?.billing_street);
  const [billing_post_code, setBillingPostCode] = useState(object?.billing_postcode);
  const [billing_city, setBillingCity] = useState(object?.billing_city);
  const [billing_street_number, setBillingStreetNumber] = useState(object?.billing_street_number);

  //error handling
  const [errorAlert, setErrorAlert] = useState(false);
  const [successAlert, setSuccessAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const [clicked, setClicked] = useState(false);
  const [selectedCompanyObject, setSelectedCompanyObject] = React.useState({});

  async function update() {
    setClicked(true);
    const Company = {
      name: company_name,
      ik_number: ik_number,
      street: street,
      street_number: street_number,
      extra_information: extra,
      postcode: post_code,
      city: city,
      creditInstituteName: creditInstituteName,
      BIC: BIC.replace(/\s+/g, "").trim(),
      IBAN: IBAN.replace(/\s+/g, "").trim(),
      differentBillingAddress: differentBillingAddress,
      billing_city: billing_city,
      billing_email: billing_email,
      billing_post_code: billing_post_code,
      billing_street: billing_street,
      billing_street_number: billing_street_number,
      monitoring_date_1: monitoring_date_1,
      monitoring_date_2: monitoring_date_2,
      major_customer: majorCustomer,
      major_customer_name: majorCustomerName,
      scope: scope,
    };

    //IBAN and BIC validation
    const isBICValid = isValidBIC(BIC.replace(/\s+/g, "").trim());
    const isIBANValid = isValidIBAN(IBAN.replace(/\s+/g, "").trim());

    if (!isIBANValid) {
      setErrorAlert(true);
      setErrorMessage("IBAN ist nicht korrekt!");
      return;
    }
    if (!isBICValid) {
      setErrorAlert(true);
      setErrorMessage("BIC ist nicht korrekt!");
      return;
    }

    //Is a Company
    if (type === 0) {
      const response = dispatch(updateCompany(object.id, Company));
      console.log("response", response);
      if (response?.error !== true) {
        setCompanyName(response?.company?.name);
        setExtra(response?.company?.extra_information);
        setStreet(response?.company?.street);
        setPostCode(response?.company?.postcode);
        setCity(response?.company?.city);
        setStreetNumber(response?.company?.street_number);
        setScope(response?.company?.scope);
        setIsMajorCustomer(response?.company?.major_customer);
        setMajorCustomerName(response?.company?.major_customer_name);
        setMonitoringDate1(response?.company?.monitoring_date_1);
        setMonitoringDate2(response?.company?.monitoring_date_2);
        setCreditInstituteName(response?.company?.creditInstituteName);
        setIBAN(response?.company?.IBAN.toUpperCase());
        setBIC(response?.company?.BIC.toUpperCase());
        setDifferentBillingAddress(response?.company?.differentBillingAddress);
        setBillingEmail(response?.company?.billing_email);
        setBillingStreet(response?.company?.billing_street);
        setBillingPostCode(response?.company?.billing_postcode);
        setBillingCity(response?.company?.billing_city);
        setBillingStreetNumber(response?.company?.billing_street_number);
        setSuccessAlert(true);
        setSuccessMessage("Firma wurde aktualisiert!");
      } else {
        setErrorAlert(true);
        setErrorMessage(response?.message);
      }
    }
    //Is A Facility
    else if (type === 1) {
      const response = await updateFacility(object.id, Company);
      if (response?.error !== true) {
        setCompanyName(response?.company?.name);
        setExtra(response?.company?.extra_information);
        setStreet(response?.company?.street);
        setPostCode(response?.company?.postcode);
        setCity(response?.company?.city);
        setStreetNumber(response?.company?.street_number);
        setScope(response?.company?.scope);
        setIsMajorCustomer(response?.company?.major_customer);
        setMonitoringDate1(response?.company?.monitoring_date_1);
        setMonitoringDate2(response?.company?.monitoring_date_2);
        setCreditInstituteName(response?.company?.creditInstituteName);
        setIBAN(response?.company?.IBAN.toUpperCase());
        setBIC(response?.company?.BIC.toUpperCase());
        setDifferentBillingAddress(response?.company?.differentBillingAddress);
        setBillingEmail(response?.company?.billing_email);
        setBillingStreet(response?.company?.billing_street);
        setBillingPostCode(response?.company?.billing_postcode);
        setBillingCity(response?.company?.billing_city);
        setBillingStreetNumber(response?.company?.billing_street_number);
        setSuccessAlert(true);
        setSuccessMessage("Betriebsstätte wurde aktualisiert!");
      } else {
        setErrorAlert(true);
        setErrorMessage(response?.message);
      }
    }

    setClicked(false);
  }

  // async function deleteObject() {
  //   const response = await adminDeleteFacility(object.id);
  //   // if (response?.error !== true) {
  //   //   setSuccessAlert(true);
  //   //   setSuccessMessage("Firma wurde gelöscht!");
  //   // } else {
  //   //   setErrorAlert(true);
  //   //   setErrorMessage(response?.message);
  //   // }
  // }

  const allCompanies = useSelector((state) => state.company) ?? dispatch(CompanyList());
  // END CREATION ----------------------------------------------
  return (
    <CustomContainer>
      <Divider sx={{ my: 3 }} />
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Box sx={{ display: "flex", flexDirection: "row", mb: 2 }}>
          <Autocomplete
            disablePortal
            getOptionLabel={(option) => option?.name ?? ""}
            options={allCompanies ? allCompanies : []}
            sx={{ width: 300 }}
            value={selectedCompanyObject.name ? selectedCompanyObject : object.company ? object.company[0] : {}}
            isOptionEqualToValue={(option, value) => {
              console.log(option, value);
              return option.name === value.name;
            }}
            onChange={(event, companyObject) => {
              setSelectedCompanyObject(companyObject);
            }}
            renderOption={(props, option, { selected }) => (
              <li {...props} style={{ fontWeight: selected ? "bold" : "normal" }}>
                {option.name}
              </li>
            )}
            renderInput={(params) => <TextField {...params} label="Unternehmen" />}
          />
          <Button
            variant="contained"
            onClick={() => {
              dispatch(moveFacilityToCompany(object, selectedCompanyObject));
            }}
          >
            Verschieben
          </Button>
          {/* <Button
            variant="contained"
            onClick={() => {
              deleteObject();
            }}
          >
            Löschen
          </Button> */}
        </Box>

        <TextField
          sx={{ flex: 0.75 }}
          name="company_name"
          label="Unternehmensname"
          value={company_name}
          onChange={(e) => setCompanyName(e.target.value)}
        />
        <TextField
          name="extra"
          label="Zusatz"
          sx={{ display: "flex", flex: 0.75, mt: 2 }}
          value={extra}
          onChange={(e) => setExtra(e.target.value)}
        />
        <TextField
          name="ik_number"
          label="IK-Nummer"
          sx={{ flex: 0.75, mt: 2 }}
          value={ik_number}
          onChange={(e) => setIKNumber(e.target.value)}
        />
        <TextField
          name="extra_ik_numbers"
          label="Zusätzliche IK-Nummern"
          sx={{ flex: 0.75, mt: 2 }}
          value={extraIKNumbers}
          onChange={(e) => setExtraIKNumbers(e.target.value)}
          helperText="Mehrere IK-Nummern durch Komma getrennt, bitte beim Ändern auch durch kommata getrennt eingeben."
        />
        <Box sx={{ display: "flex", flex: 1, justifyContent: "space-between" }}>
          <TextField
            sx={{ mt: 2, display: "flex", flex: 0.6 }}
            label="Straße"
            value={street}
            onChange={(e) => setStreet(e.target.value)}
          />
          <TextField
            sx={{ mt: 2, display: "flex", flex: 0.3 }}
            label="Hausnummer"
            value={street_number}
            onChange={(e) => setStreetNumber(e.target.value)}
          />
        </Box>
        <Box sx={{ display: "flex", flexDirection: "row", flex: 1, justifyContent: "space-between" }}>
          <TextField
            sx={{ mt: 2, display: "flex", flex: 0.3 }}
            label="Postleitzahl"
            value={post_code}
            onChange={(e) => setPostCode(e.target.value)}
          />
          <TextField
            sx={{ mt: 2, display: "flex", flex: 0.6 }}
            label="Ort"
            value={city}
            onChange={(e) => setCity(e.target.value)}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            p: 1,
            mt: 2,
          }}
        >
          <Typography>Großkunde</Typography>
          <Checkbox checked={majorCustomer} onChange={() => setIsMajorCustomer(!majorCustomer)} />

          <TextField
            sx={{ width: "90%" }}
            label="Kunden Name für Stripe"
            value={majorCustomerName}
            onChange={(e) => setMajorCustomerName(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Tooltip
                    disableFocusListener
                    disableTouchListener
                    arrow
                    title={
                      <Typography variant="body1">
                        Der hier eingegebene Name muss mit dem Namen in den Metadaten der zu verwendenen Stripe Preise
                        übereinstimmen.
                        <br />
                      </Typography>
                    }
                  >
                    <InfoOutlined sx={{ mr: 3, scale: "1.5" }} />
                  </Tooltip>
                </InputAdornment>
              ),
            }}
          />
        </Box>

        <Divider sx={{ my: 3 }} textAlign="left">
          <Typography variant="h6">Kontodaten</Typography>
        </Divider>
        <Box sx={{ display: "flex", flexDirection: "row", flex: 1, justifyContent: "space-between" }}>
          <Box sx={{ flex: 0.5, mt: 2 }}>
            <TextField
              sx={{ width: "90%" }}
              label="Kreditinstitut"
              value={creditInstituteName}
              onChange={(e) => setCreditInstituteName(e.target.value)}
            />
            <TextField
              sx={{ width: "90%", mt: 2 }}
              label="IBAN"
              value={IBAN}
              onChange={(event) => {
                const inputValue = event.target.value;
                setIBAN(inputValue.toUpperCase());

                // Validate the IBAN against the regex pattern
                setIBANError(!isValidIBAN(inputValue));
              }}
              error={IBANError}
              helperText={IBANError ? "Bitte geben Sie eine gültige IBAN ein" : ""}
              InputProps={{
                inputProps: {
                  pattern: ibanRegex, // Assign the regex pattern
                },
              }}
            />
          </Box>
          <Box sx={{ flex: 0.5, mt: 2 }}>
            <TextField
              label="BIC"
              sx={{ width: "90%" }}
              value={BIC}
              onChange={(event) => {
                const inputValue = event.target.value;
                setBIC(inputValue.toUpperCase());

                // Validate the BIC against the regex pattern
                setBICError(!isValidBIC(inputValue));
              }}
              error={BICError}
              helperText={BICError ? "Bitte geben Sie eine gültige BIC ein" : ""}
              InputProps={{
                inputProps: {
                  pattern: bicRegex, // Assign the regex pattern
                },
              }}
            />
          </Box>
        </Box>
        <Divider sx={{ my: 3 }} textAlign="left">
          <Typography variant="h6">Scope</Typography>
        </Divider>
        <Select
          sx={{ flex: 0.75 }}
          value={scope}
          label="Scope"
          onChange={(e) => {
            setScope(e.target.value);
          }}
        >
          <MenuItem value={1}>Orthopäditechnik (1)</MenuItem>
          <MenuItem value={2}>Orth.Schuhtechnik (2)</MenuItem>
          <MenuItem value={3}>Hörakustik (3)</MenuItem>
          <MenuItem value={4}>Augenoptik (4)</MenuItem>
          <MenuItem value={5}>Blindenführhunde (5)</MenuItem>
          <MenuItem value={6.1}>Apotheke (6.1)</MenuItem>
          <MenuItem value={6.2}>Friseur (6.2)</MenuItem>
          <MenuItem value={6.3}>Altenheim (6.3)</MenuItem>
          <MenuItem value={6.4}>Sonstige (6.4)</MenuItem>
        </Select>
      </Box>

      {type === 1 && (
        <>
          <Divider sx={{ my: 2 }} />
          <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
            <DatePicker
              sx={{ flex: 0.45 }}
              label="Überwachungsdatum 1"
              value={moment(monitoring_date_1)}
              onChange={(newValue) => setMonitoringDate1(newValue)}
            />
            <DatePicker
              sx={{ flex: 0.45 }}
              label="Überwachungsdatum 2"
              value={moment(monitoring_date_2)}
              onChange={(newValue) => setMonitoringDate2(newValue)}
            />
          </Box>
        </>
      )}

      <Box sx={{ display: "flex", flex: 1, alignItems: "center", mt: 2 }}>
        <Checkbox
          color="primary"
          checked={differentBillingAddress}
          onClick={() => setDifferentBillingAddress(!differentBillingAddress)}
        />
        <Typography>Von der Betriebsanschrift abweichende Rechnungsadresse</Typography>
      </Box>
      {differentBillingAddress === true && (
        <>
          <Divider textAlign="left" sx={{ my: 2 }}>
            <Typography fontWeight={"bold"}>Rechnungsaddresse</Typography>
          </Divider>
          <Box sx={{ display: "flex", flex: 1, justifyContent: "space-between" }}>
            <TextField
              sx={{ mt: 2, display: "flex", flex: 0.6 }}
              label="Rechnungs-Email"
              value={billing_email}
              onChange={(e) => setBillingEmail(e.target.value)}
            />
          </Box>
          <Box sx={{ display: "flex", flex: 1, justifyContent: "space-between" }}>
            <TextField
              sx={{ mt: 2, display: "flex", flex: 0.6 }}
              label="Straße"
              value={billing_street}
              onChange={(e) => setBillingStreet(e.target.value)}
            />
            <TextField
              sx={{ mt: 2, display: "flex", flex: 0.3 }}
              label="Hausnummer"
              value={billing_street_number}
              onChange={(e) => setBillingStreetNumber(e.target.value)}
            />
          </Box>
          <Box sx={{ display: "flex", flexDirection: "row", flex: 1, justifyContent: "space-between" }}>
            <TextField
              sx={{ mt: 2, display: "flex", flex: 0.3 }}
              label="Postleitzahl"
              value={billing_post_code}
              onChange={(e) => setBillingPostCode(e.target.value)}
            />
            <TextField
              sx={{ mt: 2, display: "flex", flex: 0.6 }}
              label="Ort"
              value={billing_city}
              onChange={(e) => setBillingCity(e.target.value)}
            />
          </Box>
        </>
      )}
      <Snackbar
        open={errorAlert}
        autoHideDuration={5000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        onClose={() => setErrorAlert(false)}
      >
        <Alert variant="filled" severity="error">
          {errorMessage}
        </Alert>
      </Snackbar>
      <Snackbar
        open={successAlert}
        autoHideDuration={5000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        onClose={() => setSuccessAlert(false)}
      >
        <Alert variant="filled" severity="success">
          {successMessage}
        </Alert>
      </Snackbar>
      <Button variant="contained" disabled={clicked} onClick={() => update()}>
        Update
      </Button>
    </CustomContainer>
  );
};

export default CompanyFacilityOverview;

//Bearbeitungsfunktion.
//Antragsstellung dokument ausdruckbar.
//Bei bestätigung email.
//alle Fachlichen Leiter anzeigen.
//Ansprechpartner.
