import React, { useEffect, useState } from "react";
//mui import
import { Box, Card, Divider, Tooltip, Typography } from "@mui/material";
import { InfoRounded } from "@mui/icons-material";
//component import
import { CModal, SpecialistUpdateTab, CustomDataGrid } from "components";
import { AssessmentType } from "assets/type.ts";
import options from "utils/options.js";

const ConfirmationModal = ({ isOpen, onRequestClose, responseData, confirmRequest, setResponseData }) => {
  const response = responseData.data;

  // Store the specialists separately so that updates trigger a re-render
  const [specialists, setSpecialists] = useState(response.facility.specialist);
  const [selectedSpecialist, setSelectedSpecialist] = useState(null);
  const [requirementsList, setRequirementsList] = useState([]);

  // Compute the updated requirements list when specialists change
  useEffect(() => {
    const newRequirements = [...response.requirements_list]; // Start with existing requirements
    const requirementCodes = new Set(newRequirements.map((req) => req.code)); // Track existing requirement codes

    specialists.forEach((specialist) => {
      console.log(specialist);
      specialist.providing_fields.forEach((item) => {
        console.log(item);
        let needetRequirements = [];

        if (item.field_sales_selected && !item.in_store_selected) {
          const excludedRequirementsCodes = item.excludedOnFieldSalesSelect.map((requirement) => requirement.code);
          needetRequirements = item.requirements.filter((req) => !excludedRequirementsCodes.includes(req.code));
        } else if (!item.in_store_selected && !item.field_sales_selected) {
          console.log("No selection", item);
          return;
        } else {
          needetRequirements = [...item.requirements];
        }

        needetRequirements.forEach((req) => {
          if (!requirementCodes.has(req.code)) {
            requirementCodes.add(req.code);
            newRequirements.push(req);
          }
        });
      });
    });

    // Filter requirements based on prequalificationItem codes
    const filteredRequirements = newRequirements.filter(
      (req) => !response.facility.prequalification[0].prequalificationItem.find((item) => item.code === req.code)
    );

    setRequirementsList(filteredRequirements);
  }, [response.facility.prequalification, response.requirements_list, specialists]); // Recompute when specialists change

  const requirementColumns = [
    {
      field: "code",
      headerName: "Notwendig für:",
      flex: 0.25,
      editable: false,
      // sortComparator: customSortComparator,
      renderCell: ({ row }) => {
        const matchingSpecialists = specialists
          .map((specialist) => {
            const matchingFields = specialist.providing_fields
              .filter((field) => field.requirements.some((req) => req.code === row.code))
              .map((field) => field.code); // Extract field names

            return matchingFields.length > 0
              ? { specialistName: `${specialist.first_name} ${specialist.last_name}`, fields: matchingFields }
              : null;
          })
          .filter(Boolean); // Remove null values

        const tooltipContent =
          matchingSpecialists.length > 0
            ? matchingSpecialists.map(({ specialistName, fields }) => (
                <div key={specialistName}>
                  <strong>{specialistName}</strong>: {fields.join(", ")}
                </div>
              ))
            : "Änderungsspezifische Vorraussetzung"; // Default text when no specialist has this requirement

        return (
          <Tooltip disableFocusListener disableTouchListener arrow title={<Typography>{tooltipContent}</Typography>}>
            <InfoRounded />
          </Tooltip>
        );
      },
    },
    {
      field: "description",
      headerName: "Beschreibung",
      flex: 1,
      editable: false,
    },
  ];

  const CustomApprovalText = ({ type, oldCatalogVersion, newCatalogVersion }) => {
    switch (type) {
      case AssessmentType.MOVE:
        return (
          <Typography>
            Sie wollen einen Umzug Ihres Unternehmens anfordern. Bitte beachten Sie, dass eine neue
            Kriterien-Katalog-Version aktiv ist. <br /> Die Version auf der Sie ihre letzte Anfrage gestellt haben ist{" "}
            <b>{oldCatalogVersion}</b>, und die aktuelle Version ist <b>{newCatalogVersion}</b>.
            <hr />
            <p>
              Durch diese Änderung gibt es neue Voraussetzungen. Bitte überprüfen Sie, ob Sie diese erfüllen können.
            </p>
          </Typography>
        );
      default:
        return (
          <Typography>
            <p>
              Der Begeher hat die Version {oldCatalogVersion} genehmigt und die Version {newCatalogVersion} abgelehnt.
            </p>
          </Typography>
        );
    }
  };

  return (
    <CModal
      modalLabel={`Maßgebliche Änderung: ${options.find((option) => option.value === responseData.requestType).text}`}
      open={isOpen}
      onRequestClose={onRequestClose}
      buttonLabel={`${options.find((option) => option.value === responseData.requestType).text} beantragen`}
      backButtonLabel="Abbrechen"
      onButtonPressed={() => {
        console.log("specialists", specialists);

        setResponseData((prevData) => ({
          ...prevData,
          data: {
            ...prevData.data,
            facility: {
              ...prevData.data.facility,
              specialist: specialists, // Update the `specialist` array properly
            },
          },
          requirements: requirementsList, // Update the `requirements` array properly
        }));

        console.log("responseData in modal", responseData);
        confirmRequest();
      }}
      onBackButtonPressed={() => onRequestClose()}
    >
      <Box sx={{ display: "flex", flexDirection: "column", p: 2 }}>
        <Card sx={{ backgroundColor: "transparent", p: 2 }}>
          <CustomApprovalText
            type={responseData.requestType}
            oldCatalogVersion={response.old_catalog_version}
            newCatalogVersion={response.new_catalog_version}
          />
        </Card>

        <SpecialistUpdateTab
          specialists={specialists}
          setSpecialists={setSpecialists}
          selectedSpecialist={selectedSpecialist}
          setSelectedSpecialist={setSelectedSpecialist}
          setModalIsOpen={false} // Pass handler to update selection
        />

        <Card sx={{ backgroundColor: "transparent", p: 2, mt: 2 }}>
          <Divider textAlign="left" sx={{ my: 2 }}>
            <Typography fontWeight={"bold"}>Für die Änderung notwendige Vorraussetzungen</Typography>
          </Divider>

          <CustomDataGrid
            initialState={{
              sorting: {
                sortModel: [{ field: "code", sort: "asc" }],
              },
            }}
            rows={requirementsList}
            columns={requirementColumns}
            initialPageSize={25}
            disableSelectionOnClick
            getRowId={(row) => row.code || row.id}
          />
        </Card>
      </Box>
    </CModal>
  );
};

export default ConfirmationModal;
