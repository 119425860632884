import React from "react";
import { useDispatch, useSelector } from "react-redux";

//mui component
import { TextField, Box, Card, Checkbox, Select, MenuItem, Snackbar, Alert } from "@mui/material";

import { AcceptPrequalificationRequest, RejectPrequalificationRequest, GetSpecialist, GetProvidingFields } from "api";
//component import, Card
import { CModal, LabelName, CustomDataGrid, CustomLoadingIndicator } from "components";
import { dynamicSpecialistFieldValue } from "utils/dynamicSpecialistFieldValue";
import { specialistColumns } from "layouts/DataGridLayouts";

const AcceptPrequalification = ({ isOpen, onRequestClose, actionListItem }) => {
  // SPECIALIST LIST CREATION -----------------
  const [providing_fields, setProvidingFields] = React.useState([]);
  const [clicked, setClicked] = React.useState(false);
  const [infoOpen, setInfoOpen] = React.useState(false);
  const [scope, setScope] = React.useState(0);
  const [selectedSpecialist, setSelectedSpecialist] = React.useState(actionListItem.specialist[0]);
  const dispatch = useDispatch();

  const specialist = useSelector((state) => state.specialist);
  const occupation = useSelector((state) => state.occupation);
  const criteriaCatalog = useSelector((state) => state.criteriaCatalog);

  React.useEffect(() => {
    if (isOpen) {
      dispatch(GetSpecialist(actionListItem.facility[0].id, false));
    }
  }, [isOpen, dispatch, actionListItem]);

  const handleProvidingAction = (from, row) => {
    const element = providing_fields.findIndex((item) => item.code === row.code);

    if (from) providing_fields[element].in_store_selected = !providing_fields[element].in_store_selected;
    else providing_fields[element].field_sales_selected = !providing_fields[element].field_sales_selected;
  };

  const providingFieldsColumns = [
    {
      field: "in_store",
      headerName: "Geschäftslokal",
      flex: 0.15,
      editable: false,
      default: false,
      renderCell: ({ row }) => {
        if (row.in_store_allowed) {
          return <Checkbox checked={row.in_store_selected} onClick={() => handleProvidingAction(true, row)} />;
        } else return <></>;
      },
    },
    {
      field: "field_sales",
      headerName: "Außendienst",
      flex: 0.15,
      editable: false,
      default: false,
      renderCell: ({ row }) => {
        if (row.field_sales_allowed) {
          return <Checkbox checked={row.field_sales_selected} onClick={() => handleProvidingAction(false, row)} />;
        } else return <></>;
      },
    },
    {
      field: "code",
      headerName: "Versorgungsbereiche",
      flex: 0.2,
      editable: false,
    },
    {
      field: "description",
      headerName: "Bezeichnung",
      flex: 0.4,
      editable: false,
    },
  ];

  // END CREATION ----------------------------------------------

  return (
    <CModal
      modalLabel="Präqualifizierung Annehmen"
      open={isOpen}
      buttonLabel="Annehmen"
      backButtonLabel="Ablehnen"
      buttonDisabled={clicked}
      backButtonDisabled={clicked}
      onButtonPressed={(e) => {
        setClicked(true);
        setInfoOpen(true);
        if (scope === 0) {
          alert("Bitte waehlen Sie ein Scope aus");
          setClicked(false);
          return;
        }
        dispatch(AcceptPrequalificationRequest(actionListItem.id, actionListItem?.prequalification[0].id, scope)).then(
          () => {
            setClicked(false);
            onRequestClose();
          }
        );
      }}
      onBackButtonPressed={() => {
        setClicked(true);
        setInfoOpen(true);
        dispatch(RejectPrequalificationRequest(actionListItem.id, actionListItem?.prequalification[0].id)).then(() => {
          setClicked(false);
          onRequestClose();
        });
      }}
      onRequestClose={() => onRequestClose()}
    >
      <Card sx={{ p: 4, width: "80%", mt: 2, mx: 2 }}>
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <LabelName titel="Unternehmen" />
          <TextField sx={{ flex: 0.5 }} name="name" value={actionListItem?.facility[0]?.name} />
        </Box>
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <LabelName titel="Zusatz" />
          <TextField
            name="extra_information"
            sx={{ flex: 0.5 }}
            value={actionListItem?.facility[0]?.extra_information}
          />
        </Box>
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <LabelName titel="IK-Nummer" />
          <TextField name="ik_number" sx={{ flex: 0.5 }} value={actionListItem?.facility[0]?.ik_number} />
        </Box>
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <LabelName titel="Zus. IK-Nummer" />
          <TextField name="ik_number" sx={{ flex: 0.5 }} value={actionListItem?.facility[0]?.extraIKNumbers} />
        </Box>
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <LabelName titel="Straße, Hausnummer" />
          <TextField name="street" sx={{ flex: 0.25 }} value={actionListItem?.facility[0]?.street} />
          <TextField name="street_number" sx={{ flex: 0.25 }} value={actionListItem?.facility[0]?.street_number} />
        </Box>
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <LabelName titel="Postleitzahl, Stadt" />
          <TextField name="postcode" sx={{ flex: 0.25 }} value={actionListItem?.facility[0]?.postcode} />
          <TextField name="city" sx={{ flex: 0.25 }} value={actionListItem?.facility[0]?.city} />
        </Box>
        <Box sx={{ display: "flex", flexDirection: "row", mt: 2 }}>
          <LabelName titel="IBAN" />
          <TextField name="IBAN" sx={{ flex: 0.5 }} value={actionListItem?.facility[0]?.IBAN} />
        </Box>
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <LabelName titel="BIC" />
          <TextField name="BIC" sx={{ flex: 0.5 }} value={actionListItem?.facility[0]?.BIC} />
        </Box>
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <LabelName titel="Scope" />
          <Select
            sx={{ flex: 0.5 }}
            value={scope}
            onChange={(e) => {
              setScope(e.target.value);
            }}
          >
            <MenuItem value={1}>Orthopäditechnik (1)</MenuItem>
            <MenuItem value={2}>Orth.Schuhtechnik (2)</MenuItem>
            <MenuItem value={3}>Hörakustik (3)</MenuItem>
            <MenuItem value={4}>Augenoptik (4)</MenuItem>
            <MenuItem value={5}>Blindenführhunde (5)</MenuItem>
            <MenuItem value={6.1}>Apotheke (6.1)</MenuItem>
            <MenuItem value={6.2}>Friseur (6.2)</MenuItem>
            <MenuItem value={6.3}>Altenheim (6.3)</MenuItem>
            <MenuItem value={6.4}>Sonstige (6.4)</MenuItem>
          </Select>
        </Box>
      </Card>
      <CustomDataGrid
        sx={{ mx: 2 }}
        rows={specialist}
        columns={specialistColumns}
        initialPageSize={10}
        disableSelectionOnClick
        selectionModel={selectedSpecialist ? [selectedSpecialist?.id] : []}
        // onSelectionModelChange={(rows) => handlePrequalificationSelect(rows)}
        checkboxSelection
        onSelectionModelChange={(rows) => {
          if (rows.length === 0) {
            if (selectedSpecialist) {
              setSelectedSpecialist(null);
              setProvidingFields([]);
            }
            return;
          }
          const specialistObject = specialist?.filter((item) => item.id.includes(rows));
          setSelectedSpecialist(specialistObject[0]);
          dispatch(GetProvidingFields(specialistObject[0].id)).then(
            setProvidingFields(dynamicSpecialistFieldValue(criteriaCatalog, specialistObject[0]))
          );
        }}
      />

      <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", mx: 2 }}>
        <Card sx={{ p: 4, width: "45%", mt: 2 }}>
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <LabelName titel="Anrede" />
            <TextField
              sx={{ flex: 0.5 }}
              value={selectedSpecialist?.gender === 0 ? "Herr" : selectedSpecialist?.gender === 1 ? "Frau" : ""}
            />
          </Box>
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <LabelName titel="Vorname" />
            <TextField sx={{ flex: 0.5 }} value={selectedSpecialist?.first_name || ""} />
          </Box>
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <LabelName titel="Nachname" />
            <TextField sx={{ flex: 0.5 }} value={selectedSpecialist?.last_name || ""} />
          </Box>
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <LabelName titel="Email" />
            <TextField sx={{ flex: 0.5 }} value={selectedSpecialist?.email || ""} />
          </Box>
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <LabelName titel="Beruf / Qualifizierung" />
            <TextField
              sx={{ flex: 0.7 }}
              value={
                occupation[occupation.findIndex((item) => item.short_form === selectedSpecialist?.qualification)]
                  ?.description || ""
              }
            />
          </Box>
        </Card>
        <Card sx={{ p: 4, width: "50%", mt: 2 }}>
          <CustomDataGrid
            rows={providing_fields || []}
            columns={providingFieldsColumns}

            // checkboxSelection={checkboxSelection}
            // onSelectionModelChange={(rows) => setSelectedFields(rows)}
          />
        </Card>
      </Box>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={infoOpen}
        autoHideDuration={5000}
        onClose={() => setInfoOpen(false)}
      >
        <Alert variant="filled" severity="info">
          Bitte einen Moment gedult.
        </Alert>
      </Snackbar>
      <CustomLoadingIndicator open={infoOpen} message={"Einen Moment gedult. Antrag wird erstellt."} />
    </CModal>
  );
};

export default AcceptPrequalification;

//Bearbeitungsfunktion.
//Antragsstellung dokument ausdruckbar.
//Bei bestätigung email.
//alle Fachlichen Leiter anzeigen.
//Ansprechpartner.
