import React from "react";
import { useDispatch } from "react-redux";
import { DatePicker } from "@mui/x-date-pickers";
import { Box, TextField, Select, MenuItem, Typography, ListItemText } from "@mui/material";

//component import
import { CModal, LabelName } from "components";
import { createHistoryItem } from "api/history";
import moment from "moment";

const preWrittenMessages = [
  {
    id: 0,
    text: "Email eingang",
  },
  {
    id: 1,
    text: "Irgendwas anderes",
  },
];

const CreateHistoryItemModal = ({ isOpen, onRequestClose, selectedFacility }) => {
  const dispatch = useDispatch();
  const [message, setMessage] = React.useState(null);

  const [date, setDate] = React.useState(null);
  const [endDate, setEndDate] = React.useState(null);

  const createHistoryItemFunction = () => {
    dispatch(
      createHistoryItem(selectedFacility.id, {
        message: message,
        date: date ? moment(date).format("YYYY-MM-DD") : null,
        due_date: endDate ? moment(endDate).format("YYYY-MM-DD") : null,
      })
    );
  };

  return (
    <CModal
      modalLabel="Historie Erstellen"
      open={isOpen}
      onRequestClose={onRequestClose}
      buttonLabel="Ok - Historieneintrag erstellen"
      backButtonLabel="abbrechen"
      onButtonPressed={() => {
        //API CALL
        createHistoryItemFunction();
        onRequestClose();
      }}
      onBackButtonPressed={() => onRequestClose()}
    >
      <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "space-between", p: 2 }}>
        <Box sx={{ display: "flex", flexDirection: "row", mt: 2, mb: 2, flex: 1 }}>
          <LabelName titel="Nachricht" />
          <TextField sx={{ flex: 0.5 }} value={message} onChange={(e) => setMessage(e.target.value)} />
          <Select
            onChange={(e) => {
              const preselect = preWrittenMessages.filter((item) => item.id === e.target.value);
              setMessage(preselect[0].text);
            }}
          >
            {preWrittenMessages?.map((item) => (
              <MenuItem key={item.id} value={item.id}>
                <ListItemText primary={`${item.text}`} />
              </MenuItem>
            ))}
          </Select>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "row", mt: 2, mb: 2, flex: 1 }}>
          <LabelName titel="Erstellungsdatum" />
          <Box sx={{ flex: 0.75 }}>
            <DatePicker value={date} title="Anfangsdatum" onChange={(newValue) => setDate(newValue)} />
            <Typography>Freilassen für direkten Einsatz </Typography>
          </Box>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "row", mt: 2, mb: 2, flex: 1 }}>
          <LabelName titel="Ablauf Datum" />
          <Box sx={{ flex: 0.75 }}>
            <DatePicker value={endDate} title="Anfangsdatum" onChange={(newValue) => setEndDate(newValue)} />
            <Typography>Freilassen um kein Enddatum hinzuzufügen </Typography>
          </Box>
        </Box>
        {/* <Box sx={{ display: "flex", flexDirection: "column", mt: 2, mb: 2, flex: 1 }}>
          <Typography>Handelt von </Typography>
          <CustomDataGrid
            rows={selectedFacility.specialist}
            columns={specialistColumns}
             initialPageSize={25}
              
            
            
            checkboxSelection
            onSelectionModelChange={(rows) => {
              if (rows.length === 0) {
                if (selectedSpecialist) {
                  setSelectedSpecialist({});
                }
                return;
              }
              const selected = selectedFacility.specialist.filter((item) => item.id === rows[0]);

              setSelectedSpecialist(selected[0]);
            }}
          />
        </Box> */}
      </Box>
    </CModal>
  );
};

export default CreateHistoryItemModal;
