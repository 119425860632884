import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { filesize } from "filesize";
import { uniqueId } from "lodash";

import { StatusType } from "assets/type.ts";

import { Card, Divider, Typography, Box, Checkbox, Button } from "@mui/material";
import { InsertDriveFile, Clear } from "@mui/icons-material";

import { UploadWalkerFile, DeleteFile, AcceptInspectionCompletion } from "api";

import { ShowFileModal } from "pages/modals";
import { inspectionColumns } from "layouts/DataGridLayouts";
import { Uploader, CustomSnackbarAlert, CustomDataGrid } from "components";

const WalkerUpload = () => {
  const dispatch = useDispatch();
  const inspections = useSelector((state) => state.inspection);

  const [accept, setAccept] = React.useState(false);
  const [pressed, setPressed] = React.useState(false);
  const [clicked, setClicked] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [message, setMessage] = React.useState("");

  const [selectedInspection, setSelectedInspection] = React.useState({
    facility: [{}],
    prequalification: [{}],
    assessment: [{}],
    images: [],
  });

  const [openModal, setModalOpen] = React.useState(false);
  const [selectedFile, setSelectedFile] = React.useState({});

  const handleInspectionSelect = (rows) => {
    if (rows.length === 0) {
      setSelectedInspection({
        facility: [{}],
        prequalification: [{}],
        assessment: [{}],
        images: [],
      });
      return;
    }
    if (rows.length === 2) rows.shift();
    const inspectionObject = inspections?.filter((item) => item.id.includes(rows));
    setSelectedInspection(inspectionObject[0]);
  };

  //Upload

  const handleUpload = (files) => {
    const uploadedFiles = files?.map((file) => ({
      file,
      id: uniqueId(),
      name: file.name,
      readableSize: filesize(file.size),
      preview: URL.createObjectURL(file),
      progress: 0,
      type: file.type,
      uploaded: false,
      error: false,
      url: null,
      inspectionId: selectedInspection?.id,
    }));

    uploadedFiles.forEach((item) => processUpload(item));
  };

  const processUpload = (uploadedFile) => {
    const data = new FormData();
    const isPrequalification = selectedInspection?.prequalification.length !== 0;

    data.append("file", uploadedFile.file);
    data.append("name", uploadedFile.name);
    data.append(
      "prequalificationId",
      isPrequalification ? selectedInspection?.prequalification[0].id : selectedInspection?.assessment[0].id
    );
    dispatch(UploadWalkerFile(uploadedFile, selectedInspection?.id)).then((response) => {
      setPressed(true);
      setSuccess(response.error ? false : true);
      setMessage(response.message);
    });
  };

  const handleClicked = (item) => {
    setSelectedFile(item);
    setModalOpen(true);
  };

  const handleDelete = (item) => {
    if (selectedInspection.status === StatusType.COMPLETED) return;
    dispatch(DeleteFile(item, { inspectionId: selectedInspection?.id })).then((response) => {
      setPressed(true);
      console.log("response", response);
      setSuccess(response.error ? false : true);
      setMessage(response.message);
    });
  };

  const handleInspectionCompletion = () => {
    dispatch(AcceptInspectionCompletion(selectedInspection?.id)).then((response) => {
      setPressed(true);
      setSuccess(response.error ? false : true);
      setMessage(response.message);
      setClicked(false);
    });
  };

  React.useEffect(() => {
    const inspectionObject = inspections?.filter((item) => item.id === selectedInspection?.id);
    setSelectedInspection(inspectionObject[0]);
  }, [inspections, selectedInspection?.id]);

  return (
    <>
      <Divider textAlign="left" sx={{ mt: 8, mb: 2 }}>
        <Typography fontWeight={"bold"}>Hochladen</Typography>
      </Divider>
      <Card>
        <CustomDataGrid
          rows={inspections ? inspections : []}
          columns={inspectionColumns}
          initialPageSize={10}
          disableSelectionOnClick
          onSelectionModelChange={(rows) => handleInspectionSelect(rows)}
          checkboxSelection
          experimentalFeatures={{ newEditingApi: true }}
        />
      </Card>

      <Card sx={{ minWidth: 100, p: 2, my: 2 }}>
        <Box sx={{ mb: 2 }}>
          <Typography variant="h5" color="primary">
            {selectedInspection?.facility[0]?.name}
          </Typography>
          <Typography color="text.secondary">Bitte laden Sie Ihr fertiges Protokol hier hoch.</Typography>

          <Box sx={{ mt: 2 }}>
            <Typography variant="h6">Dateien</Typography>
            <Typography color="text.secondary">{"Keine Dateien vorhanden!"}</Typography>
          </Box>
        </Box>
        <Box>
          {selectedInspection?.facility[0]?.id && (
            <Uploader key={selectedInspection?.facility[0]?.name} onUpload={(file) => handleUpload(file)} />
          )}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "column",
              flex: 1,
            }}
          >
            {selectedInspection?.images?.map((item) => {
              const germanTimeFormat = new Date(item.date)
                .toLocaleString("de-DE", { timeZone: "UTC", hour12: false })
                .replace(/\//g, ".")
                .replace(", ", " ");
              return (
                <Box
                  key={item.id}
                  sx={{
                    backgroundColor: "secondary.light",
                    display: "flex",
                    justifyContent: "space-between",
                    flex: 1,
                    borderRadius: 1,
                    p: 1,
                    mb: 1,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      flex: 0.75,
                    }}
                    onClick={() => handleClicked(item)}
                  >
                    <InsertDriveFile />
                    <Typography>{`${item.imageBaseName}`}</Typography>
                    <Typography>{`${germanTimeFormat}`}</Typography>
                  </Box>
                  <Clear onClick={() => handleDelete(item)} />
                </Box>
              );
            })}
          </Box>
        </Box>
        <Typography variant="h6" sx={{ mt: 2 }}>
          Unterstützte Dateiformate: Bilder (*.jpg, *.jpeg, *.png, *.heic), Dateien (*.pdf)
        </Typography>
      </Card>
      <Divider textAlign="left" sx={{ mt: 8, mb: 2 }}>
        <Typography fontWeight={"bold"}>Bestätigen</Typography>
      </Divider>
      <Card sx={{ p: 2 }}>
        <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
          <Checkbox value={accept} onChange={() => setAccept(!accept)} />
          <Typography>
            Hiermit bestätige Ich dass, von mir hochgeladene Dokument auf Richtigkeit und vollständigkeit überprüft zu
            haben.
          </Typography>
        </Box>
        <Button
          variant="contained"
          disabled={accept === false || selectedInspection?.images?.length === 0 || clicked}
          onClick={() => {
            setClicked(true);
            handleInspectionCompletion();
          }}
        >
          Abschicken
        </Button>
      </Card>
      {<ShowFileModal isOpen={openModal} onRequestClose={() => setModalOpen(false)} file={selectedFile} />}
      <CustomSnackbarAlert
        open={pressed}
        setOpen={setPressed}
        severity={success ? "success" : "error"}
        message={message}
      />
    </>
  );
};

export default WalkerUpload;
