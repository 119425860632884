import React from "react";
import {
  Card,
  Box,
  Typography,
  Tabs,
  Tab,
  Button,
  TextField,
  Divider,
  Select,
  MenuItem,
  InputAdornment,
  Checkbox,
  Tooltip,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { InfoOutlined } from "@mui/icons-material";

import { useDispatch } from "react-redux";
import moment from "moment";
import {
  changeBillingAddress,
  changeBillingEmail,
  changeBankCredentials,
  changeContactPersonEmailAddress,
  createNewContactPerson,
  removeContactPerson,
  changeMainContactPerson,
} from "api/changes";
import { isValidBIC, isValidIBAN } from "ibantools";
import { ChangesContactPersonType } from "assets/type.ts";
import { CustomTabPanel, CustomDataGrid, CustomSnackbarAlert, CustomDataLine } from "components";

const EditContactPersonTab = ({ selectedFacility, setSelectedFacility }) => {
  const dispatch = useDispatch();
  const [selectedTabIndex, setSelectedTabIndex] = React.useState(0);

  const [selectedContactPerson, setSelectedContactPerson] = React.useState(selectedFacility?.main_contact_person);
  const [newMainContactPerson, setNewMainContactPerson] = React.useState(null);
  const [contactPersonDelete, setContactPersonDelete] = React.useState(null);
  const [changeForAllFacilities, setChangeForAllFacilities] = React.useState(false);

  const [phoneNumber, setPhoneNumber] = React.useState("");
  const [faxNumber, setFaxNumber] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [gender, setGender] = React.useState(0);
  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");

  const [creditInstituteName, setCreditInstituteName] = React.useState("");
  const [IBAN, setIBAN] = React.useState("");
  const ibanRegex = /^[A-Z]{2}\d{2}[A-Z0-9]{1,30}$/;
  const [IBANError, setIBANError] = React.useState(false);
  const [BIC, setBIC] = React.useState("");
  const bicRegex = /^[A-Z]{6}[A-Z2-9]{2}[A-NP-Z0-9]{3}$/;
  const [BICError, setBICError] = React.useState(false);

  const [street, setStreet] = React.useState("");
  const [streetNumber, setStreetNumber] = React.useState("");
  const [postCode, setPostCode] = React.useState("");
  const [city, setCity] = React.useState("");

  const [date, setDate] = React.useState(null);
  const [clicked, setClicked] = React.useState(false);

  const [alertOpen, setAlertOpen] = React.useState(false);
  const [alertSeverity, setAlertSeverity] = React.useState("info");
  const [alertMessage, setAlertMessage] = React.useState("");

  const [errorIndicator, setErrorIndicator] = React.useState(false);
  const [openMainContactPersonInfo, setOpenMainContactPersonInfo] = React.useState(false);
  const [openInfoText, setOpenInfoText] = React.useState(false);

  const mainContactConnectionsLength = selectedFacility?.main_contact_person?.isFacilityMain?.length;

  const handleResponse = (response) => {
    if (response.error) {
      setAlertSeverity("error");
      setAlertMessage(response.message);
    } else {
      setAlertSeverity("success");
      setAlertMessage(response.message);
      setSelectedFacility(response.value);
    }
    setAlertOpen(true);
    setClicked(false);
  };
  const createNewContactPersonFunction = () => {
    setClicked(true);
    if (!phoneNumber || !email || !firstName || !lastName || gender == null || !faxNumber) {
      setErrorIndicator(true);
      setClicked(false);
      return;
    }

    dispatch(
      createNewContactPerson(selectedFacility.id, {
        phone_number: phoneNumber,
        fax_number: faxNumber,
        email: email,
        gender: gender,
        first_name: firstName.trim(),
        last_name: lastName.trim(),
        entry_date: date ? moment(date).format("YYYY-MM-DD") : null,
      })
    ).then((response) => handleResponse(response));
  };

  const sendRemoveContactPerson = () => {
    setClicked(true);
    if (contactPersonDelete === null) {
      setErrorIndicator(true);
      setAlertSeverity("error");
      setAlertMessage("Bitte wählen Sie einen zu entfernenden Ansprechpartner aus.");
      setAlertOpen(true);
      setClicked(false);
      return;
    }
    dispatch(
      removeContactPerson(selectedFacility.id, contactPersonDelete.id, date ? moment(date).format("YYYY-MM-DD") : null)
    ).then((response) => handleResponse(response));
  };

  const changeMainContactPersonFunction = () => {
    setClicked(true);
    if (!selectedContactPerson || !newMainContactPerson) {
      setErrorIndicator(true);
      setAlertSeverity("error");
      setAlertMessage("Bitte wählen Sie einen neuen Hauptansprechpartner aus.");
      setAlertOpen(true);
      setClicked(false);
      return;
    }

    dispatch(
      changeMainContactPerson(
        selectedFacility.id,
        selectedContactPerson.id,
        newMainContactPerson.id,
        changeForAllFacilities
      )
    ).then((response) => handleResponse(response));
    setClicked(false);
  };

  const changeFacilityBillingAddress = () => {
    setClicked(true);
    if (!city || !postCode || !street || !streetNumber) {
      setErrorIndicator(true);
      setClicked(false);
      return;
    }
    dispatch(
      changeBillingAddress(selectedFacility.id, {
        billing_city: city,
        billing_postcode: postCode,
        billing_street: street,
        billing_street_number: streetNumber,
      })
    ).then((response) => handleResponse(response));
  };
  const changeBillingEmailAddress = () => {
    setClicked(true);
    if (!email) {
      setErrorIndicator(true);
      setClicked(false);
      return;
    }
    dispatch(changeBillingEmail(selectedFacility.id, email, changeForAllFacilities)).then((response) =>
      handleResponse(response)
    );
  };

  const changeBankData = () => {
    setClicked(true);

    if (!IBAN || !BIC) {
      setAlertSeverity("error");
      setAlertMessage("Bitte geben Sie IBAN und BIC an.");
      setAlertOpen(true);
      setClicked(false);
      return;
    }
    dispatch(
      changeBankCredentials(selectedFacility.id, {
        creditInstituteName: creditInstituteName,
        IBAN: IBAN.replace(/\s+/g, "").trim(),
        BIC: BIC.replace(/\s+/g, "").trim(),
      })
    ).then((response) => handleResponse(response));
  };

  const changeContactEmailAddress = () => {
    setClicked(true);
    if (!email) {
      setErrorIndicator(true);
      setClicked(false);
      return;
    }
    console.log("changeContactEmailAddress", selectedFacility.id, selectedContactPerson.id, email);
    dispatch(changeContactPersonEmailAddress(selectedFacility.id, selectedContactPerson.id, email)).then((response) =>
      handleResponse(response)
    );
  };

  const handleEmailChangeSelect = (rows) => {
    //check if selectedContactPerson is main_contact_person. if yes then open info text.
    //maybe also check for email === facility.billing_email

    if (rows.length === 0) {
      if (selectedContactPerson) {
        setSelectedContactPerson(null);
        setOpenMainContactPersonInfo(false);
        setOpenInfoText(false);
      }
      return;
    }
    if (rows.length === 2) rows.shift();
    const contact_person = selectedFacility?.contact_person?.filter((item) => item.id.includes(rows));

    if (contact_person[0].id === selectedFacility.main_contact_person.id) {
      setOpenMainContactPersonInfo(true);
      if (contact_person[0].email === selectedFacility.billing_email) {
        setOpenInfoText(true);
      }
    } else {
      setOpenMainContactPersonInfo(false);
      setOpenInfoText(false);
    }
    setSelectedContactPerson(contact_person[0]);
  };

  const contact_person_layout = [
    {
      field: "name",
      headerName: "Name",
      flex: 0.33,
      valueGetter: (params) => {
        return `${params.row.first_name}  ${params.row.last_name}`;
      },
    },
    {
      field: "phone_number",
      headerName: "Telefonnummer",
      flex: 0.33,
    },
    {
      field: "email",
      headerName: "Email",
      flex: 0.33,
    },
  ];

  return (
    <>
      <Box sx={{ borderBottom: 1, borderColor: "divider", display: "flex", flex: 1, width: "100%" }}>
        <Tabs
          variant="fullWidth"
          sx={{ width: "100%" }}
          selectionFollowsFocus
          value={selectedTabIndex}
          onChange={(e, selectedTabIndex) => setSelectedTabIndex(selectedTabIndex)}
        >
          <Tab label={ChangesContactPersonType.CHANGECONTACTPERSON} sx={{ textTransform: "none" }} />
          <Tab label={ChangesContactPersonType.ADDCONTACTPERSON} sx={{ textTransform: "none" }} />
          <Tab label={ChangesContactPersonType.REMOVECONTACTPERSON} sx={{ textTransform: "none" }} />
          <Tab label={ChangesContactPersonType.CHANGEBILLADDRESS} sx={{ textTransform: "none" }} />
          <Tab label={ChangesContactPersonType.CHANGEBANKACCOUNTDATA} sx={{ textTransform: "none" }} />
          <Tab label={ChangesContactPersonType.CHANGEEMAILCONTACTPERSON} sx={{ textTransform: "none" }} />
          <Tab label={ChangesContactPersonType.CHANGEBILLINGEMAIL} sx={{ textTransform: "none" }} />
        </Tabs>
      </Box>
      <CustomTabPanel value={selectedTabIndex} index={0}>
        <Card sx={{ p: 3, mt: 2 }}>
          <Divider textAlign="left" sx={{ mt: 2, mb: 2 }}>
            <Typography fontWeight={"bold"}>Hauptansprechpartner</Typography>
          </Divider>
          <CustomDataGrid
            rows={[selectedFacility?.main_contact_person]}
            columns={contact_person_layout}
            checkboxSelection
            disableSelectionOnClick
            selectionModel={selectedContactPerson ? [selectedContactPerson?.id] : []}
          />
          {selectedFacility.main_house && mainContactConnectionsLength > 1 && (
            <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", mt: 2 }}>
              <Typography>
                Diese Kontakt Person ist als Hauptansprechpartner für mehrere weitere Betriebssstätten eingetragen.
                <br />
                Möchten Sie auch für die anderen betroffenen Betriebsstätten in diesem Unternehmen den Ansprechpartner
                wechseln?
              </Typography>
              <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                <Checkbox
                  checked={changeForAllFacilities}
                  onClick={() => setChangeForAllFacilities(!changeForAllFacilities)}
                />
                <Typography>Für Alle Betriebsstätten in diesem Unternehmen wechseln</Typography>
              </Box>
            </Box>
          )}
        </Card>
        <Card sx={{ p: 3, mt: 2 }}>
          <Divider textAlign="left" sx={{ mt: 2, mb: 2 }}>
            <Typography fontWeight={"bold"}>Ansprechpartner</Typography>
          </Divider>

          <CustomDataGrid
            rows={selectedFacility?.contact_person.filter(
              (item) => item.id !== selectedFacility.main_contact_person.id
            )}
            columns={contact_person_layout}
            checkboxSelection
            disableSelectionOnClick
            onSelectionModelChange={(rows) => {
              if (rows.length === 0) {
                if (newMainContactPerson) {
                  setNewMainContactPerson(null);
                }
                return;
              }
              const contact_person = selectedFacility?.contact_person?.filter((item) => item.id.includes(rows));

              setNewMainContactPerson(contact_person[0]);
            }}
          />

          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              mt: 1,
            }}
          >
            <Button
              sx={{ mt: 2, mb: 2, textTransform: "none" }}
              variant="contained"
              disabled={clicked}
              onClick={() => changeMainContactPersonFunction()}
            >
              Ansprechpartner wechseln
            </Button>
          </Box>
        </Card>
      </CustomTabPanel>
      <CustomTabPanel value={selectedTabIndex} index={1}>
        <Card sx={{ p: 3, mt: 2 }}>
          <Divider textAlign="left" sx={{ mt: 2, mb: 2 }}>
            <Typography fontWeight={"bold"}>Daten</Typography>
          </Divider>

          <CustomDataLine titel="Anrede">
            <Select
              sx={{ display: "flex", flex: 0.3 }}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={gender}
              onChange={(e) => setGender(e.target.value)}
              error={errorIndicator && gender == null}
            >
              <MenuItem value={0}>Herr</MenuItem>
              <MenuItem value={1}>Frau</MenuItem>
              <MenuItem value={2}>* (divers)</MenuItem>
            </Select>
          </CustomDataLine>
          <CustomDataLine titel="Vorname" value={firstName} setValue={setFirstName} errorIndicator={errorIndicator} />
          <CustomDataLine titel="Nachname" value={lastName} setValue={setLastName} errorIndicator={errorIndicator} />
          <CustomDataLine
            titel="Telefonnummer"
            value={phoneNumber}
            setValue={setPhoneNumber}
            errorIndicator={errorIndicator}
          />
          <CustomDataLine titel="Faxnummer" value={faxNumber} setValue={setFaxNumber} errorIndicator={errorIndicator} />
          <CustomDataLine titel="Email" value={email} setValue={setEmail} errorIndicator={errorIndicator} />

          <CustomDataLine titel="Anfangsdatum">
            <Box sx={{ display: "flex", flex: 0.3, flexDirection: "column" }}>
              <DatePicker value={date} title="Anfangsdatum" onChange={(newValue) => setDate(newValue)} />
              <Typography>Freilassen für direkten Einsatz </Typography>
            </Box>
          </CustomDataLine>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              mt: 1,
            }}
          >
            <Button
              sx={{ mt: 2, mb: 2 }}
              variant="contained"
              disabled={clicked}
              onClick={() => createNewContactPersonFunction()}
            >
              Zusätzlichen Ansprechpartner anlegen
            </Button>
          </Box>
        </Card>
      </CustomTabPanel>
      <CustomTabPanel value={selectedTabIndex} index={2}>
        <Card sx={{ p: 3, mt: 2 }}>
          <Box sx={{ display: "flex", flexDirection: "column", mt: 2, mb: 2, flex: 1 }}>
            <Divider textAlign="left" sx={{ mt: 2, mb: 2 }}>
              <Typography fontWeight={"bold"}>Ansprechpartner auswählen</Typography>
            </Divider>
            <CustomDataGrid
              rows={selectedFacility?.contact_person.filter(
                (item) => item.id !== selectedFacility.main_contact_person.id
              )}
              columns={[
                ...contact_person_layout,
                {
                  field: "exit_date",
                  headerName: "Wegfall Datum",
                  flex: 0.33,
                  renderCell: (params) => {
                    return <Box>{`${moment(params.row.date).locale("de").format("DD.MM.YYYY")}`}</Box>;
                  },
                },
              ]}
              disableSelectionOnClick
              onSelectionModelChange={(rows) => {
                if (rows.length === 0) {
                  if (contactPersonDelete) {
                    setContactPersonDelete(null);
                  }
                  return;
                }
                const contact_person = selectedFacility?.contact_person?.filter((item) => item.id.includes(rows));
                setContactPersonDelete(contact_person[0]);
              }}
            />
          </Box>
          {contactPersonDelete?.isFacilityMain?.length > 0 && (
            <Box
              sx={{ display: "flex", flexDirection: "column", mt: 2, mb: 2, flex: 1, border: 1, borderColor: "red" }}
            >
              <Typography sx={{ my: 2 }}>
                Der ausgewählte Ansprechpartner ist Hauptansprechpartner für folgende Betriebsstätten:
              </Typography>
              {contactPersonDelete?.isFacilityMain?.map((item) => {
                return <Typography>{item.name}</Typography>;
              })}
              <Typography sx={{ mt: 2 }}>
                Bitte änderns Sie den Status des Ansprechpartners bei diesen Betriebsstätten, da Ansprechpartner solange
                sie Hauptansprechpartner einer Betriebsstätte sind nicht entfernt werden dürfen.
              </Typography>
            </Box>
          )}

          {/* <Box sx={{ display: "flex", flexDirection: "row", mt: 2, mb: 2, flex: 1 }}>
            <LabelName titel="Wegfalldatum" />
            <Box>
              <DatePicker value={date} title="Wegfalldatum" onChange={(newValue) => setDate(newValue)} />
              <Typography>Freilassen für direkten Wegfall </Typography>
            </Box>
          </Box> */}

          <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
            <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
              <InfoOutlined sx={{ color: "primary.main", fontSize: 30, mr: 2 }} />
              <Typography>Es können nur Ansprechpartner wegfallen, welche keine Hauptansprechpartner sind.</Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                mt: 1,
              }}
            >
              <Button
                sx={{ mt: 2, mb: 2 }}
                variant="contained"
                disabled={clicked || contactPersonDelete?.isFacilityMain?.length > 0}
                onClick={() => sendRemoveContactPerson()}
              >
                Wegfall bestätigen
              </Button>
            </Box>
          </Box>
        </Card>
      </CustomTabPanel>
      <CustomTabPanel value={selectedTabIndex} index={3}>
        <Card sx={{ p: 3, mt: 2 }}>
          <Box sx={{ display: "flex", flexDirection: "row", mt: 2, flex: 1 }}>
            <Typography variant="body2">Bitte teilen Sie uns Ihre neue Rechnungsanschrift mit.</Typography>
          </Box>

          <CustomDataLine
            titel="Straße"
            value={street}
            placeholder={selectedFacility?.billing_street || ""}
            setValue={setStreet}
            errorIndicator={errorIndicator}
          />
          <CustomDataLine
            titel="Hausnummer"
            value={streetNumber}
            placeholder={selectedFacility?.billing_street_number || ""}
            setValue={setStreetNumber}
            errorIndicator={errorIndicator}
          />
          <CustomDataLine
            titel="Postleitzahl"
            value={postCode}
            placeholder={selectedFacility?.billing_postcode || ""}
            setValue={setPostCode}
            errorIndicator={errorIndicator}
          />
          <CustomDataLine
            titel="Stadt"
            value={city}
            placeholder={selectedFacility?.billing_city || ""}
            setValue={setCity}
            errorIndicator={errorIndicator}
          />
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              mt: 1,
            }}
          >
            <Button
              sx={{ mt: 2, mb: 2 }}
              variant="contained"
              disabled={clicked}
              onClick={() => changeFacilityBillingAddress()}
            >
              Neue Anschrift absenden
            </Button>
          </Box>
        </Card>
      </CustomTabPanel>
      <CustomTabPanel value={selectedTabIndex} index={4}>
        <Card sx={{ p: 3, mt: 2 }}>
          <Box sx={{ display: "flex", flexDirection: "row", mt: 2, flex: 1 }}>
            <Typography variant="body2">Bitte teilen Sie uns Ihre neue Rechnungsanschrift mit.</Typography>
          </Box>
          <Divider textAlign="left" sx={{ mt: 2, mb: 2 }}>
            <Typography fontWeight={"bold"}>Kontodaten:</Typography>
          </Divider>

          <CustomDataLine
            titel="Name des Kreditinstitutes (Optional)"
            value={creditInstituteName}
            placeholder={selectedFacility?.creditInstituteName || "Name Ihres Kreditinstituts"}
            setValue={setCreditInstituteName}
            errorIndicator={errorIndicator}
          />

          <CustomDataLine titel="IBAN">
            <TextField
              sx={{ display: "flex", flex: 0.3, my: 1 }}
              value={IBAN}
              placeholder={selectedFacility?.IBAN || ""}
              onChange={(event) => {
                const inputValue = event.target.value;
                const trimmedValue = inputValue.replace(/\s+/g, "").trim();
                setIBAN(inputValue.toUpperCase());

                // Validate the IBAN against the regex pattern
                setIBANError(!isValidIBAN(trimmedValue));
              }}
              error={IBANError}
              helperText={IBANError ? "Bitte geben Sie eine gültige IBAN ein" : ""}
              InputProps={{
                inputProps: {
                  pattern: ibanRegex, // Assign the regex pattern
                },
              }}
            />
          </CustomDataLine>

          <CustomDataLine titel="BIC">
            <TextField
              sx={{ display: "flex", flex: 0.3 }}
              value={BIC}
              placeholder={selectedFacility?.BIC || ""}
              onChange={(event) => {
                const inputValue = event.target.value;
                const trimmedValue = inputValue.replace(/\s+/g, "").trim();
                setBIC(inputValue.toUpperCase());

                // Validate the BIC against the regex pattern
                setBICError(!isValidBIC(trimmedValue));
              }}
              error={BICError}
              helperText={BICError ? "Bitte geben Sie eine gültige BIC ein" : ""}
              InputProps={{
                inputProps: {
                  pattern: bicRegex, // Assign the regex pattern
                },
              }}
            />
          </CustomDataLine>

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              mt: 1,
            }}
          >
            <Button sx={{ mt: 3, mb: 2 }} variant="contained" disabled={clicked} onClick={() => changeBankData()}>
              Kontodaten ändern
            </Button>
          </Box>
        </Card>
      </CustomTabPanel>
      <CustomTabPanel value={selectedTabIndex} index={5}>
        <Card sx={{ p: 3, mt: 2 }}>
          <Box sx={{ display: "flex", flexDirection: "row", my: 2, flex: 1, alignItems: "center" }}>
            <Tooltip
              disableFocusListener
              disableTouchListener
              arrow
              title={
                <Typography variant="body1">
                  Bitte beachten Sie! <br />
                  Wenn die ausgewählte Kontaktperson als Hauptansprechpartner für, eine oder mehrere, Betriebsstätten
                  ausgewählt ist, kann dies dazu führen das dadurch die Rechnungs E-Mail Adresse für diese
                  Betriebsstätten geändert wird.
                </Typography>
              }
            >
              <InfoOutlined sx={{ mr: 3, scale: "1.5" }} />
            </Tooltip>
            <Typography variant="body2">Bitte teilen Sie uns Ihre neue E-Mail-Adresse mit.</Typography>
          </Box>

          <CustomDataGrid
            rows={selectedFacility?.contact_person}
            columns={contact_person_layout}
            onSelectionModelChange={(rows) => handleEmailChangeSelect(rows)}
          />

          {openMainContactPersonInfo && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                border: 1,
                p: 1,
                my: 1,
                borderColor: "secondary.main",
              }}
            >
              <InfoOutlined sx={{ mx: 2, scale: "1.5" }} />
              <Typography>
                Bitte beachten Sie! Diese Kontaktperson ist Hauptansprechpartner für eine oder mehrere Betriebsstätten.
                <hr />
                {selectedContactPerson?.isFacilityMain?.map((item) => {
                  return <Typography>{item.name}</Typography>;
                })}
              </Typography>
            </Box>
          )}
          {openInfoText && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                border: 1,
                p: 1,
                my: 1,
                borderColor: "secondary.main",
              }}
            >
              <InfoOutlined sx={{ mx: 2, scale: "2", color: "error.main" }} />

              <Typography>
                Die E-Mail-Adresse dieser Kontaktperson ist als Rechnungsadresse hinterlegt. <br /> Wenn sich die
                E-Mail-Adresse ändert, ändert sich auch die Rechnungs-E-Mail-Adresse für folgende Betriebsstätten:
                <hr />
                {selectedContactPerson?.isFacilityMain?.map((item) => {
                  if (item.billing_email === selectedContactPerson.email) {
                    return <Typography>{item.name}</Typography>;
                  } else return null;
                })}
                <hr />
                Wenn Sie das nicht wollen, ändern Sie die Rechnungs-E-Mail-Adresse für diese Betriebsstätte. <br />
                Das können sie im Reiter "Wechsel Email-Adresse Rechnung" tun.
              </Typography>
            </Box>
          )}
          <Box sx={{ display: "flex", flexDirection: "row", mt: 3, flex: 1 }}>
            <TextField
              sx={{ display: "flex", flex: 1 }}
              variant="outlined"
              placeholder={selectedContactPerson?.email || ""}
              value={email || ""}
              onChange={(e) => setEmail(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Box sx={{ backgroundColor: "primary.main", p: 1, borderRadius: 1, alignContent: "center", mr: 2 }}>
                      <Typography sx={{ color: "white", fontSize: 20 }}>E-Mail-Adresse:</Typography>
                    </Box>
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <Button variant="contained" disabled={clicked} onClick={() => changeContactEmailAddress()}>
                      E-Mail-Adresse wechseln
                    </Button>
                  </InputAdornment>
                ),
              }}
              error={errorIndicator && !email}
              helperText={errorIndicator && !email ? "Bitte geben Sie eine gültige E-Mail-Adresse ein" : ""}
            />
          </Box>
        </Card>
      </CustomTabPanel>

      <CustomTabPanel value={selectedTabIndex} index={6}>
        <Card sx={{ p: 3, mt: 2 }}>
          <Box sx={{ display: "flex", flexDirection: "row", mt: 2, flex: 1 }}>
            <Tooltip
              disableFocusListener
              disableTouchListener
              arrow
              title={
                <Typography variant="body1">
                  Bitte beachten Sie! <br />
                  Wenn die ausgewählte Kontaktperson als Hauptansprechpartner für, eine oder mehrere, Betriebsstätten
                  ausgewählt ist, kann dies dazu führen das dadurch die Rechnungs E-Mail Adresse für diese
                  Betriebsstätten geändert wird.
                </Typography>
              }
            >
              <InfoOutlined sx={{ mr: 3, scale: "1.5" }} />
            </Tooltip>
            <Typography variant="body2">Bitte teilen Sie uns Ihre neue Rechnungs E-Mail-Adresse mit.</Typography>
          </Box>
          {selectedFacility.main_house && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                border: 1,
                p: 1,
                my: 1,
                borderColor: "secondary.main",
              }}
            >
              <Typography>
                <b>
                  <u>Bitte beachten Sie!</u>
                </b>
                <br />
                Sie befinden sich in der Detailansicht des Haupthauses. <br />
                Hier haben Sie die Möglichkeit zur Änderung der Rechnungs-E-Mail-Adresse <br />
                für alle Betriebsstätten mit der gleichen Rechnungs E-Mail-Adresse.
                <hr />
                {/* <b>Betriebsstätten mit der selben Rechnungs E-Mail-Adresse:</b>
                {selectedFacility?.company[0?.map((item) => {
                  return <Typography>{item.name}</Typography>;
                })}
                <hr /> */}
              </Typography>
              <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                <Checkbox
                  checked={changeForAllFacilities}
                  onClick={() => setChangeForAllFacilities(!changeForAllFacilities)}
                />
                <Typography>Für Alle Betriebsstätten in diesem Unternehmen wechseln</Typography>
              </Box>
            </Box>
          )}
          <Box sx={{ display: "flex", flexDirection: "row", mt: 3, flex: 1 }}>
            <TextField
              sx={{ display: "flex", flex: 1 }}
              variant="outlined"
              placeholder={selectedFacility?.billing_email}
              value={email || ""}
              onChange={(e) => setEmail(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Box sx={{ backgroundColor: "primary.main", p: 1, borderRadius: 1, alignContent: "center", mr: 2 }}>
                      <Typography sx={{ color: "white", fontSize: 20 }}>E-Mail-Adresse:</Typography>
                    </Box>
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <Button variant="contained" disabled={clicked} onClick={() => changeBillingEmailAddress()}>
                      E-Mail-Adresse wechseln
                    </Button>
                  </InputAdornment>
                ),
              }}
              error={errorIndicator && !email}
              helperText={errorIndicator && !email ? "Bitte geben Sie eine gültige E-Mail-Adresse ein" : ""}
            />
          </Box>
        </Card>
      </CustomTabPanel>
      <CustomSnackbarAlert open={alertOpen} setOpen={setAlertOpen} severity={alertSeverity} message={alertMessage} />
    </>
  );
};
export default EditContactPersonTab;
