enum AssessmentType {
  FIRSTMONITORING = "FIRSTMONITORING",
  SECONDMONITORING = "SECONDMONITORING",
  MOVE = "MOVE",
  CHANGEOFROOM = "CHANGEOFROOM",
  ADDCONTACTPERSON = "ADDCONTACTPERSON",
  CHANGECONTACTPERSON = "CHANGECONTACTPERSON",
  REMOVECONTACTPERSON = "REMOVECONTACTPERSON",
  CHANGEEMAILCONTACTPERSON = "CHANGEEMAILCONTACTPERSON",
  CHANGEEMAILSPECIALIST = "CHANGEEMAILSPECIALIST",
  CHANGEBILLINGEMAIL = "CHANGEBILLINGEMAIL",
  CHANGEBANKACCOUNTDATA = "CHANGEBANKACCOUNTDATA",
  CHANGEBILLADDRESS = "CHANGEBILLADDRESS",
  CHANGEOFCEO = "CHANGEOFCEO",
  CHANGEOFOWNER = "CHANGEOFOWNER",
  CHANGESPECIALIST = "CHANGESPECIALIST",
  ADDSPECIALIST = "ADDSPECIALIST",
  REMOVESPECIALIST = "REMOVESPECIALIST",
  EXTENDPROVIDINGFIELDS = "EXTENDPROVIDINGFIELDS",
  REMOVEPROVIDINGFIELDS = "REMOVEPROVIDINGFIELDS",
  CHANGECOMPANYNAME = "CHANGECOMPANYNAME",
  CHANGEOFLEGALFORM = "CHANGEOFLEGALFORM",
  OPENINSOLVENCY = "OPENINSOLVENCY",
  CLOSECOMPANY = "CLOSECOMPANY",
}

enum ActionListItemType {
  DEFAULT = "DEFAULT",
  REQUEST = "REQUEST",
  INSPECTION = "INSPECTION",
  DOCUMENTREVIEW = "DOCUMENTREVIEW",
  MONITORING = "MONITORING",
  CHANGES = "CHANGES",
  REMINDER = "REMINDER",
}

enum HistoryType {
  WITHOUTEMAILANDATTACHMENTS = "WITHOUTEMAILANDATTACHMENTS",
  WITHATTACHMENTS = "WITHATTACHMENTS",
  WITHEMAIL = "WITHEMAIL",
  WITHATTACHMENTSANDEMAIL = "WITHATTACHMENTSANDEMAIL",
  WITHXMLFILE = "WITHXMLFILE",
}

enum CollectiveInvoiceType {
  INSPECTION = "INSPECTION",
  BASIC = "BASIC",
  ASSESSMENT = "ASSESSMENT",
}

enum XMLFileType {
  INITIAL = "INITIAL",
  SUSPENSION = "SUSPENSION",
  WITHDRAWAL = "WITHDRAWAL",
  CORRECTION = "CORRECTION",
}

enum XMLNormalisedFileType {
  INITIAL = "Erstmeldung",
  CORRECTION = "Korrektur",
  SUSPENSION = "Aussetzung",
  WITHDRAWAL = "Abmeldung",
}

enum InspectionType {
  INITIAL = "INITIAL",
  CONTINUED = "CONTINUED",
  MONITORING = "MONITORING",
  CHANGERELATED = "CHANGERELATED",
  RISK = "RISK",
}

enum ChangesContactPersonType {
  CHANGECONTACTPERSON = "Wechsel Ansprechpartner/in",
  ADDCONTACTPERSON = "Zustätzlicher Ansprechpartner/in",
  REMOVECONTACTPERSON = "Wegfall Ansprechpartner/in",
  CHANGEEMAILCONTACTPERSON = "Wechsel Email-Adresse Ansprechpartner/in",
  CHANGEBILLINGEMAIL = "Wechsel Email-Adresse Rechnungen",
  CHANGEBILLADDRESS = "Änderung der Rechnungs-Anschrift",
  CHANGEBANKACCOUNTDATA = "Änderung der Konto-Daten",
}

enum ChangesFacilityType {
  CHANGEOFCEO = "Geschäftsführerwechsel",
  CHANGEOFOWNER = "Inhaberwechsel",
  CHANGECOMPANYNAME = "Umfirmierung",
  CHANGEOFLEGALFORM = "Rechtsformänderung",
  OPENINSOLVENCY = "Eröffnung Insolvenzverfahren",
  CLOSECOMPANY = "Schließung der Betriebsstätte",
}

enum StatusType {
  CREATED = "CREATED",
  PENDING = "PENDING",
  COMPLETED = "COMPLETED",
  FAILED = "FAILED",
  CANCELED = "CANCELED",
  IN_PROGRESS = "IN_PROGRESS",
  UNDER_REVIEW = "UNDER_REVIEW",
  REJECTED = "REJECTED",
  ACCEPTED = "ACCEPTED",
  DOCUMENT_REVIEW = "DOCUMENT_REVIEW",
  MONITORING = "MONITORING",
  INSOLVENCY = "INSOLVENCY",
}

export {
  AssessmentType,
  ActionListItemType,
  HistoryType,
  CollectiveInvoiceType,
  XMLFileType,
  InspectionType,
  ChangesContactPersonType,
  ChangesFacilityType,
  StatusType,
  XMLNormalisedFileType,
};
