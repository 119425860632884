import React, { useEffect } from "react";

//mock data import

//MUI Imports
import {
  Typography,
  Card,
  Button,
  Box,
  Divider,
  Checkbox,
  TextField,
  InputAdornment,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
  ListItemText,
} from "@mui/material";
import { GridToolbarQuickFilter } from "@mui/x-data-grid";

//hooks
import { useDispatch, useSelector } from "react-redux";

// mock
import { AccountList, updateAccount } from "api";
import { Check, Close, Mail, Visibility, VisibilityOff } from "@mui/icons-material";
import { CreateAdminAccountModal } from "pages/modals";
import { CustomDataGrid } from "components";

const SVAccountCard = () => {
  const dispatch = useDispatch();

  const [selectedAccount, setSelectedAccount] = React.useState({});

  useEffect(() => {
    dispatch(AccountList());
  }, [dispatch]);

  const account = useSelector((state) => state.accountList);

  const [gender, setGender] = React.useState(0);
  const [first_name, setFirstName] = React.useState("");
  const [last_name, setLastName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [verified, setVerified] = React.useState(false);

  //password
  const [showPassword, setShowPassword] = React.useState(false);
  const [showPassword2, setShowPassword2] = React.useState(false);
  const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
  const [password, setPassword] = React.useState("");
  const [passwordError, setPasswordError] = React.useState(false);
  const [password2, setPassword2] = React.useState("");
  const [password2Error, setPassword2Error] = React.useState(false);
  const [unequalPasswordError, setUnequalPasswordError] = React.useState(false);

  const [post_code, setPostCode] = React.useState("");
  const [postCodeArea, setPostCodeArea] = React.useState("");
  const [scope, setScope] = React.useState([]);

  const [openAdminCreate, setOpenAdminCreate] = React.useState(false);

  const [clicked, setClicked] = React.useState(false);

  const options = [
    { value: "1", label: "Scope 1, Orthopäditechnik" },
    { value: "2", label: "Scope 2, Orth.Schuhtechnik" },
    { value: "3", label: "Scope 3, Hörakustik" },
    { value: "4", label: "Scope 4, Augenoptik" },
    { value: "5", label: "Scope 5, Blindenführhunde" },
    { value: "6.1", label: "Scope 6.1, Apotheke" },
    { value: "6.2", label: "Scope 6.2, Friseur" },
    { value: "6.3", label: "Scope 6.3, Altenheim" },
    { value: "6.4", label: "Scope 6.4, Sonstige" },
  ];

  const handleChange = (event) => {
    setScope(event.target.value); // Update the selected values
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  function updateStates(account) {
    setSelectedAccount(account);
    setGender(account.gender);
    setFirstName(account.firstName);
    setLastName(account.lastName);
    setEmail(account.email);
    setVerified(account.verified);
    setPostCode(account.post_code);
    setPostCodeArea(account.postCodeArea);
    setScope(account?.scope ? account.scope.split(",") : []);
  }

  function update() {
    setClicked(true);
    const accountDetails = {
      gender: gender,
      firstName: first_name,
      lastName: last_name,
      email: email,
      verified: verified,
      post_code: post_code,
      postCodeArea: postCodeArea,
      scope: scope.join(","),
    };

    if (password !== "") {
      if (passwordRegex.test(password) && passwordRegex.test(password2) && !unequalPasswordError) {
        accountDetails.password = password;
      }
    }
    updateAccount(selectedAccount.id, accountDetails);

    setClicked(false);
  }

  function openAdminCreateModal() {
    console.log("openAdminCreateModal");
    setOpenAdminCreate(true);
  }

  return (
    <Box sx={{ my: 1 }}>
      <Card className="account-card" sx={{ display: "flex", flexDirection: "column", p: 2 }}>
        <Divider textAlign="left" sx={{ display: "flex", alignItems: "center", mb: 2 }}>
          <Typography variant="h5">Accounts</Typography>
        </Divider>
        <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
          <CustomDataGrid
            rows={account}
            columns={[
              {
                field: "Name",
                headerName: "Name",
                flex: 1,
                editable: false,
                valueGetter: (params) => `${params.row.firstName || ""} ${params.row.lastName || ""}`,
              },
              {
                field: "email",
                headerName: "Email",
                flex: 1,
                editable: false,
              },
              {
                field: "admin",
                headerName: "Admin",
                flex: 0.5,
                editable: false,
                renderCell: (params) => {
                  if (params.row.admin) {
                    return <Check sx={{ color: "green" }} />;
                  }
                  return <Close sx={{ color: "red" }} />;
                },
              },
              {
                field: "superAdmin",
                headerName: "SuperAdmin",
                flex: 0.5,
                editable: false,
                renderCell: (params) => {
                  if (params.row.superAdmin) {
                    return <Check sx={{ color: "green" }} />;
                  }
                  return <Close sx={{ color: "red" }} />;
                },
              },
              {
                field: "walker",
                headerName: "Begeher",
                flex: 0.5,
                editable: false,
                renderCell: (params) => {
                  if (params.row.walker) {
                    return <Check sx={{ color: "green" }} />;
                  }
                  return <Close sx={{ color: "red" }} />;
                },
              },
            ]}
            initialPageSize={10}
            checkboxSelection
            components={{
              Toolbar: (props) => (
                <>
                  <Box sx={{ display: "flex", flex: 1, p: 1, justifyContent: "space-between", alignItems: "center" }}>
                    <GridToolbarQuickFilter showQuickFilter {...props.toolbar} />
                    <Button variant="contained" onClick={() => openAdminCreateModal()}>
                      Account erstellen
                    </Button>
                  </Box>
                  <Divider sx={{ mt: 1, mb: 1 }} />
                </>
              ),
            }}
            componentsProps={{
              toolbar: {
                showQuickFilter: true,
              },
            }}
            onSelectionModelChange={(rows) => {
              console.log(rows);
              if (rows.length === 0) {
                if (selectedAccount) {
                  updateStates(null);
                }
                return;
              }
              const actionListObject = account?.find((item) => item.id === rows[0]);
              updateStates(actionListObject);
              setSelectedAccount(actionListObject);
            }}
          />
          {selectedAccount && (
            <>
              <Divider />
              <Box
                sx={{
                  mt: 5,
                  width: "100%",
                  display: "flex",
                  flex: 1,
                  flexDirection: "column",
                  alignContent: "flex-start",
                }}
              >
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Anrede</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={gender}
                    label="Age"
                    onChange={(e) => setGender(e.target.value)}
                  >
                    <MenuItem value={0}>Herr</MenuItem>
                    <MenuItem value={1}>Frau</MenuItem>
                    <MenuItem value={2}>* (divers)</MenuItem>
                  </Select>
                </FormControl>
                <Box sx={{ display: "flex", flexDirection: "row", flex: 1, justifyContent: "space-between" }}>
                  <Box sx={{ flex: 0.5, mt: 2 }}>
                    <TextField
                      sx={{ width: "80%" }}
                      label="Vorname"
                      value={first_name}
                      onChange={(e) => setFirstName(e.target.value)}
                    />
                  </Box>
                  <Box sx={{ flex: 0.5, mt: 2 }}>
                    <TextField
                      sx={{ width: "80%" }}
                      label="Nachname"
                      value={last_name}
                      onChange={(e) => setLastName(e.target.value)}
                    />
                  </Box>
                </Box>
                <TextField
                  sx={{ mt: 2 }}
                  label="E-Mail-Adresse / Benutzername"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Mail />
                      </InputAdornment>
                    ),
                  }}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                  <Checkbox checked={verified} onChange={() => setVerified(!verified)} />
                  <Typography>Email Validiert</Typography>
                </Box>
              </Box>
              <Box sx={{ display: "flex", flexDirection: "column", mt: 5 }}>
                <Typography>NUR AUSFÜLLEN WENN PASSWORD GEÄNDERT WERDEN SOLL!!</Typography>
                <TextField
                  label="Password"
                  type={showPassword ? "text" : "password"}
                  value={password}
                  onChange={(event) => {
                    const inputValue = event.target.value;
                    setPassword(inputValue);

                    // Validate the password against the regex pattern
                    setPasswordError(!passwordRegex.test(inputValue));
                    setUnequalPasswordError(inputValue !== password2);
                  }}
                  error={passwordError}
                  helperText={
                    passwordError
                      ? "Password must contain at least 8 characters, one uppercase, one lowercase, one number, and one special character"
                      : unequalPasswordError
                      ? "Die Passwörter sind unterschiedlich"
                      : ""
                  }
                  InputProps={{
                    inputProps: {
                      pattern: passwordRegex, // Assign the regex pattern
                    },
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setShowPassword(!showPassword)}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <TextField
                  label="Password"
                  type={showPassword2 ? "text" : "password"}
                  value={password2}
                  sx={{ mt: 3 }}
                  onChange={(event) => {
                    const inputValue = event.target.value;
                    setPassword2(inputValue);
                    // Validate the password against the regex pattern
                    setPassword2Error(!passwordRegex.test(inputValue));
                    setUnequalPasswordError(password !== inputValue);
                  }}
                  error={password2Error}
                  helperText={
                    password2Error
                      ? "Password must contain at least 8 characters, one uppercase, one lowercase, one number, and one special character"
                      : unequalPasswordError
                      ? "Die Passwörter sind unterschiedlich"
                      : ""
                  }
                  InputProps={{
                    inputProps: {
                      pattern: passwordRegex, // Assign the regex pattern
                    },
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setShowPassword2(!showPassword2)}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword2 ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
              {selectedAccount.walker && (
                <Box sx={{ display: "flex", flexDirection: "column", mt: 5 }}>
                  <TextField
                    sx={{ mt: 2, display: "flex", flex: 0.3 }}
                    label="Postleitzahl"
                    value={post_code}
                    onChange={(e) => setPostCode(e.target.value)}
                    helperText="Die Postleitzahl wo der Begeher wohnt."
                  />
                  <TextField
                    sx={{ mt: 2, display: "flex", flex: 0.3 }}
                    label="Postleitzahl Gebiete"
                    value={postCodeArea}
                    onChange={(e) => setPostCodeArea(e.target.value)}
                    helperText="Bitte geben sie die ersten Zwei Zahlen der Postleitzahlgebiete getrennt durch ein Komma ein. z.B 01,02,03 "
                  />
                  <Select
                    multiple
                    value={scope}
                    onChange={handleChange}
                    renderValue={(selected) => selected.join(", ")} // Render selected values as a comma-separated string
                  >
                    {options.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        <Checkbox checked={scope.indexOf(option.value) > -1} /> {/* Checkbox to show selected state */}
                        <ListItemText primary={option.label} />
                      </MenuItem>
                    ))}
                  </Select>
                </Box>
              )}
            </>
          )}
          <Button variant="contained" disabled={clicked} onClick={() => update()}>
            Update
          </Button>
        </Box>
      </Card>
      <CreateAdminAccountModal isOpen={openAdminCreate} onRequestClose={() => setOpenAdminCreate(false)} />
    </Box>
  );
};

export default SVAccountCard;
