import { createSlice } from "@reduxjs/toolkit";

const historySlice = createSlice({
  name: "history",
  initialState: [],
  reducers: {
    historyAdded(state, action) {
      if (action.payload.length === 0) {
        return (state = []);
      } else return action.payload;
    },
    historyAddedSingle(state, action) {
      console.log("payload", action.payload);
      if (action.payload.length === 0) {
        return state; // Return the current state unchanged if the payload is empty
      } else {
        return [action.payload, ...state]; // Adds the new object to the existing state array
      }
    },
    historyStatusUpdateAction(state, action) {
      const historyId = action.payload.historyId;
      const value = action.payload.value;
      const historyIndex = state.findIndex((item) => item.id === historyId);
      state[historyIndex].status = value;
    },
    historyObjectUpdate(state, action) {
      const historyId = action.payload.historyId;
      const value = action.payload.value;
      const historyIndex = state.findIndex((item) => item.id === historyId);
      state[historyIndex] = value;
    },
    historyFileAdded(state, action) {
      const file = action.payload.response;
      const historyId = action.payload.historyId;

      const inspIndex = state.findIndex((item) => item.id === historyId);
      state[inspIndex].images.length === 0 ? (state[inspIndex].images = [file]) : state[inspIndex].images.push(file);
    },
    historyFileDelete(state, action) {
      const file = action.payload.file;
      const historyId = action.payload.historyId;

      const inspIndex = state.findIndex((item) => item.id === historyId);
      state[inspIndex].images = state[inspIndex].images.filter((image) => image.id !== file.id);
    },
    logoutHistory(state, action) {
      return historySlice.getInitialState();
    },
  },
});

export const {
  historyAdded,
  historyAddedSingle,
  historyStatusUpdateAction,
  historyObjectUpdate,
  historyFileAdded,
  historyFileDelete,
  logoutHistory,
} = historySlice.actions;
export default historySlice.reducer;
