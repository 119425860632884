import React from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";

import { AssessmentType, InspectionType } from "assets/type.ts";
//mui import
import { Typography, TextField, Box, Select, MenuItem, Divider } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";

import { CreateInspection, getActionList, GetInspectionRelevantProvidingFields } from "api";
import { CModal, LabelName, CustomDataGrid, CustomLoadingIndicator } from "components";

const WalkerRequest = ({
  isOpen,
  onRequestClose,
  selectedPrequalification,
  selectedFacility,
  selectedAssessment,
  selectedActionListItem,
}) => {
  const dispatch = useDispatch();

  const [reason, setReason] = React.useState(
    selectedAssessment !== undefined
      ? selectedAssessment.type === AssessmentType.MOVE
        ? InspectionType.CHANGERELATED
        : InspectionType.MONITORING
      : InspectionType.INITIAL
  );
  const [comment, setComment] = React.useState(""); //Wunschtermine
  const [startDate, setStartDate] = React.useState(moment());
  const [endDate, setEndDate] = React.useState(moment().add(14, "d"));
  const [extra_walker_name, setExtraWalkerName] = React.useState("");
  const [providingFields, setProvidingFields] = React.useState([]);
  // const [inspectionRelevantFields, setInspectionRelevantFields] = React.useState([]);
  // const [extendet, setExtendet] = React.useState(false);
  const assessmentComment = selectedAssessment !== undefined ? JSON.parse(selectedAssessment?.comment[0]?.text) : "";

  const [selectedWalker, setSelectedWalker] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  // console.log("selectedPrequalification", selectedPrequalification);
  // console.log("comment", assessmentComment);

  // const viewInspectionRelevantFields = async () => {
  //   console.log(selectedPrequalification);
  //   const prequalification = await getSpecificPrequalificationItems(selectedPrequalification?.id);
  //   console.log("prequalification", prequalification);
  // };

  React.useEffect(() => {
    async function fetchData() {
      const response = await GetInspectionRelevantProvidingFields(
        selectedActionListItem?.id ? selectedActionListItem?.id : selectedPrequalification?.id
      );
      console.log("response", response);
      setProvidingFields(response);
    }
    fetchData();
  }, [isOpen, selectedActionListItem?.id, selectedPrequalification?.id]);

  const walker = useSelector((state) => {
    const walkers = state.walker;
    const filteredWalkers = walkers.filter((walker) => {
      const postCodeAreas = walker?.postCodeArea?.split(",") || [];
      const scope = walker?.scope?.split(",") || [];

      const isPostcodeMatch =
        postCodeAreas.includes("all") || postCodeAreas.includes(selectedFacility?.postcode.substring(0, 2));
      const isScopeMatch = scope.includes("all") || scope.includes(selectedFacility?.scope);

      return isPostcodeMatch && isScopeMatch ? walker : null;
    });
    return filteredWalkers;
  });

  const walkerColumns = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      editable: false,
      valueGetter: (params) => `${params.row.firstName || ""} ${params.row.lastName || ""}`,
    },
    {
      field: "favorite",
      headerName: "Favorisiert",
      flex: 0.5,
      editable: false,
      valueGetter: (params) => `${"Schauen wir mal"}`,
    },
    {
      field: "post_code",
      headerName: "Postleitzahl",
      flex: 1,
      editable: false,
    },
  ];

  return (
    <CModal
      modalLabel="Begehung"
      open={isOpen}
      onRequestClose={onRequestClose}
      buttonLabel="Begeher beauftragen"
      backButtonLabel="abbrechen"
      onButtonPressed={() => {
        const details = {
          reason: reason,
          comment: comment,
          startDate: startDate ? moment(startDate).format("YYYY-MM-DD") : null,
          endDate: endDate ? moment(endDate).format("YYYY-MM-DD") : null,
          walkerId: selectedWalker.id,
          preqId: selectedAssessment !== undefined ? selectedAssessment?.id : selectedPrequalification?.id,
          extra_walker_name: extra_walker_name,
        };
        setLoading(true);
        dispatch(CreateInspection(details));
        dispatch(getActionList());
        setLoading(false);
        onRequestClose();
      }}
      onBackButtonPressed={() => onRequestClose()}
    >
      <Box sx={{ p: 2 }}>
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <LabelName titel="Grund" />
          <Box sx={{ flex: 0.8 }}>
            <Select
              value={reason}
              onChange={(e) => {
                console.log(e.target.value);
                setReason(e.target.value);
              }}
            >
              <MenuItem value={InspectionType.INITIAL}>Initiale PQ</MenuItem>
              <MenuItem value={InspectionType.CONTINUED}>Folge PQ</MenuItem>
              <MenuItem value={InspectionType.MONITORING}>Überwachung</MenuItem>
              <MenuItem value={InspectionType.CHANGERELATED}>Änderungsbezogen</MenuItem>
              <MenuItem value={InspectionType.RISK}>Risikobegehung</MenuItem>
            </Select>
          </Box>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <LabelName titel="Anmerkungen zum Zeitpunkt" />
          <TextField
            sx={{ flex: 0.8, mt: 2 }}
            id="outlined-multiline-static"
            label="Begründung"
            multiline
            rows={2}
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            variant="outlined"
          />
        </Box>
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <LabelName titel="Anmerkungen zum Zeitpunkt" />
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              flex: 0.8,
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <DatePicker
              sx={{ mr: 2 }}
              value={startDate}
              title="Startdatum"
              onChange={(newValue) => setStartDate(newValue)}
            />
            <Typography> bis </Typography>
            <DatePicker sx={{ ml: 2 }} value={endDate} title="EndDatum" onChange={(newValue) => setEndDate(newValue)} />
          </Box>
        </Box>
        <Divider sx={{ margin: 2 }} />
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <LabelName titel="Unternehmen" />
          <TextField
            sx={{ flex: 0.8, mt: 2 }}
            id={`outlined-multiline-${selectedFacility?.name}`}
            value={selectedFacility?.name}
            variant="outlined"
          />
        </Box>
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <LabelName titel="Stadt" />
          <TextField
            sx={{ flex: 0.8, mt: 2 }}
            id={`outlined-multiline-${selectedFacility?.city}`}
            value={`${selectedFacility?.city} `}
            variant="outlined"
          />
        </Box>
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <LabelName titel="Postleitzahl" />
          <TextField
            sx={{ flex: 0.8, mt: 2 }}
            id={`outlined-multiline-${selectedFacility?.postcode}`}
            value={`${selectedFacility?.postcode} `}
            variant="outlined"
          />
        </Box>
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <LabelName titel="Adresse" />
          <TextField
            sx={{ flex: 0.8, mt: 2 }}
            id={`outlined-multiline-${selectedFacility?.street}`}
            value={`${selectedFacility?.street}, ${selectedFacility?.street_number}  `}
            variant="outlined"
          />
        </Box>
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <LabelName titel="Scope" />
          <TextField
            sx={{ flex: 0.8, mt: 2 }}
            id={`outlined-multiline-${selectedFacility?.scope}`}
            value={selectedFacility?.scope}
            variant="outlined"
          />
        </Box>
        {reason === 4 && (
          <>
            <Divider textAlign="left" sx={{ my: 4 }}>
              <Typography fontWeight={"bold"}>Neue Addresse</Typography>
            </Divider>
            <Box sx={{ display: "flex", flexDirection: "row" }}>
              <LabelName titel="Stadt" />
              <TextField
                sx={{ flex: 0.8, mt: 2 }}
                id={`outlined-multiline-${selectedFacility?.city}-2`}
                value={`${assessmentComment?.city} `}
                variant="outlined"
              />
            </Box>
            <Box sx={{ display: "flex", flexDirection: "row" }}>
              <LabelName titel="Postleitzahl" />
              <TextField
                sx={{ flex: 0.8, mt: 2 }}
                id={`outlined-multiline-${selectedFacility?.postcode}-2`}
                value={`${assessmentComment?.post_code} `}
                variant="outlined"
              />
            </Box>
            <Box sx={{ display: "flex", flexDirection: "row" }}>
              <LabelName titel="Adresse" />
              <TextField
                sx={{ flex: 0.8, mt: 2 }}
                id={`outlined-multiline-${selectedFacility?.street}-2`}
                value={`${assessmentComment?.street}, ${assessmentComment?.street_number}`}
                variant="outlined"
              />
            </Box>
          </>
        )}
        <Divider sx={{ margin: 2 }} />
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <LabelName titel="Begeher" />
          <CustomDataGrid
            rows={walker || []}
            columns={walkerColumns}
            checkboxSelection
            disableSelectionOnClick
            onSelectionModelChange={(rows) => {
              if (rows.length === 0) {
                if (selectedWalker) {
                  setSelectedWalker([]);
                }
                return;
              }
              const selected = walker.filter((item) => item.id === rows[0]);
              setSelectedWalker(selected[0]);
            }}
          />
        </Box>
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <LabelName titel="Mitbegeher" />
          <TextField
            sx={{ flex: 0.8, mt: 2 }}
            id={`outlined-multiline-byWalker`}
            value={extra_walker_name}
            onChange={(e) => setExtraWalkerName(e.target.value)}
            variant="outlined"
          />
        </Box>
        {providingFields?.length !== 0 && (
          <CustomDataGrid
            rows={providingFields}
            columns={[
              {
                field: "code",
                headerName: "Versorgungsbereich",
                flex: 0.25,
                editable: false,
              },
              {
                field: "description",
                headerName: "Beschreibung",
                flex: 0.75,
                editable: false,
              },
            ]}
            disableSelectionOnClick
            experimentalFeatures={{ newEditingApi: true }}
            initialState={{
              sorting: {
                sortModel: [{ field: "code", sort: "asc" }],
              },
            }}
          />
        )}
        {/* <Box>
          <Checkbox checked={extendet} onClick={() => viewInspectionRelevantFields()} />
        </Box> */}
      </Box>
      <CustomLoadingIndicator open={loading} />
    </CModal>
  );
};

//

export default WalkerRequest;
