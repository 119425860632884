import { createSlice } from "@reduxjs/toolkit";

const accountListSlice = createSlice({
  name: "accountList",
  initialState: [],
  reducers: {
    accountListAdded(state, action) {
      return action.payload;
    },
    logoutAccountList(state, action) {
      return accountListSlice.getInitialState();
    },
  },
});

export const { accountListAdded, logoutAccountList } = accountListSlice.actions;
export default accountListSlice.reducer;
