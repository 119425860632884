import React from "react";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  Collapse,
  Divider,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import ShowStatus from "./showStatus";
import { UnfoldMore, Tab } from "@mui/icons-material";
import { Link } from "react-router-dom";
import DisplayFiles from "./DisplayFiles";
// import { changeImageCommentReadableStatus } from "../api/prequalificationItems";
import { useDispatch } from "react-redux";
import { moveImageToPreqItem, updateCurrentFileName, updateItemImage } from "../api";
import { StatusType } from "../assets/type.ts";

const CollapsableImageCard = ({ item, prequalification, preqItemIndex }) => {
  const dispatch = useDispatch();
  const [clicked, setClicked] = React.useState(false);
  const [currentFileName, setCurrentFileName] = React.useState("");
  const [moveDestination, setMoveDestination] = React.useState(null);
  const [open, setOpen] = React.useState(false);

  // const updateImageCommentReadableStatus = (imageId, commentId, readable) => {
  //   setClicked(true);
  //   dispatch(changeImageCommentReadableStatus(imageId, commentId, readable)).then(() => {
  //     setClicked(false);
  //     setComment([]);
  //     setVisible(false);
  //   });
  // };

  // const sendImageComment = (imageId) => {
  //   setClicked(true);
  //   const selectedComment = comment.findIndex((x) => x.id === imageId);
  //   dispatch(createPreqItemImageComment(comment[selectedComment]?.text, visible, imageId)).then(() => {
  //     setClicked(false);
  //     setComment([]);
  //     setVisible(false);
  //   });
  // };

  const handleStatusUpdateImage = (value, imageId) => {
    setClicked(true);
    dispatch(updateItemImage(undefined, value === true ? StatusType.ACCEPTED : StatusType.REJECTED, imageId)).then(() =>
      setClicked(false)
    );
  };

  const handleImageMove = (image, preqItem) => {
    setClicked(true);
    dispatch(moveImageToPreqItem(image.id, moveDestination.id, preqItem.id, image.prequalificationId)).then(() =>
      setClicked(false)
    );
  };

  const updateFileName = (id) => {
    if (currentFileName === "") return;
    dispatch(updateCurrentFileName(id, currentFileName));
  };

  const itemDate = new Intl.DateTimeFormat("de-DE", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  }).format(new Date(item.date));

  return (
    <Card
      sx={{
        display: "flex",
        flex: 1,
        flexDirection: "column",
        boxShadow: 2,
        p: 2,
        mb: 1,
      }}
    >
      <Box sx={{ display: "flex", flex: 1, flexDirection: "row" }}>
        <TextField
          sx={{ flex: 0.8, ml: 2 }}
          value={currentFileName === "" ? item.imageBaseName : currentFileName}
          onChange={(e) => setCurrentFileName(e.target.value)}
          helperText={itemDate}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Box sx={{ backgroundColor: "primary.main", p: 1, borderRadius: 1 }}>
                  <Typography sx={{ color: "white", fontSize: 20 }}>Datei Name:</Typography>
                </Box>
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <Button variant="contained" aria-label="update Image Name" onClick={() => updateFileName(item.id)}>
                  Update
                </Button>
              </InputAdornment>
            ),
          }}
        />
        <Box sx={{ display: "flex", flex: 0.25, ml: 2, justifyContent: "flex-end" }}>
          <ShowStatus status={item.status} />
        </Box>
        <Box>
          <UnfoldMore sx={{ fontSize: 60, ml: 3 }} onClick={() => setOpen(!open)} />
          <Link
            target={"_blank"}
            to={"/showFile"}
            onClick={() => {
              localStorage.removeItem("showFile");
              localStorage.setItem("showFile", JSON.stringify(item));
            }}
          >
            <Tab sx={{ fontSize: 60 }} />
          </Link>
        </Box>
      </Box>
      {/* <Typography sx={{ ml: 2, mt: 1 }}>
        Hochgeladen am:
        {new Date(item.date)
          .toLocaleString("de-DE", { timeZone: "UTC", hour12: false })
          .replace(/\//g, ".")
          .replace(", ", " ")}
      </Typography> */}

      <Collapse in={open} key={item.id} unmountOnExit>
        <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
          <DisplayFiles file={item} />
          <Divider textAlign="left" sx={{ mb: 1 }}>
            <Typography fontWeight={"bold"}>Verschieben</Typography>
          </Divider>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              my: 1,
            }}
          >
            <Autocomplete
              sx={{ display: "flex", flex: 1, mr: 2 }}
              disablePortal
              getOptionLabel={(option) => option.description}
              options={prequalification?.prequalificationItem}
              value={moveDestination}
              onChange={(event, newValue) => {
                setMoveDestination(newValue);
              }}
              renderInput={(params) => <TextField {...params} label="Vorraussetzungen" />}
            />
            <Button
              sx={{ backgroundColor: "info.main", color: "white", m: 1 }}
              variant={"outlined"}
              disabled={clicked}
              onClick={() => handleImageMove(item, prequalification?.prequalificationItem[preqItemIndex])} // LOGIC!
            >
              Verschieben
            </Button>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignContent: "center",
              my: 2,
            }}
          >
            <Button
              sx={{ backgroundColor: "success.main", color: "white", mr: 3 }}
              variant={"outlined"}
              disabled={clicked}
              onClick={() => handleStatusUpdateImage(true, item.id)} // LOGIC!
            >
              Akzeptiert
            </Button>

            <Button
              sx={{ backgroundColor: "error.main", color: "white" }}
              variant={"outlined"}
              disabled={clicked}
              onClick={() => handleStatusUpdateImage(false, item.id)} // LOGIC!
            >
              Abgelehnt
            </Button>
          </Box>
        </Box>
      </Collapse>
    </Card>
  );
};
export default CollapsableImageCard;
