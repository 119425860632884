import { createSlice } from "@reduxjs/toolkit";

const inspectionSlice = createSlice({
  name: "inspection",
  initialState: [],
  reducers: {
    inspectionAdded(state, action) {
      return action.payload;
    },
    inspectionAccepted(state, action) {
      const inspection = action.payload;
      const specificInspectionIndex = state.findIndex((item) => item.id === inspection.id);
      state[specificInspectionIndex] = inspection;
    },
    inspectionUploadFile(state, action) {
      const inspection = action.payload;
      const specificInspectionIndex = state.findIndex((item) => item.id === inspection.id);
      state[specificInspectionIndex] = inspection;
    },
    inspectionFileAdd(state, action) {
      const file = action.payload.response;
      const inspectionId = action.payload.inspectionId;

      const inspIndex = state.findIndex((item) => item.id === inspectionId);
      state[inspIndex].images.length === 0 ? (state[inspIndex].images = [file]) : state[inspIndex].images.push(file);
    },
    inspectionFileDelete(state, action) {
      const file = action.payload.file;
      const inspectionId = action.payload.inspectionId;

      const inspIndex = state.findIndex((item) => item.id === inspectionId);
      state[inspIndex].images = state[inspIndex].images.filter((image) => image.id !== file.id);
    },
    logoutInspection(state, action) {
      return inspectionSlice.getInitialState();
    },
  },
});

export const {
  inspectionAdded,
  inspectionAccepted,
  inspectionUploadFile,
  inspectionFileAdd,
  inspectionFileDelete,
  logoutInspection,
} = inspectionSlice.actions;
export default inspectionSlice.reducer;
