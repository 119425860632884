import React from "react";

import { FormControl, Box, Select, MenuItem, InputLabel } from "@mui/material";
import { CustomTabPanel } from "components";

import EditSpecialistTab from "./tabs/editSpecialistTab";
import EditProvidingFieldsTab from "./tabs/editProvidingFieldsTab";
import EditFacilityTab from "./tabs/editFacilityTab";
import EditRoomsTab from "./tabs/editRoomsTab";
import EditContactPersonTab from "./tabs/editContactPersonTab";

const ChangesTab = ({ selectedFacility, setSelectedFacility, setSelectedTabIndex }) => {
  const [changeIndex, setChangeIndex] = React.useState(0);

  //modal States

  return (
    <Box sx={{ mt: 2 }}>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">Age</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={changeIndex}
          onChange={(e) => {
            setChangeIndex(e.target.value);
          }}
        >
          <MenuItem value={0}>Fachliche Leitung</MenuItem>
          <MenuItem value={1}>Versorgungsbereiche</MenuItem>
          <MenuItem value={2}>Unternehmensbezogen</MenuItem>
          <MenuItem value={3}>Räumlichkeiten</MenuItem>
          <MenuItem value={4}>Ansprechpartner</MenuItem>
          {/* <MenuItem value={5}>Stellvertreter</MenuItem> */}
        </Select>
      </FormControl>
      <CustomTabPanel value={changeIndex} index={0}>
        <EditSpecialistTab selectedFacility={selectedFacility} setSelectedTabIndex={setSelectedTabIndex} />
      </CustomTabPanel>
      <CustomTabPanel value={changeIndex} index={1}>
        <EditProvidingFieldsTab selectedFacility={selectedFacility} setSelectedTabIndex={setSelectedTabIndex} />
      </CustomTabPanel>
      <CustomTabPanel value={changeIndex} index={2}>
        <EditFacilityTab selectedFacility={selectedFacility} setSelectedTabIndex={setSelectedTabIndex} />
      </CustomTabPanel>
      <CustomTabPanel value={changeIndex} index={3}>
        <EditRoomsTab selectedFacility={selectedFacility} setSelectedTabIndex={setSelectedTabIndex} />
      </CustomTabPanel>
      <CustomTabPanel value={changeIndex} index={4}>
        <EditContactPersonTab
          selectedFacility={selectedFacility}
          setSelectedFacility={setSelectedFacility}
          setSelectedTabIndex={setSelectedTabIndex}
        />
      </CustomTabPanel>
      {/* <CustomTabPanel value={changeIndex} index={5}>
        <EditSubstituteTab
          selectedFacility={selectedFacility}
          setSelectedFacility={setSelectedFacility}
          setSelectedTabIndex={setSelectedTabIndex}
        /> 
      </CustomTabPanel>*/}
    </Box>
  );
};

export default ChangesTab;
