import React, { useState } from "react";

const DisplayPDF = ({ blob }) => {
  const [pdfUrl, setPdfUrl] = useState(null);

  // Load the PDF URL when the component mounts
  React.useEffect(() => {
    if (blob) {
      setPdfUrl(URL.createObjectURL(blob));
    }
    return () => {
      // Clean up the object URL when the component unmounts
      if (pdfUrl) {
        URL.revokeObjectURL(pdfUrl);
      }
    };
  }, [blob, pdfUrl]);

  return (
    <div>
      {pdfUrl ? (
        <object data={pdfUrl} type="application/pdf" width="100%" height="600px">
          <p>PDF could not be displayed.</p>
        </object>
      ) : (
        <p>Loading PDF...</p>
      )}
    </div>
  );
};

export default DisplayPDF;

// const DisplayImage = ({ blob }) => {
//   const [imageUrl, setImageUrl] = useState(null);

//   // Load the image URL when the component mounts
//   React.useEffect(() => {
//     if (blob) {
//       setImageUrl(URL.createObjectURL(blob));
//     }
//     return () => {
//       // Clean up the object URL when the component unmounts
//       if (imageUrl) {
//         URL.revokeObjectURL(imageUrl);
//       }
//     };
//   }, [blob]);

//   return (
//     <div>
//       {imageUrl ? (
//         <img src={imageUrl} alt="Image" />
//       ) : (
//         <p>Loading image...</p>
//       )}
//     </div>
//   );
// };

// export default DisplayImage;
