import React from "react";
import { Typography, Box, Divider, Card } from "@mui/material";

// import { prequalificationColumns } from "../../../layouts/DataGridLayouts";

//pages
import Events from "./events";

//hooks
import { useLocation } from "react-router";
import { useSelector } from "react-redux";
import { LabelName, CustomDataGrid } from "components";
import { StatusType } from "assets/type.ts";

const OverviewTab = () => {
  const location = useLocation();

  const facility = useSelector((state) => state.facility.filter((item) => item.id === location.state.id));

  return (
    <Box>
      <Card sx={{ backgroundColor: "transparent", p: 2, mt: 2 }}>
        <Divider textAlign="left" sx={{ my: 2 }}>
          <Typography fontWeight={"bold"}>Unternehmensdaten</Typography>
        </Divider>
        <Box sx={{ display: "flex", flexDirection: "column", flex: 1 }}>
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <LabelName titel="Unternehmensname" />

            <Card sx={{ flex: 0.75, p: 1 }}>
              <Box sx={{ display: "flex", flexDirection: "row" }}>
                <Typography sx={{ flex: 0.3 }}>Unternehmensname:</Typography>
                <Typography sx={{ flex: 0.7 }}>{`${facility[0]?.name}`}</Typography>
              </Box>
              <Box sx={{ display: "flex", flexDirection: "row" }}>
                <Typography sx={{ flex: 0.3 }}>Straße:</Typography>
                <Typography sx={{ flex: 0.7 }}>{`${facility[0]?.street}, ${facility[0]?.street_number}`}</Typography>
              </Box>
              <Box sx={{ display: "flex", flexDirection: "row" }}>
                <Typography sx={{ flex: 0.3 }}>Ort:</Typography>
                <Typography sx={{ flex: 0.7 }}>{`${facility[0]?.postcode}, ${facility[0]?.city}`}</Typography>
              </Box>
            </Card>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "row", mt: 1 }}>
            <LabelName titel="Kontaktdaten - Betriebsstätte" />
            <Card sx={{ flex: 0.75, p: 1 }}>
              <Box sx={{ display: "flex", flexDirection: "row" }}>
                <Typography sx={{ flex: 0.3 }}>Telefonnummer:</Typography>
                <Typography sx={{ flex: 0.7 }}>{`${facility[0]?.main_contact_person.phone_number}`}</Typography>
              </Box>
              <Box sx={{ display: "flex", flexDirection: "row" }}>
                <Typography sx={{ flex: 0.3 }}>Faxnummer:</Typography>
                <Typography sx={{ flex: 0.7 }}>{`${
                  facility[0]?.main_contact_person.fax_number
                    ? facility[0]?.main_contact_person.fax_number
                    : "Nicht gespeichert"
                }`}</Typography>
              </Box>
              <Box sx={{ display: "flex", flexDirection: "row" }}>
                <Typography sx={{ flex: 0.3 }}>Email:</Typography>
                <Typography sx={{ flex: 0.7 }}>{`${facility[0]?.main_contact_person.email}`}</Typography>
              </Box>
            </Card>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "row", mt: 1 }}>
            <LabelName titel="Rechnungsadresse" />
            <Card sx={{ flex: 0.75, p: 1 }}>
              <Box sx={{ display: "flex", flexDirection: "row" }}>
                <Typography sx={{ flex: 0.3 }}>Email:</Typography>
                <Typography sx={{ flex: 0.7 }}>{`${facility[0]?.billing_email}`}</Typography>
              </Box>
              <Box sx={{ display: "flex", flexDirection: "row" }}>
                <Typography sx={{ flex: 0.3 }}>Straße:</Typography>
                <Typography
                  sx={{ flex: 0.7 }}
                >{`${facility[0]?.billing_street},  ${facility[0]?.billing_street_number}`}</Typography>
              </Box>
              <Box sx={{ display: "flex", flexDirection: "row" }}>
                <Typography sx={{ flex: 0.3 }}>Ort:</Typography>
                <Typography
                  sx={{ flex: 0.7 }}
                >{`${facility[0]?.billing_postcode}, ${facility[0]?.billing_city}`}</Typography>
              </Box>
            </Card>
          </Box>
        </Box>
      </Card>
      <Card sx={{ backgroundColor: "transparent", p: 2, mt: 2 }}>
        <Divider textAlign="left" sx={{ my: 2 }}>
          <Typography fontWeight={"bold"}>Hauptansprechpartner</Typography>
        </Divider>
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <LabelName titel="Kontaktdaten - Hauptansprechpartner" />
          <Card sx={{ flex: 0.75, p: 1 }}>
            <Box sx={{ display: "flex", flexDirection: "row" }}>
              <Typography sx={{ flex: 0.3 }}>Name:</Typography>
              <Typography
                sx={{ flex: 0.7 }}
              >{`${facility[0]?.main_contact_person.first_name} ${facility[0]?.main_contact_person.last_name} `}</Typography>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "row" }}>
              <Typography sx={{ flex: 0.3 }}>Telefonnummer:</Typography>
              <Typography sx={{ flex: 0.7 }}>{`${facility[0]?.main_contact_person.phone_number}`}</Typography>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "row" }}>
              <Typography sx={{ flex: 0.3 }}>Faxnummer:</Typography>
              <Typography sx={{ flex: 0.7 }}>{`${
                facility[0]?.main_contact_person.fax_number
                  ? facility[0]?.main_contact_person.fax_number
                  : "Nicht gespeichert"
              }`}</Typography>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "row" }}>
              <Typography sx={{ flex: 0.3 }}>Email:</Typography>
              <Typography sx={{ flex: 0.7 }}>{`${facility[0]?.main_contact_person.email}`}</Typography>
            </Box>
          </Card>
        </Box>
      </Card>
      <Card sx={{ backgroundColor: "transparent", p: 2, mt: 2 }}>
        <Divider textAlign="left" sx={{ my: 2 }}>
          <Typography fontWeight={"bold"}>Präqualifizierungen</Typography>
        </Divider>
        {facility[0]?.prequalification.length === 0 ? (
          <Box sx={{ p: 2 }}>
            <Typography>Es wurde noch keine Präqualifizierungen beantragt.</Typography>
          </Box>
        ) : (
          <Card>
            <CustomDataGrid
              rows={facility[0]?.prequalification}
              columns={[
                {
                  field: "status",
                  headerName: "Status",
                  flex: 1,
                  editable: false,
                  valueGetter: (params) => {
                    switch (params.row.status) {
                      case StatusType.CREATED:
                        return "in Bearbeitung";
                      case StatusType.PENDING:
                        return "in Prüfung";
                      case StatusType.COMPLETED:
                        return "abgeschlossen";
                      case StatusType.REJECTED:
                        return "abgelehnt";
                      case StatusType.UNDER_REVIEW:
                        return "zur Überprüfung freigegeben";
                      default:
                        return "in Bearbeitung";
                    }
                  },
                },
                {
                  field: "criteriaCatalogVersion",
                  headerName: "Kriterien Katalog Version",
                  flex: 1,
                  editable: false,
                },
              ]}
              initialPageSize={10}
              disableColumnMenu
            />
          </Card>
        )}
      </Card>
      <Events facility={facility} /> {/*Real Data*/}
    </Box>
  );
};

export default OverviewTab;
