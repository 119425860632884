import React, { useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { FirstPage, LastPage } from "@mui/icons-material";
/**
 *
 * @param {Object} process - Process Object, Prequalification or Assessment, needs to have a prequalificationItem array
 * @param {Number} preqItemIndex - Index of the current prequalificationItem
 * @param {React.Dispatch<React.SetStateAction>} setPreqItemIndex - Function to set the preqItemIndex
 * @param {React.Dispatch<React.SetStateAction>} setComment - Function to set the commen
 * @returns {JSX.Element} - Custom Page Slider
 */
const CustomPageSlider = ({ process, preqItemIndex, setPreqItemIndex, setComment }) => {
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "ArrowLeft" && process?.prequalificationItem[preqItemIndex - 1] !== undefined) {
        setPreqItemIndex(preqItemIndex - 1);
        setComment([]);
      }
      if (event.key === "ArrowRight" && process?.prequalificationItem[preqItemIndex + 1] !== undefined) {
        setPreqItemIndex(preqItemIndex + 1);
        setComment([]);
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [preqItemIndex, process, setPreqItemIndex, setComment]);

  return (
    <Box sx={{ display: "flex", flex: 1, border: 1 }}>
      {/* Left Button */}
      <Box
        sx={{
          display: "flex",
          flex: 0.15,
          border: 1,
          alignItems: "center",
          justifyContent: "center",
          cursor: "pointer",
        }}
        onClick={() => {
          if (process?.prequalificationItem[preqItemIndex - 1] !== undefined) {
            setPreqItemIndex(preqItemIndex - 1);
            setComment([]);
          }
        }}
      >
        {process?.prequalificationItem[preqItemIndex - 1] !== undefined && <FirstPage fontSize="large" />}
      </Box>

      {/* Center - Description */}
      <Box sx={{ display: "flex", flex: 0.7, border: 1, alignItems: "center", justifyContent: "center" }}>
        <Typography>{process?.prequalificationItem[preqItemIndex]?.description}</Typography>
      </Box>

      {/* Right Button */}
      <Box
        sx={{
          display: "flex",
          flex: 0.15,
          border: 1,
          alignItems: "center",
          justifyContent: "center",
          cursor: "pointer",
        }}
        onClick={() => {
          if (process?.prequalificationItem[preqItemIndex + 1] !== undefined) {
            setPreqItemIndex(preqItemIndex + 1);
            setComment([]);
          }
        }}
      >
        {process?.prequalificationItem[preqItemIndex + 1] !== undefined && <LastPage fontSize="large" />}
      </Box>
    </Box>
  );
};

export default CustomPageSlider;
