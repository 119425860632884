import { useState } from "react";
import instance from "../api/axiosConfig";
import { useDispatch } from "react-redux";
import { specialistAdd } from "../redux/reducers/specialist";
import { specificFacilityAdd } from "../redux/reducers/facility";

export function useApprovalFlow() {
  const dispatch = useDispatch();
  const [responseData, setResponseData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [message, setMessage] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);

  const sendRequest = async (requestType, requestData) => {
    try {
      setIsLoading(true);
      setOpenSnackbar(false);
      setMessage(null);

      const response = await instance.post("/changes/requestWithImpactOnCertificate", {
        type: requestType,
        data: requestData,
      });

      if (response.data.error === true) {
        setOpenSnackbar(true);
        setMessage({ severity: "error", message: response?.data?.message });
        return;
      }

      if (response.data.requiresConfirmation) {
        console.log("responseData", response.data);
        setModalOpen(true);
        setResponseData({
          ...response.data,
          requestType,
          requestData,
        });
      } else {
        setOpenSnackbar(true);
        setMessage({ severity: "success", message: response?.data?.message });
        //Update Facility and Specialist state
        console.log("response", response.data);
        if (response.data.facility) dispatch(specificFacilityAdd(response.data.facility));
        if (response.data.facility.specialist) dispatch(specialistAdd(response.data.facility.specialist));
        //maybe add something for redux
      }
    } catch (err) {
      setOpenSnackbar(true);
      setMessage({ severity: "error", message: err.message || "Something went wrong" });
    } finally {
      setIsLoading(false);
    }
  };

  const confirmRequest = async (confirmed) => {
    if (!responseData) return;

    try {
      setIsLoading(true);
      setOpenSnackbar(false);

      const response = await instance.post("/changes/confirmRequest", {
        data: responseData,
      });

      console.log("response", response.data);

      if (response.data.error === true) {
        setOpenSnackbar(true);
        setMessage({ severity: "error", message: response?.data?.message });
        return;
      } else {
        setResponseData(null); // Reset state after confirmation
        setOpenSnackbar(true);
        setMessage({ severity: "success", message: response?.data?.message });
      }
    } catch (err) {
      setOpenSnackbar(true);
      setMessage({ severity: "error", message: err.message || "Something went wrong" });
    } finally {
      setIsLoading(false);
    }
  };

  return {
    sendRequest,
    confirmRequest,
    responseData,
    setResponseData,
    isLoading,
    message,
    openSnackbar,
    setOpenSnackbar,
    modalOpen,
    setModalOpen,
  };
}
