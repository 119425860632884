import React, { useCallback } from "react";
import { useSelector } from "react-redux";
import moment from "moment";

import { AssessmentType } from "assets/type.ts";

import {
  Typography,
  Card,
  FormControl,
  TextField,
  Box,
  Select,
  MenuItem,
  ListItemText,
  Checkbox,
  Button,
  Snackbar,
  Alert,
  Tooltip,
  InputLabel,
} from "@mui/material";

import { AddCircleOutline, RemoveCircleOutline, InfoOutlined } from "@mui/icons-material";

import { checkSpecialistExists } from "api/specialist";
import { CustomDataGrid } from "components";

//DATA GRID COLUMNS

const SpecialistCreationTab = ({ facilityId, sendRequest }) => {
  const [selectedOccupation, setSelectedOccupation] = React.useState("");
  const [selectedOccupationIndex, setSelectedOccupationIndex] = React.useState("");

  const occupation = useSelector((state) => state.occupation);
  const criteriaCatalog = useSelector((state) => state.criteriaCatalog);

  const [providing_fields, setProvidingFields] = React.useState([]);

  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [qualifications, setQualification] = React.useState("");
  // const [email, setEmail] = React.useState("");
  const [gender, setGender] = React.useState("");

  //Error handling
  const [errorAlertOpen, setErrorAlertOpen] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [errorIndicator, setErrorIndicator] = React.useState(false);

  const createDataGrid = useCallback(
    (inStoreValue, fieldSalesValue) => {
      // Create a copy of the criteriaCatalogItem array you want to work with
      let criteriaCatalogVersionIndex;
      if (process.env.REACT_APP_FIXED_CATALOG_VERSION === "true") {
        criteriaCatalogVersionIndex = criteriaCatalog.findIndex(
          (item) => item.version === process.env.REACT_APP_CATALOG_VERSION
        );
      } else {
        criteriaCatalogVersionIndex = criteriaCatalog.findIndex(
          (item) => moment().isBefore(item.expires) && moment().isAfter(item.valid_from)
        );
        console.log(criteriaCatalog[criteriaCatalogVersionIndex]);
      }

      if (criteriaCatalogVersionIndex !== -1) {
        const criteriaCatalogItem = criteriaCatalog[criteriaCatalogVersionIndex].catalogItem;

        // Filter the items based on the qualifications
        const filteredKatalog = criteriaCatalogItem.filter((item) => {
          const occupationArray = item.specialist_short_forms.split(",");
          return occupationArray.find(
            (item) =>
              item.trim() === qualifications.trim() || item.trim() === qualifications.replace(/[()]/g, "").trim()
          );
        });

        // Create a new array and append values to it
        const modifiedKatalog = filteredKatalog.map((item) => {
          const newItem = { ...item }; // Create a copy of the item
          newItem.criteriaCatalogVersion = criteriaCatalog[criteriaCatalogVersionIndex]?.version;
          newItem.versionOfficial = criteriaCatalog[criteriaCatalogVersionIndex]?.versionOfficial;
          newItem.in_store_selected = item.in_store_allowed ? inStoreValue : false;
          newItem.field_sales_selected = item.field_sales_allowed ? fieldSalesValue : false;
          return newItem;
        });

        console.log(modifiedKatalog);
        // Now you can use modifiedKatalog as needed
        setProvidingFields(modifiedKatalog);
      }
    },
    [criteriaCatalog, qualifications]
  );

  //PROVIDING FIELDS --------------------------------------------------------------------------
  const handleProvidingAction = (from, row) => {
    const element = providing_fields.findIndex((item) => item.code.substring(0, 3) === row.code.substring(0, 3));

    if (from) providing_fields[element].in_store_selected = !providing_fields[element].in_store_selected;
    else providing_fields[element].field_sales_selected = !providing_fields[element].field_sales_selected;
  };

  const providingFieldsColumns = [
    {
      field: "in_store_allowed",
      headerName: "Geschäftslokal",
      flex: 0.15,
      editable: false,
      default: false,
      renderCell: ({ row }) => {
        if (row.in_store_allowed) {
          return <Checkbox checked={row.in_store_selected} onClick={() => handleProvidingAction(true, row)} />;
        } else return <></>;
      },
    },
    {
      field: "field_sales_allowed",
      headerName: "Außendienst",
      flex: 0.15,
      editable: false,
      default: false,
      renderCell: ({ row }) => {
        if (row.field_sales_allowed) {
          return <Checkbox checked={row.field_sales_selected} onClick={() => handleProvidingAction(false, row)} />;
        } else return <></>;
      },
    },
    {
      field: "code",
      headerName: "Versorgungsbereiche",
      flex: 0.2,
      editable: false,
    },
    {
      field: "description",
      headerName: "Bezeichnung",
      flex: 0.4,
      editable: false,
    },
  ];

  //----------------------------------------------------------------------------------------------

  React.useEffect(() => {
    createDataGrid(false, false);
  }, [createDataGrid]);

  const createTechDirector = async () => {
    if (!firstName || !lastName || gender === "" || !selectedOccupation) {
      setErrorIndicator(true);
    } else {
      console.log(selectedOccupation);
      const response = await checkSpecialistExists(selectedOccupation, facilityId, firstName, lastName);

      if (response.error === true) {
        setErrorAlertOpen(true);
        setErrorMessage(response.message);
        return;
      }

      const filterSelectedProvidingFields = providing_fields.filter(
        (item) => item.in_store_selected || item.field_sales_selected
      );

      let providingFields = filterSelectedProvidingFields.map((item) => {
        return {
          code: item.code,
          in_store: item.in_store_selected,
          field_sales: item.field_sales_selected,
          description: item.description,
        };
      });

      if (providingFields.length === 0) {
        setErrorAlertOpen(true);
        setErrorMessage("Bitte waehlen Sie mindestens eine Versorgungsbereich aus");
        return;
      }

      await sendRequest(AssessmentType.ADDSPECIALIST, {
        first_name: firstName,
        last_name: lastName,
        qualification: qualifications,
        gender: gender,
        facilityId: facilityId,
        providing_fields: providingFields,
      });

      // dispatch(
      //   createSpecialist({
      //     first_name: firstName,
      //     last_name: lastName,
      //     qualification: qualifications,
      //     gender: gender,
      //     facilityId: facilityId,
      //     providing_fields: providingFields,
      //   })
      // ).then((response) => {
      //   if (response.error === true) {
      //     setErrorAlertOpen(true);
      //     setErrorMessage(response.message);
      //     return;
      //   } else {
      //     setFirstName("");
      //     setLastName("");
      //     setQualification("");
      //     setGender("");
      //     setSelectedOccupation(null);
      //     setProvidingFields([]);
      //   }
      // });
    }
  };

  const handleQualification = (event) => {
    const index = occupation.findIndex((item) => item.id === event.target.value);
    const jobAbkuerzung = occupation[index].short_form;
    console.log(event.target.value);
    setSelectedOccupationIndex(index);
    setQualification(jobAbkuerzung);
    setSelectedOccupation(event.target.value);
  };

  return (
    // <CModal
    //   modalLabel="Fachliche Leitung anlegen"
    //   open={isOpen}
    //   // onClose={localSaveOnClosing}
    //   onRequestClose={onRequestClose}
    //   onButtonPressed={() => handleNavigation(true)}
    //   onBackButtonPressed={() => handleNavigation(false)}
    //   buttonLabel={rightButton}
    //   backButtonLabel={leftButton}
    // >
    <>
      <Box
        sx={{
          p: 3,
          borderRadius: 0,
          display: "flex",
          flex: 1,
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Card sx={{ p: 3, mt: 3, width: "100%" }}>
          <FormControl sx={{ display: "flex", width: "100%" }}>
            <Box sx={{ display: "flex", alignItems: "center", flexDirection: "row" }}>
              <Typography variant="body2">Beruf / Qualifizierung</Typography>
            </Box>
            <Select
              value={selectedOccupation}
              onChange={(e) => {
                handleQualification(e);
              }}
              sx={{ width: "100%" }}
              displayEmpty
              startAdornment={
                <Tooltip
                  disableFocusListener
                  disableTouchListener
                  arrow
                  title={
                    <Typography variant="body1">
                      {!selectedOccupation
                        ? "Sobald Sie eine Qualifizierung ausgewählt haben, können Sie hier die Beruflichen Vorraussetzungen für diese Einsehen."
                        : `Die beruflichen Vorraussetzung für den Beruf: ${occupation[selectedOccupationIndex].description} (${occupation[selectedOccupationIndex].short_form}) sind: ${occupation[selectedOccupationIndex].certificate}`}
                    </Typography>
                  }
                >
                  <InfoOutlined sx={{ mr: 3, scale: "1" }} />
                </Tooltip>
              }
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 300,
                    width: 250,
                  },
                },
              }}
              error={errorIndicator && !selectedOccupation}
            >
              {/* Map of available Jobtypes / ADD abk_rzung als Speichermedium */}
              {occupation.map(({ id, description, short_form }) => (
                <MenuItem key={id} value={id}>
                  <ListItemText
                    primary={
                      <Typography sx={{ whiteSpace: "normal", wordBreak: "break-word" }}>
                        <strong>{description}</strong>
                        <br />
                        <Typography component="span" sx={{ color: "text.secondary" }}>
                          {short_form}
                        </Typography>
                      </Typography>
                    }
                  />
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              flex: 1,
              mt: 3,
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <FormControl sx={{ flex: 0.49, width: "95%" }}>
              <InputLabel id="demo-simple-select-label">Anrede</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={gender}
                label="Age"
                onChange={(e) => setGender(e.target.value)}
                error={errorIndicator && gender === ""}
              >
                <MenuItem value={0}>Herr</MenuItem>
                <MenuItem value={1}>Frau</MenuItem>
                <MenuItem value={2}>* (divers)</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              flex: 1,
              mt: 3,
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Box sx={{ flex: 0.49, width: "100%" }}>
              <TextField
                sx={{ width: "100%" }}
                variant="outlined"
                label="Vorname"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                error={errorIndicator && !firstName}
                helperText={errorIndicator && !firstName ? "Dieses Feld ist auszufüllen" : ""}
              />
            </Box>
            <Box sx={{ flex: 0.49 }}>
              <TextField
                sx={{ width: "100%" }}
                variant="outlined"
                label="Nachname"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                error={errorIndicator && !lastName}
                helperText={errorIndicator && !lastName ? "Dieses Feld ist auszufüllen" : ""}
              />
            </Box>
          </Box>
        </Card>
        <Card sx={{ width: "100%", mt: 3, p: 3, height: 400 }}>
          <Box sx={{ my: 2, display: "flex", justifyContent: "space-between" }}>
            <Button
              sx={{ textTransform: "none" }}
              variant="contained"
              color="success"
              onClick={() => createDataGrid(true, true)}
            >
              <AddCircleOutline /> Alle auswählen
            </Button>
            <Button
              sx={{ textTransform: "none" }}
              variant="contained"
              color="error"
              onClick={() => createDataGrid(false, false)}
            >
              <RemoveCircleOutline /> Auswahl entfernen
            </Button>
          </Box>
          <Button
            sx={{ textTransform: "none" }}
            variant="contained"
            color="success"
            onClick={() => createDataGrid(true, false)}
          >
            <AddCircleOutline /> Alle Geschäftslokal auswählen
          </Button>
          <Button
            sx={{ textTransform: "none", ml: 2 }}
            variant="contained"
            color="success"
            onClick={() => createDataGrid(false, true)}
          >
            <AddCircleOutline /> Alle Außendienst auswählen
          </Button>
          <CustomDataGrid
            rows={selectedOccupation ? providing_fields : []}
            columns={providingFieldsColumns}

            // checkboxSelection={checkboxSelection}
            // onSelectionModelChange={(rows) => setSelectedFields(rows)}
          />
        </Card>

        <Button sx={{ my: 2, alignSelf: "flex-end" }} variant="contained" onClick={createTechDirector}>
          Fachliche Leitung erstellen
        </Button>
      </Box>
      {/* )} */}
      <Snackbar
        open={errorAlertOpen}
        autoHideDuration={5000}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        onClose={() => setErrorAlertOpen(false)}
      >
        <Alert variant="filled" severity="error">
          {errorMessage}
        </Alert>
      </Snackbar>
    </>
    // </CModal>
  );
};

export default SpecialistCreationTab;
