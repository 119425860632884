import instance from "./axiosConfig";
import { historyAddedSingle, historyFileAdded, historyObjectUpdate } from "../redux/reducers/history";

export const createHistoryItem = (facilityId, data) => async (dispatch) => {
  try {
    const response = await instance.post(`/history/create`, {
      facilityId,
      data,
    });

    if (!response.data?.error) {
      dispatch(historyAddedSingle(response.data.value));
    }
    return response?.data;
  } catch (e) {
    console.error("error", e);
    return e.response?.data;
  }
};

export const AcceptHistoryFileUpload = (historyId) => async (dispatch) => {
  try {
    const response = await instance.post(`/history/acceptFileUpload`, {
      historyId,
    });

    console.log("response", response);

    if (!response.data?.error) {
      dispatch(historyObjectUpdate({ value: response.data.value, historyId }));
    }
    return response?.data;
  } catch (e) {
    console.error("error", e);
    return e.response.data;
  }
};

export const UploadHistoryFile = (file, historyId) => async (dispatch) => {
  try {
    const response = await instance.post(`/media/uploadHistory`, file, {
      headers: { "Content-Type": "multipart/form-data" },
    });

    if (!response.data?.error) {
      dispatch(historyFileAdded({ response: response.data.value, historyId }));
    }
    return response?.data;
  } catch (e) {
    return e?.response?.data;
  }
};
