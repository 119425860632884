import React from "react";
import { Box, Typography, Tabs, Tab, Button, Paper, Divider, InputLabel } from "@mui/material";
import { CustomTabPanel, CustomDataGrid, CustomLoadingIndicator, CustomSnackbarAlert } from "components";
import { specialistColumns } from "layouts/DataGridLayouts";

import { DatePicker } from "@mui/x-date-pickers";
import { useSelector } from "react-redux";
// import { deleteSpecialist } from "../../../../../api";
import { useApprovalFlow } from "hooks/useApprovalFlow";
import { ConfirmationModal } from "pages/modals";
import SpecialistCreationTab from "pages/ReusableTabs/specialistCreationTab";
import { AssessmentType } from "assets/type.ts";
import { InfoOutlined } from "@mui/icons-material";
import moment from "moment";

const EditSpecialistTab = ({ selectedFacility }) => {
  const {
    sendRequest,
    confirmRequest,
    responseData,
    setResponseData,
    isLoading,
    openSnackbar,
    setOpenSnackbar,
    message,
    modalOpen,
    setModalOpen,
  } = useApprovalFlow();

  const [selectedTabIndex, setSelectedTabIndex] = React.useState(0);
  const [date, setDate] = React.useState(null);
  const [selectedSpecialist, setSelectedSpecialist] = React.useState(null);
  const [clicked, setClicked] = React.useState(false);
  const [showError, setShowError] = React.useState(false);

  const specialistList = useSelector((state) => state.specialist);

  const sendRemoveSpecialist = async () => {
    setClicked(true);
    const formatedDate = date ? moment(date).format("YYYY-MM-DD") : null;
    await sendRequest(AssessmentType.REMOVESPECIALIST, {
      facilityId: selectedFacility.id,
      selectedSpecialist,
      formatedDate,
    });
    setClicked(false);
  };

  return (
    <>
      <Box sx={{ borderBottom: 1, borderColor: "divider", display: "flex", flex: 1, width: "100%" }}>
        <Tabs
          variant="fullWidth"
          sx={{ width: "100%" }}
          selectionFollowsFocus
          value={selectedTabIndex}
          onChange={(e, selectedTabIndex) => setSelectedTabIndex(selectedTabIndex)}
        >
          {/* <Tab label="Wechsel Fachl. Leitung" /> */}
          <Tab label="Zusätzliche Fachl. Leitung" />
          <Tab label="Wegfall Fachl. Leitung" />
        </Tabs>
      </Box>
      {/* <CustomTabPanel value={selectedTabIndex} index={0}>
        <Paper sx={{ p: 3, mt: 2 }} elevation={3}>
          <Box sx={{ display: "flex", flexDirection: "row", mt: 2, flex: 1 }}>
            <FormControl sx={{ flex: 0.35, mr: 10 }}>
              <Typography variant="body2">Neue Fachl. Leitung(Optional)</Typography>
              <Select
                value={value}
                onChange={(e) => {
                  setValue(e.target.value);
                }}
              >
                <MenuItem value={0}>Fachliche Leitung</MenuItem>
                <MenuItem value={1}>Versorgungsbereiche</MenuItem>
              </Select>
            </FormControl>
          </Box>

          <Paper
            sx={{
              display: "flex",
              flexDirection: "row",
              mt: 4,
              mb: 2,
              flex: 1,
            }}
          >
            <FormControl sx={{ flex: 0.35, mr: 10 }}>
              <Typography variant="body2">Ausscheidende Fachl. Leitung(Optional)</Typography>
              <Select
                value={value}
                onChange={(e) => {
                  setValue(e.target.value);
                }}
              >
                <MenuItem value={0}>Fachliche Leitung</MenuItem>
                <MenuItem value={1}>Versorgungsbereiche</MenuItem>
              </Select>
            </FormControl>
            {/* <FormControl sx={{ flex: 0.35 }}>
                <Typography variant="body2">Absscheidedatum(Optional)</Typography>
                <Select
                  value={value}
            
                  onChange={(e) => {
                    setValue(e.target.value);
                  }}
                ></Select>
              </FormControl> 
          </Paper>
        </Paper> 
      </CustomTabPanel >
      */}
      <CustomTabPanel value={selectedTabIndex} index={0}>
        <Paper sx={{ p: 3, mt: 2 }} elevation={3}>
          <Box sx={{ display: "flex", flexDirection: "row", mt: 2, flex: 1 }}>
            <Typography variant="body2">
              Um eine neue Fachliche Leitung anzulegen klicken sie bitte auf den Knopf. Beachten Sie hierbei, das bei
              erweiterung von Versorgungsbereichen es ggf. zu einer Begehung oder abgabe von neuen Vorraussetzungen
              kommen kann bevor die Fachliche Leitung tatsächlich erstellt wird.
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              mt: 1,
            }}
          >
            <SpecialistCreationTab sendRequest={sendRequest} facilityId={selectedFacility.id} />
            {/* <Button sx={{ my: 2, textTransform: "none" }} variant="contained" onClick={() => setIsTechDirOpen(true)}>
              Fachliche Leitung anlegen
            </Button> */}
          </Box>
        </Paper>
      </CustomTabPanel>
      <CustomTabPanel value={selectedTabIndex} index={1}>
        <Paper sx={{ p: 3, mt: 2 }} elevation={3}>
          <Box sx={{ display: "flex", flexDirection: "row", mt: 2, flex: 1 }}>
            <Typography>
              Bitte wählen Sie die Fachliche Leitung aus und tragen sie das Datum ein, ab wann die Fachliche Leitung
              wegfällt
            </Typography>
          </Box>
          <Divider textAlign="left" sx={{ my: 2, textTransform: "none" }}>
            <Typography fontWeight={"bold"}>Fachliche Leitung auswählen</Typography>
          </Divider>
          <CustomDataGrid
            rows={specialistList}
            columns={specialistColumns}
            initialPageSize={25}
            checkboxSelection
            onSelectionModelChange={(rows) => {
              if (rows.length === 0) {
                if (selectedSpecialist) {
                  setSelectedSpecialist({});
                }
                return;
              }
              setSelectedSpecialist(rows[0]);
            }}
            // onRowDoubleClick={() => {
            //   setOpen(!open);
            // }}
            // onRowClick={(params) => {
            //   dispatch(GetProvidingFields(params.row.id)).then(
            //     setProvidingFields(dynamicSpecialistFieldValue(criteriaCatalog, params.row))
            //   );
            //   setSelectedSpecialist(params.row);
            // }}
          />
          <Divider textAlign="left" sx={{ my: 2 }}>
            <Typography fontWeight={"bold"}>Wegfalldatum</Typography>
          </Divider>

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              p: 1,
              my: 1,
            }}
          >
            <InfoOutlined sx={{ mx: 1, scale: "1.5", color: "error.main" }} />

            <Typography>
              Sie können das Wegfalldatum weglassen, wenn Sie wollen das die Fachliche Leitung sofort wegfällt.
            </Typography>
          </Box>

          <DatePicker value={date} title="Wegfalldatum" onChange={(newValue) => setDate(newValue)} />
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              mt: 1,
            }}
          >
            <Button
              sx={{ my: 2 }}
              variant="contained"
              disabled={clicked}
              onClick={() => {
                if (specialistList.length > 1) {
                  sendRemoveSpecialist();
                } else {
                  setShowError(true);
                }
              }}
            >
              Absenden
            </Button>
            <InputLabel sx={{ ml: 2, my: 2.5, color: "red", visibility: showError ? "visible" : "hidden" }}>
              Sie können nicht Ihre einzige Fachliche Leitung entfernen.
            </InputLabel>
          </Box>
        </Paper>
      </CustomTabPanel>
      {modalOpen && (
        <ConfirmationModal
          isOpen={modalOpen}
          onRequestClose={() => setModalOpen(false)}
          responseData={responseData}
          setResponseData={setResponseData}
          confirmRequest={() => {
            console.log("responseData in tab", responseData);
            confirmRequest();
          }}
        />
      )}
      <CustomLoadingIndicator open={isLoading} message={"Einen Moment gedult. Änderung wird bearbeitet."} />
      <CustomSnackbarAlert
        open={openSnackbar}
        setOpen={() => {
          setOpenSnackbar(false);
        }}
        severity={message?.severity}
        message={message?.message}
      />
    </>
  );
};

export default EditSpecialistTab;
