import { createSlice } from "@reduxjs/toolkit";

const adminSlice = createSlice({
  name: "admin",
  initialState: [],
  reducers: {
    adminAdded(state, action) {
      return action.payload;
    },
    logoutAdmin(state, action) {
      return adminSlice.getInitialState();
    },
  },
});

export const { adminAdded, logoutAdmin } = adminSlice.actions;
export default adminSlice.reducer;
