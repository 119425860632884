import React, { useCallback } from "react";
import { useSelector } from "react-redux";
import moment from "moment";

//mui import
import { Typography, FormControl, TextField, Box, Select, MenuItem, ListItemText, Checkbox } from "@mui/material";

//Stepper
import { Step, StepLabel, Stepper, InputLabel } from "@mui/material";
//icons
import { Person, BusinessCenter, PeopleAlt } from "@mui/icons-material";
//component import
import { CModal, ColorlibConnector, ColorlibStepIconRoot, CustomDataGrid } from "components";
//TODO: GET REAL SELECTED PROVIDING FIELDS

const EditSpecialist = ({ isOpen, onRequestClose, facilityId }) => {
  const [value, setValue] = React.useState(0);

  const specialistList = useSelector((state) => state.specialist);
  // const providingFields = useSelector((state) => state.providingFields);
  const occupation = useSelector((state) => state.occupation);
  const criteriaCatalog = useSelector((state) => state.criteriaCatalog);

  //Stepper-----------------------------------------
  const steps = ["Auswählen", "Kontaktdaten", "Versorgungsbereiche"];
  const [activeStep, setActiveStep] = React.useState(0);
  const [leftButton, setButtonLeft] = React.useState("");
  const [rightButton, setButtonRight] = React.useState("Weiter");
  //------------------------------------------------

  const [providing_fields, setProvidingFields] = React.useState([]);
  const [selectedSpecialist, setSelectedSpecialst] = React.useState({});

  const [firstName, setFirstName] = React.useState(selectedSpecialist?.first_name);
  const [lastName, setLastName] = React.useState(selectedSpecialist?.last_name);
  const [qualifications, setQualification] = React.useState(selectedSpecialist.qualification || "");
  const [email, setEmail] = React.useState(selectedSpecialist?.email);
  const [gender, setGender] = React.useState(selectedSpecialist?.gender);

  const createDataGrid = useCallback(
    (value) => {
      // Create a copy of the criteriaCatalogItem array you want to work with
      let criteriaCatalogVersionIndex;
      if (process.env.REACT_APP_FIXED_CATALOG_VERSION === "true")
        criteriaCatalogVersionIndex = criteriaCatalog.findIndex(
          (item) => item.version === process.env.REACT_APP_CATALOG_VERSION
        );
      else criteriaCatalogVersionIndex = criteriaCatalog.findIndex((item) => moment().isBefore(item.expires));

      if (criteriaCatalogVersionIndex !== -1) {
        const criteriaCatalogItem = criteriaCatalog[criteriaCatalogVersionIndex].catalogItem;

        // Filter the items based on the qualifications
        const filteredKatalog = criteriaCatalogItem.filter((item) => {
          const occupationArray = item.specialist_short_forms.split(",");
          return occupationArray.find(
            (item) =>
              item.trim() === qualifications.trim() || item.trim() === qualifications.replace(/[()]/g, "").trim()
          );
        });

        // Create a new array and append values to it
        const modifiedKatalog = filteredKatalog.map((item) => {
          const newItem = { ...item }; // Create a copy of the item
          newItem.criteriaCatalogVersion = criteriaCatalog[criteriaCatalogVersionIndex]?.version;
          newItem.versionOfficial = criteriaCatalog[criteriaCatalogVersionIndex]?.versionOfficial;
          newItem.in_store_selected = item.in_store_allowed ? value : false;
          newItem.field_sales_selected = item.field_sales_allowed ? value : false;
          return newItem;
        });

        // Now you can use modifiedKatalog as needed
        setProvidingFields(modifiedKatalog);
      }
    },
    [criteriaCatalog, qualifications]
  );

  //PROVIDING FIELDS --------------------------------------------------------------------------
  const handleProvidingAction = (from, row) => {
    const element = providing_fields.findIndex((item) => item.code.substring(0, 3) === row.code.substring(0, 3));

    if (from) providing_fields[element].in_store_selected = !providing_fields[element].in_store_selected;
    else providing_fields[element].field_sales_selected = !providing_fields[element].field_sales_selected;
  };

  const providingFieldsColumns = [
    {
      field: "in_store_allowed",
      headerName: "Geschäftslokal",
      flex: 0.15,
      editable: false,
      default: false,
      renderCell: ({ row }) => {
        if (row.in_store_allowed) {
          return <Checkbox checked={row.in_store_selected} onClick={() => handleProvidingAction(true, row)} />;
        } else return <></>;
      },
    },
    {
      field: "field_sales_allowed",
      headerName: "Außendienst",
      flex: 0.15,
      editable: false,
      default: false,
      renderCell: ({ row }) => {
        if (row.field_sales_allowed) {
          return <Checkbox checked={row.field_sales_selected} onClick={() => handleProvidingAction(false, row)} />;
        } else return <></>;
      },
    },
    {
      field: "code",
      headerName: "Versorgungsbereiche",
      flex: 0.2,
      editable: false,
    },
    {
      field: "description",
      headerName: "Bezeichnung",
      flex: 0.4,
      editable: false,
    },
  ];

  //----------------------------------------------------------------------------------------------

  React.useEffect(() => {
    createDataGrid(false);
  }, [activeStep, createDataGrid]);

  //---Specialst Datagrid Columns
  const specialistColumns = [
    {
      field: "fullName",
      headerName: "Name",
      flex: 1,
      editable: false,
      valueGetter: (params) => `${params.row.first_name || ""} ${params.row.last_name || ""}`,
    },
    {
      field: "qualification",
      headerName: "Qualifikation",
      flex: 1,
      editable: false,
    },
  ];

  function StepIcon(props) {
    const { active, completed, className } = props;
    const icons = {
      1: <PeopleAlt />,
      2: <Person />,
      3: <BusinessCenter />,
    };
    return (
      <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
        {icons[String(props.icon)]}
      </ColorlibStepIconRoot>
    );
  }

  const editSpecialistCall = async () => {
    const filterFields = providing_fields.filter(
      (item) => ((item.in_house || item.sales_force) && item.in_house?.value) || item.sales_force?.value
    );

    const filterNewFields = () => {
      return filterFields.map((item) => {
        return {
          Code: item.Code,
          in_house: item.in_house.value,
          sales_force: item.sales_force.value,
          criteriaCatalogVersion: "15", //REWORK
        };
      });
    };

    const returnObject = () => {
      //REMOVE MAYBE
      let choosenValue = filterNewFields();
      const localObject = {
        first_name: firstName,
        last_name: lastName,
        qualification: qualifications,
        email: email,
        gender: gender,
        facilityId: facilityId,
        providing_fields: choosenValue,
      };

      console.log("localObject", localObject);
    };

    returnObject();
  };

  const handleNavigation = (value) => {
    switch (activeStep) {
      case 0: {
        if (value === true) {
          setActiveStep(activeStep + 1);
          setButtonLeft("Zurück");
        } else break;
        break;
      }

      case 1: {
        if (value === true) {
          setActiveStep(activeStep + 1);
          setButtonLeft("Zurück");
          setButtonRight("Fachliche Leitung anlegen");
        } else {
          setActiveStep(activeStep - 1);
          setButtonLeft("");
          setButtonRight("Weiter");
        }
        break;
      }
      case 2: {
        if (value === true) {
          editSpecialistCall();
        } else {
          setActiveStep(activeStep - 1);
          setButtonRight("Weiter");
        }
        break;
      }
      default: {
        break;
      }
    }
  };

  const localSaveOnClosing = async (event) => {
    event.preventDefault();
    // console.log("local", session);
    // const localObject = {
    //   first_name: firstName,
    //   last_name: lastName,
    //   qualification: qualifications,
    //   email: email,
    //   gender: gender,
    //   facilityId: facilityId,
    // };
    onRequestClose();
  };

  const handleQualification = (event) => {
    const jobAbkuerzung = occupation[event.target.value - 1].short_form;
    setQualification(jobAbkuerzung);
    setValue(event.target.value);
  };

  const handleClick = (event) => {
    const specialist = event.row;
    // dispatch(GetProvidingFields(specialist.id));
    setSelectedSpecialst(specialist);
    setFirstName(specialist.first_name);
    setLastName(specialist.last_name);
    setGender(specialist.gender);
    setEmail(specialist.email);
    const jobIndex = occupation.findIndex((item) => item.short_form.includes(specialist.qualification));
    setValue(occupation[jobIndex].id);
    setQualification(specialist.qualification);
  };

  //getLocalSavedOnOpen();

  return (
    <CModal
      modalLabel="Fachliche Leitung bearbeiten"
      open={isOpen}
      onClose={localSaveOnClosing}
      onRequestClose={onRequestClose}
      onButtonPressed={() => handleNavigation(true)}
      onBackButtonPressed={() => handleNavigation(false)}
      // buttonDisabled={!allowed}
      buttonLabel={rightButton}
      backButtonLabel={leftButton}
    >
      <Box
        sx={{
          p: 3,
          borderRadius: 0,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Stepper sx={{ width: "80%" }} activeStep={activeStep} alternativeLabel connector={<ColorlibConnector />}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel StepIconComponent={StepIcon}>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        {activeStep === 0 && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",

              width: "70%",
              mt: 5,
            }}
          >
            <CustomDataGrid
              rows={specialistList}
              columns={specialistColumns}
              initialPageSize={25}
              onRowClick={handleClick}
            />
          </Box>
        )}
        {activeStep === 1 && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",

              width: "70%",
              mt: 5,
            }}
          >
            <FormControl sx={{ display: "flex", mt: 3, width: "100%" }}>
              <Typography variant="body2">Beruf / Qualifizierung</Typography>
              <Select
                value={value}
                onChange={(e) => {
                  handleQualification(e);
                }}
                sx={{ width: "100%" }}
                displayEmpty
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 300,
                      width: 250,
                    },
                  },
                }}
              >
                {/* Map of available Jobtypes / ADD abk_rzung als Speichermedium */}
                {occupation.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    <ListItemText primary={`${item.description} (${item.short_form})`} secondary={item.certificate} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                flex: 1,
                mt: 3,
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              <FormControl sx={{ flex: 0.49, width: "95%" }}>
                <InputLabel id="demo-simple-select-label">Anrede</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={gender}
                  label="Age"
                  onChange={(e) => setGender(e.target.value)}
                >
                  <MenuItem value={0}>Herr</MenuItem>
                  <MenuItem value={1}>Frau</MenuItem>
                  <MenuItem value={2}>* (divers)</MenuItem>
                </Select>
              </FormControl>
              <Box sx={{ flex: 0.49 }}>
                <TextField
                  sx={{ width: "100%" }}
                  variant="outlined"
                  label="E-mail"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                flex: 1,
                mt: 3,
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Box sx={{ flex: 0.49, width: "100%" }}>
                <TextField
                  sx={{ width: "100%" }}
                  variant="outlined"
                  label="Vorname"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                />
              </Box>
              <Box sx={{ flex: 0.49 }}>
                <TextField
                  sx={{ width: "100%" }}
                  variant="outlined"
                  label="Nachname"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                />
              </Box>
            </Box>
          </Box>
        )}
        {activeStep === 2 && (
          <Box sx={{ width: "100%", height: 400 }}>
            <CustomDataGrid
              rows={providing_fields}
              columns={providingFieldsColumns}

              // checkboxSelection={checkboxSelection}
              // onSelectionModelChange={(rows) => setSelectedFields(rows)}
            />
          </Box>
        )}
      </Box>
    </CModal>
  );
};

export default EditSpecialist;
