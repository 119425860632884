import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Button, Typography, Card, Grid, Divider, useMediaQuery } from "@mui/material";
import { useTheme } from "@emotion/react";
import Events from "pages/details/tabs/events";

import { GetSpecialist, GetSpecificPrequalificationItemList } from "api";
import { prequalificationColumns } from "layouts/DataGridLayouts";
import { CustomDataGrid, LabelNameGrid } from "components";

const PrequalificationTab = ({
  switchTab,
  selectedFacility,
  selectedPrequalification,
  setSelectedPrequalification,
}) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs")); // Small screens (e.g., <600px)
  const prequalification = useSelector((state) => state.prequalification);

  const [clicked, setClicked] = React.useState(false);

  return (
    <Box sx={{ width: "100%", mt: 2 }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          mb: 4,
          width: "100%", // REWORD
          alignContent: "center",
          justifyContent: "space-between",
        }}
      >
        <Button
          variant={"outlined"}
          disabled={clicked}
          onClick={() => {
            setClicked(true);
            switchTab(false);
            setClicked(false);
          }}
        >
          Zurück
        </Button>
        <Button variant={"outlined"}>Aktualisieren</Button>
        <Button
          sx={{ ml: 5 }}
          variant={"outlined"}
          disabled={clicked}
          onClick={() => {
            setClicked(true);
            switchTab(true);
            setClicked(false);
          }}
        >
          Weiter
        </Button>
      </Box>
      <CustomDataGrid
        rows={prequalification}
        columns={prequalificationColumns}
        initialPageSize={10}
        disableSelectionOnClick
        checkboxSelection
        selectionModel={selectedPrequalification ? [selectedPrequalification?.id] : []}
        onSelectionModelChange={(rows) => {
          if (rows.length === 0) {
            if (selectedPrequalification) {
              setSelectedPrequalification(null);
            }
            return;
          }
          const prequalificationObject = prequalification?.filter((item) => item.id.includes(rows));
          setSelectedPrequalification(prequalificationObject[0]);
          dispatch(GetSpecialist(prequalificationObject[0]?.facility[0].id, true));
          dispatch(GetSpecificPrequalificationItemList(prequalificationObject[0]?.id));
        }}
      />

      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Events facility={[selectedFacility]} />
        </Grid>
        <Grid item xs={12} md={6}>
          <Card sx={{ boxShadow: 3, backgroundColor: "transparent", p: 2 }}>
            <Divider sx={{ my: 2 }}>
              <Typography fontWeight="bold">Bearbeiter</Typography>
            </Divider>

            <Grid
              container
              spacing={1}
              alignItems="center"
              mb={1}
              direction={isSmallScreen ? "column" : "row"} // Switch layout based on screen size
            >
              <Grid item xs={12} sm={5}>
                <LabelNameGrid titel={"Evaluierer"} />
              </Grid>
              <Grid item xs={12} sm={7}>
                <Card sx={{ p: 1, textAlign: "center" }}>
                  <Typography>
                    {selectedPrequalification?.initialCheck?.firstName +
                      " " +
                      selectedPrequalification?.initialCheck?.lastName}
                  </Typography>
                </Card>
              </Grid>
            </Grid>
            <Grid
              container
              spacing={1}
              alignItems="center"
              direction={isSmallScreen ? "column" : "row"} // Switch layout based on screen size
            >
              <Grid item xs={12} sm={5}>
                <LabelNameGrid titel={"Bewerter"} />
              </Grid>
              <Grid item xs={12} sm={7}>
                <Card sx={{ p: 1, textAlign: "center" }}>
                  <Typography>
                    {selectedPrequalification?.controlCheck?.firstName +
                      " " +
                      selectedPrequalification?.controlCheck?.lastName}
                  </Typography>
                </Card>
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default PrequalificationTab;
