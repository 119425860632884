import React from "react";
import { Box, TextField } from "@mui/material";
import { LabelName } from "./labelName";

const CustomDataLine = (props) => {
  const { children, label, titel, value, setValue, errorIndicator, placeholder, ...other } = props;

  return (
    <Box sx={{ display: "flex", flexDirection: "row", my: 1, flex: 1 }} {...other}>
      <LabelName titel={titel} />
      {!children && (
        <TextField
          sx={{ display: "flex", flex: 0.3 }}
          label={label ? label : placeholder ? null : titel}
          value={value}
          placeholder={placeholder}
          onChange={(e) => setValue(e.target.value)}
          error={errorIndicator && !value}
          helperText={errorIndicator && !value ? "Dieses Feld ist auszufüllen" : ""}
        />
      )}
      {children}
    </Box>
  );
};

export default CustomDataLine;
