import React from "react";
import { useLocation } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { Tabs, Tab, Box, Typography, Card } from "@mui/material";

// Page Import
import {
  OverviewTab,
  Events,
  Notices,
  SpecialistTab,
  CertificateTab,
  ChangesTab,
  AssessmentTab,
  BillingTab,
  PrequalificationTab,
} from "./tabs";

import { GetFacilities, getPrequalificationDetails, GetSpecialist } from "api";
import { EditSpecialist, CreateTechDirector, SendPrequalificationRequest } from "pages/modals";
import { CustomTabPanel, CustomContainer } from "components";

const Details = () => {
  // states
  const [selectedTabIndex, setSelectedTabIndex] = React.useState(0);
  const [modalIsOpen, setModalIsOpen] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [openPreqModal, setPreqModalOpen] = React.useState(false);

  const personalData = useSelector((state) => state.account);

  const [selectedFacility, setSelectedFacility] = React.useState(null);
  const [selectedSpecialist, setSelectedSpecialist] = React.useState(null);

  //hooks
  const location = useLocation();
  const dispatch = useDispatch();

  const facility = useSelector((state) => state.facility);
  const specialistList = useSelector((state) => state.specialist);

  //local
  const customer = personalData?.admin;

  React.useEffect(() => {
    if (location.state) {
      console.log(location.state);

      dispatch(GetFacilities(location.state.company[0].id));
      dispatch(GetSpecialist(location.state.id, true));
      setSelectedFacility(location.state);
    }
  }, [dispatch, location.state]);

  //functions
  const openSelectedTab = (event, newValue) => {
    setSelectedTabIndex(newValue);
  };

  //Function to Showcase the different Pages presented in the Tabs

  // Little Banner to showcase Facility Name and IK Number
  function Banner() {
    return (
      <Card sx={{ justifyConten: "center", alignContent: "center", p: 3, mb: 3 }}>
        <Typography variant="h3">{`${location?.state?.name} (IK: ${location?.state?.ik_number})`}</Typography>
      </Card>
    );
  }

  const handlePrequalification = async (row) => {
    if (specialistList?.length === 0) {
      alert("Sie haben noch keine Fachliche Leitung angelegt.");
    } else {
      dispatch(getPrequalificationDetails(selectedFacility?.id));
    }
  };

  return (
    <CustomContainer>
      <Banner />
      <Box sx={{ borderBottom: 1, borderColor: "divider", display: "flex", flex: 1, width: "100%" }}>
        <Tabs
          variant="fullWidth"
          sx={{ width: "100%" }}
          selectionFollowsFocus
          value={selectedTabIndex}
          onChange={(e, selectedTabIndex) => openSelectedTab(e, selectedTabIndex)}
        >
          <Tab label="Übersicht: Unternehmensdaten & Ansprechpartner" />
          <Tab label="Fachliche Leitung" />
          <Tab label="Aktuelle PQ-Vorgänge" />
          <Tab label="Aktuelle Änderungsanträge & Überwachungen" />
          <Tab label="Beantragung: Änderung/Erweiterung" />
          <Tab label="Zertifikat" />
          <Tab label="Rechnungen" />
        </Tabs>
      </Box>
      <CustomTabPanel value={selectedTabIndex} index={0}>
        <OverviewTab facility={facility} />
      </CustomTabPanel>
      <CustomTabPanel value={selectedTabIndex} index={1}>
        <SpecialistTab
          selectedSpecialist={selectedSpecialist}
          setSelectedSpecialist={setSelectedSpecialist}
          setModalIsOpen={setModalIsOpen}
          open={open}
          setOpen={setOpen}
          setPreqModalOpen={setPreqModalOpen}
        />
      </CustomTabPanel>
      <CustomTabPanel value={selectedTabIndex} index={2}>
        <PrequalificationTab facilityId={selectedFacility?.id} setPreqModalOpen={setPreqModalOpen} />
      </CustomTabPanel>
      <CustomTabPanel value={selectedTabIndex} index={3}>
        <AssessmentTab selectedFacility={selectedFacility} />
      </CustomTabPanel>
      <CustomTabPanel value={selectedTabIndex} index={4}>
        <ChangesTab
          selectedFacility={selectedFacility}
          setSelectedFacility={setSelectedFacility}
          setSelectedTabIndex={setSelectedTabIndex}
        />
      </CustomTabPanel>
      <CustomTabPanel value={selectedTabIndex} index={5}>
        <CertificateTab selectedFacility={selectedFacility} />
      </CustomTabPanel>
      <CustomTabPanel value={selectedTabIndex} index={6}>
        <BillingTab selectedFacility={selectedFacility} />
      </CustomTabPanel>

      {customer && <Events customer={true} />}
      {customer && <Notices />}
      <EditSpecialist isOpen={open} onRequestClose={() => setOpen(false)} facilityId={selectedFacility?.id} />
      <CreateTechDirector
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        facilityId={selectedFacility?.id}
      />
      <SendPrequalificationRequest
        isOpen={openPreqModal}
        onRequestClose={() => setPreqModalOpen(false)}
        handlePrequalification={handlePrequalification}
        row={selectedFacility}
      />
    </CustomContainer>
  );
};

export default Details;
