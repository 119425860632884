import { useDispatch } from "react-redux";
import { Box, Card, Typography } from "@mui/material";
import { Uploader } from "components";
import { uniqueId } from "lodash";
import { filesize } from "filesize";

import { uploadCriteriaCatalog } from "api";

const UploadCriteriaCatalog = () => {
  const dispatch = useDispatch();

  const handleUpload = (files) => {
    console.log("Files", files);

    const uploadedFiles = files?.map((file) => ({
      file,
      id: uniqueId(),
      name: file.name,
      readableSize: filesize(file.size),
      preview: URL.createObjectURL(file),
      progress: 0,
      type: file.type,
      uploaded: false,
      error: false,
      url: null,
    }));

    uploadedFiles.forEach((item) => processUpload(item));
  };

  const processUpload = (uploadedFile) => {
    const data = new FormData();

    data.append("file", uploadedFile.file);
    data.append("name", uploadedFile.name);

    dispatch(uploadCriteriaCatalog(uploadedFile));
  };

  return (
    <Box sx={{ display: "flex", height: "100%", alignItems: "center", justifyContent: "center" }}>
      <Card sx={{ width: "50%" }}>
        <Typography>Bitte Kriterienkatalog hochladen</Typography>
        <Uploader key={"1"} onUpload={handleUpload} />
      </Card>
    </Box>
  );
};

export default UploadCriteriaCatalog;
