import React from "react";
import { useParams } from "react-router";
import { jwtDecode } from "jwt-decode";
import moment from "moment";
import {
  Alert,
  Box,
  Button,
  Card,
  Container,
  IconButton,
  InputAdornment,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { acceptPasswordReset } from "api";

const PasswordReset = () => {
  const { token } = useParams();

  //PAGE Passwort(4)
  const [showPassword, setShowPassword] = React.useState(false);
  const [showPassword2, setShowPassword2] = React.useState(false);
  const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
  const [password, setPassword] = React.useState("");
  const [passwordError, setPasswordError] = React.useState(false);
  const [password2, setPassword2] = React.useState("");
  const [password2Error, setPassword2Error] = React.useState(false);
  const [unequalPasswordError, setUnequalPasswordError] = React.useState(false);

  const [alertOpen, setAlertOpen] = React.useState(false);
  const [alertMessage, setAlertMessage] = React.useState("");
  const [alertSeverity, setAlertSeverity] = React.useState("info");

  const decodedToken = jwtDecode(token);
  const expired = moment.unix(decodedToken.exp).isBefore(moment());

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  console.log(`Token: ${token}`);
  console.log("Expired:", decodedToken);

  const handleClick = async (event) => {
    event.preventDefault(); // Prevents the default anchor behavior

    if (!token || expired) {
      setAlertMessage("Link Fehlerhaft oder abgelaufen. Bitte versuchen Sie es erneut.");
      setAlertSeverity("error");
      setAlertOpen(true);
      return;
    }

    const response = await acceptPasswordReset(password, token);
    if (response.error) {
      setAlertMessage("Ein Fehler ist aufgetreten. Bitte versuchen Sie es erneut.");
      setAlertSeverity("error");
      setAlertOpen(true);
    } else {
      setAlertMessage("Passwort erfolgreich geändert. Sie können sich nun einloggen.");
      setAlertSeverity("success");
      setAlertOpen(true);
    }
  };

  return (
    <Container component="main" sx={{ display: "flex", flexDirection: "column" }}>
      <Card
        sx={{
          mt: 8,
          display: "flex",
          alignItems: "cente",
          borderWidth: 2,
          boxShadow: 3,
          flexDirection: "column",
          p: 3,
        }}
      >
        <Typography variant="h3">Passwort Ändern </Typography>
        <Typography sx={{ my: 2 }}>
          Bitte geben Sie Ihr neues Passwort, für den Account mit der Email {decodedToken?.email}, ein. Achten Sie
          darauf das es 8 Zeichen lang sein muss, Großbuchstaben und Kleinbuchstaben sowie sonderzeichen beinhalten
          muss.
        </Typography>
        <TextField
          label="Passwort"
          type={showPassword ? "text" : "password"}
          value={password}
          onChange={(event) => {
            const inputValue = event.target.value;
            setPassword(inputValue);

            // Validate the password against the regex pattern
            setPasswordError(!passwordRegex.test(inputValue));
            setUnequalPasswordError(inputValue !== password2);
          }}
          error={passwordError}
          helperText={
            passwordError
              ? "Das Passwort muss aus mindestens 8 Zeichen bestehen, einem Großbuchstaben, einem Kleinbuchstaben, einer Zahl und einem Sonderzeichen"
              : unequalPasswordError
              ? "Die Passwörter sind unterschiedlich"
              : ""
          }
          InputProps={{
            inputProps: {
              pattern: passwordRegex, // Assign the regex pattern
            },
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowPassword(!showPassword)}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <TextField
          label="Passwort wiederholen"
          type={showPassword2 ? "text" : "password"}
          value={password2}
          sx={{ mt: 3 }}
          onChange={(event) => {
            const inputValue = event.target.value;
            setPassword2(inputValue);
            // Validate the password against the regex pattern
            setPassword2Error(!passwordRegex.test(inputValue));
            setUnequalPasswordError(password !== inputValue);
          }}
          error={password2Error}
          helperText={
            password2Error
              ? "Das Passwort muss aus mindestens 8 Zeichen bestehen, einem Großbuchstaben, einem Kleinbuchstaben, einer Zahl und einem Sonderzeichen"
              : unequalPasswordError
              ? "Die Passwörter sind unterschiedlich"
              : ""
          }
          InputProps={{
            inputProps: {
              pattern: passwordRegex, // Assign the regex pattern
            },
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowPassword2(!showPassword2)}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword2 ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Card>
      <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
        <Button
          sx={{ display: "flex", alignSelf: "flex-end", textTransform: "none" }}
          disabled={passwordError || password2Error || password.length === 0 || password2.length === 0}
          variant="contained"
          onClick={handleClick}
        >
          Passwort ändern
        </Button>
      </Box>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={alertOpen}
        autoHideDuration={5000}
        onClose={() => setAlertOpen(false)}
      >
        <Alert variant="filled" severity={alertSeverity}>
          {alertMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default PasswordReset;
