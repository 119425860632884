import React, { useState } from "react";
import Dropzone from "react-dropzone";
import { DropContainer, UploadMessage } from "./styles";

const Upload = ({ onUpload }) => {
  const [isRejected, setIsRejected] = useState(false);
  const [isFileTooLarge, setIsFileTooLarge] = useState(false);

  const renderDragMessage = (isDragActive) => {
    if (!isDragActive && !isRejected && !isFileTooLarge) {
      return <UploadMessage>Dateien hierher ziehen...</UploadMessage>;
    }

    if (isRejected || isFileTooLarge) {
      return (
        <UploadMessage type="error">
          {isFileTooLarge ? "Datei zu groß (Max. 16 MB)" : "Datei nicht unterstützt"}
        </UploadMessage>
      );
    }

    return <UploadMessage type="success">Legen Sie die Dateien hier ab</UploadMessage>;
  };

  // Helper function to check MIME type based on file extension
  const mimeTypeCheck = (file) => {
    const supportedExtensions = [".pdf", ".doc", ".docx", ".xls", ".xlsx", ".eml", ".msg"];
    return supportedExtensions.some((ext) => file.name.toLowerCase().endsWith(ext));
  };

  return (
    <Dropzone
      onDrop={(acceptedFiles, fileRejections) => {
        console.log("fileUpload event", acceptedFiles);

        // Reset states
        setIsRejected(false);
        setIsFileTooLarge(false);

        if (acceptedFiles.length > 0) {
          onUpload(acceptedFiles);
        }

        if (fileRejections.length > 0) {
          console.log("Rejected files:", fileRejections);

          const isSizeRejected = fileRejections.some((file) => file.file.size > 16 * 1024 * 1024);
          const isMimeRejected = fileRejections.some((file) => !mimeTypeCheck(file.file));

          setIsRejected(isMimeRejected);
          setIsFileTooLarge(isSizeRejected);
        }
      }}
      accept={{
        "image/jpeg": [".jpg", ".jpeg"],
        "image/png": [".png"],
        "image/heic": [".heic"],
        "image/heif": [".heif"],
        "application/pdf": [".pdf"],
        "application/msword": [".doc"],
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [".docx"],
        "application/vnd.ms-excel": [".xls"],
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [".xlsx"],
        "message/rfc822": [".eml"],
        "application/vnd.ms-outlook": [".msg"],
      }}
      maxSize={16 * 1024 * 1024} // 16 MB
      onDragEnter={() => {
        setIsRejected(false);
        setIsFileTooLarge(false);
      }}
      onDragLeave={() => {
        setIsRejected(false);
        setIsFileTooLarge(false);
      }}
    >
      {({ getRootProps, getInputProps, isDragActive, isDragReject }) => (
        <DropContainer {...getRootProps()} isDragActive={isDragActive} isDragReject={isRejected || isFileTooLarge}>
          <input {...getInputProps()} />
          {renderDragMessage(isDragActive)}
        </DropContainer>
      )}
    </Dropzone>
  );
};

export default Upload;
