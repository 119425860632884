import React from "react";
import { useDispatch, useSelector } from "react-redux";

//mui component
import { TextField, Box, Card, Checkbox, Select, MenuItem, Divider, Typography } from "@mui/material";
import { Close } from "@mui/icons-material";

import { GetProvidingFields, GetSpecificAssessment } from "api";
import { AcceptRemovingSpecialist, RejectRemovingSpecialst } from "api/changes";
//component import, Card
import { CModal, LabelName, CustomDataGrid } from "components";
import { dynamicSpecialistFieldValue } from "utils/dynamicSpecialistFieldValue";

const AcceptRemoveSpecialist = ({ isOpen, onRequestClose, actionListItem }) => {
  // SPECIALIST LIST CREATION -----------------
  const [providing_fields, setProvidingFields] = React.useState([]);
  const [scope, setScope] = React.useState(1);
  const dispatch = useDispatch();

  const occupation = useSelector((state) => state.occupation);
  const criteriaCatalog = useSelector((state) => state.criteriaCatalog);
  const assessment = useSelector((state) =>
    state.assessment.find((item) => (isOpen ? item.id === actionListItem.assessment[0].id : { specialist: [] }))
  );

  React.useEffect(() => {
    if (isOpen === true) {
      dispatch(GetSpecificAssessment(actionListItem.assessment[0].id));
    }
  }, [isOpen, dispatch, actionListItem]);

  React.useEffect(() => {
    console.log("assessment", assessment);
    if (isOpen === true) {
      dispatch(GetProvidingFields(assessment?.specialist[0].id)).then(
        setProvidingFields(dynamicSpecialistFieldValue(criteriaCatalog, assessment?.specialist[0]))
      );
    }
  }, [assessment, criteriaCatalog, dispatch, isOpen]);

  const handleProvidingAction = (from, row) => {
    const element = providing_fields.findIndex((item) => item.code === row.code);

    if (from) providing_fields[element].in_store_selected = !providing_fields[element].in_store_selected;
    else providing_fields[element].field_sales_selected = !providing_fields[element].field_sales_selected;
  };

  const providingFieldsColumns = [
    {
      field: "in_store",
      headerName: "Geschäftslokal",
      flex: 0.15,
      editable: false,
      default: false,
      renderCell: ({ row }) => {
        if (row.in_store_allowed && row.in_store_deactivated) {
          return <Close color="error" />;
        }
        if (row.in_store_allowed) {
          return (
            <Checkbox
              disabled={row.in_store_deactivated}
              checked={row.in_store_selected}
              onClick={() => handleProvidingAction(true, row)}
            />
          );
        } else return <></>;
      },
    },
    {
      field: "field_sales",
      headerName: "Außendienst",
      flex: 0.15,
      editable: false,
      default: false,
      renderCell: ({ row }) => {
        if (row.field_sales_allowed && row.field_sales_deactivated) {
          return <Close color="error" />;
        }
        if (row.field_sales_allowed) {
          return (
            <Checkbox
              checked={row.field_sales_selected}
              onClick={() => handleProvidingAction(false, row)}
              icon={<Close />}
            />
          );
        } else return <></>;
      },
    },
    {
      field: "code",
      headerName: "Versorgungsbereiche",
      flex: 0.2,
      editable: false,
    },
    {
      field: "description",
      headerName: "Bezeichnung",
      flex: 0.4,
      editable: false,
    },
  ];

  // END CREATION ----------------------------------------------

  return (
    <CModal
      modalLabel="Wegfall Versorgungsbereiche"
      open={isOpen}
      buttonLabel="Annehmen"
      backButtonLabel="Ablehnen"
      onButtonPressed={() => {
        dispatch(AcceptRemovingSpecialist(assessment.id, scope));
        onRequestClose();
      }}
      onBackButtonPressed={() => {
        RejectRemovingSpecialst(actionListItem.id, actionListItem?.prequalification[0].id);
        onRequestClose();
      }}
      onRequestClose={() => onRequestClose()}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          mt: 2,
          mb: 2,
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "row", mt: 2, mx: 2, flex: 1 }}>
          <Box sx={{ display: "flex", flexDirection: "column", flex: 0.5 }}>
            <Box sx={{ display: "flex", flexDirection: "row" }}>
              <LabelName titel="Unternehmen" />
              <TextField sx={{ flex: 0.5 }} name="name" value={actionListItem?.facility[0]?.name} />
            </Box>
            <Box sx={{ display: "flex", flexDirection: "row" }}>
              <LabelName titel="Zusatz" />
              <TextField
                name="extra_information"
                sx={{ flex: 0.5 }}
                value={actionListItem?.facility[0]?.extra_information}
              />
            </Box>
            <Box sx={{ display: "flex", flexDirection: "row" }}>
              <LabelName titel="IK-Nummer" />
              <TextField name="ik_number" sx={{ flex: 0.5 }} value={actionListItem?.facility[0]?.ik_number} />
            </Box>
            <Box sx={{ display: "flex", flexDirection: "row" }}>
              <LabelName titel="Straße, Hausnummer" />
              <TextField name="street" sx={{ flex: 0.25 }} value={actionListItem?.facility[0]?.street} />
              <TextField name="street_number" sx={{ flex: 0.25 }} value={actionListItem?.facility[0]?.street_number} />
            </Box>
            <Box sx={{ display: "flex", flexDirection: "row" }}>
              <LabelName titel="Postleitzahl, Stadt" />
              <TextField name="postcode" sx={{ flex: 0.25 }} value={actionListItem?.facility[0]?.postcode} />
              <TextField name="city" sx={{ flex: 0.25 }} value={actionListItem?.facility[0]?.city} />
            </Box>
            <Box sx={{ display: "flex", flexDirection: "row", mt: 2 }}>
              <LabelName titel="IBAN" />
              <TextField name="IBAN" sx={{ flex: 0.5 }} value={actionListItem?.facility[0]?.IBAN} />
            </Box>
            <Box sx={{ display: "flex", flexDirection: "row" }}>
              <LabelName titel="BIC" />
              <TextField name="BIC" sx={{ flex: 0.5 }} value={actionListItem?.facility[0]?.BIC} />
            </Box>
            <Box sx={{ display: "flex", flexDirection: "row" }}>
              <LabelName titel="Scope" />
              <Select
                sx={{ flex: 0.5 }}
                value={scope}
                onChange={(e) => {
                  setScope(e.target.value);
                }}
              >
                <MenuItem value={1}>Orthopäditechnik (1)</MenuItem>
                <MenuItem value={2}>Orth.Schuhtechnik (2)</MenuItem>
                <MenuItem value={3}>Hörakustik (3)</MenuItem>
                <MenuItem value={4}>Augenoptik (4)</MenuItem>
                <MenuItem value={5}>Blindenführhunde (5)</MenuItem>
                <MenuItem value={6.1}>Apotheke (6.1)</MenuItem>
                <MenuItem value={6.2}>Friseur (6.2)</MenuItem>
                <MenuItem value={6.3}>Altenheim (6.3)</MenuItem>
                <MenuItem value={6.4}>Sonstige (6.4)</MenuItem>
              </Select>
            </Box>
          </Box>

          <Box sx={{ display: "flex", flexDirection: "column", flex: 0.5 }}>
            <Box sx={{ display: "flex", flexDirection: "row" }}>
              <LabelName titel="Anrede" />
              <TextField
                sx={{ flex: 0.5 }}
                value={
                  assessment?.specialist[0]?.gender === 0
                    ? "Herr"
                    : assessment?.specialist[0]?.gender === 1
                    ? "Frau"
                    : ""
                }
              />
            </Box>
            <Box sx={{ display: "flex", flexDirection: "row" }}>
              <LabelName titel="Vorname" />
              <TextField sx={{ flex: 0.5 }} value={assessment?.specialist[0]?.first_name} />
            </Box>
            <Box sx={{ display: "flex", flexDirection: "row" }}>
              <LabelName titel="Nachname" />
              <TextField sx={{ flex: 0.5 }} value={assessment?.specialist[0]?.last_name} />
            </Box>
            <Box sx={{ display: "flex", flexDirection: "row" }}>
              <LabelName titel="Email" />
              <TextField sx={{ flex: 0.5 }} value={assessment?.specialist[0]?.email} />
            </Box>
            <Box sx={{ display: "flex", flexDirection: "row" }}>
              <LabelName titel="Beruf / Qualifizierung" />
              <TextField
                sx={{ flex: 0.7 }}
                value={
                  occupation[
                    occupation.findIndex((item) => item.short_form.includes(assessment?.specialist[0]?.qualification))
                  ]?.description
                }
              />
            </Box>
            {assessment?.comment?.length ? (
              <Box sx={{ mt: 2 }}>
                <CustomDataGrid
                  rows={assessment.comment ?? []}
                  columns={[{ field: "text", headerName: "Nachricht", flex: 1 }]}

                  // checkboxSelection={checkboxSelection}
                  // onSelectionModelChange={(rows) => setSelectedFields(rows)}
                />
              </Box>
            ) : (
              // You can render an alternative component or message when assessment.comment is empty or undefined
              <p>No comments available</p>
            )}
          </Box>
        </Box>
      </Box>

      <Divider textAlign="left" sx={{ my: 4 }}>
        <Typography fontWeight={"bold"}>Versorgungsbereiche</Typography>
      </Divider>

      <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", mx: 2 }}>
        <Card sx={{ p: 4, flex: 1 }}>
          <CustomDataGrid
            rows={providing_fields}
            columns={providingFieldsColumns ?? []}

            // checkboxSelection={checkboxSelection}
            // onSelectionModelChange={(rows) => setSelectedFields(rows)}
          />
        </Card>
      </Box>

      <Divider textAlign="left" sx={{ my: 4 }}>
        <Typography fontWeight={"bold"}>Entfallene Vorraussetzungen</Typography>
      </Divider>
      <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", mx: 2 }}>
        <Card sx={{ p: 4, flex: 1 }}>
          <CustomDataGrid
            rows={assessment?.prequalificationItem ?? []}
            columns={[{ field: "description", flex: 1 }]}

            // checkboxSelection={checkboxSelection}
            // onSelectionModelChange={(rows) => setSelectedFields(rows)}
          />
        </Card>
      </Box>
    </CModal>
  );
};

export default AcceptRemoveSpecialist;

//Bearbeitungsfunktion.
//Antragsstellung dokument ausdruckbar.
//Bei bestätigung email.
//alle Fachlichen Leiter anzeigen.
//Ansprechpartner.
