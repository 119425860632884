import React from "react";
import { useLocation } from "react-router";
import { Box, Typography, Card, Divider, Button, TextField, Select, MenuItem, Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { CustomUpload, LabelName, CustomContainer } from "components";
import PreconditionTab from "../ReusableTabs/preconditionTab";
import { getAdminList } from "api";
import { AssessmentCheckModal } from "pages/modals";
import ProvidingFieldsTab from "./tabs/providingFieldsTab";
// import SpecialistInfo from "./extentions/specialistInfo";
import { AssessmentType } from "assets/type.ts";
import RemoveSpecialistEvaluation from "./changeSpecificPages/removeSpecialist.js";

const ContactPersonDetails = ({ contactPerson }) => (
  <Card sx={{ p: 2 }}>
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Grid container alignItems="center" spacing={2}>
          <Grid item xs={3}>
            <LabelName titel="Name" />
          </Grid>
          <Grid item xs={9}>
            <TextField
              fullWidth
              name="name"
              value={`${contactPerson?.first_name} ${contactPerson?.last_name}`}
              variant="outlined"
              InputProps={{ readOnly: true }}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Grid container alignItems="center" spacing={2}>
          <Grid item xs={3}>
            <LabelName titel="Email" />
          </Grid>
          <Grid item xs={9}>
            <TextField
              fullWidth
              name="email"
              value={contactPerson?.email}
              variant="outlined"
              InputProps={{ readOnly: true }}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Grid container alignItems="center" spacing={2}>
          <Grid item xs={3}>
            <LabelName titel="Telefonnummer" />
          </Grid>
          <Grid item xs={9}>
            <TextField
              fullWidth
              name="phone_number"
              value={contactPerson?.phone_number}
              variant="outlined"
              InputProps={{ readOnly: true }}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Grid container alignItems="center" spacing={2}>
          <Grid item xs={3}>
            <LabelName titel="Faxnummer" />
          </Grid>
          <Grid item xs={9}>
            <TextField
              fullWidth
              name="fax_number"
              value={contactPerson?.fax_number}
              variant="outlined"
              InputProps={{ readOnly: true }}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  </Card>
);
const FacilityDetails = ({ facility }) => (
  <Card sx={{ p: 2, mt: 2, mx: 2 }}>
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Grid container alignItems="center" spacing={2}>
          <Grid item xs={3}>
            <LabelName titel="Unternehmen" />
          </Grid>
          <Grid item xs={9}>
            <TextField
              fullWidth
              name="name"
              value={facility?.name}
              variant="outlined"
              InputProps={{ readOnly: true }}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Grid container alignItems="center" spacing={2}>
          <Grid item xs={3}>
            <LabelName titel="Zusatz" />
          </Grid>
          <Grid item xs={9}>
            <TextField
              fullWidth
              name="extra_information"
              value={facility?.extra_information}
              variant="outlined"
              InputProps={{ readOnly: true }}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Grid container alignItems="center" spacing={2}>
          <Grid item xs={3}>
            <LabelName titel="IK-Nummer" />
          </Grid>
          <Grid item xs={9}>
            <TextField
              fullWidth
              name="ik_number"
              value={facility?.ik_number}
              variant="outlined"
              InputProps={{ readOnly: true }}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Grid container alignItems="center" spacing={2}>
          <Grid item xs={3}>
            <LabelName titel="Straße, Hausnummer" />
          </Grid>
          <Grid item xs={9}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  name="street"
                  value={facility?.street}
                  variant="outlined"
                  InputProps={{ readOnly: true }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  name="street_number"
                  value={facility?.street_number}
                  variant="outlined"
                  InputProps={{ readOnly: true }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Grid container alignItems="center" spacing={2}>
          <Grid item xs={3}>
            <LabelName titel="Postleitzahl, Stadt" />
          </Grid>
          <Grid item xs={9}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  name="postcode"
                  value={facility?.postcode}
                  variant="outlined"
                  InputProps={{ readOnly: true }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  name="city"
                  value={facility?.city}
                  variant="outlined"
                  InputProps={{ readOnly: true }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Grid container alignItems="center" spacing={2}>
          <Grid item xs={3}>
            <LabelName titel="IBAN" />
          </Grid>
          <Grid item xs={9}>
            <TextField
              fullWidth
              name="IBAN"
              value={facility?.IBAN}
              variant="outlined"
              InputProps={{ readOnly: true }}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Grid container alignItems="center" spacing={2}>
          <Grid item xs={3}>
            <LabelName titel="BIC" />
          </Grid>
          <Grid item xs={9}>
            <TextField fullWidth name="BIC" value={facility?.BIC} variant="outlined" InputProps={{ readOnly: true }} />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Grid container alignItems="center" spacing={2}>
          <Grid item xs={3}>
            <LabelName titel="Scope" />
          </Grid>
          <Grid item xs={9}>
            <Select
              fullWidth
              value={facility?.scope}
              // Add onChange handler if needed
            >
              <MenuItem value={1}>Orthopädietechnik (1)</MenuItem>
              <MenuItem value={2}>Orthopädieschuhtechnik (2)</MenuItem>
              <MenuItem value={3}>Hörakustik (3)</MenuItem>
              <MenuItem value={4}>Augenoptik (4)</MenuItem>
              <MenuItem value={5}>Blindenführhunde (5)</MenuItem>
              <MenuItem value={6.1}>Apotheke (6.1)</MenuItem>
              <MenuItem value={6.2}>Friseur (6.2)</MenuItem>
              <MenuItem value={6.3}>Altenheim (6.3)</MenuItem>
              <MenuItem value={6.4}>Sonstige (6.4)</MenuItem>
            </Select>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  </Card>
);

const RenderTabs = ({ assessment, locate }) => {
  switch (assessment?.type) {
    case AssessmentType.FIRSTMONITORING:
    case AssessmentType.SECONDMONITORING:
    case AssessmentType.MOVE:
    case AssessmentType.CHANGEOFROOM:
    case AssessmentType.CHANGECOMPANYNAME:
    case AssessmentType.CHANGEOFLEGALFORM:
      return (
        <PreconditionTab
          selectedProcess={locate.state.assessment[0]}
          processType="assessment"
          selectedFacility={assessment?.facility[0]}
        />
      );
    case AssessmentType.CHANGEOFCEO:
    case AssessmentType.CLOSECOMPANY:
      return (
        <Box>
          {assessment?.prequalificationItem?.map((item) => (
            <CustomUpload
              key={item.titel}
              titel={item.titel}
              description={item.description}
              specialist={item.specialist}
              status={item.status}
              preqStatus={item.status}
              files={item.image}
              providingFields={item.providing_fields}
            />
          ))}
        </Box>
      );
    case AssessmentType.EXTENDPROVIDINGFIELDS:
    case AssessmentType.REMOVEPROVIDINGFIELDS:
      return <ProvidingFieldsTab selectedAssessment={locate.state.assessment[0].id} />;
    case AssessmentType.ADDSPECIALIST:
    case AssessmentType.REMOVESPECIALIST:
      return <RemoveSpecialistEvaluation assessment={assessment} />;
    default:
      return null;
  }
};

const AssessmentPage = () => {
  const locate = useLocation();
  const dispatch = useDispatch();
  const [assessmentCheckModalState, setAssessmentCheckModalState] = React.useState(false);
  const [clicked, setClicked] = React.useState(false);

  const assessment = useSelector((state) => {
    const index = state.assessment.findIndex((item) => item.id === locate.state.assessment[0].id);
    return state.assessment[index];
  });

  const main_contact_person = assessment?.facility[0]?.main_contact_person;

  const sendAssessment = () => {
    dispatch(getAdminList()).then(() => setClicked(false));
    setAssessmentCheckModalState(true);
  };

  return (
    <CustomContainer>
      {assessment?.facility ? (
        <Box>
          <Card sx={{ display: "flex", alignItems: "center", p: 2, boxShadow: 2 }}>
            <Box>
              <Typography variant="h4">{assessment?.message}</Typography>
              {assessment?.comment && <Typography variant="h4">{assessment?.comment[0]?.text}</Typography>}
            </Box>
          </Card>
          <Box>
            <Divider textAlign="left" sx={{ mt: 2, mb: 2 }}>
              <Typography fontWeight="bold">Details</Typography>
            </Divider>
            <Grid container spacing={2}>
              {main_contact_person && (
                <Grid item xs={12} md={6}>
                  <Typography fontWeight="bold" mb={1}>
                    Ansprechpartner
                  </Typography>
                  <ContactPersonDetails contactPerson={main_contact_person} />
                </Grid>
              )}
              <Grid item xs={12} md={6}>
                <Typography fontWeight="bold" mb={1}>
                  Unternehmensdaten
                </Typography>
                <FacilityDetails facility={assessment?.facility[0]} />
              </Grid>
            </Grid>
          </Box>
          <Divider textAlign="left" sx={{ mt: 2, mb: 2 }}>
            <Typography fontWeight="bold">Überwachungs / Änderungsbezogene Daten</Typography>
          </Divider>
          <RenderTabs assessment={assessment} locate={locate} />
          <Box>
            <Button
              variant="contained"
              disabled={clicked}
              onClick={() => {
                setClicked(true);
                sendAssessment();
              }}
            >
              Absenden
            </Button>
          </Box>
        </Box>
      ) : (
        <Typography>No Assessment Data Available</Typography>
      )}
      {assessment?.facility && (
        <AssessmentCheckModal
          isOpen={assessmentCheckModalState}
          onRequestClose={() => setAssessmentCheckModalState(false)}
          assessment={assessment}
        />
      )}
    </CustomContainer>
  );
};

export default AssessmentPage;
