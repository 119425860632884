import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

//MUI Imports
import { Typography, Card, Box, Divider } from "@mui/material";
import { GridToolbarQuickFilter } from "@mui/x-data-grid";

import { CompanyList } from "api";
import { companyColumns } from "layouts/DataGridLayouts";
import CompanyFacilityOverview from "../reuseablePages/companyFacilityOverview";
import { CustomDataGrid } from "components";

const SVCompanyCard = () => {
  const dispatch = useDispatch();

  const [selectedCompany, setSelectedCompany] = React.useState(null);

  useEffect(() => {
    dispatch(CompanyList());
  }, [dispatch]);

  const companies = useSelector((state) => (state.company ? state.company : []));

  return (
    <Box sx={{ my: 1 }}>
      <Card className="company-card" sx={{ display: "flex", flexDirection: "column", p: 2 }}>
        <Divider textAlign="left" sx={{ display: "flex", alignItems: "center", mb: 2 }}>
          <Typography variant="h5">Unternehmen</Typography>
        </Divider>
        <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
          <CustomDataGrid
            rows={companies}
            columns={companyColumns}
            initialPageSize={10}
            checkboxSelection
            components={{
              Toolbar: (props) => (
                <>
                  <Box sx={{ display: "flex", flex: 1, p: 1, justifyContent: "space-between", alignItems: "center" }}>
                    <GridToolbarQuickFilter showQuickFilter {...props.toolbar} />
                  </Box>
                  <Divider sx={{ mt: 1, mb: 1 }} />
                </>
              ),
            }}
            componentsProps={{
              toolbar: {
                showQuickFilter: true,
              },
            }}
            onSelectionModelChange={(rows) => {
              console.log(rows);
              if (rows.length === 0) {
                if (selectedCompany) {
                  setSelectedCompany(null);
                }
                return;
              }
              const actionListObject = companies?.find((item) => item.id === rows[0]);
              setSelectedCompany(actionListObject);
            }}
          />
          {selectedCompany && <CompanyFacilityOverview object={selectedCompany} type={0} />}
        </Box>
      </Card>
    </Box>
  );
};

export default SVCompanyCard;
