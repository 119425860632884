import React, { useState } from "react";
import { Box, MenuItem, Select, FormControl, InputLabel } from "@mui/material";
import CollapseItemList from "./CollapseItemList";

const statusOrder = {
  ACCEPTED: 1,
  REJECTED: 2,
  DEFAULT: 3, // Any other status
};

const SortableCollapseItemList = ({ process, preqItemIndex }) => {
  const [sortBy, setSortBy] = useState("date");
  const [dateOrder, setDateOrder] = useState("desc"); // Default to newest first
  const [statusFilter, setStatusFilter] = useState("ALL");

  const handleSortChange = (event) => {
    setSortBy(event.target.value);
    if (event.target.value !== "date") setDateOrder("desc"); // Reset date order when switching away
    setStatusFilter("ALL"); // Reset status filter when switching sorting
  };

  const handleDateOrderChange = (event) => {
    setDateOrder(event.target.value);
  };

  const handleStatusFilterChange = (event) => {
    setStatusFilter(event.target.value);
  };

  let sortedImages = process?.prequalificationItem[preqItemIndex]?.image?.slice().sort((a, b) => {
    if (sortBy === "date") {
      return dateOrder === "asc" ? new Date(a.date) - new Date(b.date) : new Date(b.date) - new Date(a.date);
    }
    if (sortBy === "status") {
      return (statusOrder[a.status] || statusOrder.DEFAULT) - (statusOrder[b.status] || statusOrder.DEFAULT);
    }
    return 0;
  });

  // Apply status filter if sorting by status
  if (sortBy === "status" && statusFilter !== "ALL") {
    sortedImages = sortedImages.filter((item) => item.status === statusFilter);
  }

  return (
    <>
      {sortedImages.length > 0 && (
        <Box sx={{ mt: 2 }}>
          {/* Sorting Dropdown */}
          <FormControl size="small" sx={{ mb: 2, width: 200 }}>
            <InputLabel>Sort By</InputLabel>
            <Select
              value={sortBy}
              onChange={handleSortChange}
              label="Sortieren nach"
              MenuProps={{
                anchorOrigin: { vertical: "bottom", horizontal: "left" },
                transformOrigin: { vertical: "top", horizontal: "left" },
                sx: { maxHeight: 250 },
              }}
            >
              <MenuItem value="date">Datum</MenuItem>
              <MenuItem value="status">Status</MenuItem>
            </Select>
          </FormControl>

          {/* Date Order Dropdown (Only when sorting by date) */}
          {sortBy === "date" && (
            <FormControl size="small" sx={{ mb: 2, ml: 2, width: 200 }}>
              <InputLabel>Date Order</InputLabel>
              <Select
                value={dateOrder}
                onChange={handleDateOrderChange}
                label="Date Order"
                MenuProps={{
                  anchorOrigin: { vertical: "bottom", horizontal: "left" },
                  transformOrigin: { vertical: "top", horizontal: "left" },
                  sx: { maxHeight: 250 },
                }}
              >
                <MenuItem value="desc">Neuste zuerst</MenuItem>
                <MenuItem value="asc">Älteste zuerst</MenuItem>
              </Select>
            </FormControl>
          )}

          {/* Status Filter Dropdown (Only when sorting by status) */}
          {sortBy === "status" && (
            <FormControl size="small" sx={{ mb: 2, ml: 2, width: 200 }}>
              <InputLabel>Filter Status</InputLabel>
              <Select
                value={statusFilter}
                onChange={handleStatusFilterChange}
                label="Filter Status"
                MenuProps={{
                  anchorOrigin: { vertical: "bottom", horizontal: "left" },
                  transformOrigin: { vertical: "top", horizontal: "left" },
                  sx: { maxHeight: 250 },
                }}
              >
                <MenuItem value="ALL">Alle</MenuItem>
                <MenuItem value="ACCEPTED">Akzeptiert</MenuItem>
                <MenuItem value="REJECTED">Abgelehnt</MenuItem>
              </Select>
            </FormControl>
          )}

          {/* Pass sorted & filtered images to the list */}
          <CollapseItemList images={sortedImages} process={process} preqItemIndex={preqItemIndex} />
        </Box>
      )}
    </>
  );
};

export default SortableCollapseItemList;
