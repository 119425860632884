import React from "react";
//mui import
import { Box, TextField, Button, Tabs, Tab, Typography, Card, Divider, Autocomplete } from "@mui/material";

//REDUX
import { useDispatch } from "react-redux";
import { useLocation } from "react-router";
//api
import {
  CompanyList,
  GetFacilities,
  GetFacilityPrequalifications,
  GetSpecialist,
  GetSpecificPrequalificationItemList,
  getAdminList,
  getFacilityHistory,
  getSpecificFacility,
  getSpecificSpecialist,
} from "api";
import instance from "api/axiosConfig";

// import { useLocation } from "react-router";

import { CompanyTab, FacilityTab, PrequalificationTab, SpecialistTab, PreconditionTab, EvaluationTab } from "./tabs";
import { SelectFacility } from "pages/modals";

import { StatusType } from "assets/type.ts";
import { CustomContainer, CustomTabPanel } from "components";

const EvaluationPage = () => {
  const dispatch = useDispatch();
  const locate = useLocation();

  //CONST

  const [selectedCompany, setSelectedCompany] = React.useState(null);
  const [selectedFacility, setSelectedFacility] = React.useState(null);
  const [selectedPrequalification, setSelectedPrequalification] = React.useState(null);
  const [selectedAdmin, setSelectedAdmin] = React.useState(null);
  const [selectedSpecialist, setSelectedSpecialist] = React.useState(null);
  const [facilityModal, setFacilityModal] = React.useState(false);
  const [facilityList, setFacilityList] = React.useState(null);
  const [main_contact_person, setMainContactPerson] = React.useState(null);

  const [, setLoading] = React.useState(false);

  React.useEffect(() => {
    if (locate.state) {
      setSelectedTabIndex(1);
      const { company, facility, prequalification, specialist } = locate.state;

      if (company && company.length > 0) {
        setSelectedCompany(company[0]);
      }
      // Set the first object from the facility array to the state
      if (facility && facility.length > 0) {
        dispatch(getSpecificFacility(facility[0]?.id)).then((response) => {
          const facility = response;
          setLoading(true);
          setSelectedCompany(facility?.company?.[0]);
          setSelectedFacility(facility);
          setMainContactPerson(facility.main_contact_person);
          dispatch(getFacilityHistory(facility.id));

          const prequalificationId = facility.prequalification?.find((item) => item.active === true)?.id;
          if (prequalificationId) {
            dispatch(GetFacilityPrequalifications(facility.id)).then((response) => {
              if (!response.error) setSelectedPrequalification(response.value[0]);
            });
            dispatch(GetSpecificPrequalificationItemList(prequalificationId));
          }

          setSelectedTabIndex(1);
          setLoading(false);
        });

        if (company.length === 0) {
          dispatch(CompanyList());
        }
      }
      // Set the first object from the prequalification array to the state
      if (prequalification && prequalification.length > 0) {
        dispatch(GetSpecificPrequalificationItemList(prequalification[0]?.id));
        setSelectedPrequalification(prequalification[0]);
      }
      if (specialist && specialist.length > 0) {
        setSelectedSpecialist(specialist[0]);
      }
      // if (account && account.length > 0) {
      //   setAccount(account);
      // }
      // if (prequalificationItem && prequalificationItem.length > 0) {
      //   // Do something with the prequalificationItem data
      // }
      // if (contact_person && contact_person.length > 0) {
      //   // Do something with the contact_person data
      // }

      // ... other checks
    }
  }, [locate.state, dispatch]);

  // React.useEffect(() => {
  //   setSelectedPrequalification(null);
  // }, [selectedFacility]); //Need to redo at some point

  //FUNCTION TO CHECK locate.state and change load selected stuff for it.

  //Tabs
  const [selectedTabIndex, setSelectedTabIndex] = React.useState(0);
  const [searchValue, setSearchValue] = React.useState("");
  const [searchOptions, setSearchOptions] = React.useState([]);

  React.useEffect(() => {
    dispatch(CompanyList());
    dispatch(getAdminList());
  }, [dispatch]);

  // React.useEffect(() => {
  //   console.log("selectedCompany", selectedCompany);
  //   dispatch(getCompanyMainContactPerson(selectedCompany.id));
  // }, [selectedCompany, dispatch]);

  const openSelectedTab = (selectedTabIndex) => {
    switch (selectedTabIndex) {
      case 0: {
        dispatch(CompanyList());
        setSelectedTabIndex(selectedTabIndex);
        break;
      }
      case 1: {
        if (!locate) {
          dispatch(GetFacilities(selectedCompany.id));
        }
        setSelectedTabIndex(selectedTabIndex);
        break;
      }
      case 2: {
        dispatch(GetFacilityPrequalifications(selectedFacility.id)).then((response) => {
          const responseValue = response.value;

          // Initialize selectedPrequalification outside of the find method
          const currentSelectedPrequalification = selectedPrequalification; // or use a different state or variable

          const newSelectedPrequalification = responseValue.find(
            (item) => item.id === currentSelectedPrequalification?.id
          );

          if (newSelectedPrequalification) setSelectedPrequalification(newSelectedPrequalification);
        });

        setSelectedTabIndex(selectedTabIndex);
        break;
      }
      case 3: {
        dispatch(GetSpecialist(selectedFacility.id, true));
        setSelectedTabIndex(selectedTabIndex);

        break;
      }
      case 4: {
        if (selectedPrequalification !== undefined) {
          dispatch(GetSpecificPrequalificationItemList(selectedPrequalification?.id)).then((response) => {
            let preq = true;
            if (response.length === 0) {
              preq = false;
            }
            if (preq === false) {
              alert("Die Vorraussetzungsfelder wurden Kundenseitig noch nicht erstellt.");
              return;
            } else {
              setSelectedTabIndex(selectedTabIndex);
            }
          });
        } else {
          alert("Sie haben keine Präqualifizierung ausgewählt");
        }

        break;
      }
      case 5: {
        dispatch(getAdminList());
        setSelectedTabIndex(selectedTabIndex);
        break;
      }
      default:
        break;
    }
  };

  const elasticSearch = async (value) => {
    const result = await instance.get(`/search?query=${value}`);
    setSearchOptions(result?.data);
  };

  if (
    locate.state !== null &&
    locate.state.defaultTab !== undefined &&
    locate.state.defaultTab !== null &&
    locate.state.defaultTab === 4
  ) {
    openSelectedTab(locate.state.defaultTab);
  }

  const switchTab = async (isSwitchToNextTab) => {
    if (
      (selectedTabIndex === 0 && !isSwitchToNextTab) ||
      (selectedTabIndex === 5 && isSwitchToNextTab) ||
      (selectedTabIndex === 3 && isSwitchToNextTab && selectedPrequalification?.status === StatusType.REJECTED) //NEEDS REWORK IF GETS REJECTED AFTER DATA ADDED
    ) {
      return;
    }

    openSelectedTab(selectedTabIndex + (isSwitchToNextTab ? 1 : -1));
    // setSelectedTabIndex(selectedTabIndex + (isSwitchToNextTab ? 1 : -1));
  };

  const openFacilityModal = async () => {
    dispatch(GetFacilities(selectedCompany.id)).then((response) => {
      setFacilityList(response);
      setFacilityModal(true);
    });
  };

  const openSelectedFacility = async (facility, shouldRedirect) => {
    if (!facility) return;

    setLoading(true);
    setSelectedCompany(facility?.company?.[0]);
    setSelectedFacility(facility);
    setMainContactPerson(facility.main_contact_person);
    dispatch(getFacilityHistory(facility.id));

    if (shouldRedirect) setSelectedTabIndex(1);

    if (facility.prequalification.length > 0) {
      const prequalificationId = facility.prequalification[0]?.id;

      dispatch(GetFacilityPrequalifications(facility.id)).then((response) => {
        if (!response.error) setSelectedPrequalification(response.value[0]);
      });

      dispatch(GetSpecialist(facility.id));
      dispatch(GetSpecificPrequalificationItemList(prequalificationId)).then((response) => {
        if (response.length === 0) {
          alert("Die Vorraussetzungsfelder wurden Kundenseitig noch nicht erstellt.");
        } else if (shouldRedirect) {
          setSelectedTabIndex(4);
        }
        setFacilityModal(false);
      });
    }

    setLoading(false);
  };

  const handleFacilitySelection = async (companyId, shouldRedirect) => {
    if (!companyId) return;

    setLoading(true);
    dispatch(GetFacilities(companyId)).then((response) => {
      const mainHouse = response.find((item) => item.main_house);
      openSelectedFacility(mainHouse, shouldRedirect);
    });
  };

  const fastForward = async () => {
    handleFacilitySelection(selectedCompany?.id, true);
  };

  const fastForwardWithoutRedirect = async (selectedCompany) => {
    if (!selectedCompany) return resetStates();
    handleFacilitySelection(selectedCompany.id, false);
  };

  const fastForwardFacility = async (facility) => {
    if (!facility) return;

    setLoading(true);
    dispatch(getSpecificFacility(facility.id)).then((response) => openSelectedFacility(response, true));
  };

  const fastForwardFacilityWithoutRedirect = async (facility) => {
    if (!facility) return;

    setLoading(true);
    dispatch(getSpecificFacility(facility.id)).then((response) => openSelectedFacility(response, false));
  };

  const fastForwardSpecialist = async (specialist) => {
    if (!specialist) return;

    dispatch(getSpecificSpecialist(specialist.id)).then((response) => {
      if (response?.facility?.length) {
        fastForwardFacilityWithoutRedirect(response.facility[0]);
      }
    });
  };

  const fastForwardSearch = async (value) => {
    if (!value) return;

    setLoading(true);

    switch (value.source) {
      case "company":
        handleFacilitySelection(value.id, false);
        break;
      case "facility":
        fastForwardFacilityWithoutRedirect(value);
        setSelectedTabIndex(1);
        break;
      case "specialist":
        fastForwardSpecialist(value);
        break;
      default:
        fastForwardWithoutRedirect(value);
    }

    setSearchValue("");
    setSearchOptions([]);
  };

  const resetStates = () => {
    setSelectedCompany(null);
    setSelectedFacility(null);
    setSelectedPrequalification(null);
    setSelectedAdmin(null);
    setSelectedSpecialist(null);
    setFacilityModal(null);
    setFacilityList(null);
    setMainContactPerson(null);
  };

  const handleSearchInput = (value) => {
    if (value?.trim()) {
      setSearchValue(value);
      elasticSearch(value);
    } else {
      setSearchValue("");
      setSearchOptions([]);
    }
  };

  const getOptionLabel = (option) => {
    switch (option.source) {
      case "company":
        return `🏢 Unternehmen: ${option.ik_number} - ${option.name}`;
      case "facility":
        return `🏥 Betriebsstätte: ${option.ik_number} - ${option.name}`;
      case "specialist":
        return `👤 ${option.first_name} ${option.last_name} - ${option.qualification}`;
      default:
        return option.name || "Unbekannt";
    }
  };

  return (
    <CustomContainer>
      <Box sx={{ display: "flex", flex: 1, flexDirection: "row", justifyContent: "space-between" }}>
        <Box sx={{ display: "flex", flexDirection: "row", flex: 0.5, alignItems: "center", gap: 2 }}>
          <Autocomplete
            sx={{ mt: 2, flex: 0.6 }}
            disablePortal
            loading={searchOptions.length === 0 && searchValue.length < 5}
            value={searchValue}
            options={searchOptions}
            noOptionsText="Keine Ergebnisse"
            filterOptions={(x) => x}
            getOptionLabel={(option) => option.name || searchValue}
            onInputChange={(e, value) => handleSearchInput(value)}
            onChange={(e, value) => fastForwardSearch(value)}
            renderInput={(params) => <TextField {...params} label="Suche" placeholder="Suchbegriff eingeben..." />}
            renderOption={(props, option) => {
              const label = getOptionLabel(option);

              return (
                <li
                  {...props}
                  key={option.id}
                  style={{
                    backgroundColor: "#f9fafb",
                    padding: "10px",
                    display: "flex",
                    alignItems: "center",
                    gap: "8px",
                    cursor: "pointer",
                    transition: "background 0.2s",
                  }}
                  onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = "#eef1f5")}
                  onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = "#f9fafb")}
                >
                  {label}
                </li>
              );
            }}
            isOptionEqualToValue={(option, value) => option.id === value.id}
          />
          <Button variant="contained" onClick={() => elasticSearch(searchValue)}>
            Suchen
          </Button>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            flex: 0.5,
            visibility: selectedCompany === null || main_contact_person === null ? "hidden" : "visible",
          }}
        >
          <Card sx={{ p: 1 }}>
            <Divider>
              <Typography fontWeight={"bold"}>Ansprechpartner</Typography>
            </Divider>
            <Typography>
              {selectedFacility
                ? `${selectedFacility.main_contact_person?.first_name} ${selectedFacility.main_contact_person?.last_name}`
                : `${main_contact_person?.first_name} ${main_contact_person?.last_name}`}
            </Typography>
            <Typography>
              {selectedFacility
                ? selectedFacility.main_contact_person?.phone_number
                : main_contact_person?.phone_number}
            </Typography>
            <Typography>
              {selectedFacility ? selectedFacility.main_contact_person?.fax_number : main_contact_person?.fax_number}
            </Typography>
            <Typography>
              {selectedFacility ? selectedFacility.main_contact_person?.email : main_contact_person?.email}
            </Typography>
          </Card>
        </Box>
      </Box>
      <Box sx={{ borderBottom: 1, borderColor: "divider", display: "flex", flex: 1, width: "100%" }}>
        <Tabs
          variant="fullWidth"
          sx={{ width: "100%" }}
          selectionFollowsFocus
          value={selectedTabIndex}
          onChange={(e, selectedTabIndex) => openSelectedTab(selectedTabIndex)}
        >
          <Tab label="Unternehmen" />
          <Tab disabled={locate ? !selectedFacility : !selectedCompany} label="Betriebsstätten" />
          <Tab disabled={locate ? !selectedFacility : !selectedPrequalification} label="PQ-Vorgänge" />
          <Tab disabled={!selectedPrequalification} label="Fachliche Leitung & Ansprechpartner/-in" />
          <Tab disabled={!selectedPrequalification} label="Vorraussetzungen" />
          <Tab label="Bewertung" />
        </Tabs>
      </Box>

      <CustomTabPanel value={selectedTabIndex} index={0}>
        <CompanyTab
          switchTab={switchTab}
          selectedCompany={selectedCompany}
          setSelectedCompany={setSelectedCompany}
          fastForward={fastForward}
          fastForwardWithoutRedirect={fastForwardWithoutRedirect}
          resetStates={resetStates}
        />
      </CustomTabPanel>
      <CustomTabPanel value={selectedTabIndex} index={1}>
        <FacilityTab
          switchTab={switchTab}
          selectedFacility={selectedFacility}
          setSelectedFacility={setSelectedFacility}
          setSelectedPrequalification={setSelectedPrequalification}
          fastForward={fastForwardFacility}
        />
      </CustomTabPanel>
      <CustomTabPanel value={selectedTabIndex} index={2}>
        <PrequalificationTab
          switchTab={switchTab}
          selectedFacility={selectedFacility}
          selectedPrequalification={selectedPrequalification}
          setSelectedPrequalification={setSelectedPrequalification}
        />
      </CustomTabPanel>
      <CustomTabPanel value={selectedTabIndex} index={3}>
        <SpecialistTab
          switchTab={switchTab}
          selectedSpecialist={selectedSpecialist}
          setSelectedSpecialist={setSelectedSpecialist}
        />
      </CustomTabPanel>
      <CustomTabPanel value={selectedTabIndex} index={4}>
        <PreconditionTab
          switchTab={switchTab}
          selectedProcess={selectedPrequalification}
          processType="prequalification"
          selectedFacility={selectedFacility}
          openFacilityModal={openFacilityModal}
        />
      </CustomTabPanel>
      <CustomTabPanel value={selectedTabIndex} index={5}>
        <EvaluationTab
          switchTab={switchTab}
          selectedFacility={selectedFacility}
          selectedAdmin={selectedAdmin}
          setSelectedAdmin={setSelectedAdmin}
          selectedPrequalification={selectedPrequalification}
        />
      </CustomTabPanel>
      {/* {<ShowFile isOpen={openModal} onRequestClose={() => setModalOpen(false)} file={selectedFile} />} */}
      <SelectFacility
        isOpen={facilityModal}
        onRequestClose={() => setFacilityModal(false)} //FUNCTION TO MOVE STUFF ETC
        facilities={facilityList}
        openSelectedFacility={openSelectedFacility}
      />
    </CustomContainer>
  );
};
export default EvaluationPage;
