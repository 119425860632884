import React from "react";
import { useDispatch, useSelector } from "react-redux";

//mui component
import { TextField, Box, Card, Checkbox, Select, MenuItem, Divider, Typography } from "@mui/material";
import { Close } from "@mui/icons-material";

import { RejectPrequalificationRequest, GetProvidingFields, GetSpecificAssessment } from "api";
import { AcceptRemovingProvidingFields } from "api/changes";
//component import, Card
import { LabelName, CModal, CustomDataGrid } from "components";

const AcceptRemoveProvidingFields = ({ isOpen, onRequestClose, actionListItem }) => {
  // SPECIALIST LIST CREATION -----------------
  const [providing_fields, setProvidingFields] = React.useState([]);
  const [scope, setScope] = React.useState(1);
  const [selectedSpecialist, setSelectedSpecialist] = React.useState(actionListItem.specialist[0]);
  const dispatch = useDispatch();

  const occupation = useSelector((state) => state.occupation);
  const criteriaCatalog = useSelector((state) => state.criteriaCatalog);
  const assessment = useSelector((state) =>
    state.assessment.find((item) => (isOpen ? item.id === actionListItem.assessment[0].id : { specialist: [] }))
  );

  console.log("assessment", assessment);

  React.useEffect(() => {
    if (isOpen === true) {
      dispatch(GetSpecificAssessment(actionListItem.assessment[0].id));
    }
  }, [isOpen, dispatch, actionListItem]);

  const filterProvidingFieldsForDataGrid = (criteriaCatalog, oldSpecialist, newSpecialist) => {
    let criteriaCatalogVersionIndex;
    let newestVersion = 0;

    if (!newSpecialist) return [];

    for (const item of newSpecialist.providing_fields) {
      console.log(item);
      if (item.criteriaCatalogVersion > newestVersion) newestVersion = item.criteriaCatalogVersion;
    }

    criteriaCatalogVersionIndex = criteriaCatalogVersionIndex = criteriaCatalog.findIndex(
      (item) => item.version === newestVersion
    );

    let oldProvidingFields = [];
    let newProvidingFields = [];

    console.log(newestVersion, criteriaCatalogVersionIndex);

    if (criteriaCatalogVersionIndex !== -1) {
      const criteriaCatalogItemList = criteriaCatalog[criteriaCatalogVersionIndex].catalogItem;

      for (const field of oldSpecialist.providing_fields) {
        if (field.active === true) {
          for (const item of criteriaCatalogItemList) {
            if (item.code.substring(0, 3) === field.code.substring(0, 3)) {
              const newItem = { ...item };
              const providingFieldIndex = oldSpecialist.providing_fields.findIndex(
                (provItem) => provItem.code.substring(0, 3) === item.code.substring(0, 3)
              );

              newItem.criteriaCatalogVersion = criteriaCatalog[criteriaCatalogVersionIndex]?.version;
              newItem.versionOfficial = criteriaCatalog[criteriaCatalogVersionIndex]?.versionOfficial;
              newItem.in_store_selected = oldSpecialist.providing_fields[providingFieldIndex]?.in_store;
              newItem.field_sales_selected = oldSpecialist.providing_fields[providingFieldIndex]?.field_sales;
              oldProvidingFields.push(newItem);
            }
          }
        }
      }

      for (const field of newSpecialist.providing_fields) {
        for (const item of criteriaCatalogItemList) {
          if (item.code.substring(0, 3) === field.code.substring(0, 3)) {
            const newItem = { ...item };
            const providingFieldIndex = newSpecialist.providing_fields.findIndex(
              (provItem) => provItem.code.substring(0, 3) === item.code.substring(0, 3)
            );

            newItem.criteriaCatalogVersion = criteriaCatalog[criteriaCatalogVersionIndex]?.version;
            newItem.versionOfficial = criteriaCatalog[criteriaCatalogVersionIndex]?.versionOfficial;
            newItem.in_store_selected = newSpecialist.providing_fields[providingFieldIndex]?.in_store;
            newItem.field_sales_selected = newSpecialist.providing_fields[providingFieldIndex]?.field_sales;
            newProvidingFields.push(newItem);
          }
        }
      }

      let finalField = [];

      for (const field of oldProvidingFields) {
        const newItem = { ...field };
        const equalField = newProvidingFields.find((item) => item.code.substring(0, 3) === field.code.substring(0, 3));
        if (equalField === undefined) {
          finalField.push({ ...field, in_store_deactivated: true, field_sales_deactivated: true });
        } else {
          if (field.in_store_selected === true && equalField.in_store_selected === false) {
            newItem.in_store_deactivated = true;
          }
          if (field.field_sales_selected === true && equalField.field_sales_selected === false) {
            newItem.field_sales_deactivated = true;
          }
          finalField.push(newItem);
        }
      }

      return finalField.sort((a, b) => (a.code > b.code ? 1 : -1));
    }
  };

  React.useEffect(() => {
    if (isOpen === true) {
      const oldSpecialist = assessment?.specialist.find((item) => item.active === true);
      const newSpecialist = assessment?.specialist.find((item) => item.active === false);
      console.log(oldSpecialist, newSpecialist);
      if (oldSpecialist !== undefined)
        dispatch(GetProvidingFields(oldSpecialist.id)).then(
          setProvidingFields(filterProvidingFieldsForDataGrid(criteriaCatalog, oldSpecialist, newSpecialist))
        );
    }
    setSelectedSpecialist(assessment?.specialist[0]);
  }, [assessment, criteriaCatalog, dispatch, isOpen]);

  const handleProvidingAction = (from, row) => {
    const element = providing_fields.findIndex((item) => item.code === row.code);

    if (from) providing_fields[element].in_store_selected = !providing_fields[element].in_store_selected;
    else providing_fields[element].field_sales_selected = !providing_fields[element].field_sales_selected;
  };

  const providingFieldsColumns = [
    {
      field: "in_store",
      headerName: "Geschäftslokal",
      flex: 0.15,
      editable: false,
      default: false,
      renderCell: ({ row }) => {
        if (row.in_store_allowed && row.in_store_deactivated) {
          return <Close color="error" />;
        }
        if (row.in_store_allowed) {
          return (
            <Checkbox
              disabled={row.in_store_deactivated}
              checked={row.in_store_selected}
              onClick={() => handleProvidingAction(true, row)}
            />
          );
        } else return <></>;
      },
    },
    {
      field: "field_sales",
      headerName: "Außendienst",
      flex: 0.15,
      editable: false,
      default: false,
      renderCell: ({ row }) => {
        if (row.field_sales_allowed && row.field_sales_deactivated) {
          return <Close color="error" />;
        }
        if (row.field_sales_allowed) {
          return (
            <Checkbox
              checked={row.field_sales_selected}
              onClick={() => handleProvidingAction(false, row)}
              icon={<Close />}
            />
          );
        } else return <></>;
      },
    },
    {
      field: "code",
      headerName: "Versorgungsbereiche",
      flex: 0.2,
      editable: false,
    },
    {
      field: "description",
      headerName: "Bezeichnung",
      flex: 0.4,
      editable: false,
    },
  ];

  // END CREATION ----------------------------------------------

  return (
    <CModal
      modalLabel="Wegfall Versorgungsbereiche"
      open={isOpen}
      buttonLabel="Annehmen"
      backButtonLabel="Ablehnen"
      onButtonPressed={() => {
        dispatch(AcceptRemovingProvidingFields(assessment.id, scope));
        onRequestClose();
      }}
      onBackButtonPressed={() => {
        RejectPrequalificationRequest(actionListItem.id, actionListItem?.prequalification[0].id);
        onRequestClose();
      }}
      onRequestClose={() => onRequestClose()}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          mt: 2,
          mb: 2,
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "row", mt: 2, mx: 2, flex: 1 }}>
          <Box sx={{ display: "flex", flexDirection: "column", flex: 0.5 }}>
            <Box sx={{ display: "flex", flexDirection: "row" }}>
              <LabelName titel="Unternehmen" />
              <TextField sx={{ flex: 0.5 }} name="name" value={actionListItem?.facility[0]?.name} />
            </Box>
            <Box sx={{ display: "flex", flexDirection: "row" }}>
              <LabelName titel="Zusatz" />
              <TextField
                name="extra_information"
                sx={{ flex: 0.5 }}
                value={actionListItem?.facility[0]?.extra_information}
              />
            </Box>
            <Box sx={{ display: "flex", flexDirection: "row" }}>
              <LabelName titel="IK-Nummer" />
              <TextField name="ik_number" sx={{ flex: 0.5 }} value={actionListItem?.facility[0]?.ik_number} />
            </Box>
            <Box sx={{ display: "flex", flexDirection: "row" }}>
              <LabelName titel="Straße, Hausnummer" />
              <TextField name="street" sx={{ flex: 0.25 }} value={actionListItem?.facility[0]?.street} />
              <TextField name="street_number" sx={{ flex: 0.25 }} value={actionListItem?.facility[0]?.street_number} />
            </Box>
            <Box sx={{ display: "flex", flexDirection: "row" }}>
              <LabelName titel="Postleitzahl, Stadt" />
              <TextField name="postcode" sx={{ flex: 0.25 }} value={actionListItem?.facility[0]?.postcode} />
              <TextField name="city" sx={{ flex: 0.25 }} value={actionListItem?.facility[0]?.city} />
            </Box>
            <Box sx={{ display: "flex", flexDirection: "row", mt: 2 }}>
              <LabelName titel="IBAN" />
              <TextField name="IBAN" sx={{ flex: 0.5 }} value={actionListItem?.facility[0]?.IBAN} />
            </Box>
            <Box sx={{ display: "flex", flexDirection: "row" }}>
              <LabelName titel="BIC" />
              <TextField name="BIC" sx={{ flex: 0.5 }} value={actionListItem?.facility[0]?.BIC} />
            </Box>
            <Box sx={{ display: "flex", flexDirection: "row" }}>
              <LabelName titel="Scope" />
              <Select
                sx={{ flex: 0.5 }}
                value={scope}
                onChange={(e) => {
                  setScope(e.target.value);
                }}
              >
                <MenuItem value={1}>Orthopäditechnik (1)</MenuItem>
                <MenuItem value={2}>Orth.Schuhtechnik (2)</MenuItem>
                <MenuItem value={3}>Hörakustik (3)</MenuItem>
                <MenuItem value={4}>Augenoptik (4)</MenuItem>
                <MenuItem value={5}>Blindenführhunde (5)</MenuItem>
                <MenuItem value={6.1}>Apotheke (6.1)</MenuItem>
                <MenuItem value={6.2}>Friseur (6.2)</MenuItem>
                <MenuItem value={6.3}>Altenheim (6.3)</MenuItem>
                <MenuItem value={6.4}>Sonstige (6.4)</MenuItem>
              </Select>
            </Box>
          </Box>

          <Box sx={{ display: "flex", flexDirection: "column", flex: 0.5 }}>
            <Box sx={{ display: "flex", flexDirection: "row" }}>
              <LabelName titel="Anrede" />
              <TextField
                sx={{ flex: 0.5 }}
                value={selectedSpecialist?.gender === 0 ? "Herr" : selectedSpecialist?.gender === 1 ? "Frau" : ""}
              />
            </Box>
            <Box sx={{ display: "flex", flexDirection: "row" }}>
              <LabelName titel="Vorname" />
              <TextField sx={{ flex: 0.5 }} value={selectedSpecialist?.first_name} />
            </Box>
            <Box sx={{ display: "flex", flexDirection: "row" }}>
              <LabelName titel="Nachname" />
              <TextField sx={{ flex: 0.5 }} value={selectedSpecialist?.last_name} />
            </Box>
            <Box sx={{ display: "flex", flexDirection: "row" }}>
              <LabelName titel="Email" />
              <TextField sx={{ flex: 0.5 }} value={selectedSpecialist?.email} />
            </Box>
            <Box sx={{ display: "flex", flexDirection: "row" }}>
              <LabelName titel="Beruf / Qualifizierung" />
              <TextField
                sx={{ flex: 0.7 }}
                value={
                  occupation[
                    occupation.findIndex((item) => item.short_form.includes(selectedSpecialist?.qualification))
                  ]?.description
                }
              />
            </Box>
            {assessment?.comment?.length ? (
              <Box sx={{ mt: 2 }}>
                <CustomDataGrid
                  rows={assessment.comment}
                  columns={[{ field: "text", headerName: "Nachricht", flex: 1 }]}

                  // checkboxSelection={checkboxSelection}
                  // onSelectionModelChange={(rows) => setSelectedFields(rows)}
                />
              </Box>
            ) : (
              // You can render an alternative component or message when assessment.comment is empty or undefined
              <p>No comments available</p>
            )}
          </Box>
        </Box>
      </Box>

      <Divider textAlign="left" sx={{ my: 4 }}>
        <Typography fontWeight={"bold"}>Versorgungsbereiche</Typography>
      </Divider>

      <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", mx: 2 }}>
        <Card sx={{ p: 4, flex: 1 }}>
          <CustomDataGrid
            rows={providing_fields || []}
            columns={providingFieldsColumns}

            // checkboxSelection={checkboxSelection}
            // onSelectionModelChange={(rows) => setSelectedFields(rows)}
          />
        </Card>
      </Box>

      <Divider textAlign="left" sx={{ my: 4 }}>
        <Typography fontWeight={"bold"}>Entfallene Vorraussetzungen</Typography>
      </Divider>
      <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", mx: 2 }}>
        <Card sx={{ p: 4, flex: 1 }}>
          <CustomDataGrid
            rows={assessment?.prequalificationItem || []}
            columns={[{ field: "description", flex: 1 }]}

            // checkboxSelection={checkboxSelection}
            // onSelectionModelChange={(rows) => setSelectedFields(rows)}
          />
        </Card>
      </Box>
    </CModal>
  );
};

export default AcceptRemoveProvidingFields;

//Bearbeitungsfunktion.
//Antragsstellung dokument ausdruckbar.
//Bei bestätigung email.
//alle Fachlichen Leiter anzeigen.
//Ansprechpartner.
