import React from "react";
import {
  Card,
  Box,
  Typography,
  Select,
  MenuItem,
  Tabs,
  Tab,
  Button,
  Divider,
  TextField,
  Checkbox,
  Collapse,
  InputAdornment,
  FormControl,
  InputLabel,
} from "@mui/material";
import { CustomTabPanel, CustomSnackbarAlert, CustomLoadingIndicator } from "components";

import { changeCEO, changeFacilityName, changeOwner, closeFacility, openInsolvency } from "api/changes";
import { DatePicker } from "@mui/x-date-pickers";
import { Fax, LocalPhone, Mail } from "@mui/icons-material";
import { AssessmentType, ChangesFacilityType } from "assets/type.ts";
import { useApprovalFlow } from "hooks/useApprovalFlow.js";
import { ConfirmationModal } from "pages/modals";
import moment from "moment";

const EditFacilityTab = ({ selectedFacility, setSelectedTabIndex }) => {
  const {
    sendRequest,
    confirmRequest,
    responseData,
    setResponseData,
    isLoading,
    openSnackbar,
    setOpenSnackbar,
    message,
    modalOpen,
    setModalOpen,
  } = useApprovalFlow();

  const [selectedEditTabIndex, setSelectedEditTabIndex] = React.useState(0);
  const [open, setOpen] = React.useState(false);

  const [nameNew, setNameNew] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [date, setDate] = React.useState(null);

  //Insolvency Agent
  const [gender, setGender] = React.useState(0);
  const [first_name, setFirstName] = React.useState("");
  const [last_name, setLastName] = React.useState("");
  const [phone_number, setPhoneNumber] = React.useState("");
  const [faxNumber, setFaxNumber] = React.useState("");
  const [usesInsolvencyAgent, setUsesInsolvencyAgent] = React.useState(false);
  const [success, setSuccess] = React.useState(false);

  const [clicked, setClicked] = React.useState(false);
  const [messages, setMessages] = React.useState("");

  const sendCompanyRelated = async () => {
    setClicked(true);
    switch (selectedEditTabIndex) {
      case 0: {
        setClicked(true);
        await sendRequest(AssessmentType.CHANGECOMPANYNAME, {
          facilityId: selectedFacility.id,
          data: { name: nameNew },
        });
        setClicked(false);
        break;
      }
      case 1: {
        setClicked(true);
        await sendRequest(AssessmentType.CHANGECOMPANYNAME, {
          facilityId: selectedFacility.id,
          data: { name: nameNew },
        });
        setClicked(false);
        break;
        //Rechtsformwechsel
        // changeFacilityName(selectedFacility.id, nameNew).then((data) => {
        //   setClicked(true);
        //   setSuccess(data.error ? false : true);
        //   setMessages(data.message);
        //   setClicked(false);
        // });
        //break;
      }
      case 2: {
        setClicked(true);
        await sendRequest(AssessmentType.CHANGEOFOWNER, {});
        setClicked(false);
        //Inhaberwechsel
        //changeOwner(selectedFacility.id, email);
        break;
      }
      case 3: {
        //Geschäftsführerwechsel
        setClicked(true);
        await sendRequest(AssessmentType.CHANGEOFCEO, {});
        setClicked(false);
        changeCEO(selectedFacility.id);
        break;
      }
      case 4: {
        if (usesInsolvencyAgent && (!gender || !first_name || !last_name || !email)) {
          break;
          //show some kind of error message
        }
        //Eröffnung Insolvenzverfahren
        const insolvencyAgentData = {
          gender: gender,
          first_name: first_name,
          last_name: last_name,
          phone_number: phone_number,
          fax_number: faxNumber,
          email: email,
          usesInsolvencyAgent: usesInsolvencyAgent,
        };

        setClicked(true);
        await sendRequest(AssessmentType.OPENINSOLVENCY, {});
        setClicked(false);
        openInsolvency(selectedFacility.id, date ? moment(date).format("YYYY-MM-DD") : null, insolvencyAgentData);
        break;
      }
      case 5: {
        //Schließung der Betriebsstätte
        setClicked(true);
        await sendRequest(AssessmentType.CLOSECOMPANY, {});
        setClicked(false);
        closeFacility(selectedFacility.id, date ? moment(date).format("YYYY-MM-DD") : null);
        break;
      }
      default: {
        break;
      }
    }
    setClicked(false);
  };

  function handleClick(id) {
    setOpen((prevState) => ({ ...prevState, [id]: !prevState[id] }));
    setUsesInsolvencyAgent(!usesInsolvencyAgent);
  }

  return (
    <>
      <Box sx={{ borderBottom: 1, borderColor: "divider", display: "flex", flex: 1, width: "100%" }}>
        <Tabs
          variant="fullWidth"
          sx={{ width: "100%" }}
          selectionFollowsFocus
          value={selectedEditTabIndex}
          onChange={(e, selectedEditTabIndex) => setSelectedEditTabIndex(selectedEditTabIndex)}
        >
          <Tab label={ChangesFacilityType.CHANGECOMPANYNAME} />
          <Tab label={ChangesFacilityType.CHANGEOFLEGALFORM} />
          <Tab label={ChangesFacilityType.CHANGEOFOWNER} />
          <Tab label={ChangesFacilityType.CHANGEOFCEO} />
          <Tab label={ChangesFacilityType.OPENINSOLVENCY} />
          <Tab label={ChangesFacilityType.CLOSECOMPANY} />
        </Tabs>
      </Box>
      <CustomTabPanel value={selectedEditTabIndex} index={0}>
        <Card sx={{ p: 3, mt: 2 }}>
          <Box sx={{ display: "flex", flexDirection: "column", flex: 1 }}>
            <Typography fontWeight={"bold"}>Neuer Unternehmensname</Typography>
            <TextField value={nameNew} onChange={(e) => setNameNew(e.target.value)} />
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              mt: 1,
            }}
          >
            <Button sx={{ my: 2 }} variant="contained" disabled={clicked} onClick={() => sendCompanyRelated()}>
              Umfirmieren
            </Button>
          </Box>
        </Card>
      </CustomTabPanel>
      <CustomTabPanel value={selectedEditTabIndex} index={1}>
        <Card sx={{ p: 3, mt: 2 }}>
          <Box sx={{ display: "flex", flexDirection: "column", flex: 1 }}>
            <Typography fontWeight={"bold"}>Neuer Unternehmensname</Typography>
            <TextField value={nameNew} onChange={(e) => setNameNew(e.target.value)} />
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              mt: 1,
            }}
          >
            <Button sx={{ my: 2 }} variant="contained" disabled={clicked} onClick={() => sendCompanyRelated()}>
              Umfirmieren
            </Button>
          </Box>
        </Card>
      </CustomTabPanel>

      <CustomTabPanel value={selectedEditTabIndex} index={2}>
        <Card sx={{ p: 3, mt: 2 }}>
          <Box sx={{ display: "flex", flexDirection: "column", mt: 2, flex: 1 }}>
            <Typography variant="body2">
              Bei einem Inhaberwechsel steht eine neue Präqualifizierung an. Bei Bestätigung werden wir uns bei Ihnen
              melden.
            </Typography>

            <Box sx={{ display: "flex", flexDirection: "column", flex: 1 }}>
              <Typography fontWeight={"bold"}>E-Mail neuer Besitzer</Typography>
              <TextField value={email} onChange={(e) => setEmail(e.target.value)} />
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              mt: 1,
            }}
          >
            <Button sx={{ my: 2 }} variant="contained" disabled={clicked} onClick={() => sendCompanyRelated()}>
              Inhaberwechsel beantragen
            </Button>
          </Box>
        </Card>
      </CustomTabPanel>
      <CustomTabPanel value={selectedEditTabIndex} index={3}>
        <Card sx={{ p: 3, mt: 2 }}>
          <Box sx={{ display: "flex", flexDirection: "row", mt: 2, flex: 1 }}>
            <Typography variant="body2">Bitte laden Sie den Nachweis (Handelsregisterauszug) hoch.</Typography>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column", flex: 1 }}>
            <Typography fontWeight={"bold"}>Name neuer Geschäftsführer(Optional)</Typography>
            <TextField value={nameNew} onChange={(e) => setNameNew(e.target.value)} />
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              mt: 1,
            }}
          >
            <Button
              sx={{ my: 2 }}
              variant="contained"
              disabled={clicked}
              onClick={() => {
                sendCompanyRelated();
              }}
            >
              Absenden
            </Button>
          </Box>
        </Card>
      </CustomTabPanel>
      <CustomTabPanel value={selectedEditTabIndex} index={4}>
        <Card sx={{ p: 3, mt: 2, display: "flex", flexDirection: "column" }}>
          <Typography variant="body2">Bei Eröffnung eines Insolvensverfahrens werden wir auf Sie zukommen.</Typography>
          <Divider textAlign="left" sx={{ my: 2 }}>
            <Typography fontWeight={"bold"}>Datum der Eröffnung des Insolvensverfahrens</Typography>
          </Divider>
          <DatePicker value={date} title="" onChange={(newValue) => setDate(newValue)} />

          <Divider sx={{ mt: 2 }} />
          <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
            <Checkbox checked={usesInsolvencyAgent} onChange={() => handleClick("usesInsolvencyAgent")} />
            <Typography>Insolvenzverwalter ihrem Unternehmen hinzufügen</Typography>
          </Box>

          <Collapse in={open["usesInsolvencyAgent"]}>
            <Divider />
            <Box
              sx={{
                mt: 5,
                width: "100%",
                display: "flex",
                flex: 1,
                flexDirection: "column",
                alignContent: "flex-start",
              }}
            >
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Anrede</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={gender}
                  label="Age"
                  onChange={(e) => setGender(e.target.value)}
                >
                  <MenuItem value={0}>Herr</MenuItem>
                  <MenuItem value={1}>Frau</MenuItem>
                  <MenuItem value={2}>* (divers)</MenuItem>
                </Select>
              </FormControl>
              <Box sx={{ display: "flex", flexDirection: "row", flex: 1, justifyContent: "space-between" }}>
                <Box sx={{ flex: 0.5, mt: 2 }}>
                  <TextField
                    sx={{ width: "80%" }}
                    label="Vorname"
                    value={first_name}
                    onChange={(e) => setFirstName(e.target.value)}
                  />
                </Box>
                <Box sx={{ flex: 0.5, mt: 2 }}>
                  <TextField
                    sx={{ width: "80%" }}
                    label="Nachname"
                    value={last_name}
                    onChange={(e) => setLastName(e.target.value)}
                  />
                </Box>
              </Box>
              <TextField
                sx={{ mt: 2 }}
                label="Telefon"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LocalPhone />
                    </InputAdornment>
                  ),
                }}
                value={phone_number}
                onChange={(e) => setPhoneNumber(e.target.value)}
              />
              <TextField
                sx={{ mt: 2 }}
                label="Fax"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Fax />
                    </InputAdornment>
                  ),
                }}
                value={faxNumber}
                onChange={(e) => setFaxNumber(e.target.value)}
              />
              <TextField
                sx={{ mt: 2 }}
                label="E-Mail-Adresse"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Mail />
                    </InputAdornment>
                  ),
                }}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Box>
          </Collapse>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              mt: 1,
            }}
          >
            <Button sx={{ my: 2 }} variant="contained" disabled={clicked} onClick={() => sendCompanyRelated()}>
              Insolvenzverfahren eröffnen
            </Button>
          </Box>
        </Card>
      </CustomTabPanel>
      <CustomTabPanel value={selectedEditTabIndex} index={5}>
        <Card sx={{ p: 3, mt: 2 }}>
          <Box sx={{ display: "flex", flexDirection: "row", mt: 2, flex: 1 }}>
            <Typography variant="body2">
              {
                "Bei Schließung der Betriebsstätte erhalten Sie ein Dokument von uns und ihre Daten werden bei uns im System, nach 30 Tagen, gelöscht. Innerhalb dieser Zeit können Sie, falls ein Fehler vorliegt, Ihre Betriebsstäte reaktivieren. Danach muss bei der Wiedereröffnung die Betriebsstätte neu im System angelegt werden und eine neue Präqualifizierung beantragt und durchgeführt werden."
              }
            </Typography>
          </Box>
          <Typography sx={{ mt: 3 }} variant="body2">
            (Aufbewahrugspflicht, Objekt inaktiv setzen)
          </Typography>
          <Divider textAlign="left" sx={{ my: 2 }}>
            <Typography fontWeight={"bold"}>Datum des Umzugs</Typography>
          </Divider>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              mt: 1,
            }}
          >
            <DatePicker value={date} title="Schliessungsdatum" onChange={(newValue) => setDate(newValue)} />
            <Box>
              <Button sx={{ my: 2 }} variant="contained" disabled={clicked} onClick={() => sendCompanyRelated()}>
                Schließung beantragen
              </Button>
            </Box>
          </Box>
        </Card>
      </CustomTabPanel>
      <CustomSnackbarAlert
        open={open || openSnackbar}
        setOpen={() => {
          setOpen(false);
          setOpenSnackbar(false);
        }}
        severity={message?.severity ? message.severity : success ? "success" : "error"}
        message={messages || message?.message}
      />
      {modalOpen && (
        <ConfirmationModal
          isOpen={modalOpen}
          onRequestClose={() => setModalOpen(false)}
          responseData={responseData}
          setResponseData={setResponseData}
          confirmRequest={() => {
            console.log("responseData in tab", responseData);
            confirmRequest();
          }}
        />
      )}
      <CustomLoadingIndicator open={isLoading} message={"Einen Moment gedult. Änderung wird bearbeitet."} />
    </>
  );
};
export default EditFacilityTab;
