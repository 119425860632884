import React from "react";
//mui import
import { Box } from "@mui/material";
//component import
import { CModal, DisplayFiles } from "components";
// component import

const ShowFileModal = ({ isOpen, onRequestClose, file }) => {
  console.log(file);
  return (
    <CModal open={isOpen} modalLabel="Datei Anzeigen" onRequestClose={onRequestClose}>
      <Box sx={{ display: "flex", justifyContent: "center", m: 2, backgroundColor: "secondary.main" }} key={file?.id}>
        <DisplayFiles file={file} />
      </Box>
    </CModal>
  );
};

export default ShowFileModal;
