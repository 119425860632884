import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { uniqueId } from "lodash";
import { filesize } from "filesize";

import { StatusType } from "assets/type.ts";

import { Box, Button, Tabs, Tab, Snackbar, Alert, Typography } from "@mui/material";

import { Check, PriorityHigh } from "@mui/icons-material";
import {
  UploadFile,
  DeleteFile,
  GetSpecificPrequalificationItemList,
  GetSpecialist,
  requestPrequalification,
} from "api";
import { ShowFileModal } from "pages/modals";

import { CustomUpload, CustomContainer, StyledCard } from "components";

const PrequalificationList = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const pageData = location.state;

  const [value, setValue] = React.useState(0);
  const [success, setSuccess] = React.useState(false);
  // const [failure, setFailure] = React.useState(false);

  const [openModal, setModalOpen] = React.useState(false);
  const [selectedFile, setSelectedFile] = React.useState({});

  //error handling

  const [errorAlertOpen, setErrorAlertOpen] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [successAlertOpen, setSuccessAlertOpen] = React.useState(false);
  const [successMessage, setSuccessMessage] = React.useState("");

  const [clicked, setClicked] = React.useState(false);

  // const facility = useSelector((state) => state.facility.filter((item) => item.id === pageData.facility[0].id));
  // const specialist = useSelector((state) => state.specialist);
  const prequalification = useSelector((state) => {
    const index = state.prequalification.findIndex((item) => item.id === location.state.id);
    return state.prequalification[index];
  });

  React.useEffect(() => {
    dispatch(GetSpecialist(pageData?.facility[0].id, false));
    if (prequalification?.accepted !== false) {
      dispatch(GetSpecificPrequalificationItemList(prequalification.id));
    }
  }, [dispatch, pageData?.facility, prequalification?.accepted, prequalification.id]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // const handleChangeIndex = (index) => {
  //   setValue(index);
  // };

  function DefinedTabs() {
    return (
      <Tabs variant="fullWidth" sx={{ width: "100%" }} selectionFollowsFocus value={value} onChange={handleChange}>
        <Tab icon={<PriorityHigh />} label="zu erbringene Nachweise" sx={{ textTransform: "none" }} />

        <Tab icon={<Check />} label="Akzeptiert" />
      </Tabs>
    );
  }

  const handleUpload = (files, prequalificationItem) => {
    const uploadedFiles = files?.map((file) => ({
      file,
      id: uniqueId(),
      name: file.name,
      readableSize: filesize(file.size),
      preview: URL.createObjectURL(file),
      progress: 0,
      type: file.type,
      uploaded: false,
      error: false,
      url: null,
      prequalificationItem: prequalificationItem.id,
      prequalificationId: prequalification.id,
    }));

    uploadedFiles.forEach((item) => processUpload(item, prequalificationItem));
  };

  const processUpload = (uploadedFile, item) => {
    const data = new FormData();

    data.append("file", uploadedFile.file);
    data.append("name", uploadedFile.name);
    data.append("prequalificationItemId", item.id);
    data.append("prequalificationId", prequalification.id);

    dispatch(UploadFile(uploadedFile, prequalification.id));
    setSuccess(true);

    // if (response) {
    //   const specificIndex = prequalification?.prequalificationItem?.findIndex((file) => file?.id === item.id);
    //   prequalification.prequalificationItem[specificIndex].files = response;
    //   console.log(prequalification?.prequalificationItem[specificIndex]);
    //   setSuccess(true);
    // } else {
    //   setFailure(true);
    // }

    // api
    //   .post("posts", data, {
    //     onUploadProgress: (e) => {
    //       const progress = parseInt(Math.round((e.loaded * 100) / e.total));

    //       this.updateFile(uploadedFile.id, {
    //         progress,
    //       });
    //     },
    //   })
    //   .then((response) => {
    //     this.updateFile(uploadedFile.id, {
    //       uploaded: true,
    //       id: response.data._id,
    //       url: response.data.url,
    //     });
    //   })
    //   .catch(() => {
    //     this.updateFile(uploadedFile.id, {
    //       error: true,
    //     });
    //   });
  };

  const handleClicked = (item) => {
    setSelectedFile(item);
    setModalOpen(true);
  };

  const handleDelete = (item) => {
    dispatch(DeleteFile(item, { id: item.id })).then((response) => {
      if (response) {
        setErrorAlertOpen(true);
        setErrorMessage(response);
      }
    });
  };

  function Pages() {
    return (
      <>
        {value === 0 && (
          // <>
          //   {vorraussetzungenListe.map((item) => (
          //     <CustomUpload
          //       titel={item.titel}
          //       description={item.description}
          //       specialist={item.specialist}
          //       providingFields={item.providingFields}
          //     />
          //   ))}
          // </>
          <>
            {prequalification?.prequalificationItem?.map((item) =>
              item.status !== StatusType.ACCEPTED ? (
                <CustomUpload
                  key={item.id}
                  titel={item.titel}
                  description={item.description}
                  specialist={item.specialist}
                  status={item.status}
                  comment={item.comment}
                  preqStatus={prequalification.status}
                  files={item.image}
                  providingFields={item.providing_fields}
                  handleUpload={(file) => handleUpload(file, item)}
                  handleClicked={(item) => handleClicked(item)}
                  handleDelete={handleDelete}
                />
              ) : null
            )}
          </>
        )}
        {value === 1 && (
          <>
            {prequalification?.prequalificationItem?.map((item) =>
              item.status === StatusType.ACCEPTED ? (
                <CustomUpload
                  titel={item.titel}
                  description={item.description}
                  specialist={item.specialist}
                  status={item.status}
                  comment={item.comment}
                  preqStatus={prequalification.status}
                  files={item.image}
                  providingFields={item.providing_fields}
                  handleUpload={(file) => handleUpload(file, item)}
                  handleClicked={() => handleClicked(item)}
                  handleDelete={handleDelete}
                />
              ) : null
            )}
          </>
        )}
      </>
    );
  }

  return (
    <CustomContainer>
      {prequalification?.accepted === false ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography variant="h3" sx={{ textAlign: "center" }}>
            Die DGP wird Ihren Antrag in Kürze prüfen.
          </Typography>
          <Typography variant="h4" sx={{ textAlign: "center" }}>
            Sie erhalten zeitnah eine entsprechende Bestätigungsmail.
          </Typography>
        </Box>
      ) : (
        <>
          <StyledCard fontSize="h2.fontSize">Dokumente freigeben</StyledCard>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              flex: 1,
            }}
          >
            <Box sx={{ borderBottom: 1, borderColor: "divider", display: "flex", flex: 1, width: "100%" }}>
              <DefinedTabs />
            </Box>
            <Pages />
          </Box>
          {<ShowFileModal isOpen={openModal} onRequestClose={() => setModalOpen(false)} file={selectedFile} />}
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={success}
            autoHideDuration={6000}
            onClose={() => setSuccess(false)}
          >
            <Alert severity="success" sx={{ width: "100%" }}>
              Erfolgreich hochgeladen.
            </Alert>
          </Snackbar>
          <Button
            disabled={
              (prequalification?.status !== StatusType.CREATED &&
                prequalification?.status !== StatusType.PENDING &&
                prequalification?.status !== StatusType.UNDER_REVIEW) ||
              clicked
            }
            variant="contained"
            onClick={() => {
              setClicked(true);
              dispatch(requestPrequalification(prequalification.id, pageData.facility[0].id)).then((response) => {
                console.log(response, "response", response.error);
                if (response.error === true) {
                  setErrorMessage(response.message);
                  setErrorAlertOpen(true);
                } else {
                  setSuccessMessage(response.message);
                  setSuccessAlertOpen(true);
                }

                setClicked(false);
              });
              // navigate("/dashboard/prequalification");
            }}
          >
            {prequalification?.status === StatusType.CREATED
              ? "Jetzt Auftrag stellen"
              : prequalification?.status === StatusType.PENDING || prequalification?.status === StatusType.UNDER_REVIEW
              ? "Dokumente Nachreichen"
              : "Abgeschlossen"}
          </Button>
        </>
      )}
      <Snackbar
        open={errorAlertOpen}
        autoHideDuration={5000}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        onClose={() => setErrorAlertOpen(false)}
      >
        <Alert variant="filled" severity="error">
          {errorMessage}
        </Alert>
      </Snackbar>
      <Snackbar
        open={successAlertOpen}
        autoHideDuration={5000}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        onClose={() => setSuccessAlertOpen(false)}
      >
        <Alert variant="filled" severity="success">
          {successMessage}
        </Alert>
      </Snackbar>
    </CustomContainer>
  );
};

export default PrequalificationList;
