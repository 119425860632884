import Router from "./router";
import ThemeProvider from "./theme";
import { registerLocale } from "react-datepicker";
import de from "date-fns/locale/de";

//Date Wrapper
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import "moment/locale/de";

// Redux
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";

import { store, persistor } from "./redux/store";

function App() {
  registerLocale("de", de);
  return (
    <>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ThemeProvider>
            <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="de">
              <Router />
            </LocalizationProvider>
          </ThemeProvider>
        </PersistGate>
      </Provider>
    </>
  );
}

export default App;
