import instance from "./axiosConfig";

export const changeSpecialist = (facilityId, first_specialist_id, second_specialist_id) => async (dispatch) => {
  let response;
  try {
    response = await instance.post(`/changes/specialist/changeSpecialist`, {
      facilityId,
      first_specialist_id,
      second_specialist_id,
    });
    return response?.data;
  } catch (e) {
    return e;
  } finally {
    // dispatch(specialistAdd(response?.data));
  }
};

export const createSpecialistWhenActive = (facilityId, data) => async (dispatch) => {
  let response;
  try {
    response = await instance.post(`/changes/specialist/addSpecialist`, {
      facilityId,
      data,
    });
    return response?.data;
  } catch (e) {
    return e;
  } finally {
    // dispatch(specialistAdd(response?.data));
  }
};

export const updateSpecialist = async (id, specialist) => {
  let response;
  try {
    response = await instance.post(`/specialist/update`, {
      id,
      specialist,
    });
    return response?.data;
  } catch (e) {
    return e;
  }
};

export const checkSpecialistExists = async (occupationId, facilityId, firstName, lastName) => {
  try {
    const { data } = await instance.post(`/specialist/checkSpecialistExists`, {
      occupationId,
      facilityId,
      firstName,
      lastName,
    });

    if (data?.error) {
      console.error(data);
    }

    return data;
  } catch (e) {
    console.error(e);
    return e;
  }
};
