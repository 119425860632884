import * as React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import packageJson from "../../package.json";

//Components
import {
  Button,
  TextField,
  Checkbox,
  FormControlLabel,
  Grid,
  Box,
  Typography,
  Container,
  Card,
  Alert,
  IconButton,
  InputAdornment,
  Snackbar,
  Tooltip,
  Link,
  CssBaseline,
} from "@mui/material";
import { InfoOutlined, Visibility, VisibilityOff } from "@mui/icons-material";

import { Login, getCriteriaCatalog, getOccupation, getRequirements } from "api";
import { Logo } from "components";

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {"Copyright © "}
      <Link target={"_blank"} color="inherit" href="https://www.dgp-gmbh.de">
        DGP
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

function Version(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="right" {...props}>
      {`Hochgeladen: ${packageJson.time}, Version: ${packageJson.version}`}
    </Typography>
  );
}

export default function LoginPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [open, setOpen] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [showPassword, setShowPassword] = React.useState(false);
  // const [url, setUrl] = React.useState("");
  // const [requestType, setRequestType] = React.useState("POST");

  // const submitStuff = async () => {
  //   CheckStuff(requestType, url);
  // };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const login = {
      email: data.get("email"),
      password: data.get("password"),
    };
    const account = await dispatch(Login(login));
    if (account === undefined) {
      return;
    }
    if (account?.error !== true || !account) {
      navigate("/dashboard");
      dispatch(getCriteriaCatalog());
      dispatch(getOccupation());
      dispatch(getRequirements());
    } else {
      setOpen(true);
      setErrorMessage(account?.message);
    }
  };

  return (
    <Container component="main" maxWidth="md">
      <CssBaseline />
      <Card
        sx={{
          mt: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          p: 10,
          boxShadow: 4,
        }}
      >
        <Box sx={{ px: 2.5, py: 3, display: "inline-flex" }}>
          <Logo />
        </Box>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email-Adresse"
            name="email"
            autoComplete="email"
            autoFocus
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Tooltip
                    disableFocusListener
                    disableTouchListener
                    arrow
                    title={
                      <Typography variant="body1">
                        Die mit einem * gekennzeichneten Felder sind Pflichtfelder
                      </Typography>
                    }
                  >
                    <InfoOutlined sx={{ scale: "1" }} />
                  </Tooltip>
                </InputAdornment>
              ),
            }}
          />

          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Passwort"
            type={showPassword ? "text" : "password"}
            id="password"
            autoComplete="current-password"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword(!showPassword)}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                    sx={{ mr: 0.5 }}
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                  <Tooltip
                    disableFocusListener
                    disableTouchListener
                    arrow
                    title={
                      <Typography variant="body1">
                        Die mit einem * gekennzeichneten Felder sind Pflichtfelder
                      </Typography>
                    }
                  >
                    <InfoOutlined sx={{ scale: "1" }} />
                  </Tooltip>
                </InputAdornment>
              ),
            }}
          />

          <FormControlLabel control={<Checkbox value="remember" color="primary" />} label="Angemeldet bleiben" />
          <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
            Einloggen
          </Button>
          <Grid container>
            <Grid item xs>
              <Link href="/forgottenPW" variant="body2">
                Passwort vergessen?
              </Link>
            </Grid>
            <Grid item>
              <Link href="/register" variant="body2">
                {"Keinen Account? Registrieren"}
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Card>
      <Box sx={{ alignItems: "row" }}>
        <Version />
        <Copyright sx={{ mt: 4, mb: 0 }} />
      </Box>
      {/* <Box>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={requestType}
          label="Age"
          onChange={(e) => setRequestType(e.target.value)}
        >
          <MenuItem value={"POST"}>POST</MenuItem>
          <MenuItem value={"GET"}>GET</MenuItem>
          <MenuItem value={"DELETE"}>DELETE</MenuItem>
        </Select>
        <TextField name="url" id="url" label="URL" onChange={(e) => setUrl(e.target.value)} />
        <Button onClick={() => submitStuff()}>Check</Button>
      </Box> */}
      <Snackbar open={open} autoHideDuration={2000} onClose={() => setOpen(false)}>
        <Alert variant="filled" severity="error">
          {errorMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
}
