import React, { useState, useEffect, useRef } from "react";
import { Box, CircularProgress, Typography } from "@mui/material";
import { useNavigate } from "react-router";
import { getSpecificFile } from "../api";
import { MSGReader } from "wl-msg-reader";

const DisplayFiles = ({ file }) => {
  const navigate = useNavigate();

  const [msgContent, setMsgContent] = useState(null);
  const [xmlContent, setXmlContent] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [retry, setRetry] = useState(0);
  const timeoutIdRef = useRef(null); // Use useRef to store the timeout ID
  const maxRetries = 10;
  const retryDelay = 6000; // Delay in milliseconds

  console.log("diplay file", file);

  // Function to clear the timeout when a condition is met
  const clearRetryTimeout = () => {
    if (timeoutIdRef.current) {
      clearTimeout(timeoutIdRef.current);
      timeoutIdRef.current = null;
    }
  };

  useEffect(() => {
    const loadMsg = async () => {
      const fileBuffer = await getSpecificFile(file.id);
      console.log("fileContent", fileBuffer);
      if (fileBuffer && file.imageBaseName && file.imageType) {
        console.log("is of array", typeof fileBuffer);
        const testMsg = new MSGReader(fileBuffer);
        console.log("testMsg", testMsg);
        const msg = testMsg.getFileData();
        console.log("msg", msg);
        setMsgContent((prev) => (JSON.stringify(prev) !== JSON.stringify(msg) ? msg : prev));
        setLoading(false);
      }
    };

    // Reset loading state when file changes
    setLoading(true);
    setError(null);
    setRetry(0);

    if (file.imageType === "application/vnd.ms-outlook") {
      console.log("is of type", file.imageType);
      loadMsg();
    }

    if (file.content) {
      const uint8Array = new Uint8Array(Object.values(file.content));
      const fileContent = new TextDecoder().decode(uint8Array);
      setXmlContent(fileContent);
      setLoading(false);
    }
  }, [file]);

  // Set the timeout and handle retry logic
  useEffect(() => {
    if (loading && retry < maxRetries) {
      timeoutIdRef.current = setTimeout(() => {
        setRetry((prev) => prev + 1);
      }, retryDelay);
    }

    // Cleanup function to clear the timeout when the component unmounts
    return () => clearRetryTimeout();
  }, [loading, retry]);

  // Function to handle iframe load
  const handleIframeLoad = () => {
    console.log("load");
    clearRetryTimeout(); // Clear the timeout if the iframe loads successfully
    setLoading(false);
  };

  // Function to handle iframe error
  const handleIframeError = () => {
    console.log("Iframe error");
    setLoading(false);
    setError("Failed to load document after several attempts.");
  };

  if (file.imageType === "message/rfc822") {
    console.log(true);
    navigate(file.imageUrl);
  }

  // Construct the URL with cache-busting
  const cacheBuster = new Date().getTime();
  // const googleDocsUrl = `https://docs.google.com/gview?url=${encodeURIComponent(
  //   file?.imageUrl
  // )}&embedded=true&cb=${cacheBuster}`;

  const officeUrl = `https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(
    file?.imageUrl
  )}&embedded=true&cb=${cacheBuster}`;

  const iframeSrc = `${officeUrl}&retry=${retry}`;
  const imageSrc = `${file?.imageUrl}&cb=${cacheBuster}`;

  switch (file.imageType || file.fileType) {
    case "application/pdf":
      return (
        <Box
          component="iframe"
          src={file?.imageUrl}
          sx={{
            display: "block",
            width: {
              xs: "100%", // Full width on small screens
              sm: "600px", // Medium width for tablets
              md: "800px", // Large width for desktops
              lg: "1000px", // Extra-large width for big screens
            },
            aspectRatio: "1 / 1.41", // Maintain the DIN A4 aspect ratio
            height: "auto", // Automatically adjust height based on aspect ratio
            border: 0, // Optional: removes border
            margin: "0 auto", // Center-align for larger screens
          }}
          title="Document Viewer"
        />
      );
    case "application/vnd.ms-outlook":
      return (
        <Box sx={{ overflow: "auto", whiteSpace: "pre-wrap", p: 2, backgroundColor: "white" }}>
          {msgContent ? (
            <>
              <Typography variant="h6">{msgContent.subject}</Typography>
              <Typography variant="body2">
                From: {msgContent.senderName} &lt;{msgContent.senderEmail}&gt;
              </Typography>
              <Typography variant="body2">To: {msgContent.recipients?.map((r) => r.name).join(", ")}</Typography>
              <Typography sx={{ mt: 2 }}>{msgContent.body}</Typography>
            </>
          ) : (
            <Typography>No content available.</Typography>
          )}
        </Box>
      );
    case "application/xml":
      return (
        <Box sx={{ overflow: "auto", whiteSpace: "pre-wrap", p: 2, backgroundColor: "white" }}>
          <pre>{xmlContent}</pre>
        </Box>
      );
    case "application/msword":
    case "application/octet-stream":
    case "application/vnd.oasis.opendocument.text":
    case "application/vnd.ms-excel":
    case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
    case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
      return (
        <Box
          sx={{
            position: "relative",
            minHeight: 500,
            display: "flex",
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
            mt: 1,
          }}
        >
          {loading && <CircularProgress />}
          {error && <Typography color="error">{error}</Typography>}
          <Box
            key={iframeSrc} // Force re-render with different src
            component="iframe"
            src={iframeSrc}
            onLoad={handleIframeLoad}
            onError={handleIframeError}
            sx={{
              display: loading ? "none" : "block",
              flex: 0.6,
              aspectRatio: "1 / 1.41", // Maintain the DIN A4 aspect ratio
              maxHeight: "100%",
            }}
            title="Document Viewer"
          />
        </Box>
      );

    default:
      return (
        <Box
          key={imageSrc}
          component="img"
          src={file?.imageUrl}
          onLoad={handleIframeLoad}
          onError={handleIframeError}
          sx={{ display: "flex", flex: 1 }}
          title="Image Viewer"
        />
      );
  }
};

export default DisplayFiles;
