import moment from "moment";
import { Box } from "@mui/material";
import { StatusType } from "../../assets/type.ts";

const assessmentColumns = [
  {
    field: "message",
    headerName: "Nachricht",
    flex: 1,
    editable: false,
  },
  {
    field: "date",
    headerName: "Datum",
    flex: 0.5,
    editable: false,
    renderCell: (params) => {
      return <Box>{`${moment(params.row.date).locale("de").format("D.M.YYYY, HH:MM ")}`}</Box>;
    },
  },
  {
    field: "status",
    headerName: "Status",
    flex: 0.75,
    editable: false,
    valueGetter: (params) => {
      switch (params.row.status) {
        case StatusType.CREATED:
          return "in Bearbeitung";
        case StatusType.PENDING:
          return "in Bearbeitung";
        case StatusType.ACCEPTED:
          return "angenommen";
        case StatusType.REJECTED:
          return "abgelehnt";
        default:
          return "in Bearbeitung";
      }
    },
  },
];

export default assessmentColumns;
