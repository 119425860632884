/**
 * Updates the comment input value and updates the comment state.
 *
 * @param {string} id - The ID of the comment.
 * @param {string} value - The new value of the comment input.
 * @param {Array} comment - The current comment state.
 * @param {function} setComment - The function to update the comment state.
 * @return {void}
 */
export const commentInputOnChange = (id, value, comment, setComment) => {
  const newComments = comment.map((c) => (c.id === id ? { ...c, text: value } : c));
  if (!newComments.find((c) => c.id === id)) {
    newComments.push({ id, text: value });
  }
  setComment(newComments);
};
