import React from "react";

//mui component
import { TextField, Box, Card, Checkbox, Collapse, Typography } from "@mui/material";

import { GetProvidingFields } from "api";
//component import, Card
import { dynamicSpecialistFieldValue } from "utils/dynamicSpecialistFieldValue";
import { LabelName, CustomDataGrid } from "components";
import { useDispatch, useSelector } from "react-redux";
import { Check, Close, UnfoldMore } from "@mui/icons-material";

const SpecialistInfo = ({ assessment, removed = false }) => {
  // SPECIALIST LIST CREATION -----------------
  const [providing_fields, setProvidingFields] = React.useState([]);
  const [selectedSpecialist] = React.useState(assessment?.specialist[0]);
  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();

  const occupation = useSelector((state) => state.occupation);
  const criteriaCatalog = useSelector((state) => state.criteriaCatalog);

  React.useEffect(() => {
    dispatch(GetProvidingFields(selectedSpecialist.id)).then(
      setProvidingFields(dynamicSpecialistFieldValue(criteriaCatalog, selectedSpecialist))
    );
  }, [selectedSpecialist, criteriaCatalog, dispatch]);

  const handleProvidingAction = (from, row) => {
    const element = providing_fields.findIndex((item) => item.code === row.code);

    if (from) providing_fields[element].in_store_selected = !providing_fields[element].in_store_selected;
    else providing_fields[element].field_sales_selected = !providing_fields[element].field_sales_selected;
  };

  const providingFieldsColumns = [
    {
      field: "in_store",
      headerName: "Geschäftslokal",
      flex: 0.15,
      editable: false,
      default: false,
      renderCell: ({ row }) => {
        if (row.in_store_allowed) {
          return (
            <Checkbox
              checked={row.in_store_selected}
              onClick={() => handleProvidingAction(true, row)}
              checkedIcon={removed ? <Close /> : <Check />}
            />
          );
        } else return <></>;
      },
    },
    {
      field: "field_sales",
      headerName: "Außendienst",
      flex: 0.15,
      editable: false,
      default: false,
      renderCell: ({ row }) => {
        if (row.field_sales_allowed) {
          return (
            <Checkbox
              checked={row.field_sales_selected}
              onClick={() => handleProvidingAction(false, row)}
              checkedIcon={removed ? <Close /> : <Check />}
            />
          );
        } else return <></>;
      },
    },
    {
      field: "code",
      headerName: "Versorgungsbereiche",
      flex: 0.2,
      editable: false,
    },
    {
      field: "description",
      headerName: "Bezeichnung",
      flex: 0.4,
      editable: false,
    },
  ];

  // END CREATION ----------------------------------------------

  return (
    <Box>
      {/* <CustomDataGrid
        sx={{ mx: 2 }}
        
        rows={specialist}
        columns={specialistColumns}
        initialPageSize={5}
        disableSelectionOnClick
        selectionModel={selectedSpecialist ? [selectedSpecialist?.id] : []}
        // onSelectionModelChange={(rows) => handlePrequalificationSelect(rows)}
        checkboxSelection
        onSelectionModelChange={(rows) => {
          if (rows.length === 0) {
            if (selectedSpecialist) {
              setSelectedSpecialist(null);
            }
            return;
          }
          const specialistObject = specialist?.filter((item) => item.id.includes(rows));
          setSelectedSpecialist(specialistObject[0]);
          dispatch(GetProvidingFields(specialistObject[0].id)).then(
            setProvidingFields(dynamicSpecialistFieldValue(criteriaCatalog, specialistObject[0]))
          );
        }}
      /> */}

      <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "space-between", mx: 2 }}>
        <Card sx={{ p: 4, mt: 2 }}>
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <LabelName titel="Anrede" />
            <TextField
              sx={{ flex: 0.5 }}
              value={selectedSpecialist?.gender === 0 ? "Herr" : selectedSpecialist?.gender === 1 ? "Frau" : ""}
            />
          </Box>
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <LabelName titel="Vorname" />
            <TextField sx={{ flex: 0.5 }} value={selectedSpecialist?.first_name} />
          </Box>
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <LabelName titel="Nachname" />
            <TextField sx={{ flex: 0.5 }} value={selectedSpecialist?.last_name} />
          </Box>
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <LabelName titel="Email" />
            <TextField sx={{ flex: 0.5 }} value={selectedSpecialist?.email} />
          </Box>
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <LabelName titel="Beruf / Qualifizierung" />
            <TextField
              sx={{ flex: 0.7 }}
              value={
                occupation[occupation.findIndex((item) => item.short_form.includes(selectedSpecialist?.qualification))]
                  ?.description
              }
            />
          </Box>
        </Card>
        <Card sx={{ p: 1, my: 2 }}>
          <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
            <UnfoldMore sx={{ fontSize: 60, ml: 3 }} onClick={() => setOpen(!open)} />
            <Typography variant="h6">Zur Einsicht der Versorgungsbereiche ausklappen</Typography>
          </Box>
          <Collapse in={open} key={"collapsebal-providing-fields"} unmountOnExit>
            <CustomDataGrid
              rows={providing_fields}
              columns={providingFieldsColumns}

              // checkboxSelection={checkboxSelection}
              // onSelectionModelChange={(rows) => setSelectedFields(rows)}
            />
          </Collapse>
        </Card>
      </Box>
    </Box>
  );
};

export default SpecialistInfo;

//Bearbeitungsfunktion.
//Antragsstellung dokument ausdruckbar.
//Bei bestätigung email.
//alle Fachlichen Leiter anzeigen.
//Ansprechpartner.
