import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { jwtDecode } from "jwt-decode";
import moment from "moment";

import { Alert, Link, Snackbar, Typography } from "@mui/material";
import { acceptEmailValidation, createVerificationEmail } from "api";
import { CustomContainer } from "components";

const ValidateEmail = () => {
  const navigate = useNavigate();
  const { token } = useParams();
  const dispatch = useDispatch();

  const account = useSelector((state) => state.account);
  const [message, setMessage] = React.useState("Wir validieren Ihre Email, bitte haben Sie einen moment gedult.");
  const [errorMessage, setErrorMessage] = React.useState("Hallo Welt");
  const [successMessage, setSuccessMessage] = React.useState("Hallo Welt");
  const [successOpen, setSuccessOpen] = React.useState(false);
  const [errorOpen, setErrorOpen] = React.useState(false);

  const decodedToken = jwtDecode(token);
  const expired = moment.unix(decodedToken.exp).isBefore(moment());

  console.log(account, "account");

  console.log(`Token: ${token}`);

  const handleValidation = useCallback(async () => {
    try {
      if (!token || expired || !account?.id || account.id !== decodedToken.accountId) {
        setMessage("Link Fehlerhaft, abgelaufen oder nicht für den Account. Bitte versuchen Sie es erneut.");
        return;
      }
      await dispatch(acceptEmailValidation(decodedToken.accountId));
      //TODO TIMEOUT FOR Message
      navigate("/dashboard");
    } catch (error) {
      setMessage("Ein Fehler ist aufgetreten. Bitte versuchen Sie es erneut.");
    }
  }, [token, expired, account.id, decodedToken.accountId, dispatch, navigate]);

  React.useEffect(() => {
    handleValidation();
  }, [handleValidation]);

  const handleClick = async (event) => {
    event.preventDefault(); // Prevents the default anchor behavior

    if (!account?.id) {
      setErrorOpen(true);
      setErrorMessage(
        "Sie Sind nicht eingeloggt, Bitte melden Sie sich erst im Kundenportal an bevor Sie den Link benutzen."
      );
      return;
    }
    if (account.id !== decodedToken.accountId) {
      setErrorOpen(true);
      setErrorMessage(
        "Sie Sind im Kundenportal nicht mit dem Account, zu dem dieser Link gehört, angemeldet. Bitte melden Sie sich um."
      );
      return;
    }

    const response = await createVerificationEmail(account?.id);
    if (response.error) {
      setErrorOpen(true);
      setErrorMessage(response.message);
    } else {
      setSuccessOpen(true);
      setSuccessMessage(response.message);
    }
  };

  return (
    <CustomContainer>
      <Typography variant="h4">{message}</Typography>
      <Link href="#" onClick={handleClick} sx={{ typography: "h4", textDecoration: "underline", cursor: "pointer" }}>
        oder lassen Sie sich einen neuen Link schicken.
      </Link>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={errorOpen}
        autoHideDuration={5000}
        onClose={() => setErrorOpen(false)}
      >
        <Alert variant="filled" severity="error">
          {errorMessage}
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={successOpen}
        autoHideDuration={5000}
        onClose={() => setSuccessOpen(false)}
      >
        <Alert variant="filled" severity="success">
          {successMessage}
        </Alert>
      </Snackbar>
    </CustomContainer>
  );
};

export default ValidateEmail;
