import { useState, useEffect, useCallback } from "react";

//MUI Imports
import { Card, Checkbox, Grid } from "@mui/material";

//hooks
import { useDispatch, useSelector } from "react-redux";

//layouts
import { criteriaCatalogColumns, occupationShortFrontColumns, requirementColumns } from "layouts/DataGridLayouts";
import { getBaseDataCriteriaCatalogList, getCriteriaCatalog, getOccupation, getRequirements } from "api";
import moment from "moment";
import { CustomContainer, CustomDataGrid, renderCellWithTooltip } from "components";

const BaseData = () => {
  const dispatch = useDispatch();

  const { criteriaCatalog, occupation, requirements } = useSelector((state) => ({
    criteriaCatalog: state.criteriaCatalog || [],
    occupation: state.occupation || [],
    requirements: state.requirements || [],
  }));

  const [selectedCriteriaCatalog, setSelectedCriteriaCatalog] = useState({});
  const [selectedOccupation, setSelectedOccupation] = useState({});
  const [selectedRequirement, setSelectedRequirement] = useState({});

  const [availableOccupations, setAvailableOccupations] = useState(occupation);
  const [availableRequirements, setAvailableRequirements] = useState(requirements);
  const [availableCatalogItems, setAvailableCatalogItems] = useState(
    criteriaCatalog.find((item) => moment(item.expires).isAfter(moment()))?.catalogItem || []
  );

  const handleDispatch = useCallback(() => {
    dispatch(getCriteriaCatalog());
    dispatch(getOccupation());
    dispatch(getRequirements());
  }, [dispatch]);

  useEffect(() => {
    handleDispatch();
  }, [dispatch, handleDispatch]);

  function changeSelectedOccupation(rows) {
    if (rows.id === selectedOccupation?.id) {
      setSelectedOccupation({});
      setAvailableCatalogItems(
        Object.keys(selectedCriteriaCatalog).length !== 0
          ? selectedCriteriaCatalog.catalogItem
          : criteriaCatalog[0].catalogItem
      );
      return;
    }

    const availableCatalogItemsNow =
      Object.keys(selectedCriteriaCatalog).length !== 0
        ? selectedCriteriaCatalog.catalogItem
        : criteriaCatalog[0].catalogItem;

    const occupationId = rows.id;
    const occupationObject = availableOccupations.find((item) => item.id === occupationId);
    setSelectedOccupation(occupationObject);

    console.log(occupationObject);

    const available = availableCatalogItemsNow.filter((item) => {
      const occupationArray = item.specialist_short_forms.split(",");
      return occupationArray.find(
        (item) =>
          item.trim() === occupationObject.short_form.trim() ||
          item.trim() === occupationObject.short_form.replace(/[()]/g, "").trim()
      );
    });
    console.log("available", available);

    setAvailableCatalogItems(
      availableCatalogItemsNow.filter((item) => {
        const occupationArray = item.specialist_short_forms.split(",");
        return occupationArray.find(
          (item) =>
            item.trim() === occupationObject.short_form.trim() ||
            item.trim() === occupationObject.short_form.replace(/[()]/g, "").trim()
        );
      })
    );
  }

  function changeSelectedRequirement(rows) {
    const selectedObject = rows;

    const requirementObject = availableRequirements?.find((item) => item.id === selectedObject.id);

    if (selectedRequirement?.id === selectedObject?.id) {
      setSelectedRequirement({});
      return;
    }
    setSelectedRequirement(requirementObject);
    // setAvailableCatalogItems()
  }

  function changeSelectedCriteriaCatalog(rows) {
    //MAYBE CHANGE THE UNDERLYING OBJECT
    const selectedObject = rows;

    console.log("selected", selectedObject, selectedCriteriaCatalog);

    if (selectedCriteriaCatalog?.id === selectedObject?.id) {
      setSelectedCriteriaCatalog({});
      dispatch(getCriteriaCatalog());
      dispatch(getOccupation());
      dispatch(getRequirements());
      return;
    }

    dispatch(getBaseDataCriteriaCatalogList(selectedObject?.id)).then((response) => {
      console.log("response", response);
      if (response) {
        const criteriaCatalogObject = response;
        setAvailableOccupations(criteriaCatalogObject.occupation);
        setAvailableRequirements(criteriaCatalogObject.requirements);
        setAvailableCatalogItems(criteriaCatalogObject.catalogItem);
        setSelectedCriteriaCatalog(criteriaCatalogObject);
      }
    });
  }

  const handleProvidingAction = useCallback(
    (from, row) => {
      const elementIndex = availableCatalogItems.findIndex((item) => item.code === row.code);

      if (elementIndex === -1) {
        return;
      }

      const otherCatalogItemsWithoutCurrent = availableCatalogItems.filter(
        (item, index) => index !== elementIndex && (item.in_store_selected || item.field_sales_selected)
      );

      if (from) {
        console.log("availableCatalogItems:", availableCatalogItems[elementIndex]);
        availableCatalogItems[elementIndex].in_store_selected = !availableCatalogItems[elementIndex].in_store_selected;
      } else {
        availableCatalogItems[elementIndex].field_sales_selected =
          !availableCatalogItems[elementIndex].field_sales_selected;
      }

      if (!otherCatalogItemsWithoutCurrent.length) {
        setAvailableRequirements([]);
      }

      const selectedCodes = new Set();
      const availableOccupations = new Set();
      for (const item of otherCatalogItemsWithoutCurrent) {
        item.specialist_short_forms.split(",").forEach((item) => availableOccupations.add(item.trim()));
        if (item.in_store_selected) {
          for (const req of item.requirements) {
            selectedCodes.add(req.code);
          }
        }

        console.log("item", item);
        if (!item.in_store_selected && item.field_sales_selected) {
          const excludedRequirementsCodes = item.excludedOnFieldSalesSelect.map((item) => item.code);
          const reducedRequirements = item.requirements.filter((req) => !excludedRequirementsCodes.includes(req.code));
          console.log(reducedRequirements);
          for (const req of reducedRequirements) {
            selectedCodes.add(req.code);
          }
        }

        // if (item.field_sales_selected && !item.in_store_selected && item.field_sales_selected) {
        //   //add codes
        //   for (const req of item.field_sales) {
        //     selectedCodes.add(req.code);
        //   }
        // }
      }
      if (
        availableCatalogItems[elementIndex].in_store_selected ||
        availableCatalogItems[elementIndex].field_sales_selected
      ) {
        const excludedRequirementsCodes = availableCatalogItems[elementIndex].excludedOnFieldSalesSelect.map(
          (item) => item.code
        );
        const reducedRequirements = availableCatalogItems[elementIndex].requirements.filter(
          (req) => !excludedRequirementsCodes.includes(req.code)
        );

        const newRequirements = availableCatalogItems[elementIndex].in_store_selected
          ? availableCatalogItems[elementIndex].requirements.filter((req) => !selectedCodes.has(req.code))
          : reducedRequirements.filter((req) => !selectedCodes.has(req.code));
        setAvailableRequirements((prev) => [...prev, ...newRequirements]);

        //REWORK TO ONLY SHOW THE SHORT FORMS WHICH EQUALS EACH OTHER
        const newOccupations = availableCatalogItems[elementIndex].specialist_short_forms
          .split(",")
          .map((item) => item.trim())
          .filter((item) => !availableOccupations.has(item));

        console.log("available", availableOccupations);
        console.log("new", newOccupations, `(${"ZTM"})`);

        const occupationObjects = occupation.filter(
          (item) =>
            newOccupations.includes(item.short_form) || newOccupations.includes(item.short_form.replace(/[()]/g, ""))
        );

        setAvailableOccupations(occupationObjects);
      } else {
        const otherRequirementsWithoutCurrent = availableRequirements.filter((req) => selectedCodes.has(req.code));
        const occupationObjects = occupation.filter((item) => availableOccupations.has(item.short_form));
        if (!otherCatalogItemsWithoutCurrent.length) {
          setAvailableRequirements(requirements);
          setAvailableOccupations(occupation);
        } else {
          setAvailableRequirements(otherRequirementsWithoutCurrent);
          setAvailableOccupations(occupationObjects);
        }
      }
    },
    [availableCatalogItems, availableRequirements, occupation, requirements]
  );

  const providingFieldsColumns = [
    {
      field: "in_store",
      headerName: "Geschäftslokal",
      flex: 0.15,
      editable: false,
      default: false,
      renderCell: ({ row }) => {
        if (row.in_store_allowed) {
          return <Checkbox checked={row.in_store_selected} onClick={() => handleProvidingAction(true, row)} />;
        } else return <></>;
      },
    },
    {
      field: "field_sales",
      headerName: "Außendienst",
      flex: 0.15,
      editable: false,
      default: false,
      renderCell: ({ row }) => {
        if (row.field_sales_allowed) {
          return <Checkbox checked={row.field_sales_selected} onClick={() => handleProvidingAction(false, row)} />;
        } else return <></>;
      },
    },
    {
      field: "code",
      headerName: "Versorgungsbereiche",
      flex: 0.2,
      editable: false,
    },
    {
      field: "description",
      headerName: "Bezeichnung",
      flex: 0.4,
      renderCell: renderCellWithTooltip,
    },
  ];

  const extendetCriteriaCatalogColumns = [
    {
      field: "selected",
      headerName: "Ausgewählt",
      flex: 0.15,
      editable: false,
      default: false,
      renderCell: ({ row }) => {
        return (
          <Checkbox
            checked={row.id === selectedCriteriaCatalog?.id}
            onClick={() => changeSelectedCriteriaCatalog(row)}
          />
        );
      },
    },
    ...criteriaCatalogColumns,
  ];

  const extendetOccupationColumns = [
    {
      field: "selected",
      headerName: "Ausgewählt",
      flex: 0.15,
      editable: false,
      default: false,
      renderCell: ({ row }) => {
        return <Checkbox checked={row.id === selectedOccupation?.id} onClick={() => changeSelectedOccupation(row)} />;
      },
    },
    ...occupationShortFrontColumns,
  ];

  const extendetRequirmentsColumns = [
    {
      field: "selected",
      headerName: "Ausgewählt",
      flex: 0.15,
      editable: false,
      default: false,
      renderCell: ({ row }) => {
        return <Checkbox checked={row.id === selectedRequirement?.id} onClick={() => changeSelectedRequirement(row)} />;
      },
    },
    ...requirementColumns,
  ];

  const handleMouseEnter = () => {
    document.body.style.overflow = "hidden"; // Disable body scrolling
  };

  const handleMouseLeave = () => {
    document.body.style.overflow = "auto"; // Re-enable body scrolling
  };

  return (
    <CustomContainer>
      <Card>
        {/* Main Data Grid */}
        <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
          <CustomDataGrid
            rows={criteriaCatalog}
            columns={extendetCriteriaCatalogColumns}
            initialState={{
              sorting: {
                sortModel: [{ field: "version", sort: "desc" }],
              },
            }}
          />
        </div>

        {/* Two Side-by-Side Grids */}
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
              <CustomDataGrid rows={availableCatalogItems} columns={providingFieldsColumns} sx={{ height: "50vh" }} />
            </div>
          </Grid>
          <Grid item xs={6}>
            <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
              <CustomDataGrid
                rows={availableOccupations}
                columns={extendetOccupationColumns}
                sx={{ height: "50vh" }}
                initialState={{
                  sorting: {
                    sortModel: [{ field: "short_form", sort: "asc" }],
                  },
                }}
              />
            </div>
          </Grid>
        </Grid>

        {/* Final Data Grid */}
        <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
          <CustomDataGrid
            rows={availableRequirements}
            columns={extendetRequirmentsColumns}
            sx={{ height: "50vh" }}
            initialState={{
              sorting: {
                sortModel: [{ field: "code", sort: "asc" }],
              },
            }}
          />
        </div>
      </Card>
    </CustomContainer>
  );
};

export default BaseData;
