// import { useSelector } from "react-redux";
import { accountAdded, accountVerified, logout } from "../redux/reducers/account";
import { accountListAdded, logoutAccountList } from "../redux/reducers/accountList";
import { specialistAdd, logoutSpecialist, specialistCreate, specialistSpecificAdd } from "../redux/reducers/specialist";
import {
  facilityAdd,
  specificFacilityAdd,
  facilityCreate,
  facilityEdit,
  logoutFacility,
  facilityDelete,
  facilitySpecialistCreate,
} from "../redux/reducers/facility";
import { companyAdd, companyCreate, logoutCompany, companyDelete, companyEdit } from "../redux/reducers/company";
import { providingFieldsAdd, logoutProviding } from "../redux/reducers/providingFields";
import {
  prequalificationAdd,
  prequalificationItemAdd,
  prequalificationSpecificAdd,
  prequalificationItemCreate,
  logoutPrequalification,
  prequalificationDelete,
  prequalificationItemUpdate,
  // prequalificationUpdateListItemImage,
  prequalificationItemImageUpdate,
  prequalificationMoveImage,
  prequalificationItemImageNameUpdate,
  // prequalificationReplace,
} from "../redux/reducers/prequalification";
import {
  actionListAdd,
  updateActionListItemSlice,
  moveActionListItemBetweenAdminItemSlice,
  moveMultipleActionListItemBetweenAdminItemSlice,
  detachActionListItemFromAdminItemSlice,
  detachActionListArrayFromAdminItemSlice,
  attachActionListItemToAdminItemSlice,
  attachActionListArrayToAdminItemSlice,
  actionListItemStatusUpdateItemSlice,
  logoutActionList,
  actionListCompletedAdd,
} from "../redux/reducers/actionList";
import { adminAdded, logoutAdmin } from "../redux/reducers/admin";
import {
  historyAdded,
  historyAddedSingle,
  historyFileDelete,
  historyStatusUpdateAction,
  logoutHistory,
} from "../redux/reducers/history";
import { logoutWalker, walkerAdded } from "../redux/reducers/walker";
import {
  inspectionAccepted,
  inspectionAdded,
  inspectionUploadFile,
  inspectionFileAdd,
  inspectionFileDelete,
  logoutInspection,
} from "../redux/reducers/inspection";
import {
  assessmentAdded,
  assessmentSpecificAdded,
  assessmentItemImageUpdate,
  assessmentItemUpdate,
  assessmentMoveImage,
  assessmentSend,
  assessmentFileDelete,
  assessmentFileAdd,
  logoutAssessment,
  assessmentItemImageNameUpdate,
  assessmentPreqItemUpdate,
} from "../redux/reducers/assessment";
import { occupationAdded, logoutOccupation } from "../redux/reducers/occupation";
import { requirementsAdded, logoutRequirements } from "../redux/reducers/requirements";
import { criteriaCatalogAdded, logoutCriteriaCatalog } from "../redux/reducers/criteriaCatalog";
// import { logoutToken, tokenAdded } from "../redux/reducers/token";
// import { store } from "../redux/store";
import instance from "./axiosConfig";
import { logoutXML, xmlAdded } from "../redux/reducers/xml";
// import axios from "axios";

// const useTokenExtract = () => {
//   const token = useSelector((state) => state.token);
//   console.log(token);
//   return token;
// };

export const CheckStuff = async (requestType, url) => {
  console.log(requestType, url);
  try {
    if (requestType === "GET") {
      console.log("get", url);
      const response = await instance.get(url);
      console.log("check", response);
      return response?.data;
    }
    if (requestType === "POST") {
      console.log("post", url);
      const response = await instance.post(url);
      console.log("check", response);
      return response?.data;
    }
    if (requestType === "DELETE") {
      console.log("delete", url);
      const response = await instance.delete(url);
      console.log("check", response);
      return response?.data;
    } else {
      const response = await instance.put(url);
      console.log("check", response);
      return response?.data;
    }
  } catch (e) {
    console.log(e);
    return e;
  }
};

export const AccountList = () => async (dispatch) => {
  let response;
  try {
    response = await instance.get(`/account/accountList`);
    return response?.data;
  } catch (e) {
    return e;
  } finally {
    dispatch(accountListAdded(response?.data));
  }
};

export const updateAccount = async (id, details) =>
  // async (dispatch) =>
  {
    let response;
    try {
      response = await instance.post(`/account/update`, { id, details });
      return response?.data;
    } catch (e) {
      return e;
    }
    // finally {
    //   dispatch(accountListAdded(response?.data)); //TODO
    // }
  };

export const GetSpecialist = (facilityId, includeInactive) => async (dispatch) => {
  let response;
  try {
    response = await instance.post(`/specialist/get`, {
      id: facilityId,
      includeInactive,
    });
    return response?.data;
  } catch (e) {
    console.error(e);
    return e.response.data;
  } finally {
    dispatch(specialistAdd(response?.data));
  }
};

export const getAssessmentSpecialist = (facilityId) => async (dispatch) => {
  let response;
  try {
    response = await instance.post(`/specialist/assessmentGet`, { id: facilityId });
    return response?.data;
  } catch (e) {
    return e;
  } finally {
    dispatch(specialistAdd(response?.data));
  }
};

export const editSpecialist = async (specialist, providing_fields) => {
  let response;
  try {
    response = await instance.post(`/specialist/edit`, {
      specialist,
      providing_fields,
    });
    return response?.data;
  } catch (e) {
    return e;
  }
  //finally {
  //   dispatch(specialistAdd(response?.data));
  // }
};

export const specialistList = () => async (dispatch) => {
  let response;
  try {
    response = await instance.get(`/specialist/list`);
    return response?.data;
  } catch (e) {
    return e;
  } finally {
    dispatch(specialistAdd(response?.data));
  }
};

export const getSpecificSpecialist = (id) => async (dispatch) => {
  let response;
  try {
    response = await instance.post(`/specialist/specific`, {
      id,
    });
    return response?.data;
  } catch (e) {
    return e;
  } finally {
    console.log(response);
    dispatch(specialistSpecificAdd(response?.data));
  }
};

export const createSpecialist = (specialist) => async (dispatch) => {
  let response;
  console.log(specialist);
  try {
    response = await instance.post(`/specialist/create`, { specialist });
    console.log("createSpecialistResponse", response);
    return response?.data;
  } catch (e) {
    console.log(e?.response);
    return e?.response.data;
  } finally {
    if (response?.data && Object.keys(response?.data).length > 0) {
      dispatch(specialistCreate(response?.data));
      dispatch(facilitySpecialistCreate({ specialist: response?.data, facilityId: specialist?.facilityId }));
    }
  }
};

export const deleteSpecialist = (specialistId, date, selectedFacility) => async (dispatch) => {
  try {
    instance.post(`/specialist/delete`, { specialistId, date });
    // dispatch(specialistDelete(specialistId));
  } catch (e) {
    return e;
  }
};

export const getPerson = async (id) => {
  const response = await instance.get(`/getCreatedDirectors`, { params: { id } });
  return response?.data;
};

export const createJobs = async (jobs) => {
  instance.post(`/addJobs`, jobs);
};

export const getOccupation = () => async (dispatch) => {
  try {
    const response = await instance.get(`/utils/occupationList`);

    if (!response?.data.error) {
      dispatch(occupationAdded(response?.data?.value));
    }
    return response?.data;
  } catch (e) {
    console.error(e);
    return e?.response.data;
  }
};

export const getRequirements = () => async (dispatch) => {
  try {
    const response = await instance.get(`/utils/requirementsList`);

    if (!response?.data.error) {
      dispatch(requirementsAdded(response?.data?.value));
    }
    return response?.data;
  } catch (e) {
    console.error(e);
    return e?.response.data;
  }
};
export const getCriteriaCatalog = () => async (dispatch) => {
  try {
    const response = await instance.get(`/utils/criteriaCatalog`);
    if (!response?.data.error) {
      dispatch(criteriaCatalogAdded(response?.data?.value));
    }
    return response?.data;
  } catch (e) {
    console.error(e);
    return e?.response.data;
  }
};

/**
 * Retrieves the base data criteria catalog list from the API and dispatches actions for the received data.
 *
 * @param {string} catalogId - The ID of the catalog to retrieve
 * @param {function} dispatch - The dispatch function provided by Redux
 * @return {Promise} A Promise that resolves to the response data or rejects with an error response
 */
export const getBaseDataCriteriaCatalogList = (catalogId) => async (dispatch) => {
  let response;
  try {
    response = await instance.post(`/utils/baseDataCriteriaCatalog`, {
      catalogId,
    });
    // const data = response?.data;
    // dispatch(occupationAdded(data.occupation));
    // dispatch(requirementsAdded(data.extendetRequirments));
    // dispatch(criteriaCatalogAdded(data));
    return response?.data;
  } catch (e) {
    return e?.response.data;
  }
  // finally {
  //   dispatch(criteriaCatalogAdded(response?.data));
  // }
};

export const Register = async (account, company) => {
  try {
    const response = await instance.post(`/auth/register`, { account, company });
    return response?.data;
  } catch (e) {
    return e;
  }
};

export const Login = (login) => async (dispatch) => {
  try {
    const response = await instance.post(`/auth/login`, { login });
    dispatch(accountAdded(response?.data?.value));
    return response?.data?.value;
  } catch (e) {
    console.error("loginError", e);
    return e.response?.data;
  }
};

export const Logout = (userId) => (dispatch) => {
  dispatch(logout());
  dispatch(logoutAccountList());
  dispatch(logoutSpecialist());
  dispatch(logoutFacility());
  dispatch(logoutCompany());
  dispatch(logoutProviding());
  dispatch(logoutPrequalification());
  dispatch(logoutAdmin());
  dispatch(logoutWalker());
  dispatch(logoutHistory());
  dispatch(logoutInspection());
  dispatch(logoutCriteriaCatalog());
  dispatch(logoutRequirements());
  dispatch(logoutOccupation());
  dispatch(logoutAssessment());
  dispatch(logoutActionList());
  dispatch(logoutXML());
  // dispatch(logoutToken());
  localStorage.removeItem(`persist:store`);
  localStorage.removeItem(`TOKEN`);
  localStorage.removeItem(`showFile`);
  instance.post(`/auth/logout`, { userId });
};

export const checkEmailExist = async (email) => {
  let response;
  try {
    response = await instance.post(`/auth/checkEmail`, { email });
    return response?.data;
  } catch (e) {
    return e?.response.data;
  }
};
export const acceptEmailValidation = (accountId) => async (dispatch) => {
  //redux updaten
  try {
    const response = await instance.post("/auth/acceptEmailValidation", { accountId });

    if (!response.data.error) {
      dispatch(accountVerified());
      return true;
    } else {
      console.error(response.data.message);
      return false;
    }
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const createVerificationEmail = async (accountId) => {
  try {
    const response = await instance.post(`/auth/createVerificationEmail`, { accountId });

    if (!response.data.error) {
      return response?.data;
    } else {
      console.error(response.data.message);
    }
  } catch (e) {
    return e?.response.data;
  }
};

export const checkCompanyNameExist = async (name, ikNumber) => {
  let response;
  try {
    response = await instance.post(`/auth/checkCompanyName`, { name, ikNumber });
    return response?.data;
  } catch (e) {
    return e?.response.data;
  }
};

export const getAdminList = () => async (dispatch) => {
  let response;
  try {
    response = await instance.get(`/account/adminList`);
    return response?.data;
  } catch (e) {
    return e?.response.data;
  } finally {
    dispatch(adminAdded(response.data));
  }
};

export const CreateCompany = (company, accountId) => async (dispatch) => {
  let response;
  try {
    response = await instance.post(`/company/create`, { company, accountId });
    return response?.data;
  } catch (e) {
    return e?.response?.data;
  } finally {
    dispatch(companyCreate({ company: response.data }));
  }
};

export const editCompany = (company) => async (dispatch) => {
  try {
    const response = await instance.post(`/company/edit`, { company });
    dispatch(companyEdit({ company: response.data }));
  } catch (e) {
    return e?.response?.data;
  }
};

export const updateCompany = (id, company) => async (dispatch) => {
  try {
    const { data } = await instance.post(`/company/update`, {
      id,
      company,
    });
    if (!data.error) {
      dispatch(companyEdit({ company: data.value }));
    }
    return data;
  } catch (e) {
    return e?.response?.data;
  }
  // finally {
  //   dispatch(companyEdit({ company }));
  // }
};

export const getCompany = (id) => async (dispatch) => {
  let response;
  try {
    response = await instance.post(`/company/get`, { id });
    return response?.data;
  } catch (e) {
    return e?.response.data;
  } finally {
    dispatch(companyAdd(response?.data));
  }
};

export const CompanyList = () => async (dispatch) => {
  let response;
  try {
    response = await instance.get(`/company/list`);
    return response?.data;
  } catch (e) {
    return e;
  } finally {
    dispatch(companyAdd(response?.data));
  }
};

export const deleteCompany = (company) => async (dispatch) => {
  try {
    instance.post(`/company/delete`, { company });
  } catch (e) {
    return e;
  } finally {
    dispatch(companyDelete(company));
  }
};

export const CreateFacilities = (facility, companyId) => async (dispatch) => {
  let response;
  try {
    response = await instance.post(`/facility/create`, { facility, companyId });
  } catch (e) {
    return e?.response?.data;
  } finally {
    dispatch(facilityCreate({ facility: response.data }));
  }
};

export const GetFacilities = (id) => async (dispatch) => {
  let response;
  try {
    response = await instance.post(`/facility/get`, { id });
    return response?.data;
  } catch (e) {
    return e?.response?.data;
  } finally {
    if (response?.data.length === 0) {
      return;
    } else dispatch(facilityAdd(response?.data));
  }
};

export const GetFacilitiesByAccount = (accountId) => async (dispatch) => {
  try {
    const response = await instance.post(`/facility/listByAccount`, { id: accountId });
    console.log(response, "byAccountResponse");
    if (!response.data.error) {
      dispatch(facilityAdd(response?.data.value));
    }
    // Error Message handling later
    return response?.data;
  } catch (e) {
    return e?.response?.data;
  }
};

export const getSpecificFacility = (id) => async (dispatch) => {
  let response;
  try {
    response = await instance.post(`/facility/specific`, { id });
    return response?.data;
  } catch (e) {
    return e?.response?.data;
  } finally {
    console.log(response?.data);
    dispatch(specificFacilityAdd(response?.data));
    // dispatch(historyAdded(response?.data?.history));
  }
};

export const editFacility = (facility) => async (dispatch) => {
  try {
    instance.post(`/facility/edit`, { facility });
  } catch (e) {
    return e?.response?.data;
  } finally {
    dispatch(facilityEdit({ facility }));
  }
};

export const updateFacility = async (id, company) =>
  // async (dispatch) =>
  {
    let response;
    try {
      response = await instance.post(`/facility/update`, { id, company });
      return response?.data;
    } catch (e) {
      return e?.response?.data;
    }
    // finally {
    //   dispatch(companyEdit({ company }));
    // }
  };

export const FacilityList = () => async (dispatch) => {
  let response;
  try {
    response = await instance.get(`/facility/list`);
    return response?.data;
  } catch (e) {
    return e;
  } finally {
    dispatch(facilityAdd(response?.data));
  }
};

export const deleteFacility = (facility) => async (dispatch) => {
  try {
    instance.post(`/facility/delete`, { facility });
  } catch (e) {
    return e;
  } finally {
    dispatch(facilityDelete(facility));
  }
};

export const moveFacilityToCompany = (facility, company) => async (dispatch) => {
  try {
    instance.post(`/facility/moveToCompany`, { facility, company });
  } catch (e) {
    return e;
  } finally {
    console.log(facility);
    dispatch(facilityEdit({ facility }));
  }
};

export const GetProvidingFields = (id, includeInactive) => async (dispatch) => {
  let response;

  try {
    response = await instance.post(`/providingFields/get`, {
      id,
      includeInactive: !includeInactive ? false : includeInactive,
    });
    return response.data;
  } catch (e) {
    return e?.response.data;
  } finally {
    dispatch(providingFieldsAdd(response?.data));
  }
};

export const GetProvidingFieldsList = (idList) => async (dispatch) => {
  let response;
  try {
    response = await instance.post(`/providingFields/list`, { idList });
  } catch (e) {
    return e?.response.data;
  } finally {
    dispatch(providingFieldsAdd(response?.data));
  }
};

//UTILS
export const SetCriteria = async () => {
  try {
    await instance.post(`/utils/setCriteria`);
  } catch (e) {
    return e?.response.data;
  }
};

export const SetRequirements = async () => {
  try {
    await instance.post(`/utils/setRequirements`);
  } catch (e) {
    return e?.response.data;
  }
};

export const SetOccupation = async () => {
  try {
    await instance.post(`/utils/setOccupation`);
  } catch (e) {
    return e?.response.data;
  }
};

export const getS3Object = async (file) => {
  console.log("file", file);
  const filePath = `${file.prequalificationId}/${file.imageName}`;
  try {
    const response = await instance.post(
      `/media/getS3Object`,
      { filePath, imageType: file.imageType },
      { responseType: "blob" }
    );
    console.log("resonse", response);
    return response?.data;
  } catch (e) {
    return e?.response?.data;
  }
};

export const getSpecificFile = async (imageId) => {
  try {
    const response = await instance.post(`/media/getSpecificFile`, { imageId }, { responseType: "arraybuffer" });
    return response?.data;
  } catch (e) {
    return e?.response?.data;
  }
};

export const getDownloadObjectLink = async (file) => {
  console.log("file", file);
  const filePath = `${file.prequalificationId}/${file.imageName}`;
  try {
    const response = await instance.post(`/media/getDownloadObjectLink`, { filePath });
    return response?.data;
  } catch (e) {
    return e?.response?.data;
  }
};

export const UploadFile = (FormData, prequalificationId) => async (dispatch) => {
  console.log("File", FormData);
  try {
    const response = await instance.post(`/media/upload`, FormData, {
      headers: { "Content-Type": "multipart/form-data" },
    });

    if (!response.data.error) {
      dispatch(
        prequalificationSpecificAdd({
          response: response.data.value,
          id: FormData.prequalificationItem,
          prequalificationId,
        })
      );
    }
  } catch (e) {
    console.error(e);
    return e?.response?.data;
  }
};

export const UploadWalkerFile = (file, inspectionId) => async (dispatch) => {
  try {
    const response = await instance.post(`/media/uploadWalker`, file, {
      headers: { "Content-Type": "multipart/form-data" },
    });

    console.log(response, "response", !response.data.error);
    if (!response.data.error) {
      dispatch(inspectionFileAdd({ response: response.data.value, inspectionId }));
    }
    return response.data;
  } catch (e) {
    return e?.response?.data;
  }
};

export const UploadAssessmentFile = (file, assessmentId) => async (dispatch) => {
  try {
    const response = await instance.post(`/media/uploadAssessment`, file, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    console.log(response, "response", !response.data.error);
    if (!response.data.error) {
      dispatch(
        assessmentFileAdd({ response: response.data.value, preqItemId: file.prequalificationItem, assessmentId })
      );
    }
    return response.data;
  } catch (e) {
    return e?.response?.data;
  }
};

export const uploadCriteriaCatalog = async (file) => {
  try {
    await instance.post(`/utils/uploadConvertCriteria`, file, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  } catch (e) {
    return e?.response?.data;
  }
};

export const DeleteFile = (file, id) => async (dispatch) => {
  try {
    const response = await instance.post(`/media/delete`, { file });

    if (!response?.data?.error) {
      const keyValue = Object.keys(id);
      switch (keyValue[0]) {
        case "assessmentId":
          dispatch(assessmentFileDelete({ file }));
          break;
        case "inspectionId":
          dispatch(inspectionFileDelete({ file, inspectionId: id.inspectionId }));
          break;
        case "historyId":
          dispatch(historyFileDelete({ file, historyId: id.historyId }));
          break;
        default:
          dispatch(prequalificationDelete({ file }));
      }
    } else {
      console.error(response?.data?.message);
      return response?.data;
    }
    return response?.data;
  } catch (e) {
    console.error(e);
    return e?.response?.data;
  }
};

export const GetFiles = async () => {
  let response;
  try {
    response = await instance.post(`/media/get`);
  } catch (e) {
    return e?.response.data;
  } finally {
    return response?.data;
  }
};

export const getPrequalificationDetails = (facilityId) => async (dispatch) => {
  try {
    const response = await instance.post(`/prequalification/get`, { facilityId });
    if (response?.data?.error !== true) {
      console.log("response", response);
      dispatch(prequalificationAdd(response.data.value));
      return response?.data;
    } else {
      return { error: true, message: response?.data?.message };
    }
  } catch (e) {
    return e;
  }
};

export const getFacilitySpecificPrequalifications = (facilityId) => async (dispatch) => {
  try {
    const response = await instance.post(`/prequalification/facilitySpecificList`, { facilityId });
    if (response?.data?.error !== true) {
      console.log("response", response);
      dispatch(prequalificationAdd(response.data.value));
      return response?.data;
    } else {
      return { error: true, message: response?.data?.message };
    }
  } catch (error) {}
};

export const createPrequalificationItemList = (prequalificationId) => async (dispatch) => {
  let response;
  try {
    response = await instance.post(`/prequalification/itemCreate`, {
      prequalificationId,
    });
  } catch (e) {
    return e?.response.data;
  } finally {
    dispatch(prequalificationItemCreate({ prequalificationItemList: response?.data, prequalificationId }));
  }
};

export const GetFacilityPrequalifications = (facilityId) => async (dispatch) => {
  try {
    const response = await instance.post(`/prequalification/facilityList`, { facilityId });
    if (response?.data?.error !== true) {
      dispatch(prequalificationAdd(response.data.value));
      return response?.data;
    } else {
      return { error: true, message: response?.data?.message };
    }
  } catch (error) {
    console.error(error);
  }
};

/**
 * Fetches the prequalification list for a specific facility and/or account ID.
 *
 * @param {string} facilityId - The ID of the facility
 * @param {string} accountId - The ID of the account
 * @return {Promise} The List of Prequalification for given Account or Facility
 */
export const getPrequalificationList = (facilityId, accountId) => async (dispatch) => {
  let response;
  try {
    response = await instance.post(`/prequalification/list`, { facilityId, accountId });
  } catch (e) {
    return e?.response.data;
  } finally {
    dispatch(prequalificationAdd(response?.data));
    return response?.data;
  }
};

export const getSpecificPrequalificationItems = (prequalificationId) => async (dispatch) => {
  try {
    const response = await instance.post(`/prequalification/specificItemList`, {
      prequalificationId,
    });
    return response?.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const GetSpecificPrequalificationItemList = (prequalificationId) => async (dispatch) => {
  let response;
  try {
    response = await instance.post(`/prequalification/specific`, {
      prequalificationId,
    });
    console.log("response", response);
  } catch (e) {
    return e?.response.data;
  } finally {
    dispatch(prequalificationItemAdd({ prequalificationId, prequalificationItemList: response?.data }));
    return response?.data;
  }
};

export const requestPrequalification = (prequalificationId, facilityId) => async (dispatch) => {
  try {
    const response = await instance.post(`/prequalification/request`, {
      prequalificationId,
      facilityId,
    });
    if (!response.data.error) {
      dispatch(prequalificationAdd(response.data.value));
    }
    return response?.data;
  } catch (e) {
    return e?.response.data;
  }
};

export const updateItemImage = (comment, status, imageId) => async (dispatch) => {
  let response = await instance.post(`/media/updateImage`, {
    comment,
    status,
    id: imageId,
  });
  const payload = response?.data;

  if (payload.prequalificationItem[0].prequalification.length !== 0) {
    dispatch(prequalificationItemImageUpdate(payload));
  }
  if (payload.prequalificationItem[0].assessment.length !== 0) {
    dispatch(assessmentItemImageUpdate(payload));
  }

  //MAYBE SOMETHING DO DIFFERENTIATE IF PREQ OR ASSESSMENT
  //REWORK REDUX

  // dispatch(prequalificationUpdateListItemImage({ comment, status, preqid, preqItemId, imageId }));
};

export const createPreqItemComment = (comment, readable, preqItemId) => async (dispatch) => {
  try {
    const response = await instance.post(`/prequalification/createPreqItemComment`, {
      comment,
      readable,
      preqItemId,
    });
    if (response?.data?.error !== true) {
      if (response?.data?.value?.prequalification.length !== 0) {
        dispatch(prequalificationItemUpdate(response.data.value));
        return response?.data;
      }

      if (response?.data?.value?.assessment.length !== 0) {
        dispatch(assessmentPreqItemUpdate(response.data.value));
        return response?.data;
      }
    } else {
      return { error: true, message: response?.data?.message };
    }
  } catch (error) {
    console.error(error);
  }
};

export const createPreqItemImageComment = (comment, readable, imageId) => async (dispatch) => {
  try {
    const response = await instance.post(`/prequalification/createPreqItemImageComment`, {
      comment,
      readable,
      imageId,
    });

    console.log(response);
    const payload = response?.data;
    if (payload?.error !== true) {
      console.log("payload", payload);
      if (payload?.value?.prequalificationItem[0].prequalification.length !== 0) {
        dispatch(prequalificationItemImageUpdate(payload.value));
      }
      if (payload?.value?.prequalificationItem[0].assessment.length !== 0) {
        dispatch(assessmentItemImageUpdate(payload.value));
      }
      return payload.value;
    } else {
      return { error: true, message: response?.data?.message };
    }
  } catch (error) {
    console.error(error);
  }
};

export const commentItemReadableUpdate = (comment, readable, preqItemId) => async (dispatch) => {
  try {
    const response = await instance.post(`/prequalification/commentItemReadableUpdate`, {
      comment,
      readable,
      preqItemId,
    });
    if (response?.data?.error !== true) {
      dispatch(prequalificationItemUpdate(response.data.value));
      return response?.data;
    } else {
      return { error: true, message: response?.data?.message };
    }
  } catch (error) {
    console.error(error);
  }
};

export const commentItemImageReadableUpdate = (comment, readable, preqItemId) => async (dispatch) => {
  try {
    const response = await instance.post(`/prequalification/commentItemImageReadableUpdate`, {
      comment,
      readable,
      preqItemId,
    });
    if (response?.data?.error !== true) {
      dispatch(prequalificationItemUpdate(response.data.value));
      return response?.data;
    } else {
      return { error: true, message: response?.data?.message };
    }
  } catch (error) {
    console.error(error);
  }
};

export const updateItem = (comment, status, id) => async (dispatch) => {
  try {
    const response = await instance.post(`/prequalification/updateListItem`, {
      comment,
      status,
      id,
    });

    console.log(response, "response");
    const payload = response?.data;
    if (payload?.error !== true) {
      if (payload.value.prequalification.length !== 0) {
        dispatch(prequalificationItemUpdate(payload.value));
      }
      if (payload.value.assessment.length !== 0) {
        dispatch(assessmentItemUpdate(payload.value));
      }
    }
  } catch (error) {
    console.error(error);
  }
  // dispatch(prequalificationUpdateListItem({ comment, status, id, prequalificationId }));
};

export const updateCurrentFileName = (id, fileName) => async (dispatch) => {
  try {
    const response = await instance.post(`/media/updateFileName`, {
      id,
      fileName,
    });
    const payload = response?.data;
    if (payload?.error !== true) {
      if (payload.value.prequalificationItem[0].prequalification.length !== 0) {
        dispatch(prequalificationItemImageNameUpdate(payload.value));
      }
      if (payload.value.prequalificationItem[0].assessment.length !== 0) {
        dispatch(assessmentItemImageNameUpdate(payload.value));
      }
      return payload;
    }
  } catch (error) {
    return error;
  }
};

export const moveImageToPreqItem = (imageId, destinationId, preqItemId) => async (dispatch) => {
  let response = await instance.post(`/prequalification/moveImage`, {
    imageId,
    destinationId,
    preqItemId,
  });
  const payload = response?.data;

  if (payload.prequalificationItem[0].prequalification.length !== 0) {
    dispatch(prequalificationMoveImage({ payload, destinationId, preqItemId }));
  }
  if (payload.prequalificationItem[0].assessment.length !== 0) {
    dispatch(assessmentMoveImage({ payload, destinationId, preqItemId }));
  }
};

export const AcceptPrequalificationRequest = (actionListItemId, prequalificationId, scope) => async (dispatch) => {
  try {
    const response = await instance.post(`/prequalification/acceptRequest`, {
      actionListItemId,
      prequalificationId,
      scope,
    });

    if (response?.data?.error !== true) {
      dispatch(updateActionListItemSlice(response.data.value));
      return response?.data;
    } else {
      return { error: true, message: response?.data?.message };
    }
  } catch (error) {
    console.error(error);
  }
};

export const RejectPrequalificationRequest = (actionListItemId, prequalificationId) => async (dispatch) => {
  try {
    const response = await instance.post(`/prequalification/rejectRequest`, {
      actionListItemId,
      prequalificationId,
    });

    if (response?.data?.error !== true) {
      console.log("response", response.data);
      //will be an array
      dispatch(updateActionListItemSlice(response.data.value));
      return response?.data;
    } else {
      return { error: true, message: response?.data?.message };
    }
  } catch (error) {
    console.error(error);
  }
};

export const getActionList = () => async (dispatch) => {
  try {
    const response = await instance.get(`/actionList/get`);
    dispatch(actionListAdd(response?.data));
  } catch (e) {
    console.error(e);
    return e;
  }
};

export const getCompletedActionList = () => async (dispatch) => {
  try {
    const response = await instance.get(`/actionList/getCompleted`);

    if (!response.data.error) {
      dispatch(actionListCompletedAdd(response?.data?.value));
    }
    return response?.data;
  } catch (e) {
    console.error(e);
    return e;
  }
};

export const getSpecificActionListItem = (id) => async (dispatch) => {
  try {
    const response = await instance.post(`/actionList/getSpecificitem`, { id });
    if (response?.data?.error !== true) {
      dispatch(updateActionListItemSlice(response.data.value));
    }
    return response?.data;
  } catch (e) {
    console.error(e);
    return e;
  }
};

// export const getActionList = () => async (dispatch) => {
//   const token = useTokenExtract();
//   let response;
//   try {
//     response = await instance.post(`/actionList/get`, {
//       headers: { Authorization: `Bearer ${token}` },
//     });
//   } catch (e) {
//     return e?.response.data;
//   } finally {
//     dispatch(actionListAdd(response?.data));
//   }
// };

export const attachActionListItemToAdmin = (actionListId, account) => async (dispatch) => {
  let accountId = account.id;
  try {
    await instance.post(`/actionList/attachToAdmin`, { actionListId, accountId });
  } catch (e) {
    return e?.response.data;
  } finally {
    dispatch(attachActionListItemToAdminItemSlice({ actionListId, account }));
  }
};

export const attachActionListArrayToAdmin = (actionListIdArray, account) => async (dispatch) => {
  let accountId = account.id;
  try {
    const response = await instance.post(`/actionList/attachArrayToAdmin`, { actionListIdArray, accountId });

    if (!response.data.error) {
      const { value } = response.data;
      dispatch(attachActionListArrayToAdminItemSlice(value));
      return response.data;
    }
  } catch (e) {
    return e?.response.data;
  }
};

export const moveActionListItemBetweenAdmin = (actionListId, account) => async (dispatch) => {
  let accountId = account.id;
  try {
    await instance.post(`/actionList/moveBetweenAdmin`, { actionListId, accountId });
  } catch (e) {
    return e?.response.data;
  } finally {
    dispatch(moveActionListItemBetweenAdminItemSlice({ actionListId, account }));
  }
};

export const moveMultipleActionListItemBetweenAdmin = (actionListIdArray, account) => async (dispatch) => {
  let accountId = account.id;
  try {
    const response = await instance.post(`/actionList/moveMultipleBetweenAdmin`, { actionListIdArray, accountId });

    if (!response.data.error) {
      const { value } = response.data;
      dispatch(moveMultipleActionListItemBetweenAdminItemSlice(value));
      return response.data;
    }
  } catch (e) {
    return e?.response.data;
  }
};

export const detachActionListItemFromAdmin = (actionListId) => async (dispatch) => {
  try {
    const { data } = await instance.post(`/actionList/detachFromAdmin`, {
      actionListId,
    });
    if (!data.error) {
      dispatch(detachActionListItemFromAdminItemSlice(actionListId));
    }
  } catch (e) {
    return e?.response.data;
  }
};

export const detachActionListArrayFromAdmin = (actionListItemArray) => async (dispatch) => {
  try {
    const { data } = await instance.post(`/actionList/detachArrayFromAdmin`, {
      actionListItemArray,
    });
    if (!data.error) {
      dispatch(detachActionListArrayFromAdminItemSlice(data.value));
    }
  } catch (e) {
    return e?.response.data;
  }
};

export const setPrequalificationForReview = async (adminId, prequalificationId, reason, comment) => {
  try {
    const response = await instance.post(`/prequalification/pushToReview`, {
      adminId,
      id: prequalificationId,
      reason,
      comment,
    });

    if (!response.error) {
      return response?.data;
    }
  } catch (e) {
    return e?.response.data;
  }
};

export const revertPrequalificationBack = async (prequalificationId, comment) => {
  try {
    const response = await instance.post(`/prequalification/failedReview`, {
      id: prequalificationId,
      comment,
    });

    if (!response.error) {
      return response?.data;
    }
  } catch (e) {
    return e?.response.data;
  }
};

export const successPrequalification = async (prequalificationId, endDate, startDate) => {
  try {
    const response = await instance.post(`/prequalification/successPrequalification`, {
      id: prequalificationId,
      endDate,
      startDate,
    });

    if (!response.error) {
      return response?.data;
    }
  } catch (e) {
    return e?.response.data;
  }
};

export const moveSpecialist = async (facilityId, specialistId) => {
  let response;
  try {
    response = await instance.post(`/specialist/move`, {
      facilityId,
      specialistId,
    });
  } catch (e) {
    return e?.response.data;
  } finally {
    return response?.data;
  }
};

//History

export const getFacilityHistory = (facilityId) => async (dispatch) => {
  let response;
  try {
    response = await instance.post(`/history/get`, { facilityId });
  } catch (e) {
    return e;
  } finally {
    dispatch(historyAdded(response?.data));
    return response?.data;
  }
};

export const historyStatusUpdate = (historyId, value) => async (dispatch) => {
  try {
    const { data } = await instance.post(`/history/updateStatus`, { historyId, value });
    if (!data.error) {
      dispatch(historyStatusUpdateAction(historyId, value));
    }
  } catch (e) {
    return e;
  }
};

export const actionListItemStatusUpdate = (actionListItemId, value) => async (dispatch) => {
  try {
    const { data } = await instance.post(`/actionList/updateStatus`, {
      actionListItemId,
      value,
    });
    if (!data.error) {
      dispatch(actionListItemStatusUpdateItemSlice(actionListItemId, value));
    }
  } catch (e) {
    return e;
  }
};

export const getDownloadLink = async (path) => {
  try {
    const response = await instance.post(`/utils/getDownloadLink`, {
      path,
    });
    return response.data;
  } catch (e) {
    return e;
  }
};

//Walker
export const GetWalker = () => async (dispatch) => {
  let response;
  try {
    response = await instance.get(`/account/walkerList`);
  } catch (e) {
    return e;
  } finally {
    dispatch(walkerAdded(response?.data));
    return response?.data;
  }
};

//Inspection

export const CreateInspection = (details) => async (dispatch) => {
  try {
    const { data } = await instance.post(`/inspection/create`, { details });
    if (!data.error) {
      //   dispatch(walkerAdded(response?.data));
      return data;
    }
  } catch (e) {
    return e;
  }
};

export const specificInspection = (walkerId) => async (dispatch) => {
  let response;
  try {
    response = await instance.post(`/inspection/specificInspection`, { walkerId });
  } catch (e) {
    return e;
  } finally {
    dispatch(inspectionAdded(response?.data));
    return response?.data;
  }
};

export const GetInspectionByPrequalificationId = (prequalificationId) => async (dispatch) => {
  try {
    const response = await instance.post(`/inspection/getInspectionByPrequalificationId`, { prequalificationId });
    if (!response.data.error) {
      dispatch(inspectionAdded(response?.data?.value));
      return response?.data;
    }
  } catch (e) {
    console.error(e);
    return e;
  }
};

export const acceptInspection = (inspectionId, inspectionDate, timeOfDay) => async (dispatch) => {
  let response;
  try {
    response = await instance.post(`/inspection/acceptInspection`, {
      inspectionId,
      inspectionDate,
      timeOfDay,
    });
  } catch (e) {
    return e;
  } finally {
    dispatch(inspectionAccepted(response?.data));
    return response?.data;
  }
};

export const changeInspection = (inspectionId, inspectionDate, timeOfDay) => async (dispatch) => {
  try {
    const response = await instance.post(`/inspection/changeInspection`, {
      inspectionId,
      inspectionDate,
      timeOfDay,
    });
    if (!response.data.error) {
      dispatch(changeInspection(response?.data));
      return response?.data?.value;
    }
    return response?.data;
  } catch (e) {
    console.error(e);
    return e.response.data;
  }
};

export const cancelInspection = (inspectionId) => async (dispatch) => {
  try {
    const response = await instance.post(`/inspection/cancelInspection`, {
      inspectionId,
    });
    if (!response.data.error) {
      dispatch(cancelInspection(response?.data));
      return response?.data?.value;
    }
    return response?.data;
  } catch (e) {
    console.error(e);
    return e.response.data;
  }
};

export const AcceptInspectionCompletion = (inspectionId) => async (dispatch) => {
  try {
    const { data: response } = await instance.post(`/inspection/acceptInspectionCompletion`, {
      inspectionId,
    });

    if (!response.error) {
      dispatch(inspectionUploadFile(response?.value));
    }
    return response;
  } catch (e) {
    console.error(e);
    return e;
  }
};

export const GetInspectionRelevantProvidingFields = async (actionListItemId) => {
  try {
    const response = await instance.post(`/inspection/getInspectionRelevantFields`, {
      actionListItemId,
    });

    if (!response?.data?.error) {
      return response?.data?.value;
    } else return [];
  } catch (e) {
    console.error(e);
    return [];
  }
};

// CHANGES

export const getAssessments = (facilityId) => async (dispatch) => {
  let response;
  try {
    response = await instance.post(`/assessment/get`, {
      facilityId,
    });
  } catch (e) {
    return e;
  } finally {
    dispatch(assessmentAdded(response?.data));
    return response?.data;
  }
};

export const GetSpecificAssessment = (assessmentId) => async (dispatch) => {
  let response;
  try {
    response = await instance.post(`/assessment/specific`, {
      assessmentId,
    });
  } catch (e) {
    return e;
  } finally {
    dispatch(assessmentSpecificAdded(response?.data));
    return response?.data;
  }
};

export const updateAssessmentListItem = (comment, status, id, prequalificationId) => async (dispatch) => {
  await instance.post(`/prequalification/updateListItem`, {
    comment,
    status,
    id,
  });
  // dispatch(prequalificationUpdateListItem({ comment, status, id, prequalificationId }));
};

export const sendAssessmentData = (assessmentId, assessmentType) => async (dispatch) => {
  try {
    const { data: response } = await instance.post(`/assessment/send`, {
      assessmentId,
      assessmentType,
    });
    if (!response.error) {
      dispatch(assessmentSend(response?.value));
    }
    return response;
  } catch (e) {
    console.error(e);
    return e;
  }
};

export const getBillList = async (customerId) => {
  try {
    const response = await instance.post(`/media/billList`, {
      customerId,
    });
    if (!response.data.error) {
      return response?.data.value;
    }
  } catch (e) {
    return e.response.data;
  }
};

export const getCertificate = async (facilityId) => {
  try {
    const response = await instance.post(`/media/certificate`, {
      facilityId,
    });
    if (!response.data.error) {
      return response?.data.value;
    }
  } catch (e) {
    return {
      error: true,
      message: e.response.data.message,
    };
  }
  // if (!response.data.error) {
  //   dispatch(billAdded(response?.data));
  // }
};

export const createAdminAccount = async (account) => {
  try {
    const response = await instance.post("/account/createAdmin", { account });
    if (!response.data.error) {
      return response?.data.value;
    }
  } catch (e) {
    console.error(e);
    return e.response.data;
  }
};

export const getEML = async (id) => {
  try {
    const response = await instance.post("/email/getEML", { id });
    if (!response.data.error) {
      return response?.data;
    }
  } catch (e) {
    console.error(e);
    return e.response.data;
  }
};

export const sendPasswordResetRequest = async (email) => {
  try {
    const response = await instance.post("/auth/sendPasswordResetRequest", { email });
    return response?.data;
  } catch (error) {
    console.error(error);
    return error.response.data;
  }
};

export const acceptPasswordReset = async (password, customToken) => {
  try {
    // Override headers for this request
    const response = await instance.post(
      "/auth/acceptPasswordReset",
      { password, token: customToken },
      {
        headers: {
          authorization: customToken, // Use the custom token
        },
      }
    );
    return response?.data;
  } catch (error) {
    console.error(error);
    return error.response.data;
  }
};

export const sendCustomEmail = (facilityId, reason, freeText, message, date) => async (dispatch) => {
  try {
    const response = await instance.post(`/email/sendCustomEmail`, {
      facilityId,
      reason,
      freeText,
      message,
      date,
    });
    if (!response.data.error) {
      dispatch(historyAddedSingle(response?.data.value));
      return response?.data;
    }
  } catch (error) {
    console.error(error);
    return error.response.data;
  }
};

export const createStatusReport = (facilityId) => async (dispatch) => {
  try {
    const response = await instance.post(`/media/createStatusReport`, {
      facilityId,
    });

    if (!response.data.error) {
      dispatch(historyAddedSingle(response?.data.value));
    }

    return response?.data;
  } catch (e) {
    return e.response.data;
  }
};

export const adminDeleteFacility = async (facilityId) => {
  try {
    const response = await instance.post(`/special/deleteFacility`, {
      facilityId,
    });

    // if (!response.data.error) {
    //   dispatch(historyAddedSingle(response?.data.value));
    // }

    return response?.data;
  } catch (e) {
    return e.response.data;
  }
};

export const CreatePreviewXML = async (prequalificationId, endDate, startDate, type) => {
  console.log("endDate", endDate);

  try {
    const response = await instance.post(`/xml/createPreview`, {
      prequalificationId,
      endDate,
      startDate,
      type,
    });

    if (!response.data.error) {
      return response?.data;
    }

    return response?.data;
  } catch (e) {
    return e.response.data;
  }
};

export const CreateCorrectionXML = async (prequalificationId, endDate, startDate, type) => {
  try {
    const response = await instance.post(`/xml/createCorrectionXML`, {
      prequalificationId,
      endDate,
      startDate,
      type,
    });

    if (!response.data.error) {
      return response?.data;
    }

    return response?.data;
  } catch (e) {
    return e.response.data;
  }
};

export const CreatePreviewCertificate = async (prequalificationId, endDate, startDate) => {
  try {
    const response = await instance.post(`/media/createPreviewCertificate`, {
      prequalificationId,
      endDate,
      startDate,
    });

    if (!response.data.error) {
      return response?.data;
    }

    return response?.data;
  } catch (e) {
    return e.response.data;
  }
};

export const GetXMLList = () => async (dispatch) => {
  try {
    const response = await instance.get(`/xml/getList`);

    if (!response.data.error) {
      dispatch(xmlAdded(response?.data?.value));
    }

    return response?.data;
  } catch (e) {
    console.error(e);
    return e.response.data;
  }
};

// export const GetXMLContent = (id) => async (dispatch) => {
export const GetXMLContent = async (id) => {
  try {
    const response = await instance.post(`/xml/getXMLContent`, { id });

    console.log("response", response);

    // if (!response.data.error) {
    //   dispatch(xmlUpdateSpecific({id, response?.data?.value));
    // }

    return response?.data;
  } catch (e) {
    console.error(e);
    return e.response.data;
  }
};
