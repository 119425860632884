import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

//MUI Imports
import {
  Typography,
  Card,
  Button,
  Box,
  Divider,
  Checkbox,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  ListItemText,
} from "@mui/material";
import { GridToolbarQuickFilter } from "@mui/x-data-grid";
import { AddCircleOutline, RemoveCircleOutline } from "@mui/icons-material";

import { specialistList, getSpecificSpecialist, GetProvidingFields } from "api";
import { updateSpecialist } from "api/specialist";

import { specialistColumns } from "layouts/DataGridLayouts";
import { dynamicSpecialistFieldValue } from "utils/dynamicSpecialistFieldValue";
import { CustomDataGrid } from "components";

const SVSpecialistCard = () => {
  const dispatch = useDispatch();

  const occupation = useSelector((state) => state.occupation);
  const criteriaCatalog = useSelector((state) => state.criteriaCatalog);
  const specialist = useSelector((state) => state.specialist);

  const [clicked, setClicked] = React.useState(false);

  useEffect(() => {
    dispatch(specialistList());
  }, [dispatch]);

  //Specialist Data
  const [gender, setGender] = React.useState(0);
  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  // const [phone_number, setPhoneNumber] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [qualifications, setQualification] = React.useState("");

  //System States
  const [selectedOccupation, setSelectedOccupation] = React.useState("");
  const [providing_fields, setProvidingFields] = React.useState([]);
  const [selectedSpecialist, setSelectedSpecialist] = React.useState(null);

  function updateStates(specialist) {
    if (specialist !== null) {
      console.log("specialist", specialist);
      dispatch(getSpecificSpecialist(specialist.id)).then((response) => {
        const updatedSpecialist = response;
        setSelectedSpecialist(updatedSpecialist);
        setGender(updatedSpecialist?.gender);
        setFirstName(updatedSpecialist?.first_name);
        setLastName(updatedSpecialist?.last_name);
        setEmail(updatedSpecialist?.email);
        setQualification(updatedSpecialist?.qualification);
        const index = occupation.findIndex((item) => item.short_form === specialist?.qualification);
        setSelectedOccupation(occupation[index]?.id);
        dispatch(GetProvidingFields(updatedSpecialist.id)).then((res) => {
          setProvidingFields(dynamicSpecialistFieldValue(criteriaCatalog, updatedSpecialist));
        });
      });
    } else {
      setSelectedSpecialist(null);
      setGender("");
      setFirstName("");
      setLastName("");
      setEmail("");
      setQualification("");
    }
  }

  function handleQualification(event) {
    const index = occupation.findIndex((item) => item.id === event.target.value);
    const jobAbkuerzung = occupation[index].short_form;
    setQualification(jobAbkuerzung);
    setSelectedOccupation(event.target.value);
  }

  const createDataGrid = (value) => {
    // Create a copy of the criteriaCatalogItem array you want to work with
    setClicked(true);
    let criteriaCatalogVersionIndex;
    if (process.env.REACT_APP_FIXED_CATALOG_VERSION === "true")
      criteriaCatalogVersionIndex = criteriaCatalog.findIndex(
        (item) => item.version === process.env.REACT_APP_CATALOG_VERSION
      );
    else criteriaCatalogVersionIndex = criteriaCatalog.findIndex((item) => moment().isBefore(item.expires));

    if (criteriaCatalogVersionIndex !== -1) {
      const criteriaCatalogItem = criteriaCatalog[criteriaCatalogVersionIndex].catalogItem;

      // Filter the items based on the qualifications
      const filteredKatalog = criteriaCatalogItem.filter((item) => {
        const occupationArray = item.specialist_short_forms.split(",");
        return occupationArray.find(
          (item) => item.trim() === qualifications.trim() || item.trim() === qualifications.replace(/[()]/g, "").trim()
        );
      });

      // Create a new array and append values to it
      const modifiedKatalog = filteredKatalog.map((item) => {
        const newItem = { ...item }; // Create a copy of the item
        newItem.criteriaCatalogVersion = criteriaCatalog[criteriaCatalogVersionIndex]?.version;
        newItem.versionOfficial = criteriaCatalog[criteriaCatalogVersionIndex]?.versionOfficial;
        newItem.in_store_selected = item.in_store_allowed ? value : false;
        newItem.field_sales_selected = item.field_sales_allowed ? value : false;
        return newItem;
      });

      // Now you can use modifiedKatalog as needed
      setProvidingFields(modifiedKatalog);
    }

    setClicked(false);
  };

  const handleProvidingAction = (from, row) => {
    const element = providing_fields.findIndex((item) => item.code.substring(0, 3) === row.code.substring(0, 3));

    if (from) providing_fields[element].in_store_selected = !providing_fields[element].in_store_selected;
    else providing_fields[element].field_sales_selected = !providing_fields[element].field_sales_selected;
  };

  const providingFieldsColumns = [
    {
      field: "in_store_allowed",
      headerName: "Geschäftslokal",
      flex: 0.15,
      editable: false,
      default: false,
      renderCell: ({ row }) => {
        if (row.in_store_allowed) {
          return <Checkbox checked={row.in_store_selected} onClick={() => handleProvidingAction(true, row)} />;
        } else return <></>;
      },
    },
    {
      field: "field_sales_allowed",
      headerName: "Außendienst",
      flex: 0.15,
      editable: false,
      default: false,
      renderCell: ({ row }) => {
        if (row.field_sales_allowed) {
          return <Checkbox checked={row.field_sales_selected} onClick={() => handleProvidingAction(false, row)} />;
        } else return <></>;
      },
    },
    {
      field: "code",
      headerName: "Versorgungsbereiche",
      flex: 0.2,
      editable: false,
    },
    {
      field: "description",
      headerName: "Bezeichnung",
      flex: 0.4,
      editable: false,
    },
  ];

  function update() {
    setClicked(true);
    const filterSelectedProvidingFields = providing_fields.filter(
      (item) => item.in_store_selected || item.field_sales_selected
    );

    let providingFields = filterSelectedProvidingFields.map((item) => {
      return {
        code: item.code,
        in_store: item.in_store_selected,
        field_sales: item.field_sales_selected,
        description: item.description,
      };
    });
    const specialist = {
      first_name: firstName,
      last_name: lastName,
      qualification: qualifications,
      email: email,
      gender: gender,
      providing_fields: providingFields,
    };
    updateSpecialist(selectedSpecialist.id, specialist);

    setClicked(false);
  }

  return (
    <Box sx={{ my: 1 }}>
      <Card className="specialist-card" sx={{ display: "flex", flexDirection: "column", p: 2 }}>
        <Divider textAlign="left" sx={{ display: "flex", alignItems: "center", mb: 2 }}>
          <Typography variant="h5">Fachliche Leitungen</Typography>
        </Divider>
        <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
          <CustomDataGrid
            rows={specialist}
            columns={specialistColumns}
            initialPageSize={10}
            checkboxSelection
            components={{
              Toolbar: (props) => (
                <>
                  <Box sx={{ display: "flex", flex: 1, p: 1, justifyContent: "space-between", alignItems: "center" }}>
                    <GridToolbarQuickFilter showQuickFilter {...props.toolbar} />
                  </Box>
                  <Divider sx={{ mt: 1, mb: 1 }} />
                </>
              ),
            }}
            componentsProps={{
              toolbar: {
                showQuickFilter: true,
              },
            }}
            onSelectionModelChange={(rows) => {
              if (rows.length === 0) {
                if (selectedSpecialist) {
                  updateStates(null);
                }
                return;
              }
              const actionListObject = specialist?.find((item) => item.id === rows[0]);
              updateStates(actionListObject);
              setSelectedSpecialist(actionListObject);
            }}
          />
          {selectedSpecialist && (
            <>
              <Divider />
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: "70%",
                  mt: 5,
                }}
              >
                <FormControl sx={{ display: "flex", mt: 3, width: "100%" }}>
                  <Typography variant="body2">Beruf / Qualifizierung</Typography>
                  <Select
                    value={selectedOccupation}
                    onChange={(e) => {
                      handleQualification(e);
                    }}
                    sx={{ width: "100%" }}
                  >
                    {/* Map of available Jobtypes / ADD abk_rzung als Speichermedium */}
                    {occupation?.map((item) => (
                      <MenuItem key={item.id} value={item.id}>
                        <ListItemText
                          primary={`${item.description} (${item.short_form})`}
                          secondary={item.certificate}
                        />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    flex: 1,
                    mt: 3,
                    width: "100%",
                    justifyContent: "space-between",
                  }}
                >
                  <FormControl sx={{ flex: 0.49, width: "95%" }}>
                    <InputLabel id="demo-simple-select-label">Anrede</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={gender}
                      label="Age"
                      onChange={(e) => setGender(e.target.value)}
                    >
                      <MenuItem value={0}>Herr</MenuItem>
                      <MenuItem value={1}>Frau</MenuItem>
                      <MenuItem value={2}>* (divers)</MenuItem>
                    </Select>
                  </FormControl>
                  <Box sx={{ flex: 0.49 }}>
                    <TextField
                      sx={{ width: "100%" }}
                      variant="outlined"
                      label="E-mail"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    flex: 1,
                    mt: 3,
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <Box sx={{ flex: 0.49, width: "100%" }}>
                    <TextField
                      sx={{ width: "100%" }}
                      variant="outlined"
                      label="Vorname"
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                    />
                  </Box>
                  <Box sx={{ flex: 0.49 }}>
                    <TextField
                      sx={{ width: "100%" }}
                      variant="outlined"
                      label="Nachname"
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                    />
                  </Box>
                </Box>
              </Box>
              <Box sx={{ width: "100%", height: 400 }}>
                <Box sx={{ my: 2, display: "flex", justifyContent: "space-between" }}>
                  <Button variant="contained" color="success" disabled={clicked} onClick={() => createDataGrid(true)}>
                    <AddCircleOutline /> Alle auswählen
                  </Button>
                  <Button variant="contained" color="error" disabled={clicked} onClick={() => createDataGrid(false)}>
                    <RemoveCircleOutline /> Auswahl entfernen
                  </Button>
                </Box>
                <CustomDataGrid
                  rows={providing_fields}
                  columns={providingFieldsColumns}

                  // checkboxSelection={checkboxSelection}
                  // onSelectionModelChange={(rows) => setSelectedFields(rows)}
                />
              </Box>
            </>
          )}
          <Button variant="contained" disabled={clicked} onClick={() => update()}>
            Update
          </Button>
        </Box>
      </Card>
    </Box>
  );
};

export default SVSpecialistCard;
