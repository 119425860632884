//TODO: Create
import React from "react";

//mui import
import {
  CssBaseline,
  Snackbar,
  Alert,
  IconButton,
  ListItemText,
  Typography,
  TextField,
  Box,
  Select,
  MenuItem,
  InputLabel,
  Checkbox,
  InputAdornment,
} from "@mui/material";
import { Mail, VisibilityOff, Visibility } from "@mui/icons-material";

//component import
import { CModal } from "components";

//api import

import { checkEmailExist, createAdminAccount } from "api";

//ICON IMPORT

/**
 * CreateFacility component function
 *
 * @param {Object} isOpen - indicates if the modal is open
 * @param {Function} onRequestClose - function to handle modal close
 * @return {JSX.Element} The CreateFacility component
 */
const CreateAdminAccountModal = ({ isOpen, onRequestClose }) => {
  const [open, setOpen] = React.useState(false);

  //Error handling

  const [openCompanyNameExist, setOpenCompanyNameExist] = React.useState(false);
  const [companyErrorMessage, setCompanyErrorMessage] = React.useState("");

  //PAGE UserData(0)
  const [gender, setGender] = React.useState("");
  const [first_name, setFirstName] = React.useState("");
  const [last_name, setLastName] = React.useState("");
  const [email, setEmail] = React.useState("");

  const [showPassword, setShowPassword] = React.useState(false);
  const [showPassword2, setShowPassword2] = React.useState(false);
  const [password, setPassword] = React.useState("");
  const [password2, setPassword2] = React.useState("");

  const [admin, setAdmin] = React.useState(false);
  const [superAdmin, setSuperAdmin] = React.useState(false);
  const [walker, setWalker] = React.useState(false);
  const [post_code, setPostCode] = React.useState("");
  const [postCodeArea, setPostCodeArea] = React.useState("");
  const [scope, setScope] = React.useState([]);

  const options = [
    { value: "1", label: "Scope 1, Orthopäditechnik" },
    { value: "2", label: "Scope 2, Orth.Schuhtechnik" },
    { value: "3", label: "Scope 3, Hörakustik" },
    { value: "4", label: "Scope 4, Augenoptik" },
    { value: "5", label: "Scope 5, Blindenführhunde" },
    { value: "6.1", label: "Scope 6.1, Apotheke" },
    { value: "6.2", label: "Scope 6.2, Friseur" },
    { value: "6.3", label: "Scope 6.3, Altenheim" },
    { value: "6.4", label: "Scope 6.4, Sonstige" },
  ];

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleChange = (event) => {
    setScope(event.target.value); // Update the selected values
  };

  //PAGE CompanyData(1)

  //register
  const handleSubmit = async () => {
    const emailExist = await checkEmailExist(email);
    console.log(emailExist);
    if (emailExist.error) {
      setCompanyErrorMessage("Email existiert bereits!");
      setOpenCompanyNameExist(true);
      return;
    }

    if (!email || !password || !password2 || !first_name || !last_name) {
      setCompanyErrorMessage("Bitte füllen Sie die vorgegebenen Felder aus!");
      setOpenCompanyNameExist(true);
      return;
    }

    const account = {
      email: email,
      password: password,
      gender: gender,
      first_name: first_name,
      last_name: last_name,
      admin: admin,
      superAdmin: superAdmin,
      walker: walker,
      post_code: post_code,
      postCodeArea: postCodeArea,
      scope: scope.join(","),
    };

    console.log(scope.join(","), postCodeArea);

    const response = await createAdminAccount(account);

    console.log(response);
    if (response.error) {
      setCompanyErrorMessage(response.message);
      setOpenCompanyNameExist(true);
      return;
    }

    onRequestClose();
  };

  return (
    <CModal
      open={isOpen}
      modalLabel="Account anlegen"
      onRequestClose={onRequestClose}
      onButtonPressed={() => handleSubmit()}
      buttonLabel={"Absenden"}
      backButtonLabel={"Zurück"}
      onBackButtonPressed={() => onRequestClose()}
    >
      <CssBaseline />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          p: 2,
          justifyContent: "space-between",
        }}
      >
        <>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flex: 1,
              flexDirection: "column",
              alignContent: "flex-start",
            }}
          >
            <Box
              sx={{
                width: "15%",
                display: "flex",
                flex: 1,
                flexDirection: "column",
                alignContent: "flex-start",
              }}
            >
              <InputLabel id="demo-simple-select-label">Anrede</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={gender}
                label="Age"
                onChange={(e) => setGender(e.target.value)}
              >
                <MenuItem value={0}>Herr</MenuItem>
                <MenuItem value={1}>Frau</MenuItem>
                <MenuItem value={2}>* (divers)</MenuItem>
              </Select>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "row", flex: 1, justifyContent: "space-between" }}>
              <Box sx={{ flex: 0.5, mt: 2 }}>
                <TextField
                  sx={{ width: "80%" }}
                  label="Vorname"
                  value={first_name}
                  onChange={(e) => setFirstName(e.target.value)}
                />
              </Box>
              <Box sx={{ flex: 0.5, mt: 2 }}>
                <TextField
                  sx={{ width: "80%" }}
                  label="Nachname"
                  value={last_name}
                  onChange={(e) => setLastName(e.target.value)}
                />
              </Box>
            </Box>
            <TextField
              sx={{ mt: 2 }}
              label="Benutzername"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Mail />
                  </InputAdornment>
                ),
              }}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />

            <Box sx={{ display: "flex", flexDirection: "column", mt: 2 }}>
              <TextField
                label="Password"
                type={showPassword ? "text" : "password"}
                value={password}
                onChange={(event) => {
                  const inputValue = event.target.value;
                  setPassword(inputValue);
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword(!showPassword)}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                label="Password"
                type={showPassword2 ? "text" : "password"}
                value={password2}
                sx={{ mt: 2 }}
                onChange={(event) => {
                  const inputValue = event.target.value;
                  setPassword2(inputValue);
                  // Validate the password against the regex pattern
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword2(!showPassword2)}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword2 ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
            <Box sx={{ display: "flex", flexDirection: "row", mt: 2, alignItems: "center" }}>
              <Checkbox
                checked={admin}
                onClick={() => {
                  if ((superAdmin || walker) && !admin) {
                    setSuperAdmin(false);
                    setWalker(false);
                    setAdmin(true);
                  } else {
                    setAdmin(true);
                  }
                }}
              />
              <Typography variant="caption">Admin</Typography>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "row", mt: 2, alignItems: "center" }}>
              <Checkbox
                checked={superAdmin}
                onClick={() => {
                  if ((admin || walker) && !superAdmin) {
                    setAdmin(false);
                    setWalker(false);
                    setSuperAdmin(true);
                  } else {
                    setSuperAdmin(true);
                  }
                }}
              />
              <Typography variant="caption">SuperAdmin</Typography>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "row", mt: 2, alignItems: "center" }}>
              <Checkbox
                checked={walker}
                onClick={() => {
                  if ((admin || superAdmin) && !walker) {
                    setAdmin(false);
                    setSuperAdmin(false);
                    setWalker(true);
                  } else {
                    setWalker(true);
                  }
                }}
              />
              <Typography variant="caption">Begeher</Typography>
            </Box>
            {walker && (
              <>
                <TextField
                  sx={{ mt: 2, display: "flex", flex: 0.3 }}
                  label="Postleitzahl"
                  value={post_code}
                  onChange={(e) => setPostCode(e.target.value)}
                  helperText="Die Postleitzahl wo der Begeher wohnt."
                />
                <TextField
                  sx={{ mt: 2, display: "flex", flex: 0.3 }}
                  label="Postleitzahl Gebiete"
                  value={postCodeArea}
                  onChange={(e) => setPostCodeArea(e.target.value)}
                  helperText="Bitte geben sie die ersten Zwei Zahlen der Postleitzahlgebiete getrennt durch ein Komma ein. z.B 01,02,03 "
                />
                <Select
                  multiple
                  value={scope}
                  onChange={handleChange}
                  renderValue={(selected) => selected.join(", ")} // Render selected values as a comma-separated string
                >
                  {options.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      <Checkbox checked={scope.indexOf(option.value) > -1} /> {/* Checkbox to show selected state */}
                      <ListItemText primary={option.label} />
                    </MenuItem>
                  ))}
                </Select>
              </>
            )}
          </Box>
        </>
      </Box>
      <Snackbar open={open} autoHideDuration={2000} onClose={() => setOpen(false)}>
        <Alert variant="filled" severity="error">
          Bitte füllen Sie die vorgegebenen Felder aus!
        </Alert>
      </Snackbar>
      <Snackbar open={openCompanyNameExist} autoHideDuration={5000} onClose={() => setOpenCompanyNameExist(false)}>
        <Alert variant="filled" severity="error">
          {companyErrorMessage}
        </Alert>
      </Snackbar>
    </CModal>
  );
};

export default CreateAdminAccountModal;
