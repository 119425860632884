import React from "react";

import Uploader from "../Uploader";
import Comment from "./comment";
import FileInfo from "./file";

//mui import
import { Box, Card, Typography } from "@mui/material";
import { StatusType } from "../../assets/type.ts";

/**
 * Renders a custom upload component.
 *
 * @param {Object} props - The props object.
 * @param {string} props.titel - The title of the upload component.
 * @param {string} props.description - The description of the upload component.
 * @param {number} props.status - The status of the upload component.
 * @param {number} props.preqStatus - The preq status of the upload component.
 * @param {Array} [props.comment=[]] - The comments of the upload component.
 * @param {Array} [props.files=[]] - The files of the upload component.
 * @param {string} props.specialist - The specialist of the upload component.
 * @param {string} props.providingFields - The providing fields of the upload component.
 * @param {function} props.handleUpload - The handle upload function of the upload component.
 * @param {function} props.handleClicked - The handle clicked function of the upload component.
 * @param {function} props.handleDelete - The handle delete function of the upload component.
 * @param {boolean} [props.displayFormatInfo=true] - Indicates whether to display format information.
 * @return {JSX.Element} The custom upload component.
 */
const CustomUpload = ({
  titel,
  description,
  status,
  preqStatus,
  comment = [], // Default to empty array
  files = [], // Default to empty array
  specialist,
  providingFields,
  handleUpload,
  handleClicked,
  handleDelete,
  displayFormatInfo = true,
}) => {
  const statusValue =
    status === StatusType.CREATED || StatusType.PENDING
      ? "in Bearbeitung"
      : status === StatusType.ACCEPTED
      ? "Akzeptiert"
      : "Abgelehnt";

  const title = titel ? titel : description.toLowerCase() === "betriebsbegehungen" ? "Begehungsunterlagen" : "";
  description =
    description.toLowerCase() === "betriebsbegehungen"
      ? "Begehungsunterlagen (Dokumente werden vom Betriebsbegeher hochgeladen)"
      : description;

  return (
    <Card
      sx={{
        minWidth: 100,
        p: 2.25,
        margin: 1,
        boxShadow:
          status === StatusType.CREATED
            ? "0 1px 6px 1px grey"
            : status === StatusType.ACCEPTED
            ? "0 1px 6px 1px green"
            : "0 1px 6px 1px red",
      }}
    >
      <Box sx={{ mb: 2.25 }}>
        <Typography variant="h5" color="primary">
          {title}
        </Typography>
        <Typography color="text.secondary">{description}</Typography>
        {providingFields && (
          <Box sx={{ mt: 2 }}>
            <Typography variant="h6">Versorgungsbereiche</Typography>
            <Typography color="text.secondary">{providingFields}</Typography>
          </Box>
        )}

        {specialist && (
          <Box sx={{ mt: 2 }}>
            <Typography variant="h6">Fachliche Leitung</Typography>
            <Typography color="text.secondary">{specialist}</Typography>
          </Box>
        )}

        <Box sx={{ mt: 2 }}>
          <Typography variant="h6">Status</Typography>
          <Typography color="text.secondary">{statusValue}</Typography>
        </Box>
        {comment?.length !== 0 && <Comment comments={comment} />}
        <Box sx={{ mt: 2 }}>
          <Typography variant="h6">Dateien</Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "column",
              flex: 1,
            }}
          >
            {files?.length === 0 ? (
              <>Noch keine Dateien hochgeladen</>
            ) : (
              [...files] // Create a copy of the array
                .sort((a, b) => new Date(a.date) - new Date(b.date))
                .map((item) => (
                  <FileInfo key={item.id} item={item} handleClicked={handleClicked} handleDelete={handleDelete} />
                ))
            )}
          </Box>
        </Box>
      </Box>
      <Box>
        <Box sx={{ mb: 1 }}>
          {![StatusType.ACCEPTED, StatusType.REJECTED].includes(preqStatus) && (
            <Uploader key={titel} onUpload={handleUpload} />
          )}
        </Box>
      </Box>
      {displayFormatInfo && (
        <Typography variant="h6" sx={{ mt: 2 }}>
          Unterstützte Dateiformate: Bilder (*.jpg, *.jpeg, *.png, *.heic), Dateien (*.pdf)
        </Typography>
      )}
    </Card>
  );
};

export default CustomUpload;
