// component
//icons durch mui icons ersetzen
import { Dashboard, DriveFolderUpload } from "@mui/icons-material";
// ----------------------------------------------------------------------

const navConfigWalker = [
  {
    title: "Dashboard",
    path: "/dashboard/walker",
    icon: <Dashboard />,
  },
  {
    title: "Upload",
    path: "/dashboard/walkerUpload",
    icon: <DriveFolderUpload />,
  },
];

export default navConfigWalker;
