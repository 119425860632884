import { Alert, Snackbar } from "@mui/material";
import React from "react";

/**
 *
 * @param {boolean} open - Snackbar open state
 * @param {function} setOpen - Function to set the open state
 * @param {string} severity - Severity of the alert
 * @param {string} message - Message to display in the alert
 * @param {string} vertical - Vertical position of the alert
 * @param {string} horizontal - Horizontal position of the alert
 * @description Custom Snackbar Alert component
 * @returns
 */
const CustomSnackbarAlert = ({
  open = false,
  setOpen = () => {},
  severity = "warning",
  message = "Irgendwas ist schiefgelaufen...",
  vertical = "bottom",
  horizontal = "right",
}) => {
  const handleClose = (event, reason) => {
    // If the Snackbar was dismissed by clicking away, ignore it.
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  return (
    <Snackbar anchorOrigin={{ vertical, horizontal }} open={open} autoHideDuration={2000} onClose={handleClose}>
      <Alert severity={severity} variant="filled" sx={{ width: "100%" }}>
        {message}
      </Alert>
    </Snackbar>
  );
};

export default CustomSnackbarAlert;
