import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";

import { Card, Typography, Box, Divider, Button } from "@mui/material";
import { GridToolbarQuickFilter } from "@mui/x-data-grid";

import { prequalificationColumns } from "layouts/DataGridLayouts";

import { getPrequalificationList, GetSpecialist, GetProvidingFieldsList } from "api";
import { CustomContainer, CustomDataGrid } from "components";

// import StombData from "./prequalificationSub/stombData";
// import Perpetration from "./prequalificationSub/perpetration";
// import PreviousPre from "./prequalificationSub/previousPre";
// import SupplyArea from "./prequalificationSub/supplyArea";
// import Activtiy from "./prequalificationSub/activity";

const Prequalification = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const account = useSelector((state) => state.account);
  const prequalification = useSelector((state) =>
    location.state
      ? state.prequalification.filter((item) => item.facility[0].id === location.state.id)
      : state.prequalification
  );

  React.useEffect(() => {
    const initialLoad = async () => {
      dispatch(getPrequalificationList(location?.state?.id, account.id));
    };
    initialLoad();
  }, [account.id, dispatch, location?.state?.id]);

  // const loadStuff = async (item) => {
  //   console.log(item);
  //   const specialist = await dispatch(getSpecialist(item.facility[0].id));
  //   let specialistsIds = specialist?.map((item) => item.id);

  //   dispatch(GetProvidingFieldsList(specialistsIds));
  // }; // MUSS DAS SEIN?

  const handleClick = async (event) => {
    try {
      const specialist = await dispatch(GetSpecialist(event.row.facility[0].id, false));
      let specialistsIds = specialist?.map((item) => item.id);

      dispatch(GetProvidingFieldsList(specialistsIds));
    } catch (e) {
      alert("ein Fehler ist aufgetreten");
    } finally {
      navigate("/dashboard/prequalificationList", { state: event.row });
    }
  };

  const extendetPrequalificationColumns = [
    {
      field: "choose",
      headerName: "Auswählen",
      flex: 0.15,
      minWidth: 100,
      editable: false,
      renderCell: (params) => {
        return (
          <Button onClick={() => handleClick(params)} variant="contained">
            Öffnen
          </Button>
        );
      },
    },
    ...prequalificationColumns,
  ];

  return (
    <CustomContainer>
      <Card
        sx={{
          display: "flex",
          alignItems: "center",
          p: 2,
          boxShadow: 2,
        }}
      >
        <Typography variant="h4">Präqualifizierungs Vorgang</Typography>
      </Card>
      <Card sx={{ mt: 5 }}>
        <CustomDataGrid
          rows={prequalification}
          columns={extendetPrequalificationColumns}
          initialPageSize={25}
          disableColumnMenu
          onRowDoubleClick={handleClick}
          components={{
            Toolbar: (props) => (
              <>
                <Box sx={{ p: 1 }}>
                  <GridToolbarQuickFilter showQuickFilter {...props.toolbar} />
                </Box>
                <Divider sx={{ mt: 1, mb: 1 }} />
              </>
            ),
          }}
          componentsProps={{
            toolbar: {
              showQuickFilter: true,
            },
          }}
        />
      </Card>
    </CustomContainer>
  );
};

export default Prequalification;
