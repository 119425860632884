import { createSlice } from "@reduxjs/toolkit";

const companySlice = createSlice({
  name: "company",
  initialState: [],
  reducers: {
    companyAdd(state, action) {
      return action.payload;
    },
    companyCreate(state, action) {
      const company = action.payload.company;
      state.push(company);
    },
    companyDelete(state, action) {
      const toDelete = state.findIndex((spec) => spec.id === action.payload);
      if (toDelete > -1) state.splice(state, 1);
    },
    companyEdit(state, action) {
      const company = action.payload;
      const companyIndex = state.findIndex((item) => item.id === company.id);
      state[companyIndex] = company;
    },
    logoutCompany(state, action) {
      return companySlice.getInitialState();
    },
  },
});

export const { companyAdd, companyCreate, companyDelete, companyEdit, logoutCompany } = companySlice.actions;
export default companySlice.reducer;
