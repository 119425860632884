import React, { useState, useEffect, useCallback } from "react";
import {
  Box,
  Button,
  Snackbar,
  Alert,
  Typography,
  InputAdornment,
  TextField,
  Collapse,
  InputLabel,
} from "@mui/material";
import { getCertificate, getS3Object } from "api";
import { UnfoldMore, Tab } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { DisplayFiles } from "components";

const CertificateTab = ({ selectedFacility }) => {
  const [download, setDownload] = useState(false);
  const [certificate, setCertificate] = useState([]);
  const [open, setOpen] = useState({});
  const [clicked, setClicked] = useState(false);

  const asyncListGetter = useCallback(async () => {
    if (selectedFacility?.id) {
      const certificateResponse = await getCertificate(selectedFacility.id);
      console.log("certificate2", certificateResponse);
      setCertificate([certificateResponse]);
      // Initialize open state for each item
      const initialOpenState = {};
      const certificateArray = [certificateResponse];
      certificateArray.forEach((item) => {
        initialOpenState[item.id] = false; // Closed initially
      });
      setOpen(initialOpenState);
    }
  }, [selectedFacility]);

  useEffect(() => {
    asyncListGetter();
  }, [asyncListGetter]);

  const handleClick = (id) => {
    setOpen((prevState) => ({ ...prevState, [id]: !prevState[id] }));
    console.log("handleClick", id, open, certificate);
  };

  const handleDownload = async (item) => {
    try {
      setClicked(true);

      if (item.error === true) {
        setClicked(false);
        return;
      }

      // Create a download link
      const objectBuffer = await getS3Object(item);
      const blob = new Blob([objectBuffer], { type: item.imageType });

      // Create a download link
      const downloadLink = document.createElement("a");
      downloadLink.href = URL.createObjectURL(blob);
      downloadLink.download = item.imageBaseName;

      // Append the link to the body and trigger the download
      document.body.appendChild(downloadLink);
      downloadLink.click();

      // Clean up
      document.body.removeChild(downloadLink);

      setClicked(false);
      setDownload(true);
    } catch (error) {
      console.error("Error downloading file:", error);
      setClicked(false);
    }
  };

  // const logCertificate = () => {
  //   console.log("certificate log", certificate);
  //   if(certificate.length >= 1) {
  //     console.log("certificate 0 log", certificate[0]);
  //   }
  // };

  const hasCertificate = () => {
    if (certificate.length === 0) return false;

    const hasError = certificate[0]?.error;
    if (hasError) return false;

    const isCertificate = certificate[0]?.imageBaseName?.includes("zertifikat");

    return isCertificate;
  };

  return (
    <>
      <InputLabel sx={{ mt: 2, display: hasCertificate() ? "none" : "block" }}>
        Noch keine Zertifikate hinterlegt.
      </InputLabel>
      {hasCertificate() ? (
        <Box sx={{ mt: 5 }}>
          {certificate?.map((item) => (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                border: 1,
                p: 1,
                mb: 1,
              }}
            >
              <Box sx={{ display: "flex", flexDirection: "row" }}>
                <TextField
                  sx={{ flex: 0.8, ml: 2 }}
                  value={item.imageBaseName}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Box sx={{ backgroundColor: "primary.main", p: 1, borderRadius: 1 }}>
                          <Typography sx={{ color: "white", fontSize: 20 }}>Datei Name:</Typography>
                        </Box>
                      </InputAdornment>
                    ),
                  }}
                />
                <Box>
                  <UnfoldMore sx={{ fontSize: 60, ml: 3 }} onClick={() => handleClick(item.id)} />
                  <Link
                    target="_blank"
                    to="/showFile"
                    onClick={() => {
                      localStorage.removeItem("showFile");
                      localStorage.setItem("showFile", JSON.stringify(item));
                    }}
                  >
                    <Tab sx={{ fontSize: 60 }} />
                  </Link>
                </Box>
              </Box>

              <Collapse key={item.id} in={open[item.id]}>
                <Box sx={{ display: "flex", flexDirection: "column" }} key={item.id}>
                  {item && <DisplayFiles file={item} />}
                </Box>
              </Collapse>
              <Button
                variant="contained"
                sx={{ mt: 2, maxWidth: "fit-content", alignSelf: "center" }}
                disabled={clicked || item.error === true}
                onClick={() => handleDownload(item)}
              >
                Herunterladen
              </Button>
            </Box>
          ))}
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={download}
            autoHideDuration={6000}
            onClose={() => setDownload(false)}
          >
            <Alert severity="success" sx={{ width: "100%" }}>
              Erfolgreich heruntergeladen.
            </Alert>
          </Snackbar>
        </Box>
      ) : null}
    </>
  );
};

export default CertificateTab;
