import { createSlice } from "@reduxjs/toolkit";
import { StatusType } from "../../assets/type.ts";

const actionListSlice = createSlice({
  name: "actionList",
  initialState: [],
  reducers: {
    actionListAdd(state, action) {
      return action.payload;
    },
    actionListCompletedAdd(state, action) {
      return (state = [...state, ...action.payload]);
    },
    actionListRemoveCompleted(state, action) {
      return state.filter((item) => item.status !== StatusType.COMPLETED);
    },
    updateActionListItemSlice(state, action) {
      const item = action.payload;

      const itemIndex = state.findIndex((i) => i.id === item.id);
      state[itemIndex] = item;
    },
    attachActionListItemToAdminItemSlice(state, action) {
      const id = action.payload.actionListId;

      const account = action.payload.account;
      const itemIndex = state.findIndex((item) => item.id === id);

      state[itemIndex].account.push(account);
    },
    attachActionListArrayToAdminItemSlice(state, action) {
      const responseArray = action.payload;

      for (const item of responseArray) {
        const itemIndex = state.findIndex((i) => i.id === item.id);
        state[itemIndex] = item;
      }
    },
    moveActionListItemBetweenAdminItemSlice(state, action) {
      const id = action.payload.actionListId;
      const account = action.payload.account;
      const itemIndex = state.findIndex((item) => item.id === id);
      state[itemIndex].account = account;
    },
    moveMultipleActionListItemBetweenAdminItemSlice(state, action) {
      const responseArray = action.payload;

      for (const item of responseArray) {
        const itemIndex = state.findIndex((i) => i.id === item.id);
        state[itemIndex] = item;
      }
    },
    detachActionListItemFromAdminItemSlice(state, action) {
      const id = action.payload;
      const itemIndex = state.findIndex((item) => item.id === id);
      state[itemIndex].account = [];
    },
    detachActionListArrayFromAdminItemSlice(state, action) {
      const responseArray = action.payload;

      for (const item of responseArray) {
        const itemIndex = state.findIndex((i) => i.id === item.id);
        state[itemIndex] = item;
      }
    },
    actionListItemStatusUpdateItemSlice(state, action) {
      const actionListId = action.payload.actionListItemId;
      const value = action.payload.value;
      const actionListItemIndex = state.findIndex((item) => item.id === actionListId);
      state[actionListItemIndex].status = value;
    },
    logoutActionList(state, action) {
      return actionListSlice.getInitialState();
    },
  },
});

export const {
  actionListAdd,
  actionListCompletedAdd,
  actionListRemoveCompleted,
  updateActionListItemSlice,
  attachActionListItemToAdminItemSlice,
  attachActionListArrayToAdminItemSlice,
  detachActionListItemFromAdminItemSlice,
  detachActionListArrayFromAdminItemSlice,
  moveActionListItemBetweenAdminItemSlice,
  moveMultipleActionListItemBetweenAdminItemSlice,
  actionListItemStatusUpdateItemSlice,
  logoutActionList,
} = actionListSlice.actions;
export default actionListSlice.reducer;
