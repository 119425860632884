import React from "react";
//mui import
import { Card, Box } from "@mui/material";
import { OpenInNew } from "@mui/icons-material";

//component import
import { CModal, CustomDataGrid } from "components";

import { facilityColumns } from "layouts/DataGridLayouts";

const SelectFacility = ({ isOpen, onRequestClose, facilities, openSelectedFacility }) => {
  const extendetFacilityColumns = [
    ...facilityColumns,
    {
      field: "choose",
      headerName: "Auswählen",
      flex: 0.15,
      editable: false,
      renderCell: (params) => {
        return (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              height: "100%",
            }}
            onClick={() => openSelectedFacility(params.row)}
          >
            <OpenInNew />
          </Box>
        );
      },
    },
  ];

  return (
    <CModal
      modalLabel="Betriebsstätten"
      open={isOpen}
      onRequestClose={onRequestClose}
      style={{ width: "70%", transform: "translate(20%, 15%)" }}
    >
      <Card
        sx={{
          display: "flex",
          flexDirection: "column",
          p: 2,
          m: 2,
        }}
      >
        <CustomDataGrid rows={facilities} columns={extendetFacilityColumns} disableSelectionOnClick />

        {/* {facilities?.map((item) => {
          return (
            <Box
              sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
              onClick={() => openSelectedFacility()}
            >
              <OpenInNew />
              <Typography>{item.name}</Typography>
            </Box>
          );
        })} */}
      </Card>
    </CModal>
  );
};

export default SelectFacility;
