import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Button,
  Autocomplete,
  TextField,
  MenuItem,
  ListItemText,
  Typography,
  Select,
  Checkbox,
  Divider,
} from "@mui/material";
import { DisabledByDefault } from "@mui/icons-material";

import { LabelName, CustomDataGrid } from "components";
import { editSpecialist, GetProvidingFields } from "api";

//REWORK MOVING SPECIALIST AND UPDATEING

import { specialistColumns } from "layouts/DataGridLayouts";
import { dynamicSpecialistFieldValue } from "utils/dynamicSpecialistFieldValue";

const SpecialistTab = ({ switchTab, selectedSpecialist, setSelectedSpecialist }) => {
  const dispatch = useDispatch();
  const specialist = useSelector((state) => state.specialist);
  const facilities = useSelector((state) => state.facility);
  const occupation = useSelector((state) => state.occupation);
  const criteriaCatalog = useSelector((state) => state.criteriaCatalog);

  const [providing_fields, setProvidingFields] = React.useState([]);
  const [destinedFacilityToMoveSpecialistTo, setDestinedFacilityToMoveSpecialistTo] = React.useState({});
  const [clicked, setClicked] = React.useState(false);

  const selectInputToState = (e, targetName) => {
    setSelectedSpecialist((prev) => ({ ...prev, [targetName]: e.target.value }));
  };

  const handleSpecialistSelect = (row) => {
    if (row.length === 0) {
      setSelectedSpecialist([]);
      return;
    }
    const selected = specialist.filter((item) => item.id === row[0]);
    setSelectedSpecialist(selected[0]);
    dispatch(GetProvidingFields(selected[0].id)).then((response) => {
      setProvidingFields(dynamicSpecialistFieldValue(criteriaCatalog, selected[0]));
    });

    // const name = `${selected[0]?.first_name} ${selected[0]?.last_name}`;
    // const filtered = prequalification?.prequalificationItem?.filter((item) => item?.specialist?.includes(name));
  };

  const handleProvidingAction = (from, row) => {
    const element = providing_fields.findIndex((item) => item.code === row.code);
    const elementSpec = selectedSpecialist?.providing_fields?.findIndex((item) => item.code === row.code);

    if (from) {
      providing_fields[element].in_store_selected = !providing_fields[element].in_store_selected;
      setSelectedSpecialist((prevState) => {
        const updatedFields = [...prevState.providing_fields];
        updatedFields[elementSpec] = {
          ...updatedFields[elementSpec],
          in_store: !updatedFields[elementSpec].in_store,
        };
        return {
          ...prevState,
          providing_fields: updatedFields,
        };
      });
    } else {
      providing_fields[element].field_sales_selected = !providing_fields[element].field_sales_selected;
      setSelectedSpecialist((prevState) => {
        const updatedFields = [...prevState.providing_fields];
        updatedFields[elementSpec] = {
          ...updatedFields[elementSpec],
          field_sales: !updatedFields[elementSpec].field_sales,
        };
        return {
          ...prevState,
          providing_fields: updatedFields,
        };
      });
    }
  };

  const providingFieldsColumns = [
    {
      field: "in_store",
      headerName: "Geschäftslokal",
      flex: 0.15,
      editable: false,
      default: false,
      renderCell: ({ row }) => {
        if (row.in_store_allowed) {
          if (row.active === false) {
            return <Checkbox checked={row.in_store_selected} checkedIcon={<DisabledByDefault />} />;
          }
          return <Checkbox checked={row.in_store_selected} onClick={() => handleProvidingAction(true, row)} />;
        } else return <></>;
      },
    },
    {
      field: "field_sales",
      headerName: "Außendienst",
      flex: 0.15,
      editable: false,
      default: false,
      renderCell: ({ row }) => {
        if (row.field_sales_allowed) {
          if (row.active === false) {
            return <Checkbox checked={row.in_store_selected} checkedIcon={<DisabledByDefault />} />;
          }
          return <Checkbox checked={row.field_sales_selected} onClick={() => handleProvidingAction(false, row)} />;
        } else return <></>;
      },
    },
    {
      field: "code",
      headerName: "Versorgungsbereiche",
      flex: 0.2,
      editable: false,
    },
    {
      field: "description",
      headerName: "Bezeichnung",
      flex: 0.4,
      editable: false,
    },
  ];

  return (
    <>
      <Box
        sx={{
          mt: 2,
          display: "flex",
          flexDirection: "row",
          mb: 4,
          width: "100%", // REWORD
          alignContent: "center",
          justifyContent: "space-between",
        }}
      >
        <Button
          variant={"outlined"}
          disabled={clicked}
          onClick={() => {
            setClicked(true);
            switchTab(false);
            setClicked(false);
          }}
        >
          Zurück
        </Button>
        <Button disabled variant={"outlined"} onClick={() => editSpecialist(selectedSpecialist)}>
          Aktualisieren
        </Button>
        <Button
          sx={{ ml: 5 }}
          variant={"outlined"}
          disabled={clicked}
          onClick={() => {
            setClicked(true);
            switchTab(true);
            setClicked(false);
          }}
        >
          Weiter
        </Button>
      </Box>
      <Typography>Inaktive anzeigbar machen</Typography>
      <CustomDataGrid
        initialState={{
          sorting: {
            sortModel: [
              {
                field: "active",
                sort: "desc",
              },
            ],
          },
        }}
        rows={specialist}
        columns={specialistColumns}
        initialPageSize={10}
        checkboxSelection
        disableSelectionOnClick
        // onRowClick={(rows) => handleSpecialistSelect(rows)}
        onSelectionModelChange={(rows) => handleSpecialistSelect(rows)}
      />

      <Divider textAlign="left" sx={{ my: 2 }}>
        <Typography fontWeight={"bold"}>Fachl. Leitung Details</Typography>
      </Divider>
      <Box sx={{ mt: 2 }}>
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <LabelName titel="Anrede" />
          <Box sx={{ flex: 0.75 }}>
            <Select
              value={selectedSpecialist?.gender ?? ""}
              // renderValue={(p) => (p === 0 ? "Herr" : p === 1 ? "Frau" : "")}
              onChange={(e) => {
                console.log(e);
                selectInputToState(e, "gender");
              }}
              sx={{ flex: 0.75 }}
            >
              <MenuItem value={0}>
                <ListItemText primary="Herr" />
              </MenuItem>
              <MenuItem value={1}>
                <ListItemText primary="Frau" />
              </MenuItem>
              <MenuItem value={2}>
                <ListItemText primary="Divers" />
              </MenuItem>
            </Select>
          </Box>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <LabelName titel="Vorname" />
          <TextField sx={{ flex: 0.75 }} value={selectedSpecialist?.first_name ?? ""} />
        </Box>
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <LabelName titel="Nachname" />
          <TextField sx={{ flex: 0.75 }} value={selectedSpecialist?.last_name ?? ""} />
        </Box>
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <LabelName titel="Email" />
          <TextField sx={{ flex: 0.75 }} value={selectedSpecialist?.email ?? ""} />
        </Box>
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <LabelName titel="Beruf / Qualifizierung" />
          <Box sx={{ flex: 0.75 }}>
            <Select
              value={
                occupation.filter((item) => item.short_form.includes(selectedSpecialist?.qualification))[0]?.id ?? ""
              }
              // renderValue={(p) => occupation[p]?.description ?? ""}
              onChange={(e) => {
                selectInputToState({ target: { value: occupation[e.target.value].short_form } }, "qualification");
              }}
              sx={{ flex: 0.75 }}
            >
              <Box sx={{ display: "flex", justifyContent: "flex-end", p: 2 }}>
                {/* <CancelPresentation onClick={onRequestClose} fontSize="large" /> */}
              </Box>

              {/* Map of available Jobtypes / ADD abk_rzung als Speichermedium */}
              {occupation?.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  <ListItemText primary={`${item.description} (${item.short_form})`} secondary={item.certificate} />
                </MenuItem>
              ))}
            </Select>
          </Box>
        </Box>
        <Divider textAlign="left" sx={{ my: 2 }}>
          <Typography fontWeight={"bold"}>Fachl. Leitung Verschieben</Typography>
        </Divider>
        <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
          <Autocomplete
            disablePortal
            getOptionLabel={(option) => option.name ?? ""}
            options={facilities ? facilities : []}
            sx={{ width: 300 }}
            value={destinedFacilityToMoveSpecialistTo}
            isOptionEqualToValue={(option, value) => {
              return option.name === value.name;
            }}
            onChange={(event, destinedFacility) => {
              //MOVE SPECIALIST
              setDestinedFacilityToMoveSpecialistTo(destinedFacility);
            }}
            renderInput={(params) => <TextField {...params} label="Betriebsstätten" />}
          />
          <Button
            variant="contained"
            disabled={clicked}
            onClick={() => {
              setClicked(true);
              editSpecialist(selectedSpecialist);
              setClicked(false);
            }}
          >
            Verschieben
          </Button>
        </Box>
      </Box>
      {selectedSpecialist?.length !== 0 && (
        <>
          <Divider textAlign="left" sx={{ my: 2 }}>
            <Typography fontWeight={"bold"}>Fachl. Leitung Versorgungsbereiche</Typography>
          </Divider>
          <CustomDataGrid
            rows={providing_fields}
            columns={providingFieldsColumns}

            // checkboxSelection={checkboxSelection}
            // onSelectionModelChange={(rows) => setSelectedFields(rows)}
          />
        </>
      )}
    </>
  );
};

export default SpecialistTab;
