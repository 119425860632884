import React, { useRef, useEffect, useState } from "react";
import { DataGrid, deDE } from "@mui/x-data-grid";
import { Tooltip } from "@mui/material";

const CustomDataGrid = ({ sx, initialPageSize = 25, ...otherProps }) => {
  const gridRef = useRef(null);

  // State for pagination model
  const [pageSize, setPageSize] = useState(initialPageSize);

  useEffect(() => {
    const stopScrollPropagation = (event) => {
      if (!gridRef.current) return;

      const scrollableDiv = gridRef.current.querySelector(".MuiDataGrid-virtualScroller");
      if (!scrollableDiv || !scrollableDiv.contains(event.target)) return;

      const { scrollHeight, clientHeight, scrollTop } = scrollableDiv;
      const atTop = scrollTop === 0;
      const atBottom = scrollTop + clientHeight >= scrollHeight;

      if ((event.deltaY < 0 && atTop) || (event.deltaY > 0 && atBottom)) {
        event.stopPropagation();
      }
    };

    document.addEventListener("wheel", stopScrollPropagation, { passive: true });
    return () => document.removeEventListener("wheel", stopScrollPropagation);
  }, []);

  return (
    <DataGrid
      ref={gridRef}
      localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
      sx={{
        "& .MuiDataGrid-row": { backgroundColor: "background.paper" },
        "& .MuiDataGrid-virtualScroller": { overflow: "auto !important" },
        ...sx,
      }}
      pageSize={pageSize}
      onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
      rowsPerPageOptions={[5, 10, 25, 50, 100]}
      pagination
      autoHeight
      scrollbarSize={1}
      //hideFooterSelectedRowCount
      {...otherProps}
    />
  );
};

export const renderCellWithTooltip = (params) => {
  return (
    <Tooltip title={params.value} arrow disableInteractive>
      <span
        style={{
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          display: "block",
          width: "100%",
        }}
      >
        {params.value}
      </span>
    </Tooltip>
  );
};

export default CustomDataGrid;
