import React from "react";
import { AssessmentType, StatusType } from "assets/type.ts";
import { CustomUpload, DefinedTabs } from "components";

export default function RemoveSpecialistPage({ selectedAssessmentState, handleUpload, handleClicked, handleDelete }) {
  const [activeTab, setActiveTab] = React.useState(0);
  const handleChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  return (
    <>
      <DefinedTabs value={activeTab} handleChange={handleChange} SecondTabLabel="Entfallende Vorraussetzungen" />
      {selectedAssessmentState?.prequalificationItem?.map((item) => (
        <>
          {item.type === 0 && activeTab === 0 ? (
            <CustomUpload
              key={item.id}
              titel={item.titel}
              description={item.description}
              specialist={item.specialist}
              status={item.status}
              preqStatus={
                selectedAssessmentState?.type !== AssessmentType.REMOVEPROVIDINGFIELDS
                  ? selectedAssessmentState?.status
                  : StatusType.ACCEPTED
              } //I dont want
              files={item.image}
              comment={item.comment}
              providingFields={item.providing_fields}
              handleUpload={(file) => handleUpload(file, item)}
              handleClicked={(item) => handleClicked(item)}
              handleDelete={handleDelete}
            />
          ) : null}
          {item.type === 1 && activeTab === 1 ? (
            <CustomUpload
              key={item.id}
              titel={item.titel}
              description={item.description}
              specialist={item.specialist}
              status={item.status}
              preqStatus={StatusType.ACCEPTED}
              files={item.image}
              comment={item.comment}
              providingFields={item.providing_fields}
              handleUpload={(file) => handleUpload(file, item)}
              handleClicked={(item) => handleClicked(item)}
              handleDelete={handleDelete}
            />
          ) : null}
        </>
      ))}
    </>
  );
}
