import { assessmentItemImageUpdate, assessmentItemUpdate } from "../redux/reducers/assessment";
import { prequalificationItemImageUpdate, prequalificationItemUpdate } from "../redux/reducers/prequalification";
import instance from "./axiosConfig";

export const changeCommentReadableStatus = (commentId, status) => async (dispatch) => {
  let response;
  try {
    response = await instance.post(`/prequalification/changeCommentReadableStatus`, {
      status,
      id: commentId,
    });
    return response?.data;
  } catch (e) {
    return e;
  } finally {
    // dispatch(specialistAdd(response?.data));
  }
};

export const changeImageCommentReadableStatus = (imageId, commentId, readable) => async (dispatch) => {
  try {
    const response = await instance.post(`/prequalification/changeImageCommentReadableStatus`, {
      imageId,
      commentId,
      readable,
    });
    const payload = response?.data;
    console.log(payload);
    if (payload?.error !== true) {
      console.log("payload", payload);
      if (payload?.value?.prequalificationItem[0].prequalification.length !== 0) {
        dispatch(prequalificationItemImageUpdate(payload.value));
      }
      if (payload?.value?.prequalificationItem[0].assessment.length !== 0) {
        dispatch(assessmentItemImageUpdate(payload.value));
      }
      return payload.value;
    } else {
      return { error: true, message: response?.data?.message };
    }
  } catch (error) {
    console.error(error);
  }
};

export const deletePreqComment = (commentId, preqItemId) => async (dispatch) => {
  try {
    const response = await instance.post(`/prequalification/deletePreqComment`, {
      commentId,
      preqItemId,
    });
    const payload = response?.data;
    if (payload?.error !== true) {
      if (payload?.value?.prequalification.length !== 0) {
        dispatch(prequalificationItemUpdate(payload.value));
      }
      if (payload?.value?.assessment.length !== 0) {
        dispatch(assessmentItemUpdate(payload.value));
      }
      return payload.value;
    } else {
      return { error: true, message: response?.data?.message };
    }
  } catch (error) {
    console.error(error);
  }
};

export const changePreqCommentReadableStatus = (commentId, readable, preqItemId) => async (dispatch) => {
  try {
    const response = await instance.post(`/prequalification/changePreqCommentReadableStatus`, {
      commentId,
      readable,
      preqItemId,
    });
    const payload = response?.data;
    console.log(payload);
    if (payload?.error !== true) {
      console.log("payload", payload);
      if (payload?.value?.prequalification.length !== 0) {
        dispatch(prequalificationItemUpdate(payload.value));
      }
      if (payload?.value?.assessment.length !== 0) {
        dispatch(assessmentItemUpdate(payload.value));
      }
      return payload.value;
    } else {
      return { error: true, message: response?.data?.message };
    }
  } catch (error) {
    console.error(error);
  }
};
