import PreconditionTab from "../../ReusableTabs/preconditionTab";
import SpecialistInfo from "../extentions/specialistInfo";

export const RemoveSpecialistEvaluation = ({ assessment }) => {
  return (
    <>
      <SpecialistInfo assessment={assessment} removed={true} />
      <PreconditionTab
        selectedProcess={assessment}
        processType="assessment"
        selectedFacility={assessment?.facility[0]}
      />
    </>
  );
};

export default RemoveSpecialistEvaluation;
