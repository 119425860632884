import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

//MUI Imports
import {
  Typography,
  Card,
  Box,
  Divider,
  TextField,
  InputAdornment,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { GridToolbarQuickFilter } from "@mui/x-data-grid";
import { Mail } from "@mui/icons-material";

import { AccountList } from "api";
import { CustomDataGrid } from "components";

const SVContactPersonCard = () => {
  const dispatch = useDispatch();

  const [selectedAccount, setSelectedAccount] = React.useState(null);

  useEffect(() => {
    dispatch(AccountList());
  }, [dispatch]);

  const account = useSelector((state) => state.accountList);

  const [gender, setGender] = React.useState(0);
  const [first_name, setFirstName] = React.useState("");
  const [last_name, setLastName] = React.useState("");
  const [email, setEmail] = React.useState("");

  function updateStates(account) {
    console.log("account", account);

    if (!account) {
      setGender(0);
      setFirstName("");
      setLastName("");
      setEmail("");
      return;
    }
    setSelectedAccount(account);
    setGender(account.gender);
    setFirstName(account.firstName);
    setLastName(account.lastName);
    setEmail(account.email);
  }

  return (
    <Box sx={{ my: 1 }}>
      <Card className="account-card" sx={{ display: "flex", flexDirection: "column", p: 2 }}>
        <Divider textAlign="left" sx={{ display: "flex", alignItems: "center", mb: 2 }}>
          <Typography variant="h5">Kontakt Personen</Typography>
        </Divider>
        <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
          <CustomDataGrid
            rows={account}
            columns={[
              {
                field: "Name",
                headerName: "Name",
                flex: 1,
                editable: false,
                valueGetter: (params) => `${params.row.firstName || ""} ${params.row.lastName || ""}`,
              },
              {
                field: "email",
                headerName: "Email",
                flex: 1,
                editable: false,
              },
            ]}
            initialPageSize={10}
            checkboxSelection
            components={{
              Toolbar: (props) => (
                <>
                  <Box sx={{ display: "flex", flex: 1, p: 1, justifyContent: "space-between", alignItems: "center" }}>
                    <GridToolbarQuickFilter showQuickFilter {...props.toolbar} />
                  </Box>
                  <Divider sx={{ mt: 1, mb: 1 }} />
                </>
              ),
            }}
            componentsProps={{
              toolbar: {
                showQuickFilter: true,
              },
            }}
            onSelectionModelChange={(rows) => {
              console.log(rows);
              if (rows.length === 0) {
                if (selectedAccount) {
                  updateStates(null);
                }
                return;
              }
              const actionListObject = account?.find((item) => item.id === rows[0]);
              updateStates(actionListObject);
              setSelectedAccount(actionListObject);
            }}
          />
          {selectedAccount && (
            <>
              <Divider />
              <Box
                sx={{
                  mt: 5,
                  width: "100%",
                  display: "flex",
                  flex: 1,
                  flexDirection: "column",
                  alignContent: "flex-start",
                }}
              >
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Anrede</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={gender}
                    label="Age"
                    onChange={(e) => setGender(e.target.value)}
                  >
                    <MenuItem value={0}>Herr</MenuItem>
                    <MenuItem value={1}>Frau</MenuItem>
                    <MenuItem value={2}>* (divers)</MenuItem>
                  </Select>
                </FormControl>
                <Box sx={{ display: "flex", flexDirection: "row", flex: 1, justifyContent: "space-between" }}>
                  <Box sx={{ flex: 0.5, mt: 2 }}>
                    <TextField
                      sx={{ width: "80%" }}
                      label="Vorname"
                      value={first_name}
                      onChange={(e) => setFirstName(e.target.value)}
                    />
                  </Box>
                  <Box sx={{ flex: 0.5, mt: 2 }}>
                    <TextField
                      sx={{ width: "80%" }}
                      label="Nachname"
                      value={last_name}
                      onChange={(e) => setLastName(e.target.value)}
                    />
                  </Box>
                </Box>
                <TextField
                  sx={{ mt: 2 }}
                  label="E-Mail-Adresse"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Mail />
                      </InputAdornment>
                    ),
                  }}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Box>
            </>
          )}
        </Box>
      </Card>
    </Box>
  );
};

export default SVContactPersonCard;
