import React from "react";
import { useLocation, useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { logoutPrequalification } from "redux/reducers/prequalification";

import { Typography, Button, Box, Card, Divider, Tooltip } from "@mui/material";
import { GridToolbarQuickFilter } from "@mui/x-data-grid";
import { InfoOutlined } from "@mui/icons-material";

// hooks
import { GetFacilities, GetFacilitiesByAccount } from "api";

//custom
import { facilityColumns } from "layouts/DataGridLayouts";
import { CreateFacility } from "pages/modals";
import { CustomContainer, CustomDataGrid } from "components";

const Facility = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [modalIsOpen, setIsOpen] = React.useState(false);

  const facility = useSelector((state) => state.facility);
  const account = useSelector((state) => state.account);

  console.log("facility", facility);

  const newfacilityColumns = [
    {
      field: "detailPage",
      headerName: "Details",
      editable: false,
      flex: 0.15,
      renderCell: ({ row }) => {
        return (
          <Button
            variant="contained"
            onClick={() => {
              dispatch(logoutPrequalification());
              navigate("/dashboard/details", { state: row });
            }}
          >
            Auswählen
          </Button>
        );
      },
    },
    ...facilityColumns,
  ];

  React.useEffect(() => {
    if (location?.state?.id === undefined) {
      dispatch(GetFacilitiesByAccount(account?.id));
    }
    dispatch(GetFacilities(location?.state?.id));
  }, [dispatch, location?.state?.id, account?.id]);

  return (
    <CustomContainer>
      <Card
        sx={{
          display: "flex",
          alignItems: "center",
          p: 2,
          boxShadow: 2,
          mb: 5,
        }}
      >
        <Typography variant="h4">Betriebsstätte</Typography>
      </Card>
      <Card>
        <CustomDataGrid
          rows={facility}
          columns={newfacilityColumns}
          initialPageSize={25}
          disableColumnMenu
          components={{
            Toolbar: (props) => (
              <>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    p: 2, // Increased padding for better spacing
                    backgroundColor: "background.default",
                    borderRadius: 1,
                  }}
                >
                  {/* Left Section: Tooltip + Quick Filter */}
                  <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                    <Tooltip
                      disableFocusListener
                      disableTouchListener
                      arrow
                      title={
                        <Box sx={{ p: 1 }}>
                          <Typography variant="body1" fontWeight={500}>
                            Zum Erstellen einer Fachl. Leitung oder Beantragung einer Präqualifizierung:
                          </Typography>
                          <Typography variant="body2">
                            Bitte den <b>Auswählen</b>-Knopf drücken.
                          </Typography>
                          <Divider sx={{ my: 1 }} />
                          <Typography variant="body2">
                            Sobald eine Präqualifizierung beantragt wurde, kann eine Fachl. Leitung nur über einen
                            <b> Änderungsantrag</b> innerhalb der Detail-Seite beantragt werden.
                          </Typography>
                        </Box>
                      }
                    >
                      <InfoOutlined sx={{ color: "text.secondary", fontSize: 28 }} />
                    </Tooltip>
                    <GridToolbarQuickFilter
                      showQuickFilter
                      {...props.toolbar}
                      sx={{
                        "& .MuiInputBase-root": {
                          borderRadius: 2, // Rounded corners
                          border: "1px solid",
                          borderColor: "divider", // Uses theme color
                          backgroundColor: "background.paper", // Adapts to light/dark mode
                          padding: "6px 12px",
                          minWidth: 200, // Ensures consistent width
                        },
                      }}
                    />
                  </Box>

                  {/* Right Section: Action Button */}
                  <Button
                    onClick={() => setIsOpen(true)}
                    variant="contained"
                    sx={{
                      textTransform: "none",
                      padding: "6px 16px", // Matches the height of the input
                      height: "40px", // Forces a consistent height with input
                      minWidth: "auto", // Prevents unnecessary width expansion
                      lineHeight: "1.5", // Ensures proper text alignment
                    }}
                  >
                    Neue Betriebsstätte anlegen
                  </Button>
                </Box>
                <Divider sx={{ my: 1 }} />
              </>
            ),
          }}
          componentsProps={{
            toolbar: {
              showQuickFilter: true,
            },
          }}
          onRowDoubleClick={(event) => navigate("/dashboard/details", { state: event.row })}
        />
      </Card>
      <CreateFacility isOpen={modalIsOpen} onRequestClose={() => setIsOpen(false)} companyId={location?.state?.id} />
    </CustomContainer>
  );
};

export default Facility;
