import React from "react";
import { useNavigate } from "react-router-dom";

import { Container, Typography, Button, Card, TextField, Snackbar, Alert, Box } from "@mui/material";
import { sendPasswordResetRequest } from "api";

const ForgotPW = () => {
  const navigate = useNavigate();

  const [email, setEmail] = React.useState("");
  const [emailConfirm, setEmailConfirm] = React.useState("");
  const [errorIndicator, setErrorIndicator] = React.useState(false);

  const [alertOpen, setAlertOpen] = React.useState(false);
  const [alertMessage, setAlertMessage] = React.useState("");
  const [alertSeverity, setAlertSeverity] = React.useState("");

  const handleClick = async () => {
    if (email.trim() !== emailConfirm.trim()) {
      setErrorIndicator(true);
    } else {
      setErrorIndicator(false);
      const response = await sendPasswordResetRequest(email);
      setAlertOpen(true);
      console.log(response);
      if (response.error) {
        setAlertSeverity("error");
        setAlertMessage(response.message);
      } else {
        setAlertSeverity("success");
        setAlertMessage(response.message);
      }
    }
  };

  return (
    <Container component="main" sx={{ display: "flex", flexDirection: "column" }}>
      <Card
        sx={{
          mt: 8,
          display: "flex",
          alignItems: "cente",
          borderWidth: 2,
          boxShadow: 3,
          flexDirection: "column",
          p: 3,
        }}
      >
        <Typography variant="h3">Passwort zurücksetzen </Typography>
        <Typography sx={{ my: 2 }}>
          Falls sie ihr Passwort vergessen haben ,können sie hier einen Zurücksetztungslink für ihr Passwort anfordern.
          Geben sie hierfür bitte ihre accountzugehörige E-mail Adresse an.
        </Typography>

        <TextField
          sx={{ mb: 3 }}
          label="E-Mail-Adresse"
          onChange={(e) => setEmail(e.target.value)}
          value={email}
          error={errorIndicator}
          helperText={errorIndicator ? "Die E-Mail-Adressen stimmen nicht überein" : ""}
        />
        <TextField
          label="E-Mail-Adresse Bestätigen"
          onChange={(e) => setEmailConfirm(e.target.value)}
          value={emailConfirm}
          error={errorIndicator}
          helperText={errorIndicator ? "Die E-Mail-Adressen stimmen nicht überein" : ""}
        />
      </Card>
      <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
        <Button sx={{ display: "flex", alignSelf: "flex-start" }} variant="contained" onClick={() => navigate("/")}>
          Zurück
        </Button>
        <Button sx={{ display: "flex", alignSelf: "flex-end" }} variant="contained" onClick={handleClick}>
          Bestätigen
        </Button>
      </Box>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={alertOpen}
        autoHideDuration={5000}
        onClose={() => setAlertOpen(false)}
      >
        <Alert variant="filled" severity={alertSeverity}>
          {alertMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default ForgotPW;
