import axios from "axios";

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 60000,
});

// const interface_ip = "10.10.10.76";

// Set local address for Axios requests

// Add a request interceptor to include the token in the headers
instance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("TOKEN");
    if (token) {
      config.headers.authorization = token;
    }
    // config.httpAgent = new http.Agent({ localAddress: interface_ip });
    // config.localAddress = interface_ip;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use((response) => {
  if (response.headers.authorization) {
    localStorage.setItem("TOKEN", response.headers.authorization);
  }
  return response;
});

export default instance;
