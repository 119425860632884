import moment from "moment";
const criteriaCatalogColumns = [
  {
    field: "version",
    headerName: "Version",
    flex: 1,
    editable: false,
  },
  {
    field: "versionOfficial",
    headerName: "Prüfkatalog-Versionsnummer",
    flex: 1,
    editable: false,
  },
  {
    field: "valid_from",
    headerName: "Gültig Von",
    flex: 1,
    editable: false,
    valueGetter: (params) => {
      return moment(params.row.expires).format("DD.MM.YYYY");
    },
  },
  {
    field: "expires",
    headerName: "Gültig Bis",
    flex: 1,
    editable: false,
    valueGetter: (params) => {
      return moment(params.row.expires).format("DD.MM.YYYY");
    },
  },
];

export default criteriaCatalogColumns;
