import React from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

import { AssessmentType, StatusType } from "assets/type.ts";

import { Box, Button, TextField, Card, Typography, InputAdornment, Select, MenuItem, Tooltip } from "@mui/material";
import { Comment, Check, Close, InfoOutlined } from "@mui/icons-material";

import { updateItem, createPreqItemComment } from "api";
import { changePreqCommentReadableStatus, deletePreqComment } from "api/prequalificationItems";

import { prequalificationItemColumn } from "layouts/DataGridLayouts";
import { commentInputOnChange } from "utils/commentInputOnChange";

import { ShowStatus, CustomDataGrid, CustomPageSlider, FloatingInfo, SortableCollapseItemList } from "components";

const PreconditionTab = ({ switchTab, selectedProcess, processType, selectedFacility, openFacilityModal }) => {
  const dispatch = useDispatch();

  const [preqItemIndex, setPreqItemIndex] = React.useState(0);
  const [comment, setComment] = React.useState([]);
  const [visible, setVisible] = React.useState(false);

  const [clicked, setClicked] = React.useState(false);

  const process = useSelector((state) => {
    if (processType === "prequalification") {
      const index = state.prequalification.findIndex((item) => item.id === selectedProcess?.id);
      return state.prequalification[index];
    } else {
      const index = state.assessment.findIndex((item) => item.id === selectedProcess?.id);
      return state.assessment[index];
    }
  });

  const sendComment = () => {
    setClicked(true);
    const selectedComment = comment.findIndex((x) => x.id === process?.prequalificationItem[preqItemIndex].id);
    dispatch(
      createPreqItemComment(comment[selectedComment]?.text, visible, process?.prequalificationItem[preqItemIndex].id)
    ).then(() => {
      setClicked(false);
      setComment([]);
      setVisible(false);
    });
  };

  // const updateImageCommentReadableStatus = (imageId, commentId, readable) => {
  //   setClicked(true);
  //   dispatch(changeImageCommentReadableStatus(imageId, commentId, readable)).then(() => {
  //     setClicked(false);
  //     setComment([]);
  //     setVisible(false);
  //   });
  // };

  const updateCommentReadableStatus = (commentId, readable, preqItemId) => {
    setClicked(true);
    dispatch(changePreqCommentReadableStatus(commentId, readable, preqItemId)).then(() => {
      setClicked(false);
      setComment([]);
      setVisible(false);
    });
  };

  const deleteComment = (commentId, preqItemId) => {
    setClicked(true);
    dispatch(deletePreqComment(commentId, preqItemId)).then(() => {
      setClicked(false);
      setComment([]);
      setVisible(false);
    });
  };

  // const sendImageComment = (imageId) => {
  //   setClicked(true);
  //   const selectedComment = comment.findIndex((x) => x.id === imageId);
  //   dispatch(createPreqItemImageComment(comment[selectedComment]?.text, visible, imageId)).then(() => {
  //     setClicked(false);
  //     setComment([]);
  //     setVisible(false);
  //   });
  // };

  // const handleStatusUpdateImage = (value, imageId) => {
  //   setClicked(true);
  //   let status;
  //   if (value === true) {
  //     status = 1;
  //   } else status = 2;
  //   const selectedComment = comment.findIndex((x) => x.id === imageId);
  //   dispatch(updateItemImage(comment[selectedComment]?.text, status, imageId)).then(() => setClicked(false));
  //   setComment([]);
  // };

  const handleStatusUpdate = (value) => {
    setClicked(true);

    const selectedComment = comment.findIndex((x) => x.id === process?.prequalificationItem[preqItemIndex].id);
    dispatch(
      updateItem(
        comment[selectedComment]?.text ? comment[selectedComment]?.text : undefined,
        value === true ? StatusType.ACCEPTED : StatusType.REJECTED,
        process?.prequalificationItem[preqItemIndex].id
      )
    ).then(() => setClicked(false));
    setComment([]);
  };

  // const handleImageMove = (image, preqItem) => {
  //   setClicked(true);
  //   dispatch(moveImageToPreqItem(image.id, moveDestination.id, preqItem.id, image.prequalificationId)).then(() =>
  //     setClicked(false)
  //   );
  // };

  const navigateToRequirements = () => {
    //navigate to Requirments
    console.log(process?.prequalificationItem[preqItemIndex]);
  };

  const prequalificationItemExtendetColumns = [
    ...prequalificationItemColumn,
    {
      field: "required_for",
      flex: 0.3,
      editable: false,
      sortable: false,
      renderHeader: (params) => {
        return (
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <Comment sx={{ mr: 2 }} onClick={() => navigateToRequirements()} />
            <Typography>Notwendig für:</Typography>
          </Box>
        );
      },
      valueGetter: (params) => {
        if (params.row.code === "005" || params.row.code === "000") return `${params.row.specialist}`;
        else return `${params.row.required_for ? params.row.required_for : ""}`;
      },
    },
    processType === "assessment" && process.type === AssessmentType.REMOVESPECIALIST
      ? {
          field: "type",
          headerName: "Info",
          flex: 0.5,
          renderCell: ({ row }) => {
            return (
              <Box
                sx={{
                  display: "flex",
                  flex: 1,
                  justifyContent: "center",
                  alignContent: "center",
                }}
              >
                {row.type === 0 ? (
                  <Typography>Neue Vorraussetzung</Typography>
                ) : (
                  <Typography>Entfallende Vorraussetzung</Typography>
                )}
              </Box>
            );
          },
        }
      : {
          field: "active",
          headerName: "Aktiv",
          flex: 0.1,
          renderCell: ({ row }) => {
            return (
              <Box
                sx={{
                  display: "flex",
                  flex: 1,
                  justifyContent: "center",
                  alignContent: "center",
                }}
              >
                {row.active && <Check />}
                {!row.active && <Close />}
              </Box>
            );
          },
        },
  ];

  const commentColumns = [
    {
      field: "text",
      headerName: "Kommentar",
      flex: 1,
      editable: false,
      // valueGetter: (params) => `${params.row.titel}`,
    },
    {
      field: "date",
      headerName: "Datum",
      flex: 0.4,
      editable: false,
      renderCell: (params) => {
        return <Box>{`${moment(params.row.date).locale("de").format("DD.MM.YYYY")}`}</Box>;
      },
    },
    {
      field: "readable",
      headerName: "Für Kunden sichtbar",
      type: "singleSelect",
      flex: 0.4,
      editable: true,
      valueOptions: [
        {
          value: false,
          label: (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
                height: "100%",
              }}
            >
              <Close />
            </Box>
          ),
        },
        {
          value: true,
          label: (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
                height: "100%",
              }}
            >
              <Check />
            </Box>
          ),
        },
      ],
      preProcessEditCellProps: (params) => {
        const isPaidProps = params.row.readable;
        const hasError = isPaidProps.value && !params.props.value;
        return { ...params.props, error: hasError };
      },
      renderCell: (params) => {
        return (
          <Box>
            {params.row.readable === true && (
              <Box
                sx={{
                  display: "flex",
                  flex: 1,
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Check /> Ja
              </Box>
            )}
            {params.row.readable === false && (
              <Box
                sx={{
                  display: "flex",
                  flex: 1,
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Close />
              </Box>
            )}
          </Box>
        );
      },
    },
    {
      field: "account",
      headerName: "Erstellt von",
      flex: 0.5,
      editable: false,
      renderCell: (params) => {
        return (
          <>
            {params.row.account ? (
              <Box>{`${params.row.account[0]?.firstName} ${params.row.account[0]?.lastName}`}</Box>
            ) : (
              <Box>System</Box>
            )}
          </>
        );
      },
    },
  ];

  // window.addEventListener("focus")
  // window.addEventListener("keydown", (event) => {
  //   console.log(event.key);
  //   if (event.key === "ArrowLeft") {
  //     setPreqItemIndex(preqItemIndex - 1);
  //     setComment([]);
  //   }
  //   if (event.key === "ArrowRight") {
  //     setPreqItemIndex(preqItemIndex + 1);
  //     setComment([]);
  //   }
  // });

  return (
    <>
      {switchTab && (
        <>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              mb: 4,
              mt: 2,
              width: "100%", // REWORD
              alignContent: "center",
              justifyContent: "space-between",
            }}
            // onKeyDown={(event) => {
            //   console.log(event.key);
            //   if (event.key === "ArrowLeft") {
            //     setPreqItemIndex(preqItemIndex - 1);
            //     setComment([]);
            //   }
            //   if (event.key === "ArrowRight") {
            //     setPreqItemIndex(preqItemIndex + 1);
            //     setComment([]);
            //   }
            // }}
          >
            <Button
              variant={"outlined"}
              disabled={clicked}
              onClick={() => {
                setClicked(true);
                switchTab(false);
                setClicked(false);
              }}
            >
              Zurück
            </Button>
            <Button disabled variant={"outlined"} onClick={() => console.log("do smth")}>
              Aktualisieren
            </Button>
            <Button
              sx={{ ml: 5 }}
              variant={"outlined"}
              disabled={clicked}
              onClick={() => {
                setClicked(false);
                switchTab(true);
                setClicked(false);
              }}
            >
              Weiter
            </Button>
          </Box>
        </>
      )}
      {typeof process?.prequalificationItem !== "undefined" ? (
        <Box>
          <CustomDataGrid
            initialState={{
              sorting: {
                sortModel: [
                  {
                    field: "active",
                    sort: "desc",
                  },
                  {
                    field: "code",
                    sort: "asc",
                  },
                ],
              },
            }}
            rowHeight={30}
            rows={process?.prequalificationItem}
            columns={prequalificationItemExtendetColumns}
            // onRowClick={(rows) => handleSachlDoc(rows)}
            onRowDoubleClick={(rows) => {
              setPreqItemIndex(process?.prequalificationItem.findIndex((item) => item.id === rows.id));
              setComment([]);
            }}
            // experimentalFeatures={{ newEditingApi: true }}
          />
          <Card
            sx={{ p: 4, mt: 2 }}
            // onKeyDown={(event) => {
            //   console.log(event);
            //   // if (event.key === "ArrowRight") {
            //   //   setPreqItemIndex(preqItemIndex + 1);
            //   //   setComment([]);
            //   // }
            // }}
          >
            <Box sx={{ display: "flex", flex: 1, flexDirection: "row", justifyContent: "space-between", mt: 2 }}>
              <Box sx={{ display: "flex", flex: 0.175, justifyContent: "flex-end" }}>
                <ShowStatus status={process?.prequalificationItem[preqItemIndex]?.status} />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flex: 0.8,
                  flexDirection: "row",
                  justifyContent: "space-around",
                  alignContent: "center",
                  border: 1,
                }}
              >
                <CustomPageSlider
                  process={process}
                  preqItemIndex={preqItemIndex}
                  setPreqItemIndex={setPreqItemIndex}
                  setComment={setComment}
                />
              </Box>
            </Box>
            <Box>
              <TextField
                sx={{ display: "flex", flex: 0.5, mt: 2 }}
                id={`outlined-multiline-static-${process?.prequalificationItem[preqItemIndex].id}`}
                label="Kommentar"
                multiline
                rows={5}
                value={comment?.find((x) => x.id === process?.prequalificationItem[preqItemIndex].id || "")?.text || ""}
                onChange={(e) =>
                  commentInputOnChange(
                    process?.prequalificationItem[preqItemIndex].id,
                    e.target.value,
                    comment,
                    setComment
                  )
                }
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Box sx={{ display: "flex", flexDirection: "column" }}>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={visible}
                          title="Sichtbarkeit"
                          onChange={(e) => setVisible(e.target.value)}
                        >
                          <MenuItem value={true}>Sichtbar für Kunden</MenuItem>
                          <MenuItem value={false}>Nicht Sichtbar für Kunden</MenuItem>
                        </Select>
                        <Button variant="contained" onClick={() => sendComment()}>
                          Absenden
                        </Button>
                      </Box>
                    </InputAdornment>
                  ),
                }}
              />
              {process?.prequalificationItem[preqItemIndex]?.comment.length === 0 ? (
                <>Keine Kommentare bisher</>
              ) : (
                <>
                  <CustomDataGrid
                    initialState={{
                      sorting: {
                        sortModel: [
                          {
                            field: "date",
                            sort: "desc",
                          },
                        ],
                      },
                    }}
                    rows={process?.prequalificationItem[preqItemIndex]?.comment}
                    columns={[
                      {
                        field: "info",
                        headerName: "Info",
                        flex: 0.15,
                        editable: false,
                        renderCell: (params) => {
                          return (
                            <Tooltip
                              disableFocusListener
                              disableTouchListener
                              arrow
                              title={<Typography variant="body1">{params.row.text}</Typography>}
                            >
                              <InfoOutlined sx={{ mr: 3, scale: "1.5" }} />
                            </Tooltip>
                          );
                        },
                      },
                      ...commentColumns,
                      {
                        field: "delete",
                        headerName: "Entfernen",
                        flex: 0.2,
                        editable: false,
                        renderCell: (params) => {
                          return (
                            <Button
                              variant="contained"
                              aria-label="update Image Name"
                              onClick={() => deleteComment(params.id, process?.prequalificationItem[preqItemIndex]?.id)}
                            >
                              Löschen
                            </Button>
                          );
                        },
                      },
                    ]}
                    disableSelectionOnClick
                    onCellEditCommit={(params) =>
                      updateCommentReadableStatus(
                        params.id,
                        params.value,
                        process?.prequalificationItem[preqItemIndex]?.id
                      )
                    }
                  />
                </>
              )}
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                mt: 2,
              }}
            >
              <Button
                sx={{ backgroundColor: "success.main", color: "white", mr: 3 }}
                variant={"outlined"}
                disabled={clicked}
                onClick={() => handleStatusUpdate(true)} // LOGIC!
              >
                Akzeptiert
              </Button>
              <Button
                sx={{ backgroundColor: "error.main", color: "white" }}
                variant={"outlined"}
                disabled={clicked}
                onClick={() => handleStatusUpdate(false)} // LOGIC!
              >
                Abgelehnt
              </Button>
            </Box>
          </Card>
          <SortableCollapseItemList process={process} preqItemIndex={preqItemIndex} />
        </Box>
      ) : (
        <></>
      )}
      <FloatingInfo
        selectedFacility={selectedFacility}
        selectedProcess={process}
        openFacilityModal={() => openFacilityModal()}
      />
    </>
  );
};

export default PreconditionTab;
