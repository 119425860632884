const options = [
  { id: 1, value: "FIRSTMONITORING", text: "Erste Überwachung" },
  { id: 2, value: "SECONDMONITORING", text: "Zweite Überwachung" },
  { id: 3, value: "ADDSPECIALIST", text: "Zusätzliche FL" },
  { id: 4, value: "CHANGESPECIALIST", text: "Wechsel FL" },
  { id: 5, value: "REMOVESPECIALIST", text: "Wegfall FL" },
  { id: 6, value: "EXTENDPROVIDINGFIELDS", text: "Erweiterung VB" },
  { id: 7, value: "REMOVEPROVIDINGFIELDS", text: "Reduzierung VB" },
  { id: 8, value: "MOVE", text: "Umzug" },
  { id: 9, value: "CHANGEOFROOM", text: "Sonstige Änderung der Räumlichkeiten" },
  { id: 10, value: "CHANGECOMPANYNAME", text: "Umfirmierung" },
  { id: 11, value: "CHANGEOFLEGALFORM", text: "Rechtsformänderung" },
  { id: 12, value: "ADDCONTACTPERSON", text: "Zusätzlicher Ansprechpartner/in" },
  { id: 13, value: "CHANGECONTACTPERSON", text: "Wechsel Ansprechpartner/in" },
  { id: 14, value: "REMOVECONTACTPERSON", text: "Wegfall Ansprechpartner/in" },
  { id: 15, value: "CHANGEEMAILCONTACTPERSON", text: "Wechsel Email-Adresse Ansprechpartner" },
  { id: 16, value: "CHANGEEMAILSPECIALIST", text: "Wechsel Email-Adresse FL" },
  { id: 17, value: "CHANGEBILLINGEMAIL", text: "Wechsel Email-Adresse Rechnungen" },
  { id: 18, value: "CHANGEBANKACCOUNTDATA", text: "Änderung der Konto-Daten" },
  { id: 19, value: "CHANGEBILLADDRESS", text: "Änderung der Rechnungs-Anschrift" },
  { id: 20, value: "CHANGEOFCEO", text: "Geschäftsführerwechsel" },
  { id: 21, value: "CHANGEOFOWNER", text: "Inhaberwechsel" },
  { id: 22, value: "OPENINSOLVENCY", text: "Eröffnung Insolvenzverfahren" },
  { id: 23, value: "CLOSECOMPANY", text: "Schließung der Betriebsstätte" },
];

export default options;
