//REWORK GET VALID CRITERIA CATALOG, THAN CHECK WHICH CRITERIA CATALOG THE SPECIALIST IS USING

import moment from "moment";

export function addedDynamicSpecialistFieldValue(criteriaCatalog, selectedSpecialist, oldSpecialist) {
  let criteriaCatalogVersionIndex;
  if (process.env.REACT_APP_FIXED_CATALOG_VERSION === "true")
    criteriaCatalogVersionIndex = criteriaCatalog.findIndex(
      (item) => item.version === process.env.REACT_APP_CATALOG_VERSION
    );
  else criteriaCatalogVersionIndex = criteriaCatalog.findIndex((item) => moment().isBefore(item.expires));

  console.log("inxed", criteriaCatalogVersionIndex);
  console.log(selectedSpecialist);

  if (criteriaCatalogVersionIndex !== -1) {
    const criteriaCatalogItem = criteriaCatalog[criteriaCatalogVersionIndex].catalogItem;

    // Filter the items based on the qualifications
    const filteredKatalog = criteriaCatalogItem.filter((item) => {
      const occupationArray = item.specialist_short_forms.split(",");
      const qualifications = selectedSpecialist ? selectedSpecialist?.qualification : "";
      return occupationArray.find(
        (item) => item.trim() === qualifications.trim() || item.trim() === qualifications.replace(/[()]/g, "").trim()
      );
    });

    // Create a new array and append values to it
    const modifiedKatalog = filteredKatalog.map((item) => {
      const newItem = { ...item }; // Create a copy of the item
      const providingFieldIndex = selectedSpecialist.providing_fields.findIndex(
        (provItem) => provItem.code.substring(0, 3) === item.code.substring(0, 3)
      );

      newItem.criteriaCatalogVersion = criteriaCatalog[criteriaCatalogVersionIndex]?.version;
      newItem.versionOfficial = criteriaCatalog[criteriaCatalogVersionIndex]?.versionOfficial;
      newItem.in_store_selected = selectedSpecialist.providing_fields[providingFieldIndex]?.in_store;
      newItem.field_sales_selected = selectedSpecialist.providing_fields[providingFieldIndex]?.field_sales;
      return newItem;
    });

    const fixedKatalog = modifiedKatalog.filter((item) => {
      const different = oldSpecialist.providing_fields.find(
        (oldItem) => oldItem.code.substring(0, 3) === item.code.substring(0, 3)
      );

      if (different === undefined) {
        return true;
      }
      return false;
    });

    // Now you can use modifiedKatalog as needed
    return fixedKatalog;
  }
}
