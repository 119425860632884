import React, { useState, useEffect, useCallback } from "react";
import {
  Box,
  Button,
  Snackbar,
  Alert,
  Typography,
  InputAdornment,
  TextField,
  Collapse,
  InputLabel,
  Card,
} from "@mui/material";
import { getBillList, getS3Object } from "api";
import { UnfoldMore, Tab } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { DisplayFiles } from "components";

const BillingTab = ({ selectedFacility }) => {
  // States
  const [download, setDownload] = useState(false);
  const [billList, setBillList] = useState([]);
  const [open, setOpen] = useState({});
  const [clicked, setClicked] = useState(false);

  // Memoize async function to prevent recreation on each render
  const asyncListGetter = useCallback(async () => {
    if (selectedFacility?.id) {
      const billList = await getBillList(selectedFacility.id);

      console.log("billList", billList);
      setBillList(billList || []);
    } else {
      console.log("no facility found", selectedFacility);
    }
  }, [selectedFacility]);

  // Use effect to fetch data
  useEffect(() => {
    asyncListGetter();
  }, [asyncListGetter]);

  const handleClick = (id) => {
    setOpen((prevState) => ({ ...prevState, [id]: !prevState[id] }));
    console.log("handleClick", id, open, billList);
  };

  const handleDownload = async (item) => {
    try {
      setClicked(true);

      if (item.error === true) {
        setClicked(false);
        return;
      }

      // Create a download link
      const objectBuffer = await getS3Object(item);
      const blob = new Blob([objectBuffer], { type: item.imageType });

      // Create a download link
      const downloadLink = document.createElement("a");
      downloadLink.href = URL.createObjectURL(blob);
      downloadLink.download = item.imageBaseName;

      // Append the link to the body and trigger the download
      document.body.appendChild(downloadLink);
      downloadLink.click();

      // Clean up
      document.body.removeChild(downloadLink);

      setClicked(false);
      setDownload(true);
    } catch (error) {
      console.error("Error downloading file:", error);
      setClicked(false);
    }
  };

  const hasBill = () => {
    if (billList?.length === 0) return false;
    const hasError = billList[0]?.error;
    if (hasError) return false;
    const isBill = billList[0]?.image.imageBaseName.includes("rechnung");

    return isBill;
  };

  return (
    <>
      <InputLabel sx={{ mt: 2, display: hasBill() ? "none" : "block" }}>Noch keine Rechnungen hinterlegt.</InputLabel>
      <Box sx={{ mt: 5 }}>
        {billList?.map((item) => (
          <Card
            sx={{
              display: "flex",
              flex: 1,
              flexDirection: "column",
              p: 1,
              mb: 1,
            }}
          >
            <Box sx={{ display: "flex", flex: 1, flexDirection: "row", justifyContent: "space-between" }}>
              <TextField
                sx={{ flex: 0.7, ml: 2 }}
                value={item.image.imageBaseName}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Box sx={{ backgroundColor: "primary.main", p: 1, borderRadius: 1 }}>
                        <Typography sx={{ color: "white", fontSize: 20 }}>Datei Name:</Typography>
                      </Box>
                    </InputAdornment>
                  ),
                }}
              />
              <Box sx={{ display: "flex", flex: 0.3, flexDirection: "row", justifyContent: "space-between" }}>
                <Box
                  sx={{
                    display: "flex",
                    flex: 1,
                    justifyContent: "flex-start",
                    alignContent: "flex-start",
                  }}
                  onClick={() => handleClick(item.id)}
                >
                  <UnfoldMore sx={{ fontSize: 60 }} />
                </Box>
                <Link
                  target={"_blank"}
                  to={"/showFile"}
                  onClick={() => {
                    localStorage.removeItem("showFile");
                    localStorage.setItem("showFile", JSON.stringify(item.image));
                  }}
                >
                  <Tab sx={{ fontSize: 60 }} />
                </Link>
              </Box>
            </Box>
            <Collapse key={item.id} in={open[item.id]}>
              <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }} key={item.id}>
                {item.image && <DisplayFiles file={item.image} />}
              </Box>
            </Collapse>
            <Button
              variant="contained"
              sx={{ mt: 2, maxWidth: "fit-content", justifySelf: "center", alignSelf: "center" }}
              disabled={clicked || item.error === true}
              onClick={() => handleDownload(item.image)}
            >
              Herunterladen
            </Button>
          </Card>
        ))}
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={download}
          autoHideDuration={6000}
          onClose={() => setDownload(false)}
        >
          <Alert severity="success" sx={{ width: "100%" }}>
            Erfolgreich heruntergeladen.
          </Alert>
        </Snackbar>
      </Box>
    </>
  );
};

export default BillingTab;
