import React from "react";
import moment from "moment";
import { useSelector } from "react-redux";
import { StatusType } from "assets/type.ts";

import { DatePicker } from "@mui/x-date-pickers";
import {
  Box,
  Button,
  Autocomplete,
  TextField,
  Select,
  MenuItem,
  Typography,
  InputAdornment,
  Divider,
  Card,
} from "@mui/material";

import {
  successPrequalification,
  revertPrequalificationBack,
  setPrequalificationForReview,
  CreatePreviewXML,
  CreatePreviewCertificate,
} from "api";

import options from "utils/options";
import downloadFile from "utils/downloadFile.js";
import { CustomSnackbarAlert, CustomLoadingIndicator } from "components";

const EvaluationTab = ({ switchTab, selectedFacility, selectedAdmin, setSelectedAdmin, selectedPrequalification }) => {
  const admins = useSelector((state) => state.admin);

  const [reason, setReason] = React.useState(null);
  const [evaluationComment, setEvaluationComment] = React.useState(null);
  const [result, setResult] = React.useState(false);
  const [comment, setComment] = React.useState(null);
  const [endDate, setEndDate] = React.useState(null);
  const [startDate, setStartDate] = React.useState(null);
  const [clicked, setClicked] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  //error handling

  const [errorAlertOpen, setErrorAlertOpen] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [successAlertOpen, setSuccessAlertOpen] = React.useState(false);
  const [successMessage, setSuccessMessage] = React.useState("");
  const [errorIndicator, setErrorIndicator] = React.useState(false);

  const reasonText = selectedPrequalification?.comment?.length > 0 ? selectedPrequalification.comment[0]?.reason : "";

  const commentText = selectedPrequalification?.comment?.length > 0 ? selectedPrequalification.comment[0]?.text : "";

  const handleEvaluation = async (kind) => {
    setClicked(true);
    switch (kind) {
      case 0: {
        //TRANSFER
        if (!selectedAdmin?.id || !selectedPrequalification?.id) {
          alert("Bitte wähle einen Admin und eine Prequalifikation aus");
          break;
        }

        const response = await setPrequalificationForReview(
          selectedAdmin?.id,
          selectedPrequalification?.id,
          reasonText,
          evaluationComment
        );

        if (!response.error) {
          setSuccessMessage(response.message);
          setSuccessAlertOpen(true);
        } else {
          setErrorMessage(response.message);
          setSuccessAlertOpen(true);
        }
        break;
      }
      case 1: {
        if (!selectedPrequalification?.id) {
          alert("Bitte wähle sie eine Prequalifikation aus");
          break;
        }
        if (result === true) {
          if (
            selectedFacility.ik_number.toLowerCase().includes("t") ||
            selectedFacility.extraIKNumbers?.split(",").some((ik) => ik.toLowerCase().includes("t"))
          ) {
            setErrorMessage("Die eingereichte IK Nummer enthält ein T und kann somit nicht finalisiert werden.");
            setErrorAlertOpen(true);
            break;
          }

          if (!endDate || !startDate) {
            setErrorIndicator(true);
            break;
          }

          // setErrorMessage("Derzeit nicht verfügbar, bitte an Herrn Scheffer wenden.");
          // setErrorAlertOpen(true);
          // break;

          const response = await successPrequalification(
            selectedPrequalification?.id,
            endDate ? moment(endDate).format("YYYY-MM-DD") : null,
            endDate ? moment(startDate).format("YYYY-MM-DD") : null
          );
          if (!response.error) {
            setSuccessMessage(response.message);
            setSuccessAlertOpen(true);
          } else {
            setErrorMessage(response.message);
            setSuccessAlertOpen(true);
          }
        } else {
          const response = await revertPrequalificationBack(selectedPrequalification?.id, comment);
          if (!response.error) {
            setSuccessMessage(response.message);
            setSuccessAlertOpen(true);
          } else {
            setErrorMessage(response.message);
            setSuccessAlertOpen(true);
          }
        }
        break;
      }
      default:
        setErrorAlertOpen(true);
        setErrorMessage("Etwas ist schief gelaufen. Bitte versuchen Sie es erneut");
        break;
    }
    setClicked(false);
  };

  const displayXML = async () => {
    //setLoading(true);

    const {
      error,
      message,
      value: files,
    } = await CreatePreviewXML(
      selectedPrequalification?.id,
      endDate ? moment(endDate).format("YYYY-MM-DD") : null,
      startDate ? moment(startDate).format("YYYY-MM-DD") : null
    );

    if (!error && files) {
      downloadFile(files.xmlFile.data, files.xmlFile.name, files.xmlFile.type);
      setTimeout(() => downloadFile(files.aufFile.data, files.aufFile.name, files.aufFile.type), 500);
      setSuccessAlertOpen(true);
      setSuccessMessage(message);
    }
    setLoading(false);
  };

  const displayCertificate = async () => {
    setLoading(true);
    const {
      error,
      message,
      value: files,
    } = await CreatePreviewCertificate(
      selectedPrequalification?.id,
      endDate ? moment(endDate).format("YYYY-MM-DD") : null,
      startDate ? moment(startDate).format("YYYY-MM-DD") : null
    );
    if (!error && files) {
      downloadFile(files.data, files.name, files.type);
      setSuccessAlertOpen(true);
      setSuccessMessage(message);
    }
    setLoading(false);
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          mb: 4,
          mt: 2,
          width: "100%", // REWORD
          alignContent: "center",
          justifyContent: "space-between",
        }}
      >
        <Button
          variant={"outlined"}
          disabled={clicked}
          onClick={() => {
            setClicked(true);
            switchTab(false);
            setClicked(false);
          }}
        >
          Zurück
        </Button>
      </Box>

      <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
        {selectedFacility && selectedFacility.status !== StatusType.UNDER_REVIEW && (
          <Box>
            <Autocomplete
              disablePortal
              getOptionLabel={(option) => option.firstName}
              options={admins}
              sx={{ width: 300 }}
              value={selectedAdmin}
              isOptionEqualToValue={(option, value) => {
                return option.firstName === value.firstName;
              }}
              onChange={(event, newValue) => {
                setSelectedAdmin(newValue);
              }}
              renderInput={(params) => <TextField {...params} label="Bewerter" />}
            />
            <Autocomplete
              disablePortal
              getOptionLabel={(option) => option.text}
              options={options}
              sx={{ width: 300, mt: 2 }}
              value={reason}
              isOptionEqualToValue={(option, value) => {
                // if (!option || !value) {
                //   return false;
                // }
                return option.text === value.text;
              }}
              onChange={(event, newValue) => {
                setReason(newValue);
              }}
              renderInput={(params) => <TextField {...params} label="Ergänzung Betreff" />}
            />

            <TextField
              sx={{ flex: 0.8, mt: 2 }}
              id="outlined-multiline-static"
              label="Anmerkung"
              multiline
              rows={10}
              value={evaluationComment}
              onChange={(e) => setEvaluationComment(e.target.value)}
              variant="outlined"
            />
            <Button
              sx={{
                backgroundColor: selectedAdmin?.length !== 0 ? "primary.main" : "error.main",
                color: "white",
                mt: 1,
              }}
              variant={"outlined"}
              onClick={() => handleEvaluation(0)} // LOGIC!
              disabled={clicked}
            >
              Weiter geben
            </Button>
          </Box>
        )}
        {selectedFacility && selectedFacility.status === StatusType.UNDER_REVIEW && (
          <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
            {(reasonText || commentText) && (
              <>
                <Divider textAlign="left" sx={{ mb: 1 }}>
                  <Typography variant="h5">Notizen</Typography>
                </Divider>
                <Card sx={{ display: "flex", flexDirection: "column", p: 2 }}>
                  <TextField
                    sx={{ mb: 2 }}
                    value={reasonText}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Box sx={{ backgroundColor: "primary.main", p: 1, borderRadius: 1 }}>
                            <Typography sx={{ color: "white", fontSize: 20 }}>Grund:</Typography>
                          </Box>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <TextField
                    sx={{ flex: 0.8 }}
                    id="outlined-multiline-static-comment"
                    multiline
                    rows={5}
                    value={commentText}
                    variant="outlined"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Box sx={{ backgroundColor: "primary.main", p: 1, borderRadius: 1 }}>
                            <Typography sx={{ color: "white", fontSize: 20 }}>Anmerkung:</Typography>
                          </Box>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Card>
              </>
            )}
            <Divider textAlign="left" sx={{ mb: 1 }}>
              <Typography variant="h5">Bewertung</Typography>
            </Divider>
            <Card sx={{ display: "flex", flexDirection: "column", p: 2 }}>
              <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                <Select
                  sx={{ width: 300 }}
                  value={result}
                  onChange={(e) => {
                    setResult(e.target.value);
                  }}
                >
                  <MenuItem value={true}>Positiv</MenuItem>
                  <MenuItem value={false}>Negativ</MenuItem>
                </Select>
                <Box>
                  <Button
                    variant="contained"
                    onClick={() => {
                      displayCertificate();
                    }}
                  >
                    Vorschau Zertifikat Erstellen
                  </Button>

                  <Button
                    variant="contained"
                    onClick={() => {
                      displayXML();
                    }}
                  >
                    Vorschau XML Erstellen
                  </Button>
                </Box>
              </Box>
              {result && (
                <>
                  <Box sx={{ mt: 2, border: 1, p: 1 }}>
                    <Typography>Bitte Enddatum wählen</Typography>
                    {/* <Typography>Wenn Leer dann 5 Jahre</Typography> */}
                    <DatePicker value={endDate} title="Enddatum" onChange={(newValue) => setEndDate(newValue)} />
                    {errorIndicator && !endDate && <Typography color="error">Dieses Feld ist auszufüllen</Typography>}
                  </Box>
                  <Box sx={{ mt: 2, border: 1, p: 1 }}>
                    <Typography>Bitte Startdatum wählen</Typography>
                    {/* <Typography>Wenn Leer dann morgen</Typography> */}
                    <DatePicker value={startDate} title="Startdatum" onChange={(newValue) => setStartDate(newValue)} />
                    {errorIndicator && !startDate && <Typography color="error">Dieses Feld ist auszufüllen</Typography>}
                  </Box>
                </>
              )}

              <TextField
                sx={{ flex: 0.8, mt: 2 }}
                id="outlined-multiline-static"
                label="Begründung"
                multiline
                rows={10}
                value={comment ?? ""}
                onChange={(e) => setComment(e.target.value)}
                variant="outlined"
              />
              <Button
                sx={{
                  backgroundColor: "primary.main",
                  color: "white",
                  mt: 1,
                }}
                variant={"outlined"}
                onClick={() => handleEvaluation(1)} // LOGIC!
                disabled={clicked}
              >
                Abschicken
              </Button>
            </Card>
          </Box>
        )}
      </Box>
      <CustomSnackbarAlert
        open={errorAlertOpen || successAlertOpen}
        setOpen={() => {
          setErrorAlertOpen(false);
          setSuccessAlertOpen(false);
        }}
        severity={errorAlertOpen ? "error" : "success"}
        message={errorAlertOpen ? errorMessage : successMessage}
      />
      <CustomLoadingIndicator open={loading} message={"Einen Moment gedult. Datei wird erstellt."} />
    </>
  );
};

export default EvaluationTab;
