import { Box, Typography } from "@mui/material";

const Comment = ({ comments }) => {
  const isReadable = comments?.some((item) => item.readable === true);

  return (
    <>
      {isReadable ? (
        <Box sx={{ mt: 2 }}>
          <Typography variant="h6">Kommentar</Typography>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            {comments?.map((item) => {
              if (item.readable === true) {
                return <Typography sx={{ textAlign: "justify" }}>{item?.text}</Typography>;
              } else {
                return <></>;
              }
            })}
          </Box>
        </Box>
      ) : (
        <></>
      )}
    </>
  );
};

export default Comment;
