import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Button,
  Autocomplete,
  TextField,
  Card,
  Tabs,
  Tab,
  Select,
  MenuItem,
  Typography,
  Divider,
  Checkbox,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import BillingTab from "pages/details/tabs/billingTab";

import {
  editFacility,
  GetFacilityPrequalifications,
  historyStatusUpdate,
  getFacilityHistory,
  sendCustomEmail,
  createStatusReport,
} from "api";

import { historyColumns } from "layouts/DataGridLayouts";
import { CreateHistoryItemModal, UploadHistoryFilesModal } from "pages/modals";
import { LabelName, CustomTabPanel, CustomDataGrid, CustomSnackbarAlert } from "components";
import moment from "moment";

const FacilityTab = ({
  switchTab,
  selectedFacility,
  setSelectedFacility,
  setSelectedPrequalification,
  fastForward,
}) => {
  const dispatch = useDispatch();
  const facilities = useSelector((state) => state.facility);
  const history = useSelector((state) => state.history);
  const [selectedTabIndex, setSelectedTabIndex] = React.useState(0);
  const [createHistoryItemModalOpen, setCreateHistoryItemModalOpen] = React.useState(false);
  const [uploadHistoryFilesModalOpen, setUploadHistoryFilesModalOpen] = React.useState(false);
  const [selectedHistoryItem, setSelectedHistoryItem] = React.useState(null);
  const [clicked, setClicked] = React.useState(false);
  const [freeText, setFreeText] = React.useState(false);
  const [reason, setReason] = React.useState(0);
  const [message, setMessage] = React.useState("");
  const [date, setDate] = React.useState(null);

  const [alertOpen, setAlertOpen] = React.useState(false);
  const [alertMessage, setAlertMessage] = React.useState("");
  const [alertSeverity, setAlertSeverity] = React.useState("info");

  const textFieldInputToState = (e) => {
    setSelectedFacility((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const sendMessage = async () => {
    dispatch(
      sendCustomEmail(selectedFacility.id, reason, freeText, message, date ? moment(date).format("YYYY-MM-DD") : null)
    ).then((response) => {
      console.log(response);
      if (response.error) {
        setAlertOpen(true);
        setAlertMessage(response.message);
        setAlertSeverity("error");
      } else {
        setAlertOpen(true);
        setAlertMessage(response.message);
        setAlertSeverity("success");
      }
    });
  };

  async function createStatusRep() {
    console.log("Statusbericht erzeugen");
    dispatch(createStatusReport(selectedFacility.id)).then((response) => {
      console.log("onPage", response);

      if (response.error) {
        setAlertOpen(true);
        setAlertMessage(response.message);
        setAlertSeverity("error");
      } else {
        setAlertOpen(true);
        setAlertMessage(response.message);
        setAlertSeverity("success");
      }
    });
  }

  return (
    <>
      <Box sx={{ mt: 2 }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            mb: 4,
            width: "100%", // REWORD
            alignContent: "center",
            justifyContent: "space-between",
          }}
        >
          <Button
            variant={"outlined"}
            disabled={clicked}
            onClick={() => {
              setClicked(true);
              switchTab(false);
              setClicked(false);
            }}
          >
            Zurück
          </Button>
          <Button disabled variant={"outlined"} onClick={() => dispatch(editFacility(selectedFacility))}>
            Aktualisieren
          </Button>
          <Button
            sx={{ ml: 5 }}
            variant={"outlined"}
            disabled={clicked}
            onClick={() => {
              setClicked(true);
              switchTab(true);
              setClicked(false);
            }}
          >
            Weiter
          </Button>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <Autocomplete
            disablePortal
            getOptionLabel={(option) => option?.name}
            options={facilities ? facilities : []}
            sx={{ width: 300 }}
            value={selectedFacility}
            isOptionEqualToValue={(option, value) => {
              return option.name === value.name;
            }}
            onChange={(event, selectedFacilityObject) => {
              setSelectedFacility(selectedFacilityObject);
              setSelectedPrequalification(selectedFacilityObject?.prequalification[0]);
              dispatch(GetFacilityPrequalifications(selectedFacilityObject?.id));
              dispatch(getFacilityHistory(selectedFacilityObject?.id));
            }}
            renderOption={(props, option, { selected }) => (
              <li {...props} style={{ fontWeight: selected ? "bold" : "normal" }}>
                {option.name}
              </li>
            )}
            renderInput={(params) => <TextField {...params} label="Betriebsstätten" />}
          />
          <Button
            variant="contained"
            disabled={selectedFacility === null || clicked}
            onClick={() => {
              setClicked(true);
              fastForward(selectedFacility);
              setClicked(false);
            }}
          >
            Fast Forward
          </Button>
        </Box>
        <Box sx={{ mt: 1 }}>
          <Button variant="contained" onClick={createStatusRep}>
            Statusbericht erzeugen
          </Button>
        </Box>
      </Box>
      <Box sx={{ borderBottom: 1, borderColor: "divider", display: "flex", flex: 1, width: "100%" }}>
        <Tabs
          variant="fullWidth"
          sx={{ width: "100%" }}
          selectionFollowsFocus
          value={selectedTabIndex}
          onChange={(e, index) => setSelectedTabIndex(index)}
        >
          <Tab label="Daten" />
          <Tab label="Historie" />
          <Tab label="Kontakt" />
          <Tab label="Rechnungen" />
        </Tabs>
      </Box>
      <CustomTabPanel value={selectedTabIndex} index={0}>
        <Card sx={{ p: 4, width: "80%", mt: 2 }}>
          <Divider textAlign="left" sx={{ mb: 2 }}>
            <Typography variant="h5">Daten</Typography>
          </Divider>
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <LabelName titel="Unternehmen" />
            <TextField
              sx={{ flex: 0.75 }}
              name="name"
              value={selectedFacility?.name}
              onChange={(e) => textFieldInputToState(e)}
            />
          </Box>
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <LabelName titel="Zusatz" />
            <TextField
              name="extra_information"
              sx={{ flex: 0.75 }}
              value={selectedFacility?.extra_information}
              onChange={(e) => textFieldInputToState(e)}
            />
          </Box>
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <LabelName titel="IK-Nummer" />
            <TextField
              name="ik_number"
              sx={{ flex: 0.75 }}
              value={selectedFacility?.ik_number}
              onChange={(e) => textFieldInputToState(e)}
            />
          </Box>
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <LabelName titel="Zusatz IK-Nummer" />
            <TextField
              name="ik_number"
              sx={{ flex: 0.75 }}
              value={selectedFacility?.extraIKNumbers}
              onChange={(e) => textFieldInputToState(e)}
            />
          </Box>
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <LabelName titel="Straße, Hausnummer" />
            <TextField
              name="street"
              sx={{ flex: 0.375 }}
              value={selectedFacility?.street}
              onChange={(e) => textFieldInputToState(e)}
            />
            <TextField
              name="street_number"
              sx={{ flex: 0.375 }}
              value={selectedFacility?.street_number}
              onChange={(e) => textFieldInputToState(e)}
            />
          </Box>
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <LabelName titel="Postleitzahl, Stadt" />
            <TextField
              name="postcode"
              sx={{ flex: 0.375 }}
              value={selectedFacility?.postcode}
              onChange={(e) => textFieldInputToState(e)}
            />
            <TextField
              name="city"
              sx={{ flex: 0.375 }}
              value={selectedFacility?.city}
              onChange={(e) => textFieldInputToState(e)}
            />
          </Box>
        </Card>
        <Card sx={{ p: 4, width: "80%", mt: 2 }}>
          <Divider textAlign="left" sx={{ mb: 2 }}>
            <Typography variant="h5">Zusatzinformationen</Typography>
          </Divider>
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <LabelName titel="Scope" />
            <Box sx={{ flex: 0.75 }}>
              <Select autoWidth={true} value={selectedFacility?.scope} onChange={(e) => textFieldInputToState(e)}>
                <MenuItem value={1}>Orthopäditechnik (1)</MenuItem>
                <MenuItem value={2}>Orth.Schuhtechnik​ (2)</MenuItem>
                <MenuItem value={3}>Hörakustik (3)​</MenuItem>
                <MenuItem value={4}>Augenoptik (4)</MenuItem>
                <MenuItem value={5}>Blindenführhunde (5)</MenuItem>
                <MenuItem value={6.1}>Apotheke (6.1)</MenuItem>
                <MenuItem value={6.2}>Friseur (6.2)</MenuItem>
                <MenuItem value={6.3}>Altenheim (6.3)</MenuItem>
                <MenuItem value={6.4}>Sonstige (6.4)</MenuItem>
              </Select>
            </Box>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <LabelName titel="Haupthaus" />
            <Box sx={{ flex: 0.75 }}>
              <Select autoWidth={true} value={selectedFacility?.main_house} onChange={(e) => textFieldInputToState(e)}>
                <MenuItem value={true}>Ja</MenuItem>
                <MenuItem value={false}>Nein​</MenuItem>
              </Select>
            </Box>
          </Box>

          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <LabelName titel="Begehungsrelevant" />
            <Box sx={{ flex: 0.75 }}>
              <TextField
                name="großkunde"
                sx={{ flex: 0.75 }}
                value={selectedFacility?.inspection_relevance ? "Ja" : "Nein"}
              />
            </Box>
          </Box>

          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <LabelName titel="Großkunde" />
            <Box sx={{ flex: 0.75 }}>
              <TextField
                name="großkunde"
                sx={{ flex: 0.75 }}
                value={selectedFacility?.major_customer ? "Ja" : "Nein"}
              />
            </Box>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <LabelName titel="Insolvent" />
            <Box sx={{ flex: 0.75 }}>
              <Select autoWidth={true} value={selectedFacility?.insolvent} onChange={(e) => textFieldInputToState(e)}>
                <MenuItem value={true}>Ja</MenuItem>
                <MenuItem value={false}>Nein​</MenuItem>
              </Select>
            </Box>
          </Box>
        </Card>
        <Card sx={{ p: 4, width: "80%", mt: 2 }}>
          <Divider textAlign="left" sx={{ mb: 2 }}>
            <Typography variant="h5">Rechnungsinformationen</Typography>
          </Divider>

          <Box sx={{ display: "flex", flexDirection: "row", mt: 2 }}>
            <LabelName titel="Kreditinstitut" />
            <TextField
              name="kreditinstitut"
              sx={{ flex: 0.75 }}
              value={selectedFacility?.creditInstituteName ?? ""}
              onChange={(e) => textFieldInputToState(e)}
            />
          </Box>

          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <LabelName titel="IBAN" />
            <TextField
              name="IBAN"
              sx={{ flex: 0.75 }}
              value={selectedFacility?.IBAN}
              onChange={(e) => textFieldInputToState(e)}
            />
          </Box>
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <LabelName titel="BIC" />
            <TextField
              name="BIC"
              sx={{ flex: 0.75 }}
              value={selectedFacility?.BIC}
              onChange={(e) => textFieldInputToState(e)}
            />
          </Box>

          <Divider textAlign="left" sx={{ my: 2 }}>
            <Typography fontWeight={"bold"}>Rechnungsaddresse</Typography>
          </Divider>

          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <LabelName titel="Rechnungs-Email" />
            <TextField
              sx={{ flex: 0.75 }}
              label="Rechnungs-Email"
              value={selectedFacility?.billing_email}
              onChange={(e) => textFieldInputToState(e)}
            />
          </Box>
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <LabelName titel="Straße, Hausnummer" />
            <TextField
              name="street"
              sx={{ flex: 0.375 }}
              value={selectedFacility?.billing_street}
              onChange={(e) => textFieldInputToState(e)}
            />
            <TextField
              name="street_number"
              sx={{ flex: 0.375 }}
              value={selectedFacility?.billing_street_number}
              onChange={(e) => textFieldInputToState(e)}
            />
          </Box>
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <LabelName titel="Postleitzahl, Stadt" />
            <TextField
              name="street"
              sx={{ flex: 0.375 }}
              value={selectedFacility?.billing_postcode}
              onChange={(e) => textFieldInputToState(e)}
            />
            <TextField
              name="street_number"
              sx={{ flex: 0.375 }}
              value={selectedFacility?.billing_city}
              onChange={(e) => textFieldInputToState(e)}
            />
          </Box>
        </Card>
      </CustomTabPanel>
      <CustomTabPanel value={selectedTabIndex} index={1}>
        <Box sx={{ display: "flex", justifyContent: "flex-end", mb: 1 }}>
          <Button
            variant="contained"
            disabled={clicked}
            onClick={() => {
              if (!selectedHistoryItem) {
                setAlertOpen(true);
                setAlertMessage("Bitte wählen Sie einen Historieneintrag aus!");
                setAlertSeverity("error");
                return;
              }
              setClicked(true);
              setUploadHistoryFilesModalOpen(true);
              setClicked(false);
            }}
          >
            Dateien Hochladen
          </Button>
          <Button
            variant="contained"
            disabled={clicked}
            onClick={() => {
              setClicked(true);
              setCreateHistoryItemModalOpen(true);
              setClicked(false);
            }}
          >
            Historieneintrag anlegen
          </Button>
        </Box>
        <CustomDataGrid
          rows={history}
          columns={historyColumns}
          checkboxSelection
          disableSelectionOnClick
          onSelectionModelChange={(rows) => {
            console.log(rows);
            if (rows.length === 0) {
              if (selectedHistoryItem) {
                setSelectedHistoryItem(null);
              }
              return;
            }
            if (rows.length === 2) rows.shift();

            const historyObject = history?.filter((item) => item.id.includes(rows));

            setSelectedHistoryItem(historyObject[0]);
          }}
          onCellEditCommit={(params) => dispatch(historyStatusUpdate(params.id, params.value))}

          // onRowDoubleClick={(rows) => handleHistory(rows.row)}
        />
      </CustomTabPanel>
      <CustomTabPanel value={selectedTabIndex} index={2}>
        <Card sx={{ p: 4, width: "80%", mt: 2 }}>
          <Typography variant="h5" fontWeight={"bold"}>
            Hier können Sie Nachrichten an den Kunden senden.
          </Typography>
          <Box sx={{ display: "flex", flexDirection: "row", mt: 2 }}>
            <Select
              sx={{ flex: 0.3 }}
              id="demo-simple-select"
              value={reason}
              label="Grund"
              onChange={(e) => setReason(e.target.value)}
            >
              <MenuItem value={0}>Fehlende Nachweise</MenuItem>
              <MenuItem value={1}>Fristen</MenuItem>
              <MenuItem value={2}>Überwachung</MenuItem>
              <MenuItem value={3}>Re-PQ</MenuItem>
              <MenuItem value={4}>Entzug / Aussetzung / Ablehnen der PQ</MenuItem>
            </Select>
            {reason !== 0 && reason !== 4 && (
              <DatePicker value={date} onChange={(newValue) => setDate(newValue)} sx={{ flex: 0.3 }} />
            )}
          </Box>
          <Box sx={{ mt: 2, display: "flex", flexDirection: "row", alignItems: "center" }}>
            <Checkbox checked={freeText} onChange={(e) => setFreeText(e.target.checked)} />
            <Typography>Freitext verwenden</Typography>
          </Box>
          {freeText && (
            <TextField
              sx={{ width: "100%" }}
              label="Nachricht"
              multiline
              rows={4}
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
          )}

          <Button variant="contained" onClick={() => sendMessage()}>
            Absenden
          </Button>
        </Card>
      </CustomTabPanel>
      <CustomTabPanel value={selectedTabIndex} index={3}>
        <BillingTab selectedFacility={selectedFacility} />
      </CustomTabPanel>
      <CreateHistoryItemModal
        isOpen={createHistoryItemModalOpen}
        onRequestClose={() => setCreateHistoryItemModalOpen(false)}
        selectedFacility={selectedFacility}
      />
      <UploadHistoryFilesModal
        isOpen={uploadHistoryFilesModalOpen}
        onRequestClose={() => setUploadHistoryFilesModalOpen(false)}
        selectedFacility={selectedFacility}
        selectedHistoryItem={selectedHistoryItem}
      />
      <CustomSnackbarAlert open={alertOpen} setOpen={setAlertOpen} message={alertMessage} severity={alertSeverity} />
    </>
  );
};

export default FacilityTab;
