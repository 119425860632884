import React from "react";
import { Box, Button, Autocomplete, TextField, Card } from "@mui/material";

import { LabelName } from "components";
import { useDispatch, useSelector } from "react-redux";
import { editCompany, GetFacilities } from "api";

const CompanyTab = ({
  switchTab,
  selectedCompany,
  setSelectedCompany,
  fastForward,
  fastForwardWithoutRedirect,
  resetStates,
}) => {
  const dispatch = useDispatch();
  const [clicked, setClicked] = React.useState(false);

  const textFieldInputToState = (e) => {
    setSelectedCompany((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };
  const companies = useSelector((state) => state.company);

  return (
    <>
      <Box sx={{ mt: 2 }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            mb: 4,
            width: "100%", // REWORD
            alignContent: "center",
            justifyContent: "space-between",
          }}
        >
          <Button
            variant={"outlined"}
            disabled={clicked}
            onClick={() => {
              setClicked(true);
              dispatch(editCompany(selectedCompany)).then(() => setClicked(false));
            }}
          >
            Aktualisieren
          </Button>
          <Button
            sx={{ ml: 5 }}
            variant={"outlined"}
            disabled={clicked}
            onClick={() => {
              setClicked(true);
              switchTab(true);
              setClicked(false);
            }}
          >
            Weiter
          </Button>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <Autocomplete
            disablePortal
            getOptionLabel={(option) => option?.name}
            options={companies ? companies : []}
            sx={{ width: 300 }}
            value={selectedCompany}
            isOptionEqualToValue={(option, value) => {
              return option.name === value.name;
            }}
            onChange={(event, selectedCompanyObject) => {
              if (selectedCompanyObject === null) {
                resetStates();
              }
              setSelectedCompany(selectedCompanyObject);
              dispatch(GetFacilities(selectedCompanyObject.id));
              fastForwardWithoutRedirect(selectedCompanyObject);
            }}
            renderOption={(props, option, { selected }) => (
              <li {...props} style={{ fontWeight: selected ? "bold" : "normal" }}>
                {option.name}
              </li>
            )}
            renderInput={(params) => <TextField {...params} label="Unternehmen" />}
          />

          <Button
            variant="contained"
            disabled={selectedCompany === null || clicked}
            onClick={() => {
              setClicked(true);
              fastForward(selectedCompany);
              setClicked(false);
            }}
          >
            FF
          </Button>
        </Box>
      </Box>
      <Card sx={{ p: 4, width: "80%", mt: 2 }}>
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <LabelName titel="Unternehmen" />
          <TextField
            sx={{ flex: 0.75 }}
            name="name"
            value={selectedCompany?.name ?? ""}
            onChange={(e) => textFieldInputToState(e)}
          />
        </Box>
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <LabelName titel="Zusatz" />
          <TextField
            name="extra_information"
            sx={{ flex: 0.75 }}
            value={selectedCompany?.extra_information ?? ""}
            onChange={(e) => textFieldInputToState(e)}
          />
        </Box>
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <LabelName titel="IK-Nummer" />
          <TextField
            name="ik_number"
            sx={{ flex: 0.75 }}
            value={selectedCompany?.ik_number ?? ""}
            onChange={(e) => textFieldInputToState(e)}
          />
        </Box>
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <LabelName titel="Straße, Hausnummer" />
          <TextField
            name="street"
            sx={{ flex: 0.375 }}
            value={selectedCompany?.street ?? ""}
            onChange={(e) => textFieldInputToState(e)}
          />
          <TextField
            name="street_number"
            sx={{ flex: 0.375 }}
            value={selectedCompany?.street_number ?? ""}
            onChange={(e) => textFieldInputToState(e)}
          />
        </Box>
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <LabelName titel="Postleitzahl, Stadt" />
          <TextField
            name="postcode"
            sx={{ flex: 0.375 }}
            value={selectedCompany?.postcode ?? ""}
            onChange={(e) => textFieldInputToState(e)}
          />
          <TextField
            name="city"
            sx={{ flex: 0.375 }}
            value={selectedCompany?.city ?? ""}
            onChange={(e) => textFieldInputToState(e)}
          />
        </Box>
        <Box sx={{ display: "flex", flexDirection: "row", mt: 2 }}>
          <LabelName titel="Kreditinstitut" />
          <TextField
            name="kreditinstitut"
            sx={{ flex: 0.75 }}
            value={selectedCompany?.creditInstituteName ?? ""}
            onChange={(e) => textFieldInputToState(e)}
          />
        </Box>
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <LabelName titel="IBAN" />
          <TextField
            name="IBAN"
            sx={{ flex: 0.75 }}
            value={selectedCompany?.IBAN ?? ""}
            onChange={(e) => textFieldInputToState(e)}
          />
        </Box>
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <LabelName titel="BIC" />
          <TextField
            name="BIC"
            sx={{ flex: 0.75 }}
            value={selectedCompany?.BIC ?? ""}
            onChange={(e) => textFieldInputToState(e)}
          />
        </Box>

        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <LabelName titel="Großkunde" />
          <Box sx={{ flex: 0.75 }}>
            <TextField name="BIC" sx={{ flex: 0.75 }} value={selectedCompany?.major_customer ? "Ja" : "Nein"} />
          </Box>
        </Box>
      </Card>
    </>
  );
};

export default CompanyTab;
