import { Box, Typography } from "@mui/material";

const LabelName = ({ titel = "Label" }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flex: 0.25,
        justifyContent: "center",
        alignItems: "center",
        padding: 1,
        border: 1,
        borderColor: "secondary.lighter",
        borderRadius: 1,
        backgroundColor: "primary.main",
        marginRight: 2,
      }}
    >
      <Typography variant="subtitle2" sx={{ color: "white" }}>
        {titel}
      </Typography>
    </Box>
  );
};

const LabelNameGrid = ({ titel = "Label" }) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        px: 2,
        py: 1,
        borderRadius: 2,
        backgroundColor: "primary.main",
        color: "white",
        minWidth: 140,
        textAlign: "center",
      }}
    >
      <Typography variant="subtitle2">{titel}</Typography>
    </Box>
  );
};

export { LabelName, LabelNameGrid };
