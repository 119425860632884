import { createSlice } from "@reduxjs/toolkit";

const prequalificationSlice = createSlice({
  name: "prequalification",
  initialState: [],
  reducers: {
    prequalificationAdd(state, action) {
      const newItems = action.payload;
      const existingItemsMap = new Map(state.map((item) => [item.id, item]));

      newItems.forEach((newItem) => {
        if (existingItemsMap.has(newItem.id)) {
          existingItemsMap.set(newItem.id, { ...existingItemsMap.get(newItem.id), ...newItem });
        } else {
          existingItemsMap.set(newItem.id, newItem);
        }
      });

      return Array.from(existingItemsMap.values());
    },

    prequalificationCreate(state, action) {
      state.push(action.payload);
    },

    prequalificationItemCreate(state, action) {
      const { prequalificationItemList, prequalificationId } = action.payload;
      const prequalification = state.find((item) => item.id === prequalificationId);
      if (prequalification) {
        prequalification.prequalificationItem = prequalificationItemList;
      }
    },

    prequalificationItemAdd(state, action) {
      const { prequalificationId, prequalificationItemList } = action.payload;

      // Find the prequalification by ID
      const prequalification = state.find((item) => item.id === prequalificationId);

      if (prequalification && prequalificationItemList) {
        // If the prequalification exists, overwrite its items with the new list
        prequalification.prequalificationItem = prequalificationItemList;
      } else {
        // If the prequalification does not exist, you may want to handle this case,
        // for example, by adding a new prequalification entry or throwing an error.
        console.warn(`Prequalification with ID ${prequalificationId} not found.`);
      }
    },

    prequalificationDelete(state, action) {
      const { file } = action.payload;
      const { prequalificationId, id: fileId } = file;

      const prequalification = state.find((item) => item.id === prequalificationId);
      if (prequalification) {
        prequalification.prequalificationItem = prequalification.prequalificationItem.map((spec) => ({
          ...spec,
          image: spec.image.filter((img) => img.id !== fileId),
        }));
      }
    },

    prequalificationSpecificAdd(state, action) {
      const { id, response: newImage, prequalificationId } = action.payload;
      const prequalification = state.find((item) => item.id === prequalificationId);

      if (prequalification) {
        const preqItem = prequalification.prequalificationItem.find((item) => item.id === id);
        if (preqItem) {
          if (preqItem.image.length === 0) {
            preqItem.image = [newImage];
          } else {
            preqItem.image.push(newImage);
          }
        }
      }
    },

    logoutPrequalification() {
      return [];
    },

    prequalificationReplace(state, action) {
      const { prequalificationId, prequalification } = action.payload;
      const prequalificationIndex = state.findIndex((item) => item.id === prequalificationId);

      state[prequalificationIndex] = prequalification;
      // Logic for handling prequalification request can be added here.
    },

    prequalificationItemUpdate(state, action) {
      const updatedPreqItem = action.payload;
      const prequalificationId = updatedPreqItem.prequalification[0]?.id;

      const prequalification = state.find((item) => item.id === prequalificationId);
      if (prequalification) {
        const preqItemIndex = prequalification.prequalificationItem.findIndex((item) => item.id === updatedPreqItem.id);
        if (preqItemIndex !== -1) {
          prequalification.prequalificationItem[preqItemIndex] = updatedPreqItem;
        }
      }
    },

    prequalificationItemImageUpdate(state, action) {
      const imageObject = action.payload;
      const prequalificationItem = imageObject.prequalificationItem[0];
      const prequalificationId = prequalificationItem.prequalification[0]?.id;

      const prequalification = state.find((item) => item.id === prequalificationId);
      if (prequalification) {
        const preqItem = prequalification.prequalificationItem.find((item) => item.id === prequalificationItem.id);
        if (preqItem) {
          const imageIndex = preqItem.image.findIndex((img) => img.id === imageObject.id);
          if (imageIndex !== -1) {
            delete imageObject.assessment;
            delete imageObject.prequalification;
            preqItem.image[imageIndex] = imageObject;
          }
        }
      }
    },

    prequalificationMoveImage(state, action) {
      const { payload: image, destinationId, preqItemId } = action.payload;
      const prequalificationId = image.prequalificationItem[0]?.prequalification[0]?.id;

      const prequalification = state.find((item) => item.id === prequalificationId);
      if (prequalification) {
        const fromItem = prequalification.prequalificationItem.find((item) => item.id === preqItemId);
        const toItem = prequalification.prequalificationItem.find((item) => item.id === destinationId);

        if (fromItem && toItem) {
          fromItem.image = fromItem.image.filter((img) => img.id !== image.id);

          delete image.assessment;
          delete image.prequalification;

          toItem.image.push(image);
        }
      }
    },

    prequalificationItemImageNameUpdate(state, action) {
      const imageObject = action.payload;
      const prequalificationItem = imageObject.prequalificationItem[0];
      const prequalificationId = prequalificationItem.prequalification[0]?.id;

      const prequalification = state.find((item) => item.id === prequalificationId);
      if (prequalification) {
        const preqItem = prequalification.prequalificationItem.find((item) => item.id === prequalificationItem.id);
        if (preqItem) {
          const image = preqItem.image.find((img) => img.id === imageObject.id);
          if (image) {
            image.imageBaseName = imageObject.imageBaseName;
          }
        }
      }
    },
  },
});

export const {
  prequalificationAdd,
  prequalificationCreate,
  prequalificationItemCreate,
  prequalificationItemAdd,
  prequalificationSpecificAdd,
  prequalificationDelete,
  prequalificationReplace,
  prequalificationItemUpdate,
  prequalificationItemImageUpdate,
  prequalificationMoveImage,
  prequalificationItemImageNameUpdate,
  logoutPrequalification,
} = prequalificationSlice.actions;

export default prequalificationSlice.reducer;
