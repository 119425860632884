// component

//Mui imports
import { Business, Work, Assignment } from "@mui/icons-material";

// ----------------------------------------------------------------------

const navConfigGuest = [
  {
    title: "Unternehmen",
    path: "/dashboard/company",
    icon: <Business />,
  },
  {
    title: "Betriebsstätten",
    path: "/dashboard/facility",
    icon: <Work />,
  },
  {
    title: `${"Formulare zum downloaden"}`,
    path: "/dashboard/inventoryList",
    icon: <Assignment />,
  },
];

export default navConfigGuest;
