import { Box } from "@mui/material";
import { AssignmentLate, Assignment, DownloadDone } from "@mui/icons-material";
import moment from "moment";
import { StatusType } from "../../assets/type.ts";

export const inspectionColumns = [
  {
    field: "statusIcon",
    headerName: "Status",
    flex: 0.15,
    editable: false,
    renderCell: (params) => {
      switch (params.row.status) {
        case StatusType.CANCELED:
          return (
            <Box
              sx={{
                display: "flex",
                backgroundColor: "error.main",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
                height: "100%",
              }}
            >
              <AssignmentLate />
            </Box>
          );
        case StatusType.ACCEPTED:
          return (
            <Box
              sx={{
                display: "flex",
                backgroundColor: "warning.main",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
                height: "100%",
              }}
            >
              <Assignment />
            </Box>
          );
        case StatusType.COMPLETED:
          return (
            <Box
              sx={{
                display: "flex",
                backgroundColor: "success.main",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
                height: "100%",
              }}
            >
              <DownloadDone />
            </Box>
          );
        default: {
          return (
            <Box
              sx={{
                display: "flex",
                backgroundColor: "secondary.main",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
                height: "100%",
              }}
            >
              <Assignment />
            </Box>
          );
        }
      }
    },
    valueGetter: (params) => params.row.status,

    // Custom sorting logic for string-based enum values
    sortComparator: (v1, v2) => {
      // Define custom order values for each status
      const statusPriority = {
        CANCELED: 0,
        ACCEPTED: 1,
        COMPLETED: 2,
        DEFAULT: 3, // You can adjust this to any default status that needs to appear last
      };

      // Return the comparison based on the assigned priority
      const priority1 = statusPriority[v1] !== undefined ? statusPriority[v1] : statusPriority["DEFAULT"];
      const priority2 = statusPriority[v2] !== undefined ? statusPriority[v2] : statusPriority["DEFAULT"];

      if (priority1 < priority2) return -1;
      if (priority1 > priority2) return 1;
      return 0;
    },
  },
  // {
  //   field: "name",
  //   headerName: "Unternehmen",
  //   flex: 1,
  //   editable: false,
  //   valueGetter: (params) => `${params.row.facility[0].name}`,
  // },
  {
    field: "name",
    headerName: "Unternehmen",
    flex: 1,
    editable: false,
    valueGetter: (params) => `${params.row.facility[0].name}`,
  },
  {
    field: "ik_number",
    headerName: "IK-Nummer",
    flex: 0.8,
    editable: false,
    valueGetter: (params) => `${params.row.facility[0].ik_number}`,
  },
  {
    field: "city",
    headerName: "Stadt",
    flex: 1,
    editable: false,
    valueGetter: (params) => `${params.row.facility[0].city}`,
  },
  {
    field: "postcode",
    headerName: "PLZ",
    flex: 0.6,
    editable: false,
    valueGetter: (params) => `${params.row.facility[0].postcode}`,
  },

  {
    field: "address",
    headerName: "Straße",
    flex: 1,
    editable: false,
    valueGetter: (params) => `${params.row.facility[0].street || ""}, ${params.row.facility[0].street_number || ""}`,
  },
  {
    field: "due_date",
    headerName: "Begehung Deadline",
    flex: 1,
    editable: false,
    valueGetter: (params) => `${moment(params.row?.due_date).locale("de").format("DD.MM.YYYY")}`,
  },
];

export const adminInspectionColumns = [
  {
    field: "statusIcon",
    headerName: "Status",
    flex: 0.15,
    editable: false,
    renderCell: (params) => {
      switch (params.row.status) {
        case StatusType.CANCELED:
          return (
            <Box
              sx={{
                display: "flex",
                backgroundColor: "error.main",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
                height: "100%",
              }}
            >
              <AssignmentLate />
            </Box>
          );
        case StatusType.ACCEPTED:
          return (
            <Box
              sx={{
                display: "flex",
                backgroundColor: "warning.main",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
                height: "100%",
              }}
            >
              <Assignment />
            </Box>
          );
        case StatusType.COMPLETED:
          return (
            <Box
              sx={{
                display: "flex",
                backgroundColor: "success.main",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
                height: "100%",
              }}
            >
              <DownloadDone />
            </Box>
          );
        default: {
          return (
            <Box
              sx={{
                display: "flex",
                backgroundColor: "secondary.main",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
                height: "100%",
              }}
            >
              <Assignment />
            </Box>
          );
        }
      }
    },
  },
  {
    field: "name",
    headerName: "Unternehmen",
    flex: 1,
    editable: false,
    valueGetter: (params) => `${params.row.facility[0].name}`,
  },
  {
    field: "ik_number",
    headerName: "IK-Nummer",
    flex: 0.5,
    editable: false,
    valueGetter: (params) => `${params.row.facility[0].ik_number}`,
  },
  {
    field: "city",
    headerName: "Stadt",
    flex: 0.5,
    editable: false,
    valueGetter: (params) => `${params.row.facility[0].city}`,
  },
  {
    field: "postcode",
    headerName: "PLZ",
    flex: 0.3,
    editable: false,
    valueGetter: (params) => `${params.row.facility[0].postcode}`,
  },

  {
    field: "address",
    headerName: "Straße",
    flex: 0.75,
    editable: false,
    valueGetter: (params) => `${params.row.facility[0].street || ""}, ${params.row.facility[0].street_number || ""}`,
  },
  {
    field: "due_date",
    headerName: "Begehung Deadline",
    flex: 0.5,
    editable: false,
    valueGetter: (params) => `${moment(params.row?.due_date).locale("de").format("DD.MM.YYYY")}`,
  },
  {
    field: "inspector",
    headerName: "Begeher",
    flex: 0.75,
    editable: false,
    valueGetter: (params) => {
      if (params.row.account) {
        return `${params.row.account.firstName} ${params.row.account.lastName}`;
      } else {
        return "Kein Begeher zugewiesen";
      }
    },
  },
];
