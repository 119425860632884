import React from "react";

import { Card, Button, Box, Checkbox, Divider, Typography } from "@mui/material";
import CustomDataGrid from "./CustomDataGrid";
import { specialistColumns } from "../layouts/DataGridLayouts";

//mock data

const SpecialistUpdateTab = ({ specialists, setSpecialists, selectedSpecialist, setSelectedSpecialist }) => {
  const [selectedSpecialistIndex, setSelectedSpecialistIndex] = React.useState(null);

  const providingFieldsColumns = [
    {
      field: "in_store",
      headerName: "Geschäftslokal",
      flex: 0.1,
      editable: false,
      default: false,
      renderCell: ({ row }) => {
        if (row.in_store_allowed) {
          return <Checkbox checked={row.in_store_selected} onClick={() => handleProvidingAction(true, row)} />;
        } else return <></>;
      },
    },
    {
      field: "field_sales",
      headerName: "Außendienst",
      flex: 0.1,
      editable: false,
      default: false,
      renderCell: ({ row }) => {
        if (row.field_sales_allowed) {
          return <Checkbox checked={row.field_sales_selected} onClick={() => handleProvidingAction(false, row)} />;
        } else return <></>;
      },
    },
    {
      field: "code",
      headerName: "Versorgungsbereiche",
      flex: 0.2,
      editable: false,
    },
    {
      field: "Extra Info",
      headerName: "Extra Info",
      flex: 0.2,
      renderCell: ({ row }) => {
        return (
          <Button onClick={() => console.log(row)} variant="contained">
            <Typography>Extra Info</Typography>
            {row.new ? <Typography>Neu</Typography> : <Typography>Alt</Typography>}
          </Button>
        );
      },
    },
    {
      field: "description",
      headerName: "Bezeichnung",
      flex: 0.4,
      editable: false,
    },
  ];

  const handleProvidingAction = (from, row) => {
    const element = specialists[selectedSpecialistIndex].providing_fields.findIndex((item) => item.code === row.code);

    setSpecialists((prevSpecialists) => {
      const updatedSpecialists = [...prevSpecialists]; // Copy the array

      // Ensure the selected specialist exists
      if (updatedSpecialists[selectedSpecialistIndex]) {
        const updatedProvidingFields = [...updatedSpecialists[selectedSpecialistIndex].providing_fields]; // Copy providing_fields array

        if (updatedProvidingFields[element]) {
          if (from === true) {
            updatedProvidingFields[element] = {
              ...updatedProvidingFields[element], // Copy the object
              in_store_selected: !updatedProvidingFields[element].in_store_selected, // Toggle value
            };
          } else {
            updatedProvidingFields[element] = {
              ...updatedProvidingFields[element], // Copy the object
              field_sales_selected: !updatedProvidingFields[element].field_sales_selected, // Toggle value
            };
          }
        }

        // Update the specialist with modified providing_fields
        updatedSpecialists[selectedSpecialistIndex] = {
          ...updatedSpecialists[selectedSpecialistIndex],
          providing_fields: updatedProvidingFields,
        };
      }

      return updatedSpecialists;
    });
  };

  const handleSpecialistSelection = (rows) => {
    console.log(rows);
    const equalSpecialist = specialists?.find((item) => item.id === rows[0]);
    const selectedSpecialistIndex = specialists?.findIndex((item) => item.id === rows[0]);
    if (rows.length === 0) {
      if (selectedSpecialist) {
        setSelectedSpecialist(null);
        setSelectedSpecialistIndex(null);
        // setProvidingFields([]);
      }
      return;
    }
    setSelectedSpecialistIndex(selectedSpecialistIndex);
    setSelectedSpecialist(equalSpecialist);
  };

  return (
    <Box
      sx={{
        flexDirection: "column",
        mt: 2,
      }}
    >
      <Card sx={{ backgroundColor: "transparent", p: 2, mt: 2 }}>
        <Divider textAlign="left" sx={{ my: 2 }}>
          <Typography fontWeight={"bold"}>Fachliche Leitung</Typography>
        </Divider>
        <Typography variant="body2" sx={{ mb: 2 }}>
          Durch Auswählen der Fachlichen Leitung können Sie die Versorgungsbereiche bearbeiten. Die Auswahl hat
          auswirkung auf die zu erfüllenden Vorraussetzungen.
        </Typography>
        <Card>
          <CustomDataGrid
            initialState={{
              sorting: {
                sortModel: [{ field: "active", sort: "desc" }],
              },
            }}
            rows={specialists}
            columns={specialistColumns}
            initialPageSize={25}
            checkboxSelection
            disableSelectionOnClick
            onSelectionModelChange={(rows) => handleSpecialistSelection(rows)}
            // onRowDoubleClick={() => {
            //   setOpen(!open);
            // }}
            // onRowClick={(params) => {
            //   dispatch(GetProvidingFields(params.row.id)).then(
            //     setProvidingFields(dynamicSpecialistFieldValue(criteriaCatalog, params.row))
            //   );
            //   setSelectedSpecialist(params.row);
            // }}
          />
        </Card>
      </Card>
      {selectedSpecialist && selectedSpecialistIndex !== null && (
        <Card sx={{ backgroundColor: "transparent", p: 2, mt: 2 }}>
          <Divider textAlign="left" sx={{ my: 2 }}>
            <Typography fontWeight={"bold"}>Versorgungsbereiche</Typography>
          </Divider>
          <Card>
            <CustomDataGrid
              rows={specialists[selectedSpecialistIndex].providing_fields || []}
              columns={providingFieldsColumns}
              // checkboxSelection={checkboxSelection}
              // onSelectionModelChange={(rows) => setSelectedFields(rows)}
            />
          </Card>
        </Card>
      )}
    </Box>
  );
};

export default SpecialistUpdateTab;
