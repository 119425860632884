import { configureStore } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import { FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from "redux-persist";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";
import combinedReducers from "./index";
import createIndexedDBStorage from "redux-persist-indexeddb-storage";
import { createStateSyncMiddleware, initMessageListener } from "redux-state-sync";

const indexedDBStorage = createIndexedDBStorage("scopeXDB"); // Specify a database name

// Persist configuration
const persistConfig = {
  key: "root",
  stateReconciler: autoMergeLevel2,
  storage: indexedDBStorage,
};

// State sync middleware configuration
const syncMiddlewareConfig = {
  // Example: TOGGLE_TODO will not trigger in other tabs
  blacklist: ["TOGGLE_TODO", REHYDRATE, PERSIST],
};

const middlewares = [createStateSyncMiddleware(syncMiddlewareConfig)];

// Create a persisted reducer
const persistedReducer = persistReducer(persistConfig, combinedReducers);

// Configure the store
const store = configureStore(
  {
    reducer: persistedReducer,

    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
      }).concat(middlewares), // Add state sync middleware here
  },
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

// Initialize the sync message listener for the store

const persistor = persistStore(store);

initMessageListener(store);

export { store, persistor };
