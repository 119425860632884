import { specificFacilityAdd } from "../redux/reducers/facility";
import instance from "./axiosConfig";

export const requestChangeWithImpactOnCriteriaCatalog = async (type, facilityId) => {
  try {
    const { data: response } = await instance.post(`/changes/requestChangeWithImpactOnCertificate`, {
      type,
      facilityId,
    });

    if (!response.error) {
      return response;
    }
  } catch (e) {
    console.error(e);
    return e;
  }
};

const requestChangeWithImpactOnCertificat = (type) => async (dispatch) => {};

export const applyChangeWithImpactOnCriteriaCatalog = async (
  type,
  facilityId,
  email,
  changeForAllFacilities,
  address
) => {
  switch (type) {
    case "changeBillingEmail":
      return changeBillingEmail(facilityId, email, changeForAllFacilities);
      break;
    case "changeBillingAddress":
      return changeBillingAddress(facilityId, address);
      break;
    default:
      return { error: "Invalid type" };
  }
};

//Contact_Person
export const changeBillingEmail = (facilityId, email, changeForAllFacilities) => async (dispatch) => {
  try {
    const { data: response } = await instance.post(`/changes/contactPerson/changeBillingEmail`, {
      facilityId,
      email,
      changeForAllFacilities,
    });

    if (!response.error) {
      dispatch(specificFacilityAdd(response?.value));
    }

    console.log("response", response);
    return response;
  } catch (e) {
    console.error(e);
    return e.response.data;
  }
};

export const changeBillingAddress = (facilityId, address) => async (dispatch) => {
  try {
    const { data: response } = await instance.post(`/changes/contactPerson/changeBillingAddress`, {
      facilityId,
      address,
    });
    if (!response.error) {
      dispatch(specificFacilityAdd(response?.value));
    }
    console.log("response", response);
    return response;
  } catch (e) {
    console.error(e);
    return e;
  }
};

export const changeBankCredentials = (facilityId, credentials) => async (dispatch) => {
  try {
    const { data: response } = await instance.post(`/changes/contactPerson/changeBankCedentials`, {
      facilityId,
      credentials,
    });
    if (!response.error) {
      dispatch(specificFacilityAdd(response?.value));
    }

    console.log("response", response);
    return response;
  } catch (e) {
    console.error(e);
    return e;
  }
};

export const changeContactPersonEmailAddress = (facilityId, contactId, email) => async (dispatch) => {
  console.log(facilityId, contactId, email);
  try {
    const { data: response } = await instance.post(`/changes/contactPerson/changeCPEmail`, {
      facilityId,
      contactId,
      email,
    });

    if (!response.error) {
      dispatch(specificFacilityAdd(response?.value));
    }

    console.log("response", response);
    return response;
  } catch (e) {
    console.error(e);
    return e.response.data;
  }
};

export const createNewContactPerson = (facilityId, credentials) => async (dispatch) => {
  try {
    const { data: response } = await instance.post(`/changes/contactPerson/addCP`, {
      facilityId,
      credentials,
    });

    console.log("response", response);

    if (!response.error) {
      dispatch(specificFacilityAdd(response?.value));
    }

    console.log("response", response);
    return response;
  } catch (e) {
    console.error(e);
    return e;
  }
};

export const removeContactPerson = (facilityId, contactId, date) => async (dispatch) => {
  try {
    const { data: response } = await instance.post(`/changes/contactPerson/removeCP`, {
      facilityId,
      contactId,
      date,
    });

    if (!response.error) {
      dispatch(specificFacilityAdd(response?.value));
    }

    console.log("response", response);
    return response;
  } catch (e) {
    console.error(e);
    return e;
  }
};

export const changeMainContactPerson =
  (facilityId, currentMainContactId, newMainContactId, changeForAllFacilities) => async (dispatch) => {
    try {
      const { data: response } = await instance.post(`/changes/contactPerson/change`, {
        facilityId,
        currentMainContactId,
        newMainContactId,
        changeForAllFacilities,
      });

      if (!response.error) {
        dispatch(specificFacilityAdd(response?.value));
      }

      console.log("response", response);
      return response;
    } catch (e) {
      console.error(e);
      return e;
    }
  };

//SPECIALISTS

//PROVIDING_FIELDS
export const addProvidingFieldsToSpecialist = (facilityId, specialistId, providing_fields) => async (dispatch) => {
  let response;
  try {
    response = await instance.post(`/changes/providingFields/expand`, {
      facilityId,
      specialistId,
      providing_fields,
    });
    return response?.data;
  } catch (e) {
    return e;
  } finally {
    // dispatch(specialistAdd(response?.data));
  }
};

export const changeFields = (facilityId, specialistId, providing_fields, addFields) => async (dispatch) => {
  let response;
  try {
    response = await instance.post(`/changes/providingFields/changeFields`, {
      facilityId,
      specialistId,
      providing_fields,
      addFields,
    });
    return response?.data;
  } catch (e) {
    return e;
  } finally {
    // dispatch(specialistAdd(response?.data));
  }
};

export const successProvidingFields = (assessmentId) => async (dispatch) => {
  let response;
  try {
    response = await instance.post(`/changes/providingFields/success`, {
      id: assessmentId,
    });
    return response?.data;
  } catch (e) {
    return e;
  } finally {
    // dispatch(specialistAdd(response?.data));
  }
};

export const failProvidingFields = (assessmentId) => async (dispatch) => {
  let response;
  try {
    response = await instance.post(`/changes/providingFields/reject`, {
      id: assessmentId,
    });
    return response?.data;
  } catch (e) {
    return e;
  } finally {
    // dispatch(specialistAdd(response?.data));
  }
};

export const AcceptRemovingProvidingFields = (assessmentId, scope) => async (dispatch) => {
  let response;
  try {
    response = await instance.post(`/changes/providingFields/acceptRemoval`, {
      id: assessmentId,
      scope,
    });
    return response?.data;
  } catch (e) {
    return e;
  } finally {
    // dispatch(specialistAdd(response?.data));
  }
};

//COMPANY-RELATED

export const changeCEO = async (facilityId) => {
  try {
    await instance.post(`/changes/companyRelated/changeCEO`, { id: facilityId });
  } catch (e) {
    return e?.response.data;
  }
};

export const changeOwner = async (facilityId, email) => {
  try {
    await instance.post(`/changes/companyRelated/changeOwner`, {
      id: facilityId,
      email,
    });
  } catch (e) {
    return e?.response.data;
  }
};

export const acceptChangeOwner = async (account, company, informations) => {
  let response;

  console.log(account, company, informations);

  try {
    response = await instance.post(`/changes/companyRelated/acceptChangeOwner`, {
      account,
      company,
      informations,
    });
    return response;
  } catch (e) {
    return e;
  }
};

export const changeOfLegalForm = async (facilityId, name) => {
  try {
    await instance.post(`/changes/companyRelated/changeOfLegalForm/create`, {
      id: facilityId,
      name,
    });
  } catch (e) {
    return e?.response.data;
  }
};

export const openInsolvency = async (facilityId, date, insolvencyAgentData) => {
  try {
    await instance.post(`/changes/companyRelated/openInsolvency/create`, {
      id: facilityId,
      date,
      insolvencyAgentData,
    });
  } catch (e) {
    return e?.response.data;
  }
};

/**
 * Asynchronously closes an insolvency for a given assessment and facility.
 *
 * @param {string} assessmentId - The ID of the assessment
 * @param {string} facilityId - The ID of the facility
 * @return {Promise<any>} The response data if an error occurs, otherwise undefined
 */
export const closeInsolvency = async (assessmentId, facilityId) => {
  try {
    await instance.post(`/changes/companyRelated/openInsolvency/revoke`, {
      id: facilityId,
      assessmentId,
    });
  } catch (e) {
    return e?.response.data;
  }
};

export const closeFacility = async (facilityId, date) => {
  try {
    await instance.post(`/changes/companyRelated/closeFacility`, {
      id: facilityId,
      date,
    });
  } catch (e) {
    return e?.response.data;
  }
};

export const changeFacilityName = async (facilityId, name) => {
  try {
    return await instance.post(`/changes/companyRelated/changeFacilityName/create`, {
      id: facilityId,
      name,
    });
  } catch (e) {
    return { error: e?.response.data };
  }
};

export const successChangeFacilityName = async (assessmentId) => {
  try {
    const response = await instance.post(`/changes/companyRelated/changeFacilityName/success`, {
      assessmentId,
    });
    if (!response.data.error) {
      return response?.data;
    }
  } catch (e) {
    return e?.response.data;
  }
};

export const failChangeFacilityName = async (assessmentId) => {
  try {
    const response = await instance.post(`/changes/companyRelated/changeFacilityName/fail`, {
      assessmentId,
    });

    if (!response.data.error) {
      return response?.data;
    }
  } catch (e) {
    return e?.response.data;
  }
};

export const successChangeOfLegalForm = async (assessmentId) => {
  try {
    await instance.post(`/changes/companyRelated/changeOfLegalForm/success`, {
      assessmentId,
    });
  } catch (e) {
    return e?.response.data;
  }
};

export const failChangeOfLegalForm = async (assessmentId) => {
  try {
    await instance.post(`/changes/companyRelated/changeOfLegalForm/fail`, {
      assessmentId,
    });
  } catch (e) {
    return e?.response.data;
  }
};

//ROOM

/**
 * Updates the facility address and returns the response data, or an error if the request fails.
 *
 * @param {string} facilityId - The ID of the facility to update
 * @param {object} data - The new address data
 * @param {Date} date - The date of the change
 * @return {Promise} The response data or an error
 */
export const createFacilityMove = (facilityId, address, date) => async (dispatch) => {
  try {
    const { data: response } = await instance.post(`/changes/roomRelated/move/create`, {
      facilityId,
      address,
      date,
    });

    if (!response.error) {
      dispatch(specificFacilityAdd(response?.value));
    }

    console.log("response", response);
    return response;
  } catch (e) {
    console.error(e);
    return e;
  }
};

export const successFacilityMove = async (assessmentId, endDate) => {
  try {
    const response = await instance.post(`/changes/successChanges/move`, { assessmentId, endDate });

    console.log("response", response);
    // if (!response.data.error) {
    //   return response.data;
    // }

    return response;
  } catch (e) {
    console.log("error", e);
    console.error(e);
    // return e?.response.data;
  }
};

export const revokeFacilityMove = async (assessmentId, message) => {
  try {
    await instance.post(`/changes/rejectChanges/move`, { assessmentId, message });
  } catch (e) {
    return e?.response.data;
  }
};

/**
 * Asynchronous function to create a facility change of room.
 *
 * @param {string} facilityId - The ID of the facility
 * @param {string} address - The address of the new room
 * @param {string} date - The date of the room change
 * @return {Object} The response data from the API call, or an error object if the call fails
 */
export const createFacilityChangeOfRoom = async (facilityId, address, date) => {
  let response;
  try {
    response = await instance.post(`/changes/roomRelated/changeOfRoom/create`, {
      facilityId,
      address,
      date,
    });
    return response?.data;
  } catch (e) {
    return e;
  }
  // finally {
  //   // dispatch(specialistAdd(response?.data));
  // }
};

//SPECIALIST

export const successAddingSpecialist = async (assessmentId) => {
  try {
    await instance.post(`/changes/specialist/addSpecialist`, { assessmentId });
  } catch (e) {
    return e?.response.data;
  }
};

export const failAddingSpecialist = async (assessmentId) => {
  try {
    await instance.post(`/changes/specialist/failAddSpecialist`, { assessmentId });
  } catch (e) {
    return e?.response.data;
  }
};
export const AcceptRemovingSpecialist = async (assessmentId) => {
  try {
    const response = await instance.post(`/changes/specialist/acceptDeleteSpecialist`, {
      assessmentId,
    });
    return response?.data;
  } catch (e) {
    return e;
  }
};

export const RejectRemovingSpecialst = async (assessmentId) => {
  try {
    const response = await instance.post(`/changes/specialist/rejectDeleteSpecialist`, {
      assessmentId,
    });

    return response?.data;
  } catch (e) {
    return e.response.data;
  }
};
