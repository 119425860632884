import { Check, Close } from "@mui/icons-material";
import { Box } from "@mui/material";

const specialistColumns = [
  {
    field: "fullName",
    headerName: "Name",
    flex: 0.75,
    editable: false,
    valueGetter: (params) => `${params.row.first_name || ""} ${params.row.last_name || ""}`,
  },
  {
    field: "qualification",
    headerName: "Qualifikation",
    flex: 0.5,
    editable: false,
  },
  {
    field: "versorgungsbereiche",
    headerName: "Versorgungsbereiche",
    flex: 1,
    editable: false,
    valueGetter: (params) => {
      if (params.row.providing_fields) {
        const fields = [...params.row.providing_fields]
          .sort((a, b) => (a.code > b.code ? 1 : -1))
          .map((field) => field.code)
          .join(",");
        return fields;
        // return `${params.row.providing_fields.map((s) => s.code)}`;
      } else return "";
    },
  },

  // .sort((a, b) => (a.code > b.code ? 1 : -1))
  // .map((field) => field.code)
  // .join(",");

  {
    field: "active",
    flex: 0.1,
    renderCell: ({ row }) => {
      const backgroundColor = row.active && row.pending === undefined ? "green" : row.active && row.pending === true ? "orange" : "red"; 
      return (
        <Box
          sx={{
            display: "flex",
            flex: 1,
            justifyContent: "center",
            alignContent: "center",
            backgroundColor: backgroundColor,
          }}
        >
          {row.active && row.pending === undefined && <Check />}
          {(row.active && row.pending === true) && "O"}
          {!row.active && <Close />}
        </Box>
      );
    },
  },
];

export default specialistColumns;
