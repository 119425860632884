import { createSlice } from "@reduxjs/toolkit";

const specialistSlice = createSlice({
  name: "specialist",
  initialState: [],
  reducers: {
    specialistAdd(state, action) {
      return action.payload;
    },
    specialistSpecificAdd(state, action) {
      const specialist = action.payload;
      console.log(specialist);
      if (!action.payload) {
        return state;
      } else {
        const specialistIndex = state.findIndex((item) => item.id === specialist.id);
        if (specialistIndex === -1) {
          return [...state, specialist];
        } else {
          state[specialistIndex] = specialist;
        }
      }
    },
    specialistCreate(state, action) {
      const specialist = action.payload;
      console.log(specialist);
      state.push(action.payload);
    },
    specialistDelete(state, action) {
      const toDelete = state.findIndex((spec) => spec.id === action.payload);
      if (toDelete > -1) state.splice(toDelete, 1);
    },
    logoutSpecialist(state, action) {
      return specialistSlice.getInitialState();
    },
  },
});

export const { specialistAdd, logoutSpecialist, specialistCreate, specialistDelete, specialistSpecificAdd } =
  specialistSlice.actions;
export default specialistSlice.reducer;
