import * as React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

//Components
import Container from "@mui/material/Container";

import { Logout } from "api";

export default function LogoutPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  dispatch(Logout());
  navigate("/");

  return <Container component="main" maxWidth="md"></Container>;
}
