import React from "react";
import moment from "moment";
import { Card, Divider, Typography, Grid, useMediaQuery, useTheme } from "@mui/material";
import { LabelNameGrid } from "components";

const Events = ({ facility }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs")); // Small screens (e.g., <600px)

  return (
    <Card sx={{ backgroundColor: "transparent", p: 2 }}>
      <Divider sx={{ my: 2 }}>
        <Typography fontWeight="bold">PQ Daten</Typography>
      </Divider>
      <Grid container spacing={1} direction="column">
        {[
          { label: "Auftragsdatum", value: facility[0]?.date_of_issue },
          { label: "Erteilungsdatum", value: facility[0]?.date_of_issue }, //REWORK
          { label: "Gültig bis", value: facility[0]?.due_date },
          {
            label: "Erste Überwachung",
            value: facility[0]?.monitoring_date_1
              ? `Q${moment(facility[0]?.monitoring_date_1).quarter()} ${moment(facility[0]?.monitoring_date_1).year()}`
              : null,
          },
          {
            label: "Zweite Überwachung",
            value: facility[0]?.monitoring_date_2
              ? `Q${moment(facility[0]?.monitoring_date_2).quarter()} ${moment(facility[0]?.monitoring_date_2).year()}`
              : null,
          },
        ].map((item, index) => (
          <Grid
            item
            key={index}
            container
            spacing={1}
            alignItems="center"
            direction={isSmallScreen ? "column" : "row"} // Switch layout based on screen size
          >
            <Grid item xs={12} sm={5}>
              <LabelNameGrid titel={item.label} />
            </Grid>
            <Grid item xs={12} sm={7}>
              <Card sx={{ p: 1, textAlign: "center" }}>
                <Typography>
                  {item.value ? moment(item.value).locale("de").format("DD.MM.YYYY") : "Nicht verfügbar"}
                </Typography>
              </Card>
            </Grid>
          </Grid>
        ))}
      </Grid>
    </Card>
  );
};

export default Events;
