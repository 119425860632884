import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { XMLFileType, XMLNormalisedFileType } from "assets/type.ts";

//MUI Imports
import {
  Typography,
  Card,
  Box,
  Divider,
  Grid,
  CardContent,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Select,
  MenuItem,
  ListItemText,
  Button,
} from "@mui/material";
import { GridToolbarQuickFilter } from "@mui/x-data-grid";
import { DatePicker } from "@mui/x-date-pickers";

// mock
import { CreateCorrectionXML, CreatePreviewXML, GetXMLContent, GetXMLList } from "api";
import downloadFile from "utils/downloadFile.js";
import { CustomDataGrid } from "components";
import moment from "moment";

const SVXMLCard = () => {
  const dispatch = useDispatch();

  const [selectedXML, setSelectedXML] = React.useState(null);
  const [xmlContent, setXMLContent] = React.useState(null);

  const [type, setType] = React.useState(null);
  const [endDate, setEndDate] = React.useState(null);
  const [startDate, setStartDate] = React.useState(null);
  const [errorIndicator, setErrorIndicator] = React.useState(false);

  useEffect(() => {
    dispatch(GetXMLList()); //XML LISTE
  }, [dispatch]);

  const xmlList = useSelector((state) => state.xml);

  const handleSelectionModelChange = async (selection) => {
    if (selection.length === 0) {
      if (selectedXML) {
        setSelectedXML(null);
        setXMLContent(null);
      }
      return;
    }
    const { error, message, value } = await GetXMLContent(selection[0]);
    if (error) {
      console.log("error", message);
      return;
    } else {
      console.log("value", value);
      setXMLContent(value);
    }

    const xmlObject = xmlList?.find((item) => item.id === selection[0]);
    setSelectedXML(xmlObject);
  };
  const handleXMLPreview = async () => {
    // setLoading(true);

    // if (type === XMLFileType.CORRECTION && (!endDate || !startDate)) {
    //   setErrorIndicator(true);
    //   return;
    // }
    const {
      error,
      message,
      value: files,
    } = await CreatePreviewXML(
      selectedXML.prequalificationId,
      endDate ? moment(endDate).format("YYYY-MM-DD") : null,
      startDate ? moment(startDate).format("YYYY-MM-DD") : null,
      XMLNormalisedFileType[type]
    );

    if (!error && files) {
      console.log("error", message);
      downloadFile(files.xmlFile.data, files.xmlFile.name, files.xmlFile.type);
      setTimeout(() => downloadFile(files.aufFile.data, files.aufFile.name, files.aufFile.type), 500);
    }
    setErrorIndicator(false);
  };

  const handleXMLSubmit = async () => {
    // setLoading(true);

    if (type === XMLFileType.CORRECTION && (!endDate || !startDate)) {
      setErrorIndicator(true);
      return;
    }

    if (type === XMLFileType.CORRECTION) {
      const {
        error,
        message,
        value: files,
      } = await CreateCorrectionXML(
        selectedXML.prequalificationId,
        endDate ? moment(endDate).format("YYYY-MM-DD") : null,
        endDate ? moment(startDate).format("YYYY-MM-DD") : null,
        XMLNormalisedFileType[type]
      );

      if (!error && files) {
        console.log("error", message);
        downloadFile(files.xmlFile.data, files.xmlFile.name, files.xmlFile.type);
        setTimeout(() => downloadFile(files.aufFile.data, files.aufFile.name, files.aufFile.type), 500);
        // setSuccessAlertOpen(true);
        // setSuccessMessage(message);
      }
    } else {
      alert("Not implemented yet");
    }
    // setLoading(false);

    setErrorIndicator(false);
  };

  return (
    <Box sx={{ my: 1 }}>
      <Card className="xml-datagrid-card" sx={{ display: "flex", flexDirection: "column", p: 2 }}>
        <Divider textAlign="left" sx={{ display: "flex", alignItems: "center", mb: 2 }}>
          <Typography variant="h5">XML</Typography>
        </Divider>

        <CustomDataGrid
          rows={xmlList || []}
          columns={[
            { field: "name", headerName: "Firmenname", valueGetter: (params) => params.row.facility.name, flex: 1 },
            { field: "transferId", headerName: "Transfernummer", flex: 1 },
            { field: "status", headerName: "Status", flex: 1 },
            {
              field: "date",
              headerName: "Datum",
              valueGetter: (params) =>
                `${new Date(params.row.date).toLocaleDateString()} ${new Date(params.row.date).toLocaleTimeString()}`,
              flex: 1,
            },
            {
              field: "type",
              headerName: "Variante",
              valueGetter: (params) => XMLNormalisedFileType[params.row.type],
              flex: 1,
            },
            {
              field: "gkv_number",
              headerName: "Bestätigungsnummer",
              valueGetter: (params) => String(params.row?.prequalification?.consecutiveNumber).padStart(8, "0"),
              flex: 1,
            },
          ]}
          initialPageSize={10}
          checkboxSelection
          components={{
            Toolbar: (props) => (
              <>
                <Box sx={{ display: "flex", flex: 1, p: 1, justifyContent: "space-between", alignItems: "center" }}>
                  <GridToolbarQuickFilter showQuickFilter {...props.toolbar} />
                </Box>
                <Divider sx={{ mt: 1, mb: 1 }} />
              </>
            ),
          }}
          componentsProps={{
            toolbar: {
              showQuickFilter: true,
            },
          }}
          onSelectionModelChange={handleSelectionModelChange}
        />
      </Card>
      {selectedXML && xmlContent && (
        <Box className="xml-content-card" sx={{ display: "flex", flexDirection: "column", mt: 2 }}>
          <Grid container spacing={3}>
            {/* XML File Details */}
            <Grid item xs={12}>
              <Card sx={{ boxShadow: 3 }}>
                <CardContent>
                  <Typography variant="h5">XML File Details</Typography>
                  <Typography variant="body2">Bestaetigungsnummer: {xmlContent.bestaetigungsnummer}</Typography>
                  <Typography variant="body2">Ausstellungsdatum: {xmlContent.ausstellungsdatum}</Typography>
                  <Typography variant="body2">Antragsdatum: {xmlContent.antragsdatum}</Typography>
                  <Typography variant="body2">Gültig bis: {xmlContent.endDate}</Typography>
                  <Typography variant="body2">Antragsbezeichnung: {xmlContent.antragsBezeichnung}</Typography>
                  <Typography variant="body2">Typ: {xmlContent.typ}</Typography>
                  <Typography variant="body2">Catalog Version: {xmlContent.catalogVersion}</Typography>
                </CardContent>
              </Card>
            </Grid>

            {/* Company Details */}
            <Grid item xs={12} md={6}>
              <Card sx={{ boxShadow: 3 }}>
                <CardContent>
                  <Typography variant="h5">Company</Typography>
                  <Typography variant="body1">{xmlContent.company.name}</Typography>
                  <Typography variant="body2">IK Number: {xmlContent.company.ikNumber}</Typography>
                  <Typography variant="body2">
                    Address: {xmlContent.company.street} {xmlContent.company.street_number},{" "}
                    {xmlContent.company.postcode} {xmlContent.company.city}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>

            {/* Facility Details */}
            <Grid item xs={12} md={6}>
              <Card sx={{ boxShadow: 3 }}>
                <CardContent>
                  <Typography variant="h5">Facility</Typography>
                  <Typography variant="body1">{xmlContent.facility.name}</Typography>
                  <Typography variant="body2">IK Number: {xmlContent.facility.ikNumber}</Typography>
                  <Typography variant="body2">
                    Address: {xmlContent.facility.street} {xmlContent.facility.street_number},{" "}
                    {xmlContent.facility.postcode} {xmlContent.facility.city}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>

            {/* Specialists and Providing Fields */}
            <Grid item xs={12}>
              <Card sx={{ boxShadow: 3 }}>
                <CardContent>
                  <Typography variant="h5">Specialists</Typography>
                  {xmlContent.facility.specialist.map((specialist, index) => (
                    <div key={index} style={{ marginBottom: "20px" }}>
                      <Typography variant="h6">
                        {specialist.first_name} {specialist.last_name}
                      </Typography>

                      <TableContainer component={Paper} sx={{ mt: 2 }}>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell>Code</TableCell>
                              <TableCell>Due Date</TableCell>
                              <TableCell>Status</TableCell>
                              <TableCell>Reason</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {specialist.providing_fields.map((field, idx) => (
                              <TableRow key={idx}>
                                <TableCell>{field.code}</TableCell>
                                <TableCell>{field.due_date}</TableCell>
                                <TableCell>{field.status}</TableCell>
                                <TableCell>{field.reason}</TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </div>
                  ))}
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12}>
              <Card sx={{ boxShadow: 3, p: 2 }}>
                <CardContent>
                  <Typography variant="h5" gutterBottom>
                    XML Änderungsoptionen
                  </Typography>

                  <Card sx={{ p: 2 }}>
                    <Box sx={{ display: "flex", alignItems: "center", flexDirection: "row", mb: 1 }}>
                      <Typography variant="body2">Meldungsart</Typography>
                    </Box>
                    <Select value={type} onChange={(e) => setType(e.target.value)} sx={{ width: "100%" }}>
                      {Object.keys(XMLFileType).map((key) => (
                        <MenuItem key={key} value={key}>
                          <ListItemText
                            primary={
                              <Typography sx={{ whiteSpace: "normal", wordBreak: "break-word" }}>
                                {XMLNormalisedFileType[key]}
                              </Typography>
                            }
                          />
                        </MenuItem>
                      ))}
                    </Select>
                  </Card>

                  {type === XMLFileType.CORRECTION && (
                    <Grid container spacing={2} sx={{ mt: 2 }}>
                      <Grid item xs={12}>
                        <Divider variant="body2" align="center">
                          Korrektur
                        </Divider>
                      </Grid>
                      <Grid item xs={6}>
                        <Card sx={{ p: 2 }}>
                          <Typography>Bitte Startdatum wählen</Typography>
                          <DatePicker
                            value={startDate}
                            title="Startdatum"
                            onChange={(newValue) => setStartDate(newValue)}
                          />
                          {errorIndicator && !startDate && (
                            <Typography color="error">Dieses Feld ist auszufüllen</Typography>
                          )}
                        </Card>
                      </Grid>
                      <Grid item xs={6}>
                        <Card sx={{ p: 2 }}>
                          <Typography>Bitte Enddatum wählen</Typography>
                          <DatePicker value={endDate} title="Enddatum" onChange={(newValue) => setEndDate(newValue)} />
                          {errorIndicator && !endDate && (
                            <Typography color="error">Dieses Feld ist auszufüllen</Typography>
                          )}
                        </Card>
                      </Grid>
                    </Grid>
                  )}

                  <Grid container justifyContent="flex-end" sx={{ mt: 2 }}>
                    <Grid item>
                      <Button variant="contained" sx={{ textTransform: "none", mr: 1 }} onClick={handleXMLPreview}>
                        Preview
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button variant="contained" sx={{ textTransform: "none" }} onClick={handleXMLSubmit}>
                        Senden
                      </Button>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Box>
      )}
    </Box>
  );
};

export default SVXMLCard;
