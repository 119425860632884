import { createSlice } from "@reduxjs/toolkit";

const occupationSlice = createSlice({
  name: "occupation",
  initialState: [],
  reducers: {
    occupationAdded(state, action) {
      return action.payload;
    },
    logoutOccupation(state, action) {
      return occupationSlice.getInitialState();
    },
  },
});

export const { occupationAdded, logoutOccupation } = occupationSlice.actions;
export default occupationSlice.reducer;
