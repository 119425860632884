import { renderCellWithTooltip } from "../../components/CustomDataGrid";

const customSortComparator = (v1, v2) => {
  const num1 = parseInt(v1.slice(1));
  const num2 = parseInt(v2.slice(1));
  return num1 - num2;
};

const requirementColumns = [
  {
    field: "code",
    headerName: "Kurzform",
    flex: 0.15,
    editable: false,
    sortComparator: customSortComparator,
  },
  {
    field: "description",
    headerName: "Beschreibung",
    flex: 1,
    renderCell: renderCellWithTooltip,
  },
];

export default requirementColumns;
