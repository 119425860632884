import React from "react";
import { Card, Box, Typography, Tabs, Tab, Button, Divider, TextField, InputAdornment } from "@mui/material";
import { CustomTabPanel, CustomSnackbarAlert, CustomLoadingIndicator } from "components";
import { DatePicker } from "@mui/x-date-pickers";

// import { createFacilityMove } from "../../../../../api/changes";
import { useSelector } from "react-redux";
import { useApprovalFlow } from "hooks/useApprovalFlow";
import { AssessmentType } from "assets/type.ts";
import { ConfirmationModal } from "pages/modals";
import moment from "moment";

const EditRoomsTab = ({ selectedFacility }) => {
  const {
    sendRequest,
    confirmRequest,
    responseData,
    setResponseData,
    isLoading,
    openSnackbar,
    setOpenSnackbar,
    message,
    modalOpen,
    setModalOpen,
  } = useApprovalFlow();
  const [selectedTabIndex, setSelectedTabIndex] = React.useState(0);

  const [street, setStreet] = React.useState("");
  const [streetNumber, setStreetNumber] = React.useState("");
  const [postCode, setPostCode] = React.useState("");
  const [city, setCity] = React.useState("");
  const [date, setDate] = React.useState(null);
  const [clicked, setClicked] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [successMessage, setSuccessMessage] = React.useState("");
  const [successOpen, setSuccessOpen] = React.useState(false);

  const [errorIndicator, setErrorIndicator] = React.useState(false);

  const prequalification = useSelector((state) => state.prequalification);

  const changeAddress = async () => {
    // setClicked(true);
    if (!street || !streetNumber || !postCode || !city) {
      setErrorIndicator(true);
      setClicked(false);
      return;
    }

    await sendRequest(AssessmentType.MOVE, {
      facilityId: selectedFacility.id,
      data: { street: street, street_number: streetNumber, post_code: postCode, city: city },
      date: date ? moment(date).format("YYYY-MM-DD") : null,
    });
  };

  const sendChangeOfRoom = async () => {
    setClicked(true);
    if (prequalification.length === 0) {
      setSuccess(false);
      setSuccessOpen(true);
      setSuccessMessage(
        "Sie haben keine Prequalifikation. Daher brauchen Sie eine Änderung der Räumlichkeiten nicht zu beantragen."
      );
    } else {
      await sendRequest(AssessmentType.CHANGEOFROOM, {
        facilityId: selectedFacility.id,
        data: {},
      });
    }
    setClicked(false);

    //FOTODOKUMENTATION (NUR WENN NICHT BEGEHUNGSPFLICHTIG),
  };

  return (
    <>
      <Box sx={{ borderBottom: 1, borderColor: "divider", display: "flex", flex: 1, width: "100%" }}>
        <Tabs
          variant="fullWidth"
          sx={{ width: "100%" }}
          selectionFollowsFocus
          value={selectedTabIndex}
          onChange={(e, selectedTabIndex) => setSelectedTabIndex(selectedTabIndex)}
        >
          <Tab label="Umzug" />
          <Tab label="Änderung der Räumlichkeiten" />
        </Tabs>
      </Box>

      <CustomTabPanel value={selectedTabIndex} index={0}>
        <Card sx={{ p: 3, mt: 2 }}>
          <Box sx={{ display: "flex", flexDirection: "row", mt: 2, flex: 1 }}>
            <Typography variant="body2">
              Wenn Sie Umgezogen sind, geben Sie bitte Ihre neuen Standortdaten an und laden Sie bitte folgende
              Nachweise mit hoch.
            </Typography>
          </Box>
          <Typography sx={{ mt: 2 }} variant="body2">
            Handwerkskammerrolleneintragung, Gewerbeummeldung, IK-Nachweis, Versicherungsnachweis, neuer Mietvertrag
            (falls gemietet), Eigenerklärung. Bei Haupthäusern Gewerbezentralregisterauszug. BEGEHUNG WENN
            BEGEHUNGSPFLICHTIG(SCOPE)
          </Typography>

          <Divider textAlign="left" sx={{ my: 2 }}>
            <Typography fontWeight={"bold"}>Neue Anschrift</Typography>
          </Divider>
          <Box sx={{ display: "flex", flex: 1, justifyContent: "space-between" }}>
            <TextField
              sx={{ mt: 2, display: "flex", flex: 0.6 }}
              label="Straße"
              value={street}
              placeholder={selectedFacility?.street || "Straße"}
              onChange={(e) => setStreet(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Box sx={{ backgroundColor: "primary.main", p: 1, borderRadius: 1, alignContent: "center", mr: 2 }}>
                      <Typography sx={{ color: "white", fontSize: 20 }}>Straße:</Typography>
                    </Box>
                  </InputAdornment>
                ),
              }}
              error={errorIndicator && !street}
              helperText={errorIndicator && !street ? "Bitte füllen Sie dieses Feld aus" : ""}
            />
            <TextField
              sx={{ mt: 2, display: "flex", flex: 0.3 }}
              label="Hausnummer"
              value={streetNumber}
              placeholder={selectedFacility?.street_number || "Hausnummer"}
              onChange={(e) => setStreetNumber(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Box sx={{ backgroundColor: "primary.main", p: 1, borderRadius: 1, alignContent: "center", mr: 2 }}>
                      <Typography sx={{ color: "white", fontSize: 20 }}>Hausnummer:</Typography>
                    </Box>
                  </InputAdornment>
                ),
              }}
              error={errorIndicator && !streetNumber}
              helperText={errorIndicator && !streetNumber ? "Bitte füllen Sie dieses Feld aus" : ""}
            />
          </Box>
          <Box sx={{ display: "flex", flexDirection: "row", flex: 1, justifyContent: "space-between" }}>
            <TextField
              sx={{ mt: 2, display: "flex", flex: 0.35 }}
              label="Postleitzahl"
              value={postCode}
              placeholder={selectedFacility?.postcode || "Postleitzahl"}
              onChange={(e) => setPostCode(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Box sx={{ backgroundColor: "primary.main", p: 1, borderRadius: 1, alignContent: "center", mr: 2 }}>
                      <Typography sx={{ color: "white", fontSize: 20 }}>Postleitzahl:</Typography>
                    </Box>
                  </InputAdornment>
                ),
              }}
              error={errorIndicator && !postCode}
              helperText={errorIndicator && !postCode ? "Bitte füllen Sie dieses Feld aus" : ""}
            />
            <TextField
              sx={{ mt: 2, display: "flex", flex: 0.6 }}
              label="Ort"
              value={city}
              placeholder={selectedFacility?.city || "Ort"}
              onChange={(e) => setCity(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Box sx={{ backgroundColor: "primary.main", p: 1, borderRadius: 1, alignContent: "center", mr: 2 }}>
                      <Typography sx={{ color: "white", fontSize: 20 }}>Stadt:</Typography>
                    </Box>
                  </InputAdornment>
                ),
              }}
              error={errorIndicator && !city}
              helperText={errorIndicator && !city ? "Bitte füllen Sie dieses Feld aus" : ""}
            />
          </Box>

          <Divider textAlign="left" sx={{ my: 2 }}>
            <Typography fontWeight={"bold"}>Datum des Umzugs</Typography>
          </Divider>

          <DatePicker value={date} title="Umzugsdatum" onChange={(newValue) => setDate(newValue)} />

          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              mt: 1,
            }}
          >
            <Button sx={{ my: 2 }} variant="contained" disabled={clicked} onClick={() => changeAddress()}>
              Absenden
            </Button>
          </Box>
        </Card>
      </CustomTabPanel>
      <CustomTabPanel value={selectedTabIndex} index={1}>
        <Card sx={{ p: 3, mt: 2 }}>
          <Box sx={{ display: "flex", flexDirection: "row", mt: 2, flex: 1 }}>
            <Typography variant="body2">
              Beim Antrag zur Änderung der Räumlichkeiten wird eine neue Begehung erforderlich.
            </Typography>
          </Box>
          {/* <Box sx={{ display: "flex", flexDirection: "row", mt: 4, mb: 2, flex: 1 }}>
            <Typography variant="body2">Scope(Optional)</Typography>
            <TextField label="Scope" value={selectedFacility?.scope} />
          </Box> */}
          <Typography sx={{ mt: 2 }} variant="body2">
            Bitte laden sie Folgende Nachweise hoch:
          </Typography>
          <Typography sx={{ mt: 1 }} variant="body2">
            Fotodokumentation, Grundrissskizze, Mietvertrag
          </Typography>

          <Box sx={{ display: "flex", flexDirection: "row", mt: 4, mb: 2, flex: 1 }}></Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              mt: 1,
            }}
          >
            <Button sx={{ my: 2 }} disabled={clicked} variant="contained" onClick={() => sendChangeOfRoom()}>
              Begehung beantragen
            </Button>
          </Box>
        </Card>
      </CustomTabPanel>
      {modalOpen && (
        <ConfirmationModal
          isOpen={modalOpen}
          onRequestClose={() => setModalOpen(false)}
          responseData={responseData}
          setResponseData={setResponseData}
          confirmRequest={() => {
            console.log("responseData in tab", responseData);
            confirmRequest();
          }}
        />
      )}
      <CustomLoadingIndicator open={isLoading} message={"Einen Moment gedult. Änderung wird bearbeitet."} />
      <CustomSnackbarAlert
        open={successOpen || openSnackbar}
        setOpen={() => {
          setSuccessOpen(false);
          setOpenSnackbar(false);
        }}
        severity={message?.severity ? message?.severity : success ? "success" : "error"}
        message={successMessage || message?.message}
      />
    </>
  );
};

export default EditRoomsTab;
