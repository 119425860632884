import { Card } from "@mui/material";

const StyledCard = (props) => {
  return (
    <Card
      sx={{
        display: "flex",
        flex: !props.flex ? 1 : props.flex,
        flexDirection: !props.flexDirection ? "column" : props.flexDirection,
        alignItems: !props.alignItems ? "flex-start" : props.alignItems,
        p: !props.padding ? 2 : props.padding,
        boxShadow: 2,
        mt: 2,
        margin: 1,
        fontSize: props.fontSize,
      }}
      {...props}
    >
      {props.children}
    </Card>
  );
};

export default StyledCard;
