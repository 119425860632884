import React from "react";
import {
  Box,
  Button,
  Autocomplete,
  TextField,
  Card,
  Typography,
  InputAdornment,
  IconButton,
  Collapse,
  Checkbox,
  Divider,
} from "@mui/material";

import { FirstPage, LastPage, UnfoldMore, Comment, Tab } from "@mui/icons-material";
import { Link } from "react-router-dom";
import moment from "moment";

import { prequalificationItemColumn } from "layouts/DataGridLayouts";
import { ShowStatus, DisplayFiles, CustomDataGrid } from "components";

import { useDispatch, useSelector } from "react-redux";
import { moveImageToPreqItem, updateItemImage, updateItem, GetProvidingFields } from "api";

import { changeCommentReadableStatus } from "api/prequalificationItems";
import { addedDynamicSpecialistFieldValue } from "utils/addedSpecialistFieldValues";
import { removedDynamicSpecialistFieldValue } from "utils/removedDynamicSpecialistFieldValue";
import { AssessmentType, StatusType } from "assets/type.ts";

const ProvidingFieldsTab = ({ selectedAssessment }) => {
  const dispatch = useDispatch();

  const [preqItemIndex, setPreqItemIndex] = React.useState(0);
  const [comment, setComment] = React.useState([]);
  const [moveDestination, setMoveDestination] = React.useState(null);
  const [selectedComment, setSelectedComment] = React.useState(null);
  const [providing_fields, setProvidingFields] = React.useState([]);

  const [open, setOpen] = React.useState({});

  const [clicked, setClicked] = React.useState(false);

  const assessment = useSelector((state) => {
    const index = state.assessment.findIndex((item) => item?.id === selectedAssessment);
    return state.assessment[index];
  });

  const criteriaCatalog = useSelector((state) => state.criteriaCatalog);

  const inActiveIndex = assessment.specialist.findIndex((item) => item.active === false);
  const activeIndex = assessment.specialist.findIndex((item) => item.active === true);

  React.useEffect(() => {
    if (assessment.type === AssessmentType.EXTENDPROVIDINGFIELDS) {
      dispatch(GetProvidingFields(assessment.specialist[inActiveIndex]?.id)).then(
        setProvidingFields(
          addedDynamicSpecialistFieldValue(
            criteriaCatalog,
            assessment.specialist[inActiveIndex],
            assessment.specialist[activeIndex]
          )
        )
      );
    } else if (assessment.type === AssessmentType.REMOVEPROVIDINGFIELDS) {
      dispatch(GetProvidingFields(assessment.specialist[inActiveIndex]?.id)).then(
        setProvidingFields(
          removedDynamicSpecialistFieldValue(
            criteriaCatalog,
            assessment.specialist[inActiveIndex],
            assessment.specialist[activeIndex]
          )
        )
      );
    }
  }, [activeIndex, assessment.specialist, assessment.type, criteriaCatalog, dispatch, inActiveIndex]);

  const commentInputOnChange = (id, value) => {
    if (comment?.length === 0) {
      comment.push({ id: id, text: value });
    } else {
      const newComments = [...comment];
      const inputValue = newComments.find((x) => x?.id === id);
      if (inputValue === undefined) {
        comment.push({ id: id, text: value });
      } else {
        inputValue.text = value;
        console.log(newComments);
        setComment(newComments);
      }
    }
    console.log(comment);
  };

  const handleStatusUpdateImage = (value, imageId) => {
    const selectedComment = comment.findIndex((x) => x?.id === imageId);
    dispatch(
      updateItemImage(
        comment[selectedComment]?.text ? comment[selectedComment]?.text : undefined,
        value === true ? StatusType.ACCEPTED : StatusType.REJECTED,
        imageId,
        assessment?.id
      )
    );
  };

  const handleStatusUpdate = (value) => {
    setClicked(true);
    const selectedComment = comment.findIndex((x) => x.id === assessment?.prequalificationItem[preqItemIndex].id);
    console.log(comment[selectedComment] === undefined);
    dispatch(
      updateItem(
        comment[selectedComment]?.text,
        value === true ? StatusType.ACCEPTED : StatusType.REJECTED,
        assessment?.prequalificationItem[preqItemIndex].id
      )
    ).then(() => setClicked(false));
  };

  const handleImageMove = (image, preqItem) => {
    console.log(image, preqItem, moveDestination);
    setClicked(true);
    dispatch(moveImageToPreqItem(image.id, moveDestination.id, preqItem.id, image.prequalificationId)).then(() =>
      setClicked(false)
    );
  };

  const navigateToRequirements = () => {
    //navigate to Requirments
    console.log(assessment?.prequalificationItem[preqItemIndex]);
  };

  const prequalificationItemExtendetColumns = [
    ...prequalificationItemColumn,
    {
      field: "required_for",
      flex: 0.3,
      editable: false,
      sortable: false,
      renderHeader: (params) => {
        return (
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <Comment sx={{ mr: 2 }} onClick={() => navigateToRequirements()} />
            <Typography>Notwendig für:</Typography>
          </Box>
        );
      },
    },
  ];

  const providingFieldsColumns = [
    {
      field: "in_store",
      headerName: "Geschäftslokal",
      flex: 0.15,
      editable: false,
      default: false,
      renderCell: ({ row }) => {
        if (row.in_store_allowed) {
          return <Checkbox checked={row.in_store_selected} />;
        } else return <></>;
      },
    },
    {
      field: "field_sales",
      headerName: "Außendienst",
      flex: 0.15,
      editable: false,
      default: false,
      renderCell: ({ row }) => {
        if (row.field_sales_allowed) {
          return <Checkbox checked={row.field_sales_selected} />;
        } else return <></>;
      },
    },
    {
      field: "code",
      headerName: "Versorgungsbereiche",
      flex: 0.2,
      editable: false,
    },
    {
      field: "description",
      headerName: "Bezeichnung",
      flex: 0.4,
      editable: false,
    },
  ];

  function handleClick(id) {
    setOpen((prevState) => ({ ...prevState, [id]: !prevState[id] }));
  }

  return (
    <>
      <Box>
        <Divider textAlign="left" sx={{ mt: 2, mb: 2 }}>
          <Typography variant="subtitle">Fachl. Leitung</Typography>
        </Divider>
        <CustomDataGrid
          rows={[assessment?.specialist[inActiveIndex]] ?? []}
          columns={[
            {
              field: "Name",
              headerName: "Versorgungsbereiche",
              flex: 0.2,
              editable: false,
              valueGetter: (params) => `${params.row.first_name || ""} ${params.row.last_name || ""}`,
            },
            { field: "qualification", headerName: "Berufsbezeichnung", flex: 0.2, editable: false },
            {
              field: "providing_fields",
              headerName: "Versorgungsbereiche",
              flex: 0.2,
              editable: false,
              valueGetter: (params) => {
                let providingFields = [];
                params.row.providing_fields?.forEach((element) => {
                  element && providingFields.push(`${element.code}`);
                });

                return `${providingFields
                  .sort((a, b) => (a > b ? 1 : -1))
                  .map((field) => field)
                  .join(",")}`;
              },
            },
          ]}
          onRowDoubleClick={(rows) => {
            console.log(rows);
          }}
          // checkboxSelection={checkboxSelection}
          // onSelectionModelChange={(rows) => setSelectedFields(rows)}
        />
        <Divider textAlign="left" sx={{ mt: 2, mb: 2 }}>
          <Typography variant="subtitle">Hinzugefügte Versorgungsbereiche</Typography>
        </Divider>
        <CustomDataGrid
          rows={providing_fields ? providing_fields : []}
          columns={providingFieldsColumns}

          // checkboxSelection={checkboxSelection}
          // onSelectionModelChange={(rows) => setSelectedFields(rows)}
        />

        <Divider textAlign="left" sx={{ mt: 2, mb: 2 }}>
          <Typography variant="subtitle">Neue Vorraussetzungen</Typography>
        </Divider>

        <CustomDataGrid
          rowHeight={30}
          rows={assessment?.prequalificationItem ? assessment?.prequalificationItem : []}
          columns={prequalificationItemExtendetColumns}
          initialPageSize={10}
          // onRowClick={(rows) => handleSachlDoc(rows)}
          onRowDoubleClick={(rows) => {
            console.log(rows);
            setPreqItemIndex(assessment?.prequalificationItem.findIndex((item) => item.id === rows.id));
            setComment([]);
          }}
          // experimentalFeatures={{ newEditingApi: true }}
        />
        <Card sx={{ p: 4, mt: 2 }}>
          <Box sx={{ display: "flex", flex: 1, flexDirection: "row", justifyContent: "space-between", mt: 2 }}>
            <Box sx={{ display: "flex", flex: 0.175, justifyContent: "flex-end" }}>
              <ShowStatus status={assessment?.prequalificationItem[preqItemIndex]?.status} />
            </Box>
            <Box
              sx={{
                display: "flex",
                flex: 0.8,
                flexDirection: "row",
                justifyContent: "space-around",
                alignContent: "center",
                border: 1,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flex: 0.3,
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                onClick={() => {
                  if (assessment?.prequalificationItem[preqItemIndex - 1] !== undefined) {
                    setPreqItemIndex(preqItemIndex - 1);
                    setComment([]);
                  }
                }}
              >
                <Typography>
                  {assessment?.prequalificationItem[preqItemIndex - 1]?.description.substring(0, 30)}
                </Typography>
              </Box>
              <Box sx={{ display: "flex", flex: 0.15 }}>
                <Box sx={{ display: "flex", flex: 0.5 }}>
                  {assessment?.prequalificationItem[preqItemIndex - 1] !== undefined && (
                    <FirstPage
                      fontSize="large"
                      onClick={() => {
                        setPreqItemIndex(preqItemIndex - 1);
                        setComment([]);
                      }}
                    />
                  )}
                </Box>
                <Box sx={{ display: "flex", flex: 0.5 }}>
                  {assessment?.prequalificationItem[preqItemIndex + 1] !== undefined && (
                    <LastPage
                      fontSize="large"
                      onClick={() => {
                        setPreqItemIndex(preqItemIndex + 1);
                        setComment([]);
                      }}
                    />
                  )}
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flex: 0.3,
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                onClick={() => {
                  if (assessment?.prequalificationItem[preqItemIndex + 1] !== undefined) {
                    setPreqItemIndex(preqItemIndex + 1);
                    setComment([]);
                  }
                }}
              >
                <Typography>
                  {assessment?.prequalificationItem[preqItemIndex + 1]?.description.substring(0, 30)}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box>
            <TextField
              sx={{ display: "flex", flex: 1, mt: 2 }}
              id="outlined-multiline-static"
              label="Kommentar"
              multiline
              rows={10}
              value={comment?.find((x) => x.id === assessment?.prequalificationItem[preqItemIndex]?.id)?.text}
              onChange={(e) =>
                commentInputOnChange(assessment?.prequalificationItem[preqItemIndex]?.id, e.target.value)
              }
              variant="outlined"
            />
            {assessment?.prequalificationItem[preqItemIndex]?.comment?.length !== 0 && (
              <CustomDataGrid
                rows={assessment?.prequalificationItem[preqItemIndex]?.comment ?? []}
                columns={[
                  {
                    field: "text",
                    headerName: "Kommentar",
                    flex: 1,
                    editable: false,
                    // valueGetter: (params) => `${params.row.titel}`,
                  },
                  {
                    field: "date",
                    headerName: "Datum / HIER FORMATIEREN",
                    flex: 1,
                    editable: false,
                    renderCell: (params) => {
                      return <Box>{`${moment(params.row.date)?.locale("de").format("DD.MM.YYYY")}`}</Box>;
                    },
                  },

                  {
                    field: "readable",
                    headerName: "Für Kunden sichtbar",
                    type: "singleSelect",
                    flex: 1,
                    editable: true,
                    valueOptions: [
                      {
                        value: false,
                        label: <Typography>Nicht Sichtbar</Typography>,
                      },
                      {
                        value: true,
                        label: <Typography>Sichtbar</Typography>,
                      },
                    ],
                    preProcessEditCellProps: (params) => {
                      const isPaidProps = params.row.readable;
                      const hasError = isPaidProps.value && !params.props.value;
                      return { ...params.props, error: hasError };
                    },
                    renderCell: (params) => {
                      return <Box>{params.row.readable === true ? "Sichtbar" : "Nicht Sichtbar"}</Box>;
                    },
                  },
                ]}
                onSelectionModelChange={(rows) => {
                  if (rows?.length === 0) {
                    if (selectedComment) {
                      setSelectedComment(null);
                    }
                    return;
                  }
                  const actionListObject = assessment?.prequalificationItem[preqItemIndex]?.comment?.filter((item) =>
                    item?.id?.includes(rows)
                  );
                  setSelectedComment(actionListObject[0]);
                }}
                checkboxSelection
                disableSelectionOnClick
                onCellEditCommit={(params) => dispatch(changeCommentReadableStatus(params?.id, params.value))}
              />
            )}
          </Box>
          <Box
            sx={{
              display: "flex",
              width: "50%",
              flexDirection: "row",
              justifyContent: "space-between",
              mt: 2,
            }}
          >
            <Button
              sx={{ backgroundColor: "success.main", color: "white" }}
              variant={"outlined"}
              disabled={clicked}
              onClick={() => handleStatusUpdate(true)} // LOGIC!
            >
              Akzeptiert
            </Button>
            <Button
              sx={{ backgroundColor: "error.main", color: "white" }}
              variant={"outlined"}
              disabled={clicked}
              onClick={() => handleStatusUpdate(false)} // LOGIC!
            >
              Abgelehnt
            </Button>
          </Box>
          <Box sx={{ mt: 2 }}>
            {assessment?.prequalificationItem[preqItemIndex]?.image?.map((item) => {
              return (
                <>
                  <Box
                    sx={{
                      display: "flex",
                      flex: 1,
                      flexDirection: "column",
                      border: 1,
                      p: 1,
                      mb: 1,
                    }}
                  >
                    <Box sx={{ display: "flex", flex: 1, flexDirection: "row" }}>
                      <Box
                        sx={{
                          display: "flex",
                          flex: 0.25,
                          p: 2,
                          border: 1,
                          borderColor: "secondary.lighter",
                          borderRadius: 1,
                          backgroundColor: "primary.main",
                        }}
                      >
                        <Typography sx={{ color: "white", fontSize: 20 }}>Datei Name</Typography>
                      </Box>
                      <TextField
                        sx={{ flex: 0.4, ml: 2 }}
                        value={item.imageBaseName}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="update Image Name"
                              // onClick={switchTabShowPassword}
                              // onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              <LastPage />
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                      <Box sx={{ display: "flex", flex: 0.25, ml: 2, justifyContent: "flex-end" }}>
                        <ShowStatus status={item.status} />
                      </Box>
                      <Box>
                        <UnfoldMore sx={{ fontSize: 60, ml: 3 }} onClick={() => handleClick(item?.id)} />
                        <Link
                          target={"_blank"}
                          to={"/showFile"}
                          onClick={() => {
                            localStorage.removeItem("showFile");
                            localStorage.setItem("showFile", JSON.stringify(item));
                          }}
                        >
                          <Tab sx={{ fontSize: 60 }} />
                        </Link>
                      </Box>
                    </Box>

                    <Collapse in={open[item?.id]}>
                      <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }} key={item?.id}>
                        <DisplayFiles file={item} />
                        <Box sx={{ display: "flex", flex: 0.5 }}>
                          <TextField
                            sx={{ display: "flex", flex: 0.75, mt: 2 }}
                            id={item.id}
                            label="Kommentar"
                            multiline
                            rows={10}
                            value={comment?.find((x) => x?.id === item?.id)?.text}
                            onChange={(e) => commentInputOnChange(item?.id, e.target.value)}
                            variant="outlined"
                          />
                          <Box
                            sx={{
                              display: "flex",
                              flex: 0.25,
                              // width: "50%",
                              flexDirection: "column",
                              justifyContent: "space-between",
                            }}
                          >
                            <Box sx={{ mt: 2 }}>
                              <Autocomplete
                                disablePortal
                                getOptionLabel={(option) => option.description}
                                options={assessment?.prequalificationItem}
                                value={moveDestination}
                                onChange={(event, newValue) => {
                                  setMoveDestination(newValue);
                                }}
                                renderInput={(params) => <TextField {...params} label="Vorraussetzungen" />}
                              />
                              <Button
                                sx={{ backgroundColor: "error.main", color: "white", mt: 1 }}
                                variant={"outlined"}
                                disabled={clicked}
                                onClick={() => handleImageMove(item, assessment?.prequalificationItem[preqItemIndex])} // LOGIC!d
                              >
                                Verschieben
                              </Button>
                            </Box>
                            <Box
                              sx={{
                                display: "flex",

                                justifyContent: "space-between",
                                alignContent: "center",
                              }}
                            >
                              <Button
                                sx={{ backgroundColor: "success.main", color: "white" }}
                                variant={"outlined"}
                                disabled={clicked}
                                onClick={() => handleStatusUpdateImage(true, item?.id)} // LOGIC!
                              >
                                Akzeptiert
                              </Button>

                              <Button
                                sx={{ backgroundColor: "error.main", color: "white" }}
                                variant={"outlined"}
                                disabled={clicked}
                                onClick={() => handleStatusUpdateImage(false, item?.id)} // LOGIC!
                              >
                                Abgelehnt
                              </Button>
                            </Box>
                          </Box>
                        </Box>
                        {item.comment?.length !== 0 && (
                          <CustomDataGrid
                            rows={item?.comment ?? []}
                            columns={[
                              {
                                field: "text",
                                headerName: "Kommentar",
                                flex: 1,
                                editable: false,
                                // valueGetter: (params) => `${params.row.titel}`,
                              },
                              {
                                field: "date",
                                headerName: "Datum / HIER FORMATIEREN",
                                flex: 1,
                                editable: false,
                                renderCell: (params) => {
                                  return <Box>{`${moment(params.row.date).locale("de").format("DD.MM.YYYY")}`}</Box>;
                                },
                              },
                            ]}
                            initialPageSize={10}
                            experimentalFeatures={{ newEditingApi: true }}
                          />
                        )}
                      </Box>
                    </Collapse>
                  </Box>
                </>
              );
            })}
          </Box>
        </Card>
      </Box>
    </>
  );
};

export default ProvidingFieldsTab;
