import { styled } from "@mui/material/styles";

const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  backgroundColor: theme.palette.mode === "dark" ? theme.palette.grey[700] : "#ccc", //REWORK
  zIndex: 1,
  color: "#fff",
  width: 50,
  height: 50,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  ...(ownerState.active && {
    backgroundImage: `linear-gradient( 95deg, ${theme.palette.info.light} 0%,${theme.palette.info.main} 50%,${theme.palette.primary.main} 100%)`,
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  }),
  ...(ownerState.completed && {
    backgroundImage: `linear-gradient( 95deg, ${theme.palette.info.light} 0%,${theme.palette.info.main} 50%,${theme.palette.primary.main} 100%)`,
  }),
}));

export default ColorlibStepIconRoot;
