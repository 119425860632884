import React, { useState } from "react";
import { Box, Autocomplete, TextField, Select, MenuItem, Typography, CircularProgress, Backdrop } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { useSelector, useDispatch } from "react-redux";

import { setPrequalificationForReview } from "api";
import {
  successChangeFacilityName,
  failChangeFacilityName,
  successChangeOfLegalForm,
  failChangeOfLegalForm,
  successFacilityMove,
  revokeFacilityMove,
  failAddingSpecialist,
  successAddingSpecialist,
  successProvidingFields,
  failProvidingFields,
  AcceptRemovingSpecialist,
  RejectRemovingSpecialst,
} from "api/changes.js";

import options from "utils/options";
import { AssessmentType, StatusType } from "assets/type.ts";
import { CModal } from "components";
import moment from "moment";

const AssessmentCheckModal = ({ isOpen, onRequestClose, assessment }) => {
  const dispatch = useDispatch();
  const admins = useSelector((state) => state.admin);

  // State variables
  const [selectedAdmin, setSelectedAdmin] = useState(null);
  const [reason, setReason] = useState(options.find((o) => o.value === assessment.type) ?? null);
  const [evaluationComment, setEvaluationComment] = useState("");
  const [result, setResult] = useState(false);
  const [comment, setComment] = useState("");
  const [endDate, setEndDate] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  // Helper functions for evaluation logic
  const handleForwardEvaluation = () => {
    if (!selectedAdmin?.id) {
      alert("Bitte wähle einen Admin");
      return;
    }
    setPrequalificationForReview(selectedAdmin.id, assessment.id, reason, evaluationComment);
    alert("Wurde erfolgreich weitergegeben");
  };

  const handleChangeEvaluation = async () => {
    setIsLoading(true);
    try {
      let evaluationData;
      switch (assessment.type) {
        case AssessmentType.CHANGECOMPANYNAME:
          evaluationData = result
            ? await successChangeFacilityName(assessment.id)
            : await failChangeFacilityName(assessment.id);
          break;

        case AssessmentType.CHANGEOFLEGALFORM:
          evaluationData = result
            ? await successChangeOfLegalForm(assessment.id)
            : await failChangeOfLegalForm(assessment.id);
          break;

        case AssessmentType.MOVE:
          evaluationData = result
            ? await successFacilityMove(assessment.id, endDate ? moment(endDate).format("YYYY-MM-DD") : null)
            : await revokeFacilityMove(assessment.id, comment);
          break;

        case AssessmentType.ADDSPECIALIST:
          evaluationData = result
            ? await successAddingSpecialist(assessment.id)
            : await failAddingSpecialist(assessment.id);
          break;

        case AssessmentType.REMOVESPECIALIST:
          evaluationData = result
            ? await AcceptRemovingSpecialist(assessment.id)
            : await RejectRemovingSpecialst(assessment.id);
          break;

        case AssessmentType.EXTENDPROVIDINGFIELDS:
        case AssessmentType.REMOVEPROVIDINGFIELDS:
          evaluationData = result
            ? await dispatch(successProvidingFields(assessment.id))
            : await failProvidingFields(assessment.id);
          break;

        default:
          alert("Aktion nicht definiert");
          return;
      }

      setIsLoading(false);

      // Set the returned data into state so it can be used elsewhere
      console.log("Returned evaluation data:", evaluationData);

      if (evaluationData.error) {
        alert("Fehler bei der Bewertung", evaluationData.message);
        onRequestClose();
      } else {
        alert("Korrektur erfolgreich");
        onRequestClose();
      }
    } catch (error) {
      console.error("Error during evaluation:", error);
    }
  };

  const handleModalSubmit = () => {
    assessment.status !== StatusType.UNDER_REVIEW ? handleForwardEvaluation() : handleChangeEvaluation();
    // onRequestClose();
  };

  // Common JSX Components
  const renderAutocomplete = (label, value, onChange, options, optionLabelKey) => (
    <Autocomplete
      disablePortal
      getOptionLabel={(option) => option[optionLabelKey]}
      options={options}
      sx={{ width: 300, mt: 2 }}
      value={value}
      isOptionEqualToValue={(option, value) => option[optionLabelKey] === value[optionLabelKey]}
      onChange={(_, newValue) => {
        console.log(newValue);
        onChange(newValue);
      }}
      renderInput={(params) => <TextField {...params} label={label} />}
    />
  );

  const renderTextField = (label, value, onChange) => (
    <TextField
      sx={{ flex: 0.8, mt: 2 }}
      label={label}
      multiline
      rows={10}
      value={value}
      onChange={(e) => onChange(e.target.value)}
      variant="outlined"
    />
  );

  return (
    <CModal
      modalLabel="Überprüfung"
      open={isOpen}
      onRequestClose={onRequestClose}
      buttonLabel="Ok - Auftrag erzeugen"
      backButtonLabel="abbrechen"
      onButtonPressed={handleModalSubmit}
      onBackButtonPressed={onRequestClose}
    >
      <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", p: 2 }}>
        {assessment.status !== StatusType.UNDER_REVIEW && (
          <Box>
            {renderAutocomplete("Bewerter", selectedAdmin, setSelectedAdmin, admins, "firstName")}
            {renderAutocomplete("Ergänzung Betreff", reason, setReason, options, "text")}
            {renderTextField("Anmerkung", evaluationComment, setEvaluationComment)}
          </Box>
        )}

        {assessment.status === StatusType.UNDER_REVIEW && (
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Select sx={{ width: 300 }} value={result} onChange={(e) => setResult(e.target.value)}>
              <MenuItem value={true}>Positiv</MenuItem>
              <MenuItem value={false}>Negativ</MenuItem>
            </Select>
            {result &&
              (assessment.type === AssessmentType.EXTENDPROVIDINGFIELDS ||
                assessment.type === AssessmentType.REMOVEPROVIDINGFIELDS) && (
                <>
                  <Typography>Bitte Enddatum wählen</Typography>
                  <Typography>Wenn Leer dann 5 Jahre</Typography>
                  <DatePicker value={endDate} title="Enddatum" onChange={setEndDate} />
                </>
              )}
            {renderTextField("Begründung", comment, setComment)}
          </Box>
        )}
      </Box>
      <Backdrop sx={(theme) => ({ color: "#fff", zIndex: theme.zIndex.drawer + 1 })} open={isLoading}>
        <CircularProgress color="inherit" />
        <Typography>Bitte warten...</Typography>
      </Backdrop>
    </CModal>
  );
};

export default AssessmentCheckModal;
