import { createSlice } from "@reduxjs/toolkit";

const providingFieldsSlice = createSlice({
  name: "providingFields",
  initialState: [],
  reducers: {
    providingFieldsAdd(state, action) {
      return action.payload;
    },
    logoutProviding(state, action) {
      return providingFieldsSlice.getInitialState();
    },
  },
});

export const { providingFieldsAdd, logoutProviding } = providingFieldsSlice.actions;
export default providingFieldsSlice.reducer;
