import React from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { InspectionType, StatusType } from "assets/type.ts";

import {
  Typography,
  Card,
  Box,
  TextField,
  Divider,
  Checkbox,
  Button,
  Select,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import { DateField, DatePicker } from "@mui/x-date-pickers";

import { specificInspection, acceptInspection, changeInspection, cancelInspection, GetWalker } from "api";
import { WalkerRequest } from "pages/modals";

import { inspectionColumns, adminInspectionColumns } from "layouts/DataGridLayouts";
import { LabelName, CustomDataGrid, CustomSnackbarAlert } from "components";

const WalkerPage = () => {
  const dispatch = useDispatch();

  const [selectedInspection, setSelectedInspection] = React.useState();

  const [pressed, setPressed] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [severity, setSeverity] = React.useState("info");

  const [inspectionDate, setInspectionDate] = React.useState(moment());
  const [accept, setAccept] = React.useState(false);
  const [timeOfDay, setTimeOfDay] = React.useState(false);
  const [openSelect, setOpenSelect] = React.useState(false);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [actionValue, setActionValue] = React.useState(null);
  const [walkerModal, setWalkerModal] = React.useState(false);

  const [clicked, setClicked] = React.useState(false);

  const account = useSelector((state) => state.account);
  const inspections = useSelector((state) => state.inspection);

  React.useEffect(() => {
    dispatch(specificInspection(account.id));
  }, [account.id, dispatch]);

  const handleButtonClicked = (value) => {
    setClicked(true);

    //check if anything is even selected
    console.log("selectedInspection", selectedInspection);

    if (!selectedInspection) {
      alert("Bitte wählen Sie eine Begehung aus");
      setClicked(false);
      return;
    }

    if (!accept) {
      alert("Bitte bestätigen Sie ihre Unparteilichkeit");
      setClicked(false);
      return;
    }

    if (!inspectionDate) {
      alert("Bitte wählen Sie ein Datum aus");
      setClicked(false);
      return;
    }

    // Check if the inspection date is within 14 days
    if (moment(inspectionDate).diff(moment(), "days") < 14) {
      setActionValue(value); // Store action for later execution
      setOpenDialog(true); // Open dialog for user confirmation
    } else {
      performAction(value); // Directly perform the action if no confirmation needed
    }
  };

  const handleYes = () => {
    setOpenDialog(false);
    performAction(actionValue); // Continue with stored action
  };

  const handleNo = () => {
    setOpenDialog(false);
    setClicked(false); // Reset clicked state
  };

  const performAction = (value) => {
    switch (value) {
      case 0:
        dispatch(acceptInspection(selectedInspection.id, moment(inspectionDate).format("YYYY-MM-DD"), timeOfDay)).then(
          () => setClicked(false)
        );
        break;
      case 1:
        dispatch(changeInspection(selectedInspection.id, moment(inspectionDate).format("YYYY-MM-DD"), timeOfDay)).then(
          (response) => {
            setClicked(false);
            if (response.error) {
              setPressed(true);
              setMessage(response.message);
              setSeverity("error");
            }
          }
        );
        break;
      case 2:
        dispatch(cancelInspection(selectedInspection.id)).then(() => setClicked(false));
        break;
      default:
        console.error("Error");
        setClicked(false);
        break;
    }
  };

  const handleInspectionSelect = (rows) => {
    if (rows.length === 0) {
      setSelectedInspection();
      return;
    }
    if (rows.length === 2) rows.shift();
    const inspectionObject = inspections?.filter((item) => item.id.includes(rows));
    console.log("selectedInspection", inspectionObject[0]);
    setSelectedInspection(inspectionObject[0]);
  };

  const handleInspectionRenew = () => {
    dispatch(GetWalker());
    setClicked(true);
    setWalkerModal(true);
  };

  const inspectionTypeLabels = {
    INITIAL: "Initiale PQ",
    CONTINUED: "Folge PQ",
    MONITORING: "Überwachung",
    CHANGERELATED: "Änderungsbezogen",
    RISK: "Risikobegehung",
  };

  return (
    <>
      <Divider textAlign="left" sx={{ my: 2 }}>
        <Typography fontWeight={"bold"}>Begehung</Typography>
      </Divider>
      <Card sx={{ p: 2 }}>
        <CustomDataGrid
          rows={inspections ? inspections : []}
          columns={account.admin || account.superAdmin ? adminInspectionColumns : inspectionColumns}
          disableSelectionOnClick
          onSelectionModelChange={(rows) => handleInspectionSelect(rows)}
          checkboxSelection
          experimentalFeatures={{ newEditingApi: true }}
        />
        {selectedInspection?.message && (
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <LabelName titel="Besonderheiten" />
            <TextField sx={{ flex: 0.75 }} name="name" value={selectedInspection?.message} />
          </Box>
        )}
      </Card>
      {selectedInspection &&
        [StatusType.CANCELED, StatusType.ACCEPTED].includes(selectedInspection?.status) &&
        (account.superAdmin || account.admin) && (
          <Button variant="contained" sx={{ mt: 1, textTransform: "none" }} onClick={handleInspectionRenew}>
            Begehung neu beauftragen
          </Button>
        )}

      {selectedInspection && selectedInspection.status !== StatusType.CREATED && (
        <>
          <Divider textAlign="left" sx={{ mt: 8, mb: 2 }}>
            <Typography fontWeight={"bold"}>Begehungsdatum</Typography>
          </Divider>
          <Card sx={{ p: 2 }}>
            <Box sx={{ display: "flex", flexDirection: "row" }}>
              <LabelName titel="Begehungsdatum" />
              <TextField
                name="date"
                sx={{ flex: 0.25 }}
                value={moment(selectedInspection.date).locale("de").format("dddd, D.M.YYYY")}
              />
              <TextField
                name="timeofday"
                sx={{ flex: 0.25 }}
                value={selectedInspection?.time_of_day ? "Nachmittags" : "Vormittags"}
              />
            </Box>
          </Card>
        </>
      )}

      <Divider textAlign="left" sx={{ mt: 8, mb: 2 }}>
        <Typography fontWeight={"bold"}>Addresse</Typography>
      </Divider>
      <Card sx={{ p: 2 }}>
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <LabelName titel="Straße, Hausnummer" />
          <TextField
            name="street"
            sx={{ flex: 0.25 }}
            value={
              selectedInspection?.type === InspectionType.MONITORING
                ? selectedInspection
                : selectedInspection?.facility[0]?.street
            }
          />
          <TextField name="street_number" sx={{ flex: 0.25 }} value={selectedInspection?.facility[0]?.street_number} />
        </Box>
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <LabelName titel="Postleitzahl, Stadt" />
          <TextField name="postcode" sx={{ flex: 0.25 }} value={selectedInspection?.facility[0]?.postcode} />
          <TextField name="city" sx={{ flex: 0.25 }} value={selectedInspection?.facility[0]?.city} />
        </Box>
      </Card>

      <Divider textAlign="left" sx={{ mt: 8, mb: 2 }}>
        <Typography fontWeight={"bold"}>Allgemeine Informationen</Typography>
      </Divider>

      <Card sx={{ p: 2 }}>
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <LabelName titel="Art der Betriebsstätte" />
          <TextField sx={{ flex: 0.75 }} value={selectedInspection?.facility[0]?.name} />
        </Box>
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <LabelName titel="Grund der Begehung" />
          <TextField sx={{ flex: 0.75 }} name="name" value={inspectionTypeLabels[selectedInspection?.type]} />
        </Box>
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <LabelName titel="Antragsstellungsdatum" />
          <DateField sx={{ flex: 0.75 }} value={moment(selectedInspection?.facility[0].date_of_issue)} />
        </Box>
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <LabelName titel="Zeitraum der Begehung" />
          <TextField
            sx={{ flex: 0.75 }}
            name="name"
            value={` bis zum ${moment(selectedInspection?.due_date).locale("de").format("DD.MM.YYYY")}`}
          />
        </Box>

        <Box sx={{ mt: 2 }}>
          <Typography> Für folgende Versorgungsbereiche wird die Betriebsbegehung beantragt:</Typography>
          <CustomDataGrid
            rows={selectedInspection?.providing_fields ? selectedInspection?.providing_fields : []}
            columns={[
              {
                field: "code",
                headerName: "Versorgungsbereich",
                flex: 0.25,
                editable: false,
              },
              {
                field: "description",
                headerName: "Beschreibung",
                flex: 0.75,
                editable: false,
              },
            ]}
            disableSelectionOnClick
            experimentalFeatures={{ newEditingApi: true }}
            initialState={{
              sorting: {
                sortModel: [{ field: "code", sort: "asc" }],
              },
            }}
          />
        </Box>
      </Card>
      <Divider textAlign="left" sx={{ mt: 8, mb: 2 }}>
        <Typography fontWeight={"bold"}>Kontakt Personen</Typography>
      </Divider>

      <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignContent: "center" }}>
        <Card sx={{ p: 2, flex: 0.45 }}>
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <Box>
              <LabelName titel="Kontakt Person" />
            </Box>
            <Box>
              <TextField
                name="name"
                value={
                  selectedInspection?.facility[0]?.main_contact_person?.first_name +
                  " " +
                  selectedInspection?.facility[0]?.main_contact_person?.last_name
                }
              />
            </Box>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <Box>
              <LabelName titel="Telefonnummer" />
            </Box>
            <Box>
              <TextField
                name="phoneNumber"
                value={selectedInspection?.facility[0]?.main_contact_person?.phone_number}
              />
            </Box>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <Box>
              <LabelName titel="E-Mail" />
            </Box>
            <Box>
              <TextField name="mail" value={selectedInspection?.facility[0]?.main_contact_person?.email} />
            </Box>
          </Box>
        </Card>
        <Card sx={{ p: 2, flex: 0.45 }}>
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <Box>
              <LabelName titel="Fachliche Leitung" />
            </Box>
            <Box sx={{ flex: 1, justifyContent: "center", alignContent: "center" }}>
              {selectedInspection?.facility[0]?.specialist?.map((item) => (
                <Typography key={item.id} sx={{ flex: 0.5 }}>
                  {item.first_name} {item.last_name} - {item.qualification}
                </Typography>
              ))}
            </Box>
          </Box>
        </Card>
      </Box>

      <Divider textAlign="left" sx={{ mt: 8, mb: 2 }}>
        <Typography fontWeight={"bold"}>Unternehmens Informationen</Typography>
      </Divider>
      <Card sx={{ p: 2 }}>
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <LabelName titel="Unternehmen" />
          <TextField sx={{ flex: 0.75 }} name="name" value={selectedInspection?.facility[0]?.name} />
        </Box>
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <LabelName titel="IK Nummer" />
          <TextField sx={{ flex: 0.75 }} name="name" value={selectedInspection?.facility[0]?.ik_number} />
        </Box>
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <LabelName titel="PQ Nummer" />
          <TextField
            sx={{ flex: 0.75 }}
            name="name"
            value={selectedInspection?.prequalification[0]?.consecutiveNumber}
          />
        </Box>
      </Card>
      <Divider textAlign="left" sx={{ mt: 8, mb: 2 }}>
        <Typography fontWeight={"bold"}>Bestätigung</Typography>
      </Divider>
      <Card sx={{ p: 2 }}>
        <Typography>Bitte bestätigen Sie die Auftragsannahme innerhalb von 5 Arbeitstagen</Typography>

        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <LabelName titel="Termin" />
          {/* <DateTimePicker label="Termin" value={inspectionDate} onChange={(newValue) => setInspectionDate(newValue)} /> */}
          <DatePicker value={inspectionDate} onChange={(newValue) => setInspectionDate(newValue)} />
          <Select
            open={openSelect}
            onClose={() => setOpenSelect(false)}
            onOpen={() => setOpenSelect(true)}
            value={timeOfDay}
            onChange={(e) => setTimeOfDay(e.target.value)}
          >
            <MenuItem value={false}>Vormittags</MenuItem>
            <MenuItem value={true}>Nachmittags</MenuItem>
          </Select>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
          <Checkbox value={accept} onChange={() => setAccept(!accept)} />
          <Typography>
            Mit der Bestätigung erkläre ich ausdrücklich, dass keine Probleme bezüglich meiner Unparteilichkeit (z. B.
            durch Beratung) mit diesem Kunden bestehen.
          </Typography>
        </Box>
      </Card>
      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Button
          variant="contained"
          sx={{ backgroundColor: "success.main", color: "white", borderColor: "white", margin: 2 }}
          onClick={() => handleButtonClicked(0)}
          disabled={selectedInspection?.status !== StatusType.CREATED || !accept || clicked}
        >
          Bestätigen
        </Button>
        <Button
          variant="contained"
          sx={{ backgroundColor: "warning.main", color: "white", borderColor: "white", margin: 2 }}
          disabled={clicked}
          onClick={() => handleButtonClicked(1)}
        >
          Bearbeiten
        </Button>
        <Button
          variant="contained"
          sx={{ backgroundColor: "error.main", color: "white", borderColor: "white", margin: 2 }}
          disabled={clicked}
          onClick={() => handleButtonClicked(2)}
        >
          Stonieren
        </Button>
      </Box>
      <Box>
        <Typography sx={{ fontWeight: "bold" }}>
          Bitte bestätigen Sie die Auftragsannahme innerhalb von 5 Arbeitstagen.
        </Typography>
        <Typography>Sollten noch Fragen offen sein, so stehen wir Ihnen gerne zur Verfügung.</Typography>
        <Typography>Rückantwort: DGP - GmbH Münsterstr. 5 44534 Lünen</Typography>
        <Typography>E-Mail: info@dgp-gmbh.de</Typography>
      </Box>

      <CustomSnackbarAlert
        open={pressed}
        setOpen={setPressed}
        severity={severity ? "success" : "error"}
        message={message}
      />
      <Dialog onClose={() => setOpenDialog(false)} open={openDialog}>
        <DialogTitle>Warnung</DialogTitle>
        <DialogContent>
          <Typography>
            Ihr Begehungstermin ist innerhalb von 14 Tagen. Daher wird sofort der Auditplan an den Leistungserbringer
            gesendet. Sie können dann keine Änderungen mehr vornehmen.
          </Typography>
          <Typography>Wollen Sie trotzdem fortfahren?</Typography>
          <Button variant="contained" sx={{ my: 1, mx: 1 }} onClick={handleYes}>
            Ja
          </Button>
          <Button variant="contained" onClick={handleNo}>
            Nein
          </Button>
        </DialogContent>
      </Dialog>
      {walkerModal === true && (
        <WalkerRequest
          isOpen={walkerModal}
          onRequestClose={() => {
            setClicked(false);
            setWalkerModal(false);
          }}
          selectedFacility={selectedInspection?.facility[0]}
          selectedAssessment={selectedInspection?.assessment[0]}
          selectedPrequalification={selectedInspection?.prequalification[0]}
          selectedActionListItem={{}}
        />
      )}
    </>
  );
};

export default WalkerPage;

//     value={`${moment(selectedInspection?.due_date).locale("de").format("dddd")}, d.${moment(selectedInspection?.due_date).format("dddd")}`}
