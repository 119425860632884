import { createSlice } from "@reduxjs/toolkit";

const requirementsSlice = createSlice({
  name: "requirements",
  initialState: [],
  reducers: {
    requirementsAdded(state, action) {
      return action.payload;
    },
    logoutRequirements(state, action) {
      return requirementsSlice.getInitialState();
    },
  },
});

export const { requirementsAdded, logoutRequirements } = requirementsSlice.actions;
export default requirementsSlice.reducer;
