import React, { useState } from "react";

import { Card, Typography, Tabs, Tab, Box } from "@mui/material";
import {
  SVCompanyCard,
  SVAccountCard,
  SVFacilityCard,
  SVSpecialistCard,
  SVContactPersonCard,
  SVXMLCard,
} from "./pages";

const SuperVisorPage = () => {
  const [activeTab, setActiveTab] = useState(0);

  const handleChange = (event, newValue) => {
    console.log(newValue);
    setActiveTab(newValue);
  };

  return (
    <Box>
      <Card sx={{ display: "flex", flexDirection: "column", p: 2, boxShadow: 2, mb: 5 }}>
        <Typography variant="h4">Admin Panel</Typography>

        {/* Tabs Navigation */}
        <Tabs value={activeTab} onChange={handleChange} sx={{ mt: 2 }}>
          <Tab label="Account" />
          <Tab label="Company" />
          <Tab label="Facility" />
          <Tab label="Specialist" />
          <Tab label="Contact Person" />
          <Tab label="XML" />
        </Tabs>
      </Card>

      {/* Tab Content - Only the active component is rendered */}
      <Box sx={{ mt: 3 }}>
        {activeTab === 0 && <SVAccountCard />}
        {activeTab === 1 && <SVCompanyCard />}
        {activeTab === 2 && <SVFacilityCard />}
        {activeTab === 3 && <SVSpecialistCard />}
        {activeTab === 4 && <SVContactPersonCard />}
        {activeTab === 5 && <SVXMLCard />}
      </Box>
    </Box>
  );
};

export default SuperVisorPage;
