import { useState } from "react";
import { Outlet } from "react-router-dom";
// @mui
import { styled } from "@mui/material/styles";
//
import Nav from "./nav";
import { Alert, Card, Link, Snackbar, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { createVerificationEmail } from "../../api";

// ----------------------------------------------------------------------

const StyledRoot = styled("div")({
  display: "flex",
  minHeight: "100%",
  overflow: "hidden",
});

const Main = styled("div")(({ theme }) => ({
  flexGrow: 1,
  overflow: "auto",
  minHeight: "100%",
  paddingTop: 20,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up("lg")]: {
    paddingTop: 20,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const [open, setOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("Hallo Welt");
  const [successMessage, setSuccessMessage] = useState("Hallo Welt");
  const [successOpen, setSuccessOpen] = useState(false);
  const [errorOpen, setErrorOpen] = useState(false);

  const account = useSelector((state) => state.account);

  const handleClick = async (event) => {
    event.preventDefault(); // Prevents the default anchor behavior

    const response = await createVerificationEmail(account?.id);
    if (response.error) {
      setErrorOpen(true);
      setErrorMessage(response.message);
    } else {
      setSuccessOpen(true);
      setSuccessMessage(response.message);
    }
  };

  return (
    <>
      <StyledRoot>
        <Nav openNav={open} onCloseNav={() => setOpen(false)} />

        <Main>
          {!account?.verified && !account.admin && !account.superAdmin && !account.walker && (
            <Card
              sx={{
                display: "flex",
                flexDirection: "column",
                p: 1,
                m: 3,
                justifyItems: "center",
              }}
            >
              <Typography variant="h5">
                Ihr Account ist noch nicht verifiziert. <br /> Bitten prüfen Sie ihr E-Mail Postfach und folgen Sie den
                Anweisungen in der E-Mail.
              </Typography>
              <Link href="#" onClick={handleClick} sx={{ color: "blue", textDecoration: "none", cursor: "pointer" }}>
                oder lassen Sie sich einen neuen Link schicken.
              </Link>
              <Snackbar
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                open={errorOpen}
                autoHideDuration={5000}
                onClose={() => setErrorOpen(false)}
              >
                <Alert variant="filled" severity="error">
                  {errorMessage}
                </Alert>
              </Snackbar>
              <Snackbar
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                open={successOpen}
                autoHideDuration={5000}
                onClose={() => setSuccessOpen(false)}
              >
                <Alert variant="filled" severity="success">
                  {successMessage}
                </Alert>
              </Snackbar>
            </Card>
          )}
          <Outlet />
        </Main>
      </StyledRoot>
    </>
  );
}
