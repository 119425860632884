import { createSlice } from "@reduxjs/toolkit";

const facilitySlice = createSlice({
  name: "facility",
  initialState: [],
  reducers: {
    facilityAdd(state, action) {
      if (!action || !action.payload) {
        console.error(
          "facilityAdd reducer received null action or action.payload, returning empty array to avoid null pointer reference"
        );
        return [];
      }
      return action.payload;
    },

    specificFacilityAdd(state, action) {
      if (!action || !action.payload) {
        console.error(
          "specificFacilityAdd reducer received null action or action.payload, returning unmodified state to avoid null pointer reference"
        );
        return state;
      }
      const facility = action.payload;
      const facilityIndex = state.findIndex((item) => item.id === facility.id);
      if (facilityIndex === -1) {
        return [...state, facility];
      }
      state[facilityIndex] = facility;
      return state;
    },
    facilityEdit(state, action) {
      if (!action || !action.payload) {
        console.error(
          "facilityEdit reducer received null action or action.payload, returning unmodified state to avoid null pointer reference"
        );
        return state;
      }
      const facility = action.payload;
      const facilityIndex = state.findIndex((item) => item && item.id === facility.id);
      if (facilityIndex === -1) {
        console.error(
          `facilityEdit reducer could not find facility with id ${facility.id} to update. Returning unmodified state to avoid unhandled exception.`
        );
        return state;
      }
      state[facilityIndex] = facility;
      return state;
    },

    facilitySpecialistCreate(state, action) {
      if (!action || !action.payload) {
        console.error(
          "facilitySpecialistCreate reducer received null action or action.payload, returning unmodified state to avoid null pointer reference"
        );
        return state;
      }
      const specialist = action.payload.specialist;
      const facilityId = action.payload.facilityId;
      const facilityIndex = state.findIndex((item) => item.id === facilityId);
      if (facilityIndex === -1) {
        console.error(
          `facilitySpecialistCreate reducer could not find facility with id ${facilityId} to update. Returning unmodified state to avoid unhandled exception.`
        );
        return state;
      }
      if (!state[facilityIndex].specialist) {
        console.error(
          `facilitySpecialistCreate reducer could not find specialist field in facility with id ${facilityId}. Returning unmodified state to avoid null pointer reference.`
        );
        return state;
      }
      state[facilityIndex].specialist.push(specialist);
    },
    facilityCreate(state, action) {
      if (!action || !action.payload) {
        console.error(
          "facilityCreate reducer received null action or action.payload, returning unmodified state to avoid null pointer reference"
        );
        return state;
      }
      const facility = action.payload.facility;
      if (!facility) {
        console.error(
          "facilityCreate reducer received null facility, returning unmodified state to avoid null pointer reference"
        );
        return state;
      }
      return [...state, facility];
    },

    facilityDelete(state, action) {
      if (!action || !action.payload) {
        console.error(
          "facilityDelete reducer received null action or action.payload, returning unmodified state to avoid null pointer reference"
        );
        return state;
      }
      const toDelete = state.findIndex((spec) => spec.id === action.payload);
      if (toDelete === -1) {
        console.error(
          `facilityDelete reducer could not find facility with id ${action.payload} to delete. Returning unmodified state to avoid unhandled exception.`
        );
        return state;
      }
      return [...state.slice(0, toDelete), ...state.slice(toDelete + 1)];
    },

    logoutFacility(state, action) {
      if (!state) {
        console.error(
          "logoutFacility reducer received null state, returning unmodified state to avoid null pointer reference"
        );
        return state;
      }
      return facilitySlice.getInitialState();
    },
  },
});

export const {
  facilityAdd,
  specificFacilityAdd,
  facilityEdit,
  facilityCreate,
  facilitySpecialistCreate,
  logoutFacility,
  facilityDelete,
} = facilitySlice.actions;
export default facilitySlice.reducer;
