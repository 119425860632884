import { createSlice } from "@reduxjs/toolkit";

const tokenSlice = createSlice({
  name: "Token",
  initialState: localStorage.getItem("TOKEN"),
  reducers: {
    tokenAdded(state, action) {
      return action.payload;
    },
    logoutToken(state, action) {
      return tokenSlice.getInitialState();
    },
  },
});

export const { tokenAdded, logoutToken } = tokenSlice.actions;
export default tokenSlice.reducer;
