import { Box, Typography } from "@mui/material";
import { StatusType } from "assets/type.ts";

const ShowStatus = ({ status }) => {
  switch (status) {
    case StatusType.ACCEPTED:
      return (
        <Box
          sx={{
            display: "flex",
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "success.main",
            borderRadius: 1,
          }}
        >
          <Typography>Akzeptiert</Typography>
        </Box>
      );
    case StatusType.REJECTED:
      return (
        <Box
          sx={{
            display: "flex",
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "error.main",
            borderRadius: 1,
          }}
        >
          <Typography>Abgelehnt</Typography>
        </Box>
      );
    default:
      return (
        <Box
          sx={{
            display: "flex",
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "secondary.light",
            borderRadius: 1,
          }}
        >
          <Typography>Nicht bearbeitet</Typography>
        </Box>
      );
  }
};
export default ShowStatus;
