import React from "react";

import {
  Box,
  Card,
  Tabs,
  Tab,
  Button,
  Typography,
  Select,
  MenuItem,
  ListItemText,
  Divider,
  Checkbox,
} from "@mui/material";

import { actionListLayout } from "layouts/DataGridLayouts";

import {
  getActionList,
  moveActionListItemBetweenAdmin,
  attachActionListItemToAdmin,
  attachActionListArrayToAdmin,
  detachActionListItemFromAdmin,
  detachActionListArrayFromAdmin,
  GetFacilities,
  GetSpecificPrequalificationItemList,
  actionListItemStatusUpdate,
  GetSpecificAssessment,
  GetWalker,
  getAdminList,
  moveMultipleActionListItemBetweenAdmin,
  getSpecificActionListItem,
  getCompletedActionList,
} from "api";

//Redux

import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import {
  AcceptPrequalification,
  AcceptRemoveProvidingFields,
  AcceptRemoveSpecialist,
  WalkerRequest,
} from "pages/modals";

import { ActionListItemType, AssessmentType, StatusType } from "assets/type.ts";
import { actionListRemoveCompleted } from "redux/reducers/actionList.js";
import { CustomContainer, CustomDataGrid, LabelName } from "components";

const Dashboard = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const state = useSelector((state) => state);

  //ActionListItems without connection to an Admin
  const unconnectedActionList = state.actionList.filter((item) => item?.account?.length === 0);

  const allActiveActionListItems = state.actionList.filter((item) => item.status !== StatusType.COMPLETED);
  const allDoneActionListItems = state.actionList.filter((item) => item.status === StatusType.COMPLETED);
  const allActionListItems = state.actionList;
  const account = state.account;
  const admin = state.admin;
  const actionListPrivate = state.actionList.filter(
    (item) => item?.account[0]?.id === account?.id && item.status !== StatusType.COMPLETED
  );

  const [tabs, setTabs] = React.useState(0);
  const [selected, setSelected] = React.useState();
  const [selectedAdmin, setSelectedAdmin] = React.useState([]);
  const [specialistDestination, setSpecialistDestination] = React.useState([]);
  const [selectedRows, setSelectedRows] = React.useState([]);

  const [acceptPrequalificationModal, setAcceptPrequalificationModal] = React.useState(false);
  const [acceptProvidingFieldsModal, setAcceptRemoveProvidingFields] = React.useState(false);
  const [acceptRemoveSpecialist, setAcceptRemoveSpecialist] = React.useState(false);
  const [walkerModal, setWalkerModal] = React.useState(false);
  const [selectedActionListItem, setSelectedActionListItem] = React.useState({
    facility: [],
    specialist: [{ providing_fields: [] }],
  });

  const [loadCompletedActionItems, setLoadCompletedActionItems] = React.useState(false);
  const [clicked, setClicked] = React.useState(false);

  React.useEffect(() => {
    dispatch(getActionList());
    dispatch(getAdminList()).then((response) => {
      const admin_account_index = response.findIndex((item) => item.id === account.id);

      setSelectedAdmin(response[admin_account_index]);
    });
  }, [account.id, dispatch]);

  const handleLoadCompletedActionItems = () => {
    if (!loadCompletedActionItems === true) {
      dispatch(getCompletedActionList());
    } else {
      dispatch(actionListRemoveCompleted());
    }
    setLoadCompletedActionItems(!loadCompletedActionItems);
  };

  const navigateToPrequalificatonPage = async (params, event, details) => {
    //GGF LOAD ALL COMPANIES
    //GET FACILITIES WITH HISTORY?

    //Prevent doubleclick action to be triggered when status selection is used
    if (event.target.className.includes("menu-status")) return;

    let actionListItem = params.row;

    // Await the dispatch and make sure you wait for the response
    const response = await dispatch(getSpecificActionListItem(actionListItem.id));

    console.log("response", response);
    if (!response.error) {
      actionListItem = response.value;
    } else {
      alert("Es ist ein Fehler beim laden der Daten aufgetreten");
    }

    // console.log(params, event, event.target);

    switch (params.row.type) {
      case ActionListItemType.REQUEST: {
        setSelectedActionListItem(actionListItem);
        setAcceptPrequalificationModal(true);
        break;
      }
      case ActionListItemType.INSPECTION: {
        dispatch(GetWalker());
        setSelectedActionListItem(actionListItem);
        setWalkerModal(true);
        //OPEN WALKER MODAL
        break;
      }
      case ActionListItemType.DEFAULT:
      case ActionListItemType.DOCUMENTREVIEW: {
        navigate("/dashboard/evaluation", {
          state: { ...actionListItem, defaultTab: actionListItem.type === 42 ? 4 : -1 }, // IDK What type 42 is :'(
        });

        break;
      }
      case ActionListItemType.MONITORING: {
        dispatch(GetSpecificAssessment(actionListItem.assessment[0].id));
        navigate("/dashboard/assessment", { state: actionListItem });
        break;
      }
      case ActionListItemType.CHANGES: {
        dispatch(GetSpecificAssessment(actionListItem.assessment[0].id));
        console.log("params.row.assessment[0].type", actionListItem.assessment[0]);
        if (actionListItem.assessment[0].type === AssessmentType.REMOVEPROVIDINGFIELDS) {
          setSelectedActionListItem(actionListItem);
          setAcceptRemoveProvidingFields(true);
        } else {
          navigate("/dashboard/assessment", { state: actionListItem });
        }
        break;
      }
      default: {
        try {
          if (actionListItem.facility.length !== 0) {
            dispatch(GetFacilities(actionListItem.facility[0].id));
          }
          if (actionListItem.prequalification !== 0) {
            dispatch(GetSpecificPrequalificationItemList(actionListItem.prequalification[0].id));
          }
        } catch (e) {
          alert("Es ist ein fehler aufgetreten");
        }
        break;
      }
    }
  };

  const EnableCompletedActionListItemsButton = () => {
    return (
      <Box
        sx={{
          display: "flex",
          border: 1,
          borderColor: "secondary.lighter",
          borderRadius: 1,
          alignItems: "center",
          p: 1,
        }}
      >
        <Checkbox checked={loadCompletedActionItems} onClick={handleLoadCompletedActionItems} />
        <Typography>Vergangene Aktivitäten</Typography>
      </Box>
    );
  };

  return (
    <CustomContainer>
      {/* <Box sx={{ display: "flex", flex: 1, justifyContent: "space-around", mb: 5 }}>
        <Card
          sx={{ display: "flex", flex: 0.2, p: 5, justifyContent: "space-between", flexDirection: "column" }}
          onClick={handleClicked}
        >
          <Box sx={{ display: "flex", flex: 1, flexDirection: "row", justifyContent: "space-between" }}>
            <Typography>Aktive Kunden</Typography>
            <ReplayIcon />
          </Box>
          <Divider />
          <Typography fontSize="h2.fontSize">Value</Typography>
        </Card>
        <Card
          sx={{ display: "flex", flex: 0.2, p: 5, justifyContent: "space-between", flexDirection: "column" }}
          onClick={() => setIsOpen(true)}
        >
          <Box sx={{ display: "flex", flex: 1, flexDirection: "row", justifyContent: "space-between" }}>
            <Typography>Aufträge</Typography>
            <ReplayIcon />
          </Box>
          <Divider />
          <Typography>Value</Typography>
        </Card>
        <Card
          sx={{ display: "flex", flex: 0.2, p: 5, justifyContent: "space-between", flexDirection: "column" }}
          onClick={handleClicked}
        >
          <Box sx={{ display: "flex", flex: 1, flexDirection: "row", justifyContent: "space-between" }}>
            <Typography>Massgebliche Änderungen</Typography>
            <ReplayIcon />
          </Box>
          <Divider />
          <Typography>Value</Typography>
        </Card>
        <Card
          sx={{ display: "flex", flex: 0.2, p: 5, justifyContent: "space-between", flexDirection: "column" }}
          onClick={handleClicked}
        >
          <Box sx={{ display: "flex", flex: 1, flexDirection: "row", justifyContent: "space-between" }}>
            <Typography>Aussetzungen</Typography>
            <ReplayIcon />
          </Box>
          <Divider />
          <Typography>Value</Typography>
        </Card>
      </Box> */}

      <Box sx={{ mt: 2 }}>
        <Card sx={{ mt: 2, p: 2 }}>
          <Tabs selectionFollowsFocus value={tabs} onChange={(event, value) => setTabs(value)}>
            <Tab label="Aktionsliste" />
            <Tab label="Private Aktionsliste" />
          </Tabs>
        </Card>
      </Box>
      {tabs === 0 && (
        <>
          <Box sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}>
            <EnableCompletedActionListItemsButton />
            <Button
              disabled={clicked}
              variant="contained"
              onClick={() => {
                setClicked(true);
                if (selectedRows.length >= 1) {
                  dispatch(attachActionListArrayToAdmin(selectedRows, account)).then(() => setClicked(false));
                } else {
                  dispatch(attachActionListItemToAdmin(selected.id, account)).then(() => setClicked(false));
                }
              }}
            >
              Mir zuweisen
            </Button>
          </Box>
          <Card>
            <CustomDataGrid
              rows={unconnectedActionList}
              columns={actionListLayout}
              checkboxSelection
              disableSelectionOnClick
              onRowDoubleClick={(params, event, details) => navigateToPrequalificatonPage(params, event, details)}
              onSelectionModelChange={(rows) => {
                setSelectedRows(rows);
                if (rows.length === 0) {
                  if (selected) {
                    setSelected(null);
                  }
                  return;
                }
                const actionListObject = allActionListItems?.find((item) => item.id === rows[0]);
                setSelected(actionListObject);
              }}
              onCellEditCommit={(params) => dispatch(actionListItemStatusUpdate(params.id, params.value))}
              initialState={{
                sorting: {
                  sortModel: [{ field: "date", sort: "desc" }],
                },
              }}
            />
          </Card>
        </>
      )}
      {tabs === 1 && (
        <Box sx={{ mt: 5 }}>
          <Card sx={{ p: 2, mb: 5 }}>
            <Divider textAlign="left" sx={{ mb: 2 }}>
              <Typography fontWeight={"bold"}>Auswählen</Typography>
            </Divider>
            <Box sx={{ display: "flex", flexDirection: "row" }}>
              <LabelName titel="Liste von:" />
              <Select
                labelId="0"
                id="0"
                autoWidth={true}
                value={selectedAdmin?.id ? selectedAdmin?.id : ""}
                renderValue={() => `${selectedAdmin?.firstName} ${selectedAdmin?.lastName}`}
                sx={{ minWidth: 150 }}
                onChange={(e) => {
                  const preselect = admin.filter((item) => item.id === e.target.value);
                  setSelectedAdmin(preselect[0]);
                }}
              >
                {admin?.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    <ListItemText primary={`${item.firstName} ${item.lastName}`} />
                  </MenuItem>
                ))}
              </Select>
            </Box>
            <Divider textAlign="left" sx={{ my: 2 }}>
              <Typography fontWeight={"bold"}>Verschieben in</Typography>
            </Divider>
            <Box sx={{ display: "flex", flexDirection: "column", alignContent: "space-between" }}>
              <Box sx={{ display: "flex", flexDirection: "row" }}>
                <LabelName titel="Liste von:" />
                <Select
                  labelId="1"
                  id="1"
                  sx={{ minWidth: 150 }}
                  autoWidth={true}
                  value={specialistDestination?.id ? specialistDestination?.id : ""}
                  renderValue={() => `${specialistDestination?.firstName} ${specialistDestination?.lastName}`}
                  onChange={(e) => {
                    const preselect = admin.filter((item) => item.id === e.target.value);
                    setSpecialistDestination(preselect[0]);
                  }}
                >
                  {admin?.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                      <ListItemText primary={`${item.firstName} ${item.lastName}`} />
                    </MenuItem>
                  ))}
                </Select>
              </Box>
              <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <Button
                  variant="contained"
                  disabled={clicked}
                  onClick={() => {
                    setClicked(true);
                    if (selectedRows.length >= 1) {
                      dispatch(moveMultipleActionListItemBetweenAdmin(selectedRows, specialistDestination)).then(() =>
                        setClicked(false)
                      );
                    } else {
                      dispatch(moveActionListItemBetweenAdmin(selected.id, specialistDestination)).then(() =>
                        setClicked(false)
                      );
                    }
                  }}
                >
                  Verschieben
                </Button>
              </Box>
            </Box>
          </Card>
          <Card sx={{ p: 2 }}>
            <Divider textAlign="left" sx={{ my: 2 }}>
              <Typography fontWeight={"bold"}>Liste</Typography>
            </Divider>
            <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}>
              <EnableCompletedActionListItemsButton />
              <Button
                variant="contained"
                disabled={clicked}
                onClick={() => {
                  setClicked(true);
                  if (selectedRows.length >= 1) {
                    dispatch(detachActionListArrayFromAdmin(selectedRows)).then(() => setClicked(false));
                  } else {
                    dispatch(detachActionListItemFromAdmin(selected?.id)).then(() => setClicked(false));
                  }
                }}
              >
                Von Privater Liste entfernen
              </Button>
            </Box>
            <CustomDataGrid
              rows={
                selectedAdmin?.length === 0
                  ? actionListPrivate
                  : loadCompletedActionItems
                  ? allDoneActionListItems.filter((item) => item?.account[0]?.id === selectedAdmin?.id)
                  : allActiveActionListItems.filter((item) => item?.account[0]?.id === selectedAdmin?.id)
              }
              columns={actionListLayout}
              checkboxSelection
              disableSelectionOnClick
              onRowDoubleClick={(params, event, details) => navigateToPrequalificatonPage(params, event, details)}
              onSelectionModelChange={(rows) => {
                setSelectedRows(rows);
                if (rows.length === 0) {
                  if (selected) {
                    setSelected(null);
                  }
                  return;
                }
                const actionListObject = allActionListItems?.find((item) => item.id === rows[0]);
                setSelected(actionListObject);
              }}
              onCellEditCommit={(params) => dispatch(actionListItemStatusUpdate(params.id, params.value))}
              initialState={{
                sorting: {
                  sortModel: [{ field: "date", sort: "desc" }],
                },
              }}
            />
          </Card>
        </Box>
      )}
      <AcceptPrequalification
        isOpen={acceptPrequalificationModal}
        onRequestClose={() => {
          setAcceptPrequalificationModal(false);
          dispatch(getActionList());
        }}
        actionListItem={selectedActionListItem}
      />
      {walkerModal === true && (
        <WalkerRequest
          isOpen={walkerModal}
          onRequestClose={() => setWalkerModal(false)}
          selectedFacility={selectedActionListItem?.facility[0]}
          selectedAssessment={selectedActionListItem?.assessment[0]}
          selectedPrequalification={selectedActionListItem?.prequalification[0]}
          selectedActionListItem={selectedActionListItem}
        />
      )}
      {acceptProvidingFieldsModal && (
        <AcceptRemoveProvidingFields
          isOpen={acceptProvidingFieldsModal}
          onRequestClose={() => {
            setAcceptRemoveProvidingFields(false);
            dispatch(getActionList());
          }}
          actionListItem={selectedActionListItem}
        />
      )}
      {acceptRemoveSpecialist && (
        <AcceptRemoveSpecialist
          isOpen={acceptRemoveSpecialist}
          onRequestClose={() => {
            setAcceptRemoveSpecialist(false);
            dispatch(getActionList());
          }}
          actionListItem={selectedActionListItem}
        />
      )}
    </CustomContainer>
  );
};

export default Dashboard;
