// status === 0 ? "0 1px 6px 1px grey" : status === 1 ? "0 1px 6px 1px green" : "0 1px 6px 1px red",

import { Clear, Comment, InsertDriveFile } from "@mui/icons-material";
import { Box, Tooltip, Typography } from "@mui/material";
import { StatusType } from "../../assets/type.ts";

const FileInfo = ({ item, handleClicked, handleDelete }) => {
  const germanTimeFormat = new Date(item.date)
    .toLocaleString("de-DE", { timeZone: "UTC", hour12: false })
    .replace(/\//g, ".")
    .replace(", ", " ");

  const backgroundColor =
    item.status === StatusType.CREATED || item.status === StatusType.PENDING
      ? "secondary.light"
      : item.status === StatusType.ACCEPTED
      ? "success.main"
      : "error.main";
  const hasComments = item.comment.length > 0;
  const hasReadableComments = hasComments ? item.comment.filter((item) => item.readable === true).length > 0 : false;

  return (
    <Box
      key={item.id}
      sx={{
        backgroundColor: backgroundColor,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center", // Vertically center the contents
        flex: 1,
        borderRadius: 1,
        p: 1,
        mb: 1,
        gap: 2, // Optional: Adds a gap between each child element within this box
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center", // Vertically center items
          flex: 1,
          gap: 2, // Gap between internal elements
        }}
        onClick={() => handleClicked(item)}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: 1, // Gap between icon and comment
          }}
        >
          <InsertDriveFile />
          {hasReadableComments && (
            <Tooltip
              disableFocusListener
              disableTouchListener
              arrow
              title={
                <Box>
                  {item.comment.map((commentItem, index) => {
                    if (commentItem.readable === true) {
                      return <Typography key={index}>{commentItem.text}</Typography>;
                    }
                    return null;
                  })}
                </Box>
              }
            >
              <Comment />
            </Tooltip>
          )}
        </Box>
        <Typography
          sx={{
            maxWidth: "50%",
            wordWrap: "break-word",
            whiteSpace: "normal",
            flexShrink: 1, // Allows the text to shrink if necessary
            mr: 2, // Margin right to space from next element
          }}
        >
          {item.imageBaseName}
        </Typography>
        <Typography
          sx={{
            flexShrink: 0,
            mr: 2, // Margin right to space from Clear icon
          }}
        >
          {germanTimeFormat}
        </Typography>
      </Box>
      <Clear onClick={() => handleDelete(item)} />
    </Box>
  );
};

export default FileInfo;
