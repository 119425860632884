import React from "react";

import { Container } from "@mui/system";
import { Typography } from "@mui/material";

const Notices = () => {
  return (
    <Container>
      <Typography>Hier kommen die Notizen hin</Typography>
    </Container>
  );
};

export default Notices;
