import { createSlice } from "@reduxjs/toolkit";

const accountSlice = createSlice({
  name: "account",
  initialState: {},
  reducers: {
    accountAdded(state, action) {
      return action.payload;
    },
    accountVerified(state, action) {
      localStorage.setItem("accountState", JSON.stringify({ ...state, verified: true }));
      return { ...state, verified: true };
    },
    logout(state, action) {
      return accountSlice.getInitialState();
    },
  },
});

export const { accountAdded, accountVerified, logout } = accountSlice.actions;
export default accountSlice.reducer;
