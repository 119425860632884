import React, { useState, useEffect } from "react";
// MUI imports
import { Box, Typography } from "@mui/material";
// Component import
import { DisplayFiles } from "components";

const ShowFile = () => {
  const [file, setFile] = useState(null);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const fileIndex = urlParams.get("fileIndex");

    if (fileIndex !== null) {
      const storedFile = localStorage.getItem(`showFile_${fileIndex}`);
      if (storedFile) {
        const fileObject = JSON.parse(storedFile);
        setFile(fileObject);
      }
    }
  }, []); // 🔹 Empty dependency array ensures this runs only once on mount

  return (
    <Box>
      <Typography variant="h5">Falls nicht lädt, bitte Seite neu laden</Typography>
      {file ? <DisplayFiles file={file} /> : <Typography>Lädt...</Typography>}
    </Box>
  );
};

export default ShowFile;
