import React from "react";
import moment from "moment";
import { uniqueId } from "lodash";
import { filesize } from "filesize";
import { useDispatch, useSelector } from "react-redux";
import { Alert, Box, Button, Card, Divider, Snackbar, Tooltip, Typography } from "@mui/material";

import {
  DeleteFile,
  getFacilitySpecificPrequalifications,
  GetSpecificPrequalificationItemList,
  requestPrequalification,
  UploadFile,
} from "api";

import { CustomDataGrid, CustomUpload, DefinedTabs } from "components";
import { ShowFileModal } from "pages/modals";
import { StatusType } from "assets/type.ts";

const PrequalificationTab = ({ facilityId, setPreqModalOpen }) => {
  const dispatch = useDispatch();

  const [selectedPrequalification, setSelectedPrequalification] = React.useState(null);
  const [activeTab, setActiveTab] = React.useState(0);

  const [success, setSuccess] = React.useState(false);
  // const [failure, setFailure] = React.useState(false);

  const [openModal, setModalOpen] = React.useState(false);
  const [selectedFile, setSelectedFile] = React.useState({});

  //error handling

  const [errorAlertOpen, setErrorAlertOpen] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [successAlertOpen, setSuccessAlertOpen] = React.useState(false);
  const [successMessage, setSuccessMessage] = React.useState("");

  const [clicked, setClicked] = React.useState(false);

  const handleChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const facility = useSelector((state) => state.facility.find((item) => item.id === facilityId));
  const prequalification = useSelector((state) => state.prequalification);
  const prequalificationItems = useSelector(
    (state) => state.prequalification.find((item) => item.id === selectedPrequalification?.id)?.prequalificationItem
  );

  const specialistList = useSelector((state) => state.specialist);

  React.useEffect(() => {
    setSuccess(false);
    if (prequalification.length === 0) {
      dispatch(getFacilitySpecificPrequalifications(facilityId)).then(() => {
        setSuccess(true);
      });
    }
    setSuccess(true);
  }, [dispatch, facilityId, prequalification.length]);

  const handlePrequalificationSelect = (rows) => {
    if (rows.length === 0) {
      if (selectedPrequalification) {
        setSelectedPrequalification(null);
      }
      return;
    }
    if (rows.length === 2) rows.shift(); // only one can be selected at a time so it doesn't crash
    const equalPrequalification = prequalification?.filter((item) => item.id.includes(rows));
    if (equalPrequalification[0].accepted === true) {
      dispatch(GetSpecificPrequalificationItemList(equalPrequalification[0].id)).then(() => {
        setSelectedPrequalification(equalPrequalification[0]);
      });
    } else setSelectedPrequalification(equalPrequalification[0]);
  };

  const handleUpload = (files, prequalificationItem) => {
    const uploadedFiles = files?.map((file) => ({
      file,
      id: uniqueId(),
      name: file.name,
      readableSize: filesize(file.size),
      preview: URL.createObjectURL(file),
      progress: 0,
      type: file.type,
      uploaded: false,
      error: false,
      url: null,
      prequalificationItem: prequalificationItem.id,
      prequalificationId: selectedPrequalification?.id,
    }));

    uploadedFiles.forEach((item) => processUpload(item, prequalificationItem));
  };

  const processUpload = (uploadedFile, item) => {
    const data = new FormData();

    data.append("file", uploadedFile.file);
    data.append("name", uploadedFile.name);
    data.append("prequalificationItemId", item.id);
    data.append("prequalificationId", selectedPrequalification?.id);

    dispatch(UploadFile(uploadedFile, selectedPrequalification?.id));
  };

  const handleClicked = (item) => {
    setSelectedFile(item);
    setModalOpen(true);
  };

  const handleDelete = (item) => {
    dispatch(DeleteFile(item, { id: item.id })).then((response) => {
      if (response) {
        setErrorAlertOpen(true);
        setErrorMessage(response.message);
      }
    });
  };

  const prequalificationColumns = [
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      editable: false,
      valueGetter: (params) => {
        switch (params.row.status) {
          case StatusType.CREATED:
            return "in Bearbeitung";
          case StatusType.PENDING:
            return "in Prüfung";
          case StatusType.COMPLETED:
            return "abgeschlossen";
          case StatusType.REJECTED:
            return "abgelehnt";
          case StatusType.UNDER_REVIEW:
            return "zur Überprüfung freigegeben";
          default:
            return "in Bearbeitung";
        }
      },
    },
    {
      field: "ik_number2",
      headerName: "PQ Bestätigungsnummer",
      flex: 0.75,
      editable: false,
      valueGetter: (params) => `${params.row.consecutiveNumber}`,
    },
    {
      field: "ik_number",
      headerName: "IK-Nummer",
      flex: 0.75,
      editable: false,
      valueGetter: (params) => `${params.row.facility[0]?.ik_number}`,
    },
    {
      field: "name",
      headerName: "Name",
      flex: 1.5,
      editable: false,
      valueGetter: (params) => `${params.row.facility[0]?.name}`,
    },
    {
      field: "fullAddress",
      headerName: "Adresse",
      flex: 1,
      editable: false,
      valueGetter: (params) =>
        `${facility.city || ""} ${facility.postcode || ""} / ${facility.street || ""}, ${facility.street_number || ""}`,
    },
    {
      field: "date",
      headerName: "Datum",
      flex: 1,
      editable: false,
      renderCell: (params) => {
        return <Box>{`${moment(params.row.date).locale("de").format("DD.MM.YYYY")}`}</Box>;
      },
    },
  ];

  // Helper function to render the button with tooltip
  const RenderButtonWithTooltip = ({ onClick, buttonText, disabled }) => {
    return (
      <Tooltip
        title={
          disabled
            ? "Sie haben noch keine Fachliche Leitung angelegt, oder bereits eine aktive Präqualifizierung beantragt."
            : ""
        }
        disableHoverListener={!disabled}
      >
        <span>
          <Button sx={{ p: 1, m: 1, textTransform: "none" }} variant="contained" onClick={onClick} disabled={disabled}>
            {buttonText}
          </Button>
        </span>
      </Tooltip>
    );
  };

  // Helper function to render the list of prequalification items
  const RenderPrequalificationItems = () => {
    return (
      <Card sx={{ backgroundColor: "transparent", mt: 2, p: 2 }}>
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <RenderButtonWithTooltip
            onClick={() => {
              setClicked(true);
              dispatch(requestPrequalification(selectedPrequalification.id, facilityId)).then((response) => {
                if (response.error === true) {
                  setErrorMessage(response.message);
                  setErrorAlertOpen(true);
                } else {
                  setSuccessMessage(response.message);
                  setSuccessAlertOpen(true);
                  setSelectedPrequalification(prequalification.find((item) => item.id === prequalification.id));
                }

                setClicked(false);
              });
            }}
            buttonText={
              selectedPrequalification?.status === StatusType.CREATED
                ? "Jetzt Auftrag stellen"
                : selectedPrequalification?.status === StatusType.PENDING ||
                  selectedPrequalification?.status === StatusType.UNDER_REVIEW
                ? "Dokumente Nachreichen"
                : "Abgeschlossen"
            }
          />
        </Box>
        <DefinedTabs value={activeTab} handleChange={handleChange} />
        {prequalificationItems?.map((item) => {
          const shouldRenderItem =
            (item.status !== StatusType.ACCEPTED && activeTab === 0) ||
            (item.status === StatusType.ACCEPTED && activeTab === 1);
          if (!shouldRenderItem) return null;

          return (
            <CustomUpload
              key={item.id}
              titel={item.titel}
              description={item.description}
              specialist={item.specialist}
              status={item.status}
              preqStatus={selectedPrequalification?.status}
              files={item.image}
              comment={item.comment}
              providingFields={item.providing_fields}
              handleUpload={(file) => handleUpload(file, item)}
              handleClicked={(item) => handleClicked(item)}
              handleDelete={handleDelete}
            />
          );
        })}
      </Card>
    );
  };

  // Helper function to render pending state of the prequalification
  const RenderPrequalificationPending = () => {
    return (
      <Card sx={{ backgroundColor: "transparent", mt: 2, p: 2 }}>
        <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
          <Typography variant="h3" sx={{ textAlign: "center" }}>
            Die DGP wird Ihren Antrag in Kürze prüfen.
          </Typography>
          <Typography variant="h4" sx={{ textAlign: "center" }}>
            Sie erhalten zeitnah eine entsprechende Bestätigungsmail.
          </Typography>
        </Box>
      </Card>
    );
  };

  // Helper function to render alert messages
  const RenderAlerts = () => {
    return (
      <>
        <Snackbar
          open={errorAlertOpen}
          autoHideDuration={5000}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          onClose={() => setErrorAlertOpen(false)}
        >
          <Alert variant="filled" severity="error">
            {errorMessage}
          </Alert>
        </Snackbar>
        <Snackbar
          open={successAlertOpen}
          autoHideDuration={5000}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          onClose={() => setSuccessAlertOpen(false)}
        >
          <Alert variant="filled" severity="success">
            {successMessage}
          </Alert>
        </Snackbar>
      </>
    );
  };

  const isDisabled = specialistList.length === 0 || !!prequalification.find((item) => item.active === true);

  // Main return logic for rendering the component
  if (success === false) return <div>Loading...</div>;
  if (specialistList.length === 0 || !prequalification.some((item) => item.active)) {
    return (
      <Card sx={{ p: 2, mt: 2 }}>
        <Divider textAlign="left" sx={{ my: 2 }}>
          <Typography fontWeight="bold">Präqualifizierung</Typography>
        </Divider>
        <Typography sx={{ mb: 2 }}>
          {specialistList.length === 0
            ? "Sie haben noch keine Fachliche Leitung angelegt. Bitte navigieren Sie zum Reiter Fachliche Leitung und erstellen Sie eine."
            : prequalification.length === 0
            ? 'Sie haben noch keine Präqualifizierung beantragt. Sie können eine Präqualifizierung beantragen, indem Sie auf den Knopf "Präqualifizierungsantrag stellen" klicken.'
            : "Ihre letzte Präqualifizierung ist nicht mehr aktiv, bitte beantragen Sie eine neue."}
        </Typography>
        <RenderButtonWithTooltip
          onClick={() => setPreqModalOpen(true)}
          buttonText={"Präqualifizierungsantrag stellen"}
          disabled={clicked || isDisabled}
        />
      </Card>
    );
  } else {
    return (
      <Box>
        <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>
          <RenderButtonWithTooltip
            onClick={() => setPreqModalOpen(true)}
            buttonText={"Präqualifizierungsantrag stellen"}
            disabled={clicked || isDisabled}
          />
        </Box>
        <Card sx={{ backgroundColor: "transparent", mt: 2, p: 2 }}>
          <Divider textAlign="left" sx={{ my: 2 }}>
            <Typography fontWeight="bold">Präqualifizierungen</Typography>
          </Divider>
          <Card>
            <CustomDataGrid
              rows={prequalification}
              columns={prequalificationColumns}
              checkboxSelection
              disableSelectionOnClick
              onSelectionModelChange={(rows) => handlePrequalificationSelect(rows)}
              initialPageSize={10}
            />
          </Card>
        </Card>

        {selectedPrequalification?.accepted === false ? (
          <RenderPrequalificationPending />
        ) : selectedPrequalification ? (
          <RenderPrequalificationItems />
        ) : (
          <></>
        )}

        <ShowFileModal isOpen={openModal} onRequestClose={() => setModalOpen(false)} file={selectedFile} />

        <RenderAlerts />
      </Box>
    );
  }
};

export default PrequalificationTab;
