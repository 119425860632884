import { createSlice } from "@reduxjs/toolkit";

const criteriaCatalogSlice = createSlice({
  name: "criteriaCatalog",
  initialState: [],
  reducers: {
    criteriaCatalogAdded(state, action) {
      return action.payload;
    },
    logoutCriteriaCatalog(state, action) {
      return criteriaCatalogSlice.getInitialState();
    },
  },
});

export const { criteriaCatalogAdded, logoutCriteriaCatalog } = criteriaCatalogSlice.actions;
export default criteriaCatalogSlice.reducer;
