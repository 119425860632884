import { createSlice } from "@reduxjs/toolkit";

const xmlSlice = createSlice({
  name: "xml",
  initialState: [],
  reducers: {
    xmlAdded(state, action) {
      return action.payload;
    },
    xmlUpdateSpecific(state, action) {
      const index = state.findIndex((xml) => xml.id === action.payload.id);
      if (index !== -1) {
        state[index] = action.payload;
      }
    },
    xmlUpdated(state, action) {
      return action.payload;
    },
    logoutXML(state, action) {
      return xmlSlice.getInitialState();
    },
  },
});

export const { xmlAdded, xmlUpdated, xmlUpdateSpecific, logoutXML } = xmlSlice.actions;
export default xmlSlice.reducer;
