import { CollapsableImageCard } from "components";
import { Box } from "@mui/material";

const CollapseItemList = ({ images, process, preqItemIndex }) => {
  return (
    <Box>
      {images?.map((item) => (
        <CollapsableImageCard key={item.id} item={item} prequalification={process} preqItemIndex={preqItemIndex} />
      ))}
    </Box>
  );
};

export default CollapseItemList;
