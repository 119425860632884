import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { uniqueId } from "lodash";
import { filesize } from "filesize";
import { Box, TextField, Typography, Divider, Card } from "@mui/material";
import { Clear, InsertDriveFile } from "@mui/icons-material";

import { StatusType } from "assets/type.ts";

import { DeleteFile } from "api";
import { UploadHistoryFile, AcceptHistoryFileUpload } from "api/history";

import { Uploader, CustomSnackbarAlert, CModal, LabelName } from "components";

const UploadHistoryFilesModal = ({ isOpen, onRequestClose, selectedHistoryItem, selectedFacility }) => {
  const dispatch = useDispatch();

  const [open, setOpen] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [message, setMessage] = React.useState("");

  const historyItem = useSelector((state) => state.history.find((item) => item.id === selectedHistoryItem?.id));

  const handleUpload = (files) => {
    const uploadedFiles = files?.map((file) => ({
      file,
      id: uniqueId(),
      name: file.name,
      readableSize: filesize(file.size),
      preview: URL.createObjectURL(file),
      progress: 0,
      type: file.type,
      uploaded: false,
      error: false,
      url: null,
      historyId: selectedHistoryItem?.id,
    }));

    uploadedFiles.forEach((item) => processUpload(item));
  };

  const processUpload = (uploadedFile) => {
    const data = new FormData();

    data.append("file", uploadedFile.file);
    data.append("name", uploadedFile.name);
    data.append("prequalificationId", selectedFacility.id);

    dispatch(UploadHistoryFile(uploadedFile, selectedHistoryItem?.id));
  };

  const handleDelete = (item) => {
    if (selectedHistoryItem?.status === StatusType.COMPLETED) return;
    dispatch(DeleteFile(item, { historyId: selectedHistoryItem?.id })).then((response) => {
      setOpen(true);
      setSuccess(response.error ? false : true);
      setMessage(response.message);
    });
  };

  return (
    <CModal
      modalLabel="Dateien Hochladen"
      open={isOpen}
      onRequestClose={onRequestClose}
      buttonLabel="Ok - Upload bestätigen"
      backButtonLabel="abbrechen"
      onButtonPressed={() => {
        dispatch(AcceptHistoryFileUpload(selectedHistoryItem?.id));
        onRequestClose();
      }}
      onBackButtonPressed={() => onRequestClose()}
    >
      <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "space-between", p: 2 }}>
        <Divider textAlign="left" sx={{ mt: 2, mb: 2 }}>
          <Typography fontWeight={"bold"}>Historien Daten</Typography>
        </Divider>
        <Box sx={{ display: "flex", flexDirection: "row", mt: 2, mb: 2, flex: 1 }}>
          <LabelName titel="Nachricht" />
          <TextField sx={{ flex: 0.5 }} value={historyItem?.message} />
        </Box>
        <Divider textAlign="left" sx={{ mt: 2, mb: 2 }}>
          <Typography fontWeight={"bold"}>Datei Upload</Typography>
        </Divider>
        <Card sx={{ minWidth: 100, p: 2.25, margin: 1 }}>
          <Box sx={{ mb: 2.25 }}>
            <Box sx={{ mt: 2 }}>
              <Typography variant="h6">Dateien</Typography>
              {historyItem?.images?.length === 0 && (
                <Typography color="text.secondary">{"Keine Dateien vorhanden!"}</Typography>
              )}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "column",
                  flex: 1,
                }}
              >
                {historyItem?.images?.map((item) => {
                  const germanTimeFormat = new Date(item.date)
                    .toLocaleString("de-DE", { timeZone: "UTC", hour12: false })
                    .replace(/\//g, ".")
                    .replace(", ", " ");
                  return (
                    <Box
                      key={item.id}
                      sx={{
                        backgroundColor: "secondary.light",
                        display: "flex",
                        justifyContent: "space-between",
                        flex: 1,
                        borderRadius: 1,
                        p: 1,
                        mb: 1,
                      }}
                    >
                      {/* <Link
                        target={"_blank"}
                        to={"/showFile"}
                        onClick={() => {
                          console.log(item);
                          localStorage.setItem("showFile", JSON.stringify(item));
                        }}
                      > */}
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          flex: 1,
                          border: 1,
                        }}
                        onClick={() => {
                          localStorage.removeItem("showFile");
                          localStorage.setItem("showFile", JSON.stringify(item));
                        }}
                      >
                        <InsertDriveFile />
                        <Typography>{`${item.imageBaseName}`}</Typography>
                        <Typography>{`${germanTimeFormat}`}</Typography>
                      </Box>
                      {/* </Link> */}
                      <Clear onClick={() => handleDelete(item)} />
                    </Box>
                  );
                })}
              </Box>
            </Box>
          </Box>
          <Box>
            <Uploader key={historyItem?.id} onUpload={(file) => handleUpload(file)} />
          </Box>
          <Typography variant="h6" sx={{ mt: 2 }}>
            Unterstützte Dateiformate: Bilder (*.jpg, *.jpeg, *.png, *.heic), Dateien (*.pdf) Email (*.eml, *.msg)
          </Typography>
        </Card>
      </Box>
      <CustomSnackbarAlert open={open} setOpen={setOpen} severity={success ? "success" : "error"} message={message} />
    </CModal>
  );
};

export default UploadHistoryFilesModal;
