import { Box, Modal, Button, Typography } from "@mui/material";
import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";
import React from "react";

const CModal = ({
  children,
  open,
  onClose,
  modalLabel,
  onRequestClose,
  onButtonPressed,
  buttonLabel,
  buttonDisabled,
  backButtonLabel,
  backButtonDisabled,
  onBackButtonPressed,
  style,
}) => {
  const customStyles = {
    position: "absolute",
    top: "5%",
    left: "5%",
    boxShadow: 24,
    bgcolor: "background.paper",
    borderRadius: "10px",
    width: Math.floor((window.innerWidth / 100) * 90), // Ensure no subpixels
    maxHeight: Math.floor((window.innerHeight / 100) * 90),
    overflow: "auto",
    ...style,
  };

  const [clicked, setClicked] = React.useState(false);

  return (
    <Modal open={open} onClose={onClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
      <Box sx={customStyles}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            p: 4,
          }}
        >
          <Box>
            <Typography variant="h4" color="secondary">
              {modalLabel}
            </Typography>
          </Box>
          <Box>
            <CancelPresentationIcon onClick={onRequestClose} color="secondary" fontSize="large" />
          </Box>
        </Box>
        {children}
        <Box
          sx={{
            display: "flex",
            justifyContent: backButtonLabel ? "space-between" : "flex-end",
            p: 4,
          }}
        >
          {backButtonLabel && (
            <Button
              variant="contained"
              disabled={backButtonDisabled || clicked}
              onClick={(e) => {
                setClicked(true);
                onBackButtonPressed(e);
                setClicked(false);
              }}
            >
              {backButtonLabel}
            </Button>
          )}
          {buttonLabel && (
            <Button
              variant="contained"
              sx={{ textTransform: "none" }}
              disabled={buttonDisabled || clicked}
              onClick={(e) => {
                setClicked(true);
                onButtonPressed(e);
                setClicked(false);
              }}
            >
              {buttonLabel}
            </Button>
          )}
        </Box>
      </Box>
    </Modal>
  );
};

export default CModal;
