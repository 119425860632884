import { Box } from "@mui/material";

import { PendingActions, AssignmentTurnedIn, AssignmentLate, Assignment } from "@mui/icons-material";
import { StatusType } from "../../assets/type.ts";

const prequalificationItemColumn = [
  {
    field: "status",
    headerName: "Status",
    flex: 0.075,
    editable: false,
    renderCell: (params) => {
      switch (params.row.status) {
        case StatusType.ACCEPTED:
          return (
            <Box
              sx={{
                display: "flex",
                backgroundColor: "success.main",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
                height: "100%",
              }}
            >
              <AssignmentTurnedIn />
            </Box>
          );
        case StatusType.REJECTED:
          return (
            <Box
              sx={{
                display: "flex",
                backgroundColor: "error.main",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
                height: "100%",
              }}
            >
              <AssignmentLate />
            </Box>
          );
        case StatusType.CANCELED:
          return (
            <Box
              sx={{
                display: "flex",
                backgroundColor: "warning.main",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
                height: "100%",
              }}
            >
              <Assignment />
            </Box>
          );
        default:
          return (
            <Box
              sx={{
                display: "flex",
                backgroundColor: "secondary.light",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
                height: "100%",
              }}
            >
              <PendingActions />
            </Box>
          );
      }
    },
  },
  {
    field: "titel",
    headerName: "Vorraussetzung",
    flex: 0.3,
    editable: false,
    // valueGetter: (params) => `${params.row.titel}`,
  },
  {
    field: "description",
    headerName: "Beschreibung",
    flex: 0.35,
    editable: false,
    // valueGetter: (params) => `${params.row.description}`,
  },

  // {
  //   field: "name",
  //   headerName: "Name",
  //   flex: 1,
  //   editable: false,
  //   valueGetter: (params) => `${params.row.first_name}`,
  // },
  // {
  //   field: "fullAddress",
  //   headerName: "Adresse",
  //   flex: 1,
  //   editable: false,
  //   valueGetter: (params) =>
  //     `${params.row.facility[0].city || ""} ${params.row.facility[0].postcode || ""} / ${
  //       params.row.facility[0].street || ""
  //     }, ${params.row.facility[0].street_number || ""}`,
  // },
  // {
  //   field: "date",
  //   headerName: "Datum",
  //   flex: 1,
  //   editable: false,
  //   valueGetter: (params) => {
  //     return params.row.date.slice(0, 19).replace("T", " ");
  //   },
  // },
];

export default prequalificationItemColumn;
