//React Imports
import React from "react";

//Mui Imports
import { Card, Link, Typography } from "@mui/material";
import { SaveAlt } from "@mui/icons-material";

import { getDownloadLink } from "api";
import { CustomDataGrid, CustomContainer } from "components";

const InventoryList = () => {
  const downloadFile = async (path) => {
    const downloadLink = await getDownloadLink(path);
    window.open(downloadLink, "_blank");
  };

  const inventoryListList = [
    {
      id: 0,
      description: "Anlage zur Fotodokumentation",
      path: "system-files/downloads/DGP_QMH_Prüfungskriterien_Anlage_WC.pdf",
    },
    {
      id: 1,
      description: "Begehungspflichtige Versorgungsbereiche",
      path: "system-files/downloads/begehungsrelevante_VB_Rev_0.2_SP.pdf",
    },
    {
      id: 4,
      description: "Eigenerklärung E03 inkl. Organigramm",
      path: "system-files/downloads/DGP_Eigenerklärung_E03_inkl._Organigramm.pdf",
    },
    {
      id: 5,
      description: "Eigenerklärung E04",
      path: "system-files/downloads/DGP_Eigenerklärung_E04.pdf",
    },
    {
      id: 6,
      description: "Eigenerklärung E05",
      path: "system-files/downloads/DGP_Eigenerklärung_E05.pdf",
    },
    {
      id: 7,
      description: "Eigenerklärung E06",
      path: "system-files/downloads/DGP_Eigenerklärung_E06.pdf",
    },
    {
      id: 8,
      description: "Inventarliste Augenoptik inkl.Kontaktlinsen",
      path: "system-files/downloads/DGP_Inventarliste_Augenoptik.pdf",
    },
    {
      id: 9,
      description: "Inventarliste Haarersatz",
      path: "system-files/downloads/DGP_Inventarliste_Haarersatz.pdf",
    },
    {
      id: 10,
      description: "Inventarliste Höregeräteakustiker",
      path: "system-files/downloads/DGP_Inventarliste_Hörgeräteakustiker.pdf",
    },
    {
      id: 11,
      description: "Inventarliste Kontaktlisten",
      path: "system-files/downloads/DGP_Inventarliste_Kontaktlinsen.pdf",
    },
    {
      id: 12,
      description: "Orthopädie- & Rehatechnik",
      path: "system-files/downloads/DGP_Inventarliste_Orthopädie_&_Rehatechnik.pdf",
    },
    {
      id: 13,
      description: "Schulung in Orientierung",
      path: "system-files/downloads/DGP_Inventarliste_Schulung_in_Orientierung.pdf",
    },
  ];

  const inventoryListColumns = [
    {
      field: "description",
      headerName: "Beschreibung",
      flex: 1,
      editable: false,
    },
    {
      field: "path",
      headerName: "Herunterladen",
      flex: 1,
      editable: false,
      renderCell: ({ row }) => {
        return (
          // <SaveAlt onClick={() => SetRequirements()} />
          <Link onClick={() => downloadFile(row.path)}>
            <SaveAlt />
          </Link>
        );
      },
    },
  ];
  return (
    <CustomContainer>
      <Card
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          p: 2,
          boxShadow: 2,
        }}
      >
        <Typography>Eigenerklärung und Inventarlisten</Typography>
      </Card>
      <Card sx={{ mt: 5, display: "flex" }}>
        <CustomDataGrid rows={inventoryListList} columns={inventoryListColumns} />
      </Card>
    </CustomContainer>
  );
};

export default InventoryList;
