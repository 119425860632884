import { Box, Typography } from "@mui/material";
import { AssignmentTurnedIn, AttachFile, Check, Close, AlternateEmail, FileOpen } from "@mui/icons-material";
import moment from "moment";
import { Link } from "react-router-dom";
import { HistoryType, StatusType } from "../../assets/type.ts";

const historyColumns = [
  {
    field: "status",
    headerName: "Status",
    type: "singleSelect",
    flex: 0.15,
    editable: true,
    valueOptions: [
      {
        value: StatusType.PENDING,
        label: (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              height: "100%",
            }}
          >
            <Close />
          </Box>
        ),
      },
      {
        value: StatusType.COMPLETED,
        label: (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              height: "100%",
            }}
          >
            <Check />
          </Box>
        ),
      },
    ],
    preProcessEditCellProps: (params) => {
      const isPaidProps = params.row.status;
      const hasError = isPaidProps.value && !params.props.value;
      return { ...params.props, error: hasError };
    },
    renderCell: (params) => {
      switch (params.row.status) {
        case StatusType.PENDING:
          return (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
                height: "100%",
              }}
            >
              <Close />
            </Box>
          );
        case StatusType.COMPLETED:
          return (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
                height: "100%",
              }}
            >
              <Check />
            </Box>
          );
        default:
          return <></>;
      }
    },
  },
  {
    field: "message",
    headerName: "Nachricht",
    flex: 0.75,
    editable: false,
  },
  {
    field: "type",
    headerName: "Typ",
    flex: 0.1,
    editable: false,
    renderCell: (params) => {
      switch (params.row.type) {
        case HistoryType.WITHOUTEMAILANDATTACHMENTS:
          return (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
                height: "100%",
              }}
            >
              <AssignmentTurnedIn />
            </Box>
          );
        case HistoryType.WITHATTACHMENTS:
          return (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
                height: "100%",
              }}
            >
              <Link
                to={"#"}
                onClick={(e) => {
                  e.preventDefault(); // Prevent default navigation

                  const files = params.row.images.filter((item) => !item.imageType.includes("message"));
                  if (files.length > 0) {
                    // 🔹 Remove old stored showFile keys before adding new ones
                    Object.keys(localStorage).forEach((key) => {
                      if (key.startsWith("showFile_")) {
                        localStorage.removeItem(key);
                      }
                    });

                    files.forEach((file, index) => {
                      console.log(file, index);
                      localStorage.setItem(`showFile_${index}`, JSON.stringify(file));
                      setTimeout(() => {
                        window.open(`/showFile?fileIndex=${index}`, "_blank");
                      }, index * 300);
                    });
                  }
                }}
              >
                <AttachFile />
              </Link>
            </Box>
          );
        case HistoryType.WITHEMAIL:
          return (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
                height: "100%",
              }}
            >
              <Box
                onClick={() => {
                  const email = params.row.images.filter((item) => item.imageType.includes("message"));

                  if (email.length > 0) {
                    // Trigger download by navigating to the .eml URL
                    window.location.href = email[0].imageUrl;
                  } else {
                    console.error("No .eml file found for this record.");
                  }
                }}
              >
                <AlternateEmail />
              </Box>
            </Box>
          );

        case HistoryType.WITHATTACHMENTSANDEMAIL:
          return (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
                height: "100%",
              }}
            >
              {/* <Link
                onClick={() => {
                  const email = params.row.images.filter((item) => item.imageType.includes("message"));
                  window.open(email[0].imageUrl, "_blank");
                }}
              > */}
              <Box
                onClick={() => {
                  const email = params.row.images.filter((item) => item.imageType.includes("message"));

                  if (email.length > 0) {
                    // Trigger download by navigating to the .eml URL
                    window.location.href = email[0].imageUrl;
                  } else {
                    console.error("No .eml file found for this record.");
                  }
                }}
              >
                <AlternateEmail />
              </Box>
              {/*</Link>*/}
              <Link
                to={"#"}
                onClick={(e) => {
                  e.preventDefault(); // Prevent default navigation

                  const files = params.row.images.filter((item) => !item.imageType.includes("message"));
                  if (files.length > 0) {
                    // 🔹 Remove old stored showFile keys before adding new ones
                    Object.keys(localStorage).forEach((key) => {
                      if (key.startsWith("showFile_")) {
                        localStorage.removeItem(key);
                      }
                    });

                    files.forEach((file, index) => {
                      console.log(file, index);
                      localStorage.setItem(`showFile_${index}`, JSON.stringify(file));
                      setTimeout(() => {
                        window.open(`/showFile?fileIndex=${index}`, "_blank");
                      }, index * 300);
                    });
                  }
                }}
              >
                <AttachFile />
              </Link>
            </Box>
          );
        case HistoryType.WITHXMLFILE:
          return (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
                height: "100%",
              }}
            >
              <Link
                to={"#"}
                onClick={(e) => {
                  e.preventDefault(); // Prevent default navigation

                  const files = params.row.xmlFiles;
                  if (files.length > 0) {
                    // 🔹 Remove old stored showFile keys before adding new ones
                    Object.keys(localStorage).forEach((key) => {
                      if (key.startsWith("showFile_")) {
                        localStorage.removeItem(key);
                      }
                    });

                    files.forEach((file, index) => {
                      console.log(file, index);
                      localStorage.setItem(`showFile_${index}`, JSON.stringify(file));
                      setTimeout(() => {
                        window.open(`/showFile?fileIndex=${index}`, "_blank");
                      }, index * 300);
                    });
                  }
                }}
              >
                <FileOpen />
              </Link>
            </Box>
          );
        default:
          return (
            <Box>
              <Typography>{params.row.type}</Typography>
            </Box>
          );
      }
    },
  },
  {
    field: "date",
    headerName: "Datum",
    flex: 0.25,
    editable: false,
    renderCell: (params) => {
      return <Box>{`${moment(params.row.date).locale("de").format("D.M.YYYY-HH:mm:ss")}`}</Box>;
    },
  },
  {
    field: "due_date",
    headerName: "Ablauf Datum",
    flex: 0.2,
    editable: false,
    renderCell: (params) => {
      if (params.row.due_date) return <Box>{`${moment(params.row.due_date).locale("de").format("DD.MM.YYYY")}`}</Box>;
    },
  },
  {
    field: "user",
    headerName: "Benutzer",
    flex: 0.2,
    editable: false,
    renderCell: (params) => {
      if (params.row.account.length !== 0) {
        return <Box>{params.row.account[0]?.firstName + " " + params.row.account[0]?.lastName}</Box>;
      } else return <Box>System</Box>;
    },
  },
  // {
  //   field: "facility",
  //   headerName: "FirmenName",
  //   flex: 1,
  //   editable: false,
  //   valueGetter: (params) => `${params.row.facility[0]?.name} `,
  // },
];

export default historyColumns;
