import * as React from "react";
import { useNavigate } from "react-router";

import {
  Button,
  TextField,
  Checkbox,
  Box,
  Typography,
  Container,
  Card,
  Alert,
  IconButton,
  InputAdornment,
  Snackbar,
  Link,
  CssBaseline,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Divider,
} from "@mui/material";

import {
  Person,
  BusinessCenter,
  Handshake,
  CreditCard,
  Password,
  LocalPhone,
  Mail,
  AccountBalance,
  Shuffle,
  Business,
  Visibility,
  VisibilityOff,
  Fax,
} from "@mui/icons-material";
import { Step, StepLabel, Stepper } from "@mui/material";

import { Register, checkCompanyNameExist, checkEmailExist } from "api";
//REGEX
import EmailRegex from "utils/emailRegex";
import { isValidIBAN, isValidBIC } from "ibantools";

import { ExtendableTextField, CustomLoadingIndicator, ColorlibConnector, ColorlibStepIconRoot } from "components";

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {"Copyright © "}
      <Link color="inherit" href="https://www.dgp-gmbh.de">
        DGP
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

function StepIcon(props) {
  const { active, completed, className } = props;

  const icons = {
    1: <Person />,
    2: <BusinessCenter />,
    3: <Handshake />,
    4: <CreditCard />,
    5: <Password />,
  };

  return (
    <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}

export default function SignUp() {
  const steps = [
    "Benutzerdaten",
    "Unternehmensdaten",
    "Vereinbarung",
    "Bankverbindung einrichten",
    "Passwort festlegen",
  ];
  const navigate = useNavigate();

  //Button Values

  const [buttonLeft, setButtonLeft] = React.useState("Zurück");
  const [buttonRight, setButtonRight] = React.useState("Weiter");

  const [open, setOpen] = React.useState(false);
  const [openSelect, setOpenSelect] = React.useState(false);
  const [openEmailExist, setOpenEmailExist] = React.useState(false);
  const [openCompanyNameExist, setOpenCompanyNameExist] = React.useState(false);
  const [companyErrorMessage, setCompanyErrorMessage] = React.useState("");
  const [openPasswordError, setOpenPasswordError] = React.useState(false);

  const [activeStep, setActiveStep] = React.useState(0);
  const [textFields, setTextFields] = React.useState([{ id: 1, value: "" }]);
  const [errorIndicator, setErrorIndicator] = React.useState(false);

  //PAGE UserData(0)
  const [gender, setGender] = React.useState(0);
  const [first_name, setFirstName] = React.useState("");
  const [last_name, setLastName] = React.useState("");
  const [phone_number, setPhoneNumber] = React.useState("");
  const [faxNumber, setFaxNumber] = React.useState("");
  const [email, setEmail] = React.useState("");

  //PAGE CompanyData(1)

  const [ik_number, setIKNumber] = React.useState("");
  const [company_name, setCompanyName] = React.useState("");
  const [extra, setExtra] = React.useState("");
  const [street, setStreet] = React.useState("");
  const [post_code, setPostCode] = React.useState("");
  const [city, setCity] = React.useState("");
  const [street_number, setStreetNumber] = React.useState("");

  //PAGE Vereinbarungen(2)
  const [lastSchrift, setLastSchrift] = React.useState(false);
  const [acceptDataSecurity, setAcceptDataSecurity] = React.useState(false);
  const [certification, setCertification] = React.useState(false);
  const [authorized, setAuthorized] = React.useState(false);

  //PAGE Payment(3)
  const [creditInstituteName, setCreditInstituteName] = React.useState("");
  const [IBAN, setIBAN] = React.useState("");
  const ibanRegex = /^[A-Z]{2}\d{2}[A-Z0-9]{1,30}$/;
  const [IBANError, setIBANError] = React.useState(false);
  const [BIC, setBIC] = React.useState("");
  const bicRegex = /^[A-Z]{6}[A-Z2-9]{2}[A-NP-Z0-9]{3}$/;
  const [BICError, setBICError] = React.useState(false);
  const [differentBillingAddress, setDifferentBillingAddress] = React.useState(false);
  const [billing_email, setBillingEmail] = React.useState("");
  const [billing_street, setBillingStreet] = React.useState("");
  const [billing_post_code, setBillingPostCode] = React.useState("");
  const [billing_city, setBillingCity] = React.useState("");
  const [billing_street_number, setBillingStreetNumber] = React.useState("");

  //PAGE Passwort(4)
  const [showPassword, setShowPassword] = React.useState(false);
  const [showPassword2, setShowPassword2] = React.useState(false);
  const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
  const [password, setPassword] = React.useState("");
  const [passwordError, setPasswordError] = React.useState(false);
  const [password2, setPassword2] = React.useState("");
  const [password2Error, setPassword2Error] = React.useState(false);
  const [unequalPasswordError, setUnequalPasswordError] = React.useState(false);
  const [openSignUpSuccessMessage, setOpenSignUpSuccessMessage] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const handleClick = async (value) => {
    switch (activeStep) {
      case 0: {
        if (!value) {
          navigate("/");
        } else {
          if (!email || !phone_number || !first_name || !last_name) {
            setErrorIndicator(true);
            break;
          }
          //API CALL
          const emailExist = await checkEmailExist(email);
          console.log(emailExist);
          if (emailExist.error) {
            setOpenEmailExist(true);
            break;
          }
          if (!!first_name && !!last_name && EmailRegex(email) && !!phone_number) {
            //Activate NextButton
            setButtonLeft("Zurück");
            setActiveStep(activeStep + 1);
          }
        }
        break;
      }
      case 1: {
        if (!value) {
          //Error Message
          setActiveStep(activeStep - 1);
          setButtonLeft("Zum Login zurückkehren");
        } else {
          if (!!ik_number && !!company_name && !!street && street_number && !!post_code && !!city) {
            const nameInUse = await checkCompanyNameExist(company_name, ik_number);
            console.log(nameInUse);
            if (nameInUse.error) {
              setCompanyErrorMessage(nameInUse?.message);
              setOpenCompanyNameExist(true);
              break;
            } else {
              setActiveStep(activeStep + 1);
            }
          }
        }
        break;
      }
      case 2: {
        if (!value) {
          setActiveStep(activeStep - 1);
        } else {
          if (!lastSchrift || !acceptDataSecurity || !certification || !authorized) {
            setOpenSelect(true);
          } else {
            setActiveStep(activeStep + 1);
          }
        }
        break;
      }
      case 3: {
        if (!value) {
          setActiveStep(activeStep - 1);
        } else {
          //CHECK IBAN BIC
          const isBICValid = isValidBIC(BIC.replace(/\s+/g, "").trim());
          const isIBANValid = isValidIBAN(IBAN.replace(/\s+/g, "").trim());
          if (isBICValid && isIBANValid) {
            setActiveStep(activeStep + 1);
            setButtonRight("Registrierung abschliessen");
          } else setOpen(true);
        }
        break;
      }
      case 4: {
        if (!value) {
          setActiveStep(activeStep - 1);
          setButtonRight("Weiter");
        } else {
          if (passwordRegex.test(password) && passwordRegex.test(password2) && !unequalPasswordError)
            //Fertigstellen CREATE ACCOUNT - CREATE COMPANY - CREATE FACILITY
            handleSubmit();
          else setOpenPasswordError(true);
        }
        break;
      }
      default:
        break;
    }
  };

  //register
  const handleSubmit = async () => {
    setLoading(true);
    const Account = {
      firstName: first_name,
      lastName: last_name,
      email: email,
      password: password,
      gender: gender,
    };
    const Company = {
      name: company_name,
      ik_number: ik_number,
      extraIKNumbers: textFields.map((field) => field.value).join(","),
      street: street,
      street_number: street_number,
      extra_information: extra,
      postcode: post_code,
      city: city,
      creditInstituteName: creditInstituteName,
      BIC: BIC.replace(/\s+/g, "").trim(),
      IBAN: IBAN.replace(/\s+/g, "").trim(),
      phone_number: phone_number,
      fax_number: faxNumber,
      email: email,
      differentBillingAddress: differentBillingAddress,
      billing_city: billing_city,
      billing_email: billing_email,
      billing_post_code: billing_post_code,
      billing_street: billing_street,
      billing_street_number: billing_street_number,
    };
    const response = await Register(Account, Company);

    if (!response.error) {
      setTimeout(() => {
        setLoading(false);
        navigate("/");
      }, 2500);
      setOpenSignUpSuccessMessage(true);
    } else alert("Fehler");
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleBankDetails = () => {
    if (activeStep !== 3) return false;
    return activeStep === 3 && (IBAN === "" || BIC === "");
  };

  return (
    <Container component="main" maxWidth="md">
      <CssBaseline />
      <Card
        sx={{
          mt: 8,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          p: 10,
          boxShadow: 3,
        }}
        // onKeyDown={(event) => {
        //   console.log(event.key);
        //   // if (event.key === "Enter") handleClick(true);
        // }}
      >
        <Box sx={{ mb: 5 }}>
          <Stepper activeStep={activeStep} alternativeLabel connector={<ColorlibConnector />}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel StepIconComponent={StepIcon}>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          {activeStep === 0 && (
            <>
              <Divider />
              <Box
                sx={{
                  mt: 5,
                  width: "100%",
                  display: "flex",
                  flex: 1,
                  flexDirection: "column",
                  alignContent: "flex-start",
                }}
              >
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Anrede</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={gender}
                    label="Age"
                    onChange={(e) => setGender(e.target.value)}
                  >
                    <MenuItem value={0}>Herr</MenuItem>
                    <MenuItem value={1}>Frau</MenuItem>
                    <MenuItem value={2}>* (divers)</MenuItem>
                  </Select>
                </FormControl>
                <Box sx={{ display: "flex", flexDirection: "row", flex: 1, justifyContent: "space-between" }}>
                  <Box sx={{ flex: 0.5, mt: 2 }}>
                    <TextField
                      sx={{ width: "80%" }}
                      label="Vorname"
                      value={first_name}
                      onChange={(e) => setFirstName(e.target.value)}
                      error={errorIndicator}
                      helperText={errorIndicator && !first_name ? "Dies ist ein Pflichtfeld" : ""}
                    />
                  </Box>
                  <Box sx={{ flex: 0.5, mt: 2 }}>
                    <TextField
                      sx={{ width: "80%" }}
                      label="Nachname"
                      value={last_name}
                      onChange={(e) => setLastName(e.target.value)}
                      error={errorIndicator}
                      helperText={errorIndicator && !last_name ? "Dies ist ein Pflichtfeld" : ""}
                    />
                  </Box>
                </Box>
                <TextField
                  sx={{ mt: 2 }}
                  label="Telefon"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LocalPhone />
                      </InputAdornment>
                    ),
                  }}
                  value={phone_number}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  error={errorIndicator}
                  helperText={errorIndicator && !phone_number ? "Dies ist ein Pflichtfeld" : ""}
                />
                <TextField
                  sx={{ mt: 2 }}
                  label="Fax"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Fax />
                      </InputAdornment>
                    ),
                  }}
                  value={faxNumber}
                  onChange={(e) => setFaxNumber(e.target.value)}
                />
                <TextField
                  sx={{ mt: 2 }}
                  label="E-Mail-Adresse"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Mail />
                      </InputAdornment>
                    ),
                  }}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  error={errorIndicator}
                  helperText={errorIndicator && !email ? "Dies ist ein Pflichtfeld" : ""}
                />
              </Box>
            </>
          )}
          {activeStep === 1 && (
            <>
              <Divider />
              <Box
                sx={{
                  mt: 5,
                  width: "100%",
                  display: "flex",
                  flex: 1,
                  flexDirection: "column",
                  alignContent: "flex-start",
                }}
              >
                <Box sx={{ display: "flex", flexDirection: "row", flex: 1, justifyContent: "space-between" }}>
                  <Box sx={{ flex: 0.5, mt: 2, mr: 0.04 }}>
                    <TextField
                      sx={{ mt: 2, width: "100%" }}
                      label="IK-Nummer"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <AccountBalance />
                          </InputAdornment>
                        ),
                      }}
                      value={ik_number}
                      onChange={(e) => setIKNumber(e.target.value)}
                    />
                  </Box>
                  <Box sx={{ flex: 0.5, mt: 2 }}>
                    <Button
                      sx={{ mt: 2, minHeight: 56, width: "100%" }}
                      variant="contained"
                      startIcon={<Shuffle />}
                      onClick={() => {
                        const randomValue = `t${Math.floor(Math.random() * 1000000000)}`;
                        setIKNumber(randomValue);
                      }}
                    >
                      Temporäre IK-Nummer
                    </Button>
                  </Box>
                </Box>
                <ExtendableTextField textFields={textFields} setTextFields={setTextFields} />
                <TextField
                  sx={{ mt: 2 }}
                  label="Name der Firma"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Business />
                      </InputAdornment>
                    ),
                  }}
                  value={company_name}
                  onChange={(e) => setCompanyName(e.target.value)}
                />
                <TextField sx={{ mt: 2 }} label="Zusatz" value={extra} onChange={(e) => setExtra(e.target.value)} />
                <Box sx={{ display: "flex", flex: 1, justifyContent: "space-between" }}>
                  <TextField
                    sx={{ mt: 2, display: "flex", flex: 0.6 }}
                    label="Straße"
                    value={street}
                    onChange={(e) => setStreet(e.target.value)}
                  />
                  <TextField
                    sx={{ mt: 2, display: "flex", flex: 0.3 }}
                    label="Hausnummer"
                    value={street_number}
                    onChange={(e) => setStreetNumber(e.target.value)}
                  />
                </Box>
                <Box sx={{ display: "flex", flexDirection: "row", flex: 1, justifyContent: "space-between" }}>
                  <TextField
                    sx={{ mt: 2, display: "flex", flex: 0.3 }}
                    label="Postleitzahl"
                    value={post_code}
                    onChange={(e) => setPostCode(e.target.value)}
                  />
                  <TextField
                    sx={{ mt: 2, display: "flex", flex: 0.6 }}
                    label="Ort"
                    value={city}
                    onChange={(e) => setCity(e.target.value)}
                  />
                </Box>
              </Box>
            </>
          )}
          {activeStep === 2 && (
            <>
              <Divider />
              <Box sx={{ mt: 5 }}>
                <Box sx={{ display: "flex", flexDirection: "row", mb: 2, alignItems: "center" }}>
                  <Checkbox
                    name="lastschrift"
                    color="primary"
                    checked={lastSchrift}
                    onChange={() => setLastSchrift(!lastSchrift)}
                  />
                  <Typography>Ich stimme dem Lastschrifteinzugsverfahren zu.</Typography>
                </Box>
                <Box sx={{ display: "flex", flexDirection: "row", mb: 2, alignItems: "center" }}>
                  <Checkbox
                    color="primary"
                    checked={acceptDataSecurity}
                    onClick={() => setAcceptDataSecurity(!acceptDataSecurity)}
                  />
                  <Typography>Ich akzeptiere die</Typography>
                  <Link
                    href="https://www.dgp-gmbh.de/datenschutz/"
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{ ml: 1 }}
                  >
                    Datenschutzerklärung.
                  </Link>
                </Box>
                <Box sx={{ display: "flex", flexDirection: "row", mb: 2, alignItems: "center" }}>
                  <Checkbox color="primary" checked={certification} onClick={() => setCertification(!certification)} />
                  <Typography>Ich akzeptiere die</Typography>
                  <Link
                    href="https://www.dgp-gmbh.de/zertifizierungsvereinbarung/"
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{ ml: 1 }}
                  >
                    Zertifizierungsvereinbarung.
                  </Link>
                </Box>
                <Box sx={{ display: "flex", flexDirection: "row", mb: 2, alignItems: "center" }}>
                  <Checkbox color="primary" checked={authorized} onClick={() => setAuthorized(!authorized)} />
                  <Typography> Ich bestätige, dass ich zum Zeitpunkt der Antragstellung bevollmächtigt bin.</Typography>
                </Box>
              </Box>
            </>
          )}

          {activeStep === 3 && (
            <>
              <Divider />
              <Box sx={{ mt: 5, display: "flex", flexDirection: "column" }}>
                <TextField
                  label="Name des Kreditinstitutes (Optional)"
                  value={creditInstituteName}
                  onChange={(e) => setCreditInstituteName(e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Business />
                      </InputAdornment>
                    ),
                  }}
                />
                <Box sx={{ display: "flex", flexDirection: "row", flex: 1, justifyContent: "space-between" }}>
                  <Box sx={{ flex: 0.5, mt: 2 }}>
                    <TextField
                      sx={{ width: "90%" }}
                      label="IBAN"
                      value={IBAN}
                      onChange={(event) => {
                        const inputValue = event.target.value;
                        const trimmedValue = inputValue.replace(/\s+/g, "").trim();
                        setIBAN(inputValue.toUpperCase());

                        // Validate the IBAN against the regex pattern
                        setIBANError(!isValidIBAN(trimmedValue));
                      }}
                      error={IBANError}
                      helperText={IBANError ? "Bitte geben Sie eine gültige IBAN ein" : ""}
                      InputProps={{
                        inputProps: {
                          pattern: ibanRegex, // Assign the regex pattern
                        },
                      }}
                    />
                  </Box>
                  <Box sx={{ flex: 0.5, mt: 2 }}>
                    <TextField
                      label="BIC"
                      sx={{ width: "90%" }}
                      value={BIC}
                      onChange={(event) => {
                        const inputValue = event.target.value;
                        const trimmedValue = inputValue.replace(/\s+/g, "").trim();
                        setBIC(inputValue.toUpperCase());

                        // Validate the BIC against the regex pattern
                        setBICError(!isValidBIC(trimmedValue));
                      }}
                      error={BICError}
                      helperText={BICError ? "Bitte geben Sie eine gültige BIC ein" : ""}
                      InputProps={{
                        inputProps: {
                          pattern: bicRegex, // Assign the regex pattern
                        },
                      }}
                    />
                  </Box>
                </Box>
                <Box sx={{ display: "flex", flex: 1, alignItems: "center", mt: 2 }}>
                  <Checkbox
                    color="primary"
                    checked={differentBillingAddress}
                    onClick={() => setDifferentBillingAddress(!differentBillingAddress)}
                  />
                  <Typography>Von der Betriebsanschrift abweichende Rechnungsadresse</Typography>
                </Box>
                {differentBillingAddress === true && (
                  <>
                    <Divider textAlign="left" sx={{ my: 2 }}>
                      <Typography fontWeight={"bold"}>Rechnungsaddresse</Typography>
                    </Divider>
                    <Box sx={{ display: "flex", flex: 1, justifyContent: "space-between" }}>
                      <TextField
                        sx={{ mt: 2, display: "flex", flex: 0.6 }}
                        label="Rechnungs-Email"
                        value={billing_email}
                        onChange={(e) => setBillingEmail(e.target.value)}
                      />
                    </Box>
                    <Box sx={{ display: "flex", flex: 1, justifyContent: "space-between" }}>
                      <TextField
                        sx={{ mt: 2, display: "flex", flex: 0.6 }}
                        label="Straße"
                        value={billing_street}
                        onChange={(e) => setBillingStreet(e.target.value)}
                      />
                      <TextField
                        sx={{ mt: 2, display: "flex", flex: 0.3 }}
                        label="Hausnummer"
                        value={billing_street_number}
                        onChange={(e) => setBillingStreetNumber(e.target.value)}
                      />
                    </Box>
                    <Box sx={{ display: "flex", flexDirection: "row", flex: 1, justifyContent: "space-between" }}>
                      <TextField
                        sx={{ mt: 2, display: "flex", flex: 0.3 }}
                        label="Postleitzahl"
                        value={billing_post_code}
                        onChange={(e) => setBillingPostCode(e.target.value)}
                      />
                      <TextField
                        sx={{ mt: 2, display: "flex", flex: 0.6 }}
                        label="Ort"
                        value={billing_city}
                        onChange={(e) => setBillingCity(e.target.value)}
                      />
                    </Box>
                  </>
                )}
              </Box>
            </>
          )}
          {activeStep === 4 && (
            <>
              <Divider />

              <Box sx={{ display: "flex", flexDirection: "column", mt: 5 }}>
                <TextField
                  label="Passwort"
                  type={showPassword ? "text" : "password"}
                  value={password}
                  onChange={(event) => {
                    const inputValue = event.target.value;
                    setPassword(inputValue);

                    // Validate the password against the regex pattern
                    setPasswordError(!passwordRegex.test(inputValue));
                    setUnequalPasswordError(inputValue !== password2);
                  }}
                  error={passwordError}
                  helperText={
                    passwordError
                      ? "Das Passwort muss aus mindestens 8 Zeichen bestehen, einem Großbuchstaben, einem Kleinbuchstaben, einer Zahl und einem Sonderzeichen"
                      : unequalPasswordError
                      ? "Die Passwörter sind unterschiedlich"
                      : ""
                  }
                  InputProps={{
                    inputProps: {
                      pattern: passwordRegex, // Assign the regex pattern
                    },
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setShowPassword(!showPassword)}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <TextField
                  label="Passwort wiederholen"
                  type={showPassword2 ? "text" : "password"}
                  value={password2}
                  sx={{ mt: 3 }}
                  onChange={(event) => {
                    const inputValue = event.target.value;
                    setPassword2(inputValue);
                    // Validate the password against the regex pattern
                    setPassword2Error(!passwordRegex.test(inputValue));
                    setUnequalPasswordError(password !== inputValue);
                  }}
                  error={password2Error}
                  helperText={
                    password2Error
                      ? "Das Passwort muss aus mindestens 8 Zeichen bestehen, einem Großbuchstaben, einem Kleinbuchstaben, einer Zahl und einem Sonderzeichen"
                      : unequalPasswordError
                      ? "Die Passwörter sind unterschiedlich"
                      : ""
                  }
                  InputProps={{
                    inputProps: {
                      pattern: passwordRegex, // Assign the regex pattern
                    },
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setShowPassword2(!showPassword2)}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword2 ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
            </>
          )}
        </Box>
        <Box sx={{ display: "flex", flex: 1, justifyContent: "space-between" }}>
          <Button sx={{ flex: 0.3 }} size="small" variant="contained" onClick={() => handleClick(false)}>
            {buttonLeft}
          </Button>
          <Button
            sx={{ flex: 0.3, textTransform: "none" }}
            size="small"
            variant="contained"
            primary
            disabled={handleBankDetails()}
            onClick={() => handleClick(true)}
          >
            {buttonRight}
          </Button>
        </Box>
      </Card>
      <Copyright sx={{ mt: 5 }} />
      <Snackbar open={open} autoHideDuration={5000} onClose={() => setOpen(false)}>
        <Alert variant="filled" severity="error">
          Bitte füllen Sie die vorgegebenen Felder aus!
        </Alert>
      </Snackbar>
      <Snackbar open={openSelect} autoHideDuration={5000} onClose={() => setOpenSelect(false)}>
        <Alert variant="filled" severity="error">
          Bitte bestätigen Sie alle Felder um fortzufahren.
        </Alert>
      </Snackbar>
      <Snackbar open={openEmailExist} autoHideDuration={5000} onClose={() => setOpenEmailExist(false)}>
        <Alert variant="filled" severity="error">
          Die von Ihnen eingegebene Email Addresse gehört bereits zu einem Aktiven Account
        </Alert>
      </Snackbar>
      <Snackbar open={openCompanyNameExist} autoHideDuration={5000} onClose={() => setOpenCompanyNameExist(false)}>
        <Alert variant="filled" severity="error">
          {companyErrorMessage}
        </Alert>
      </Snackbar>
      <Snackbar open={openPasswordError} autoHideDuration={5000} onClose={() => setOpenPasswordError(false)}>
        <Alert variant="filled" severity="error">
          Das von Ihnen eingegebene Password ist entweder ungültig oder mit dem Password nicht identisch
        </Alert>
      </Snackbar>
      <Snackbar
        open={openSignUpSuccessMessage}
        autoHideDuration={5000}
        onClose={() => setOpenSignUpSuccessMessage(false)}
      >
        <Alert variant="filled" severity="success">
          Die Registrierung war erfolgreich!
        </Alert>
      </Snackbar>
      <CustomLoadingIndicator open={loading} message={"Sie werden jeden Moment weitergeleitet..."} />
    </Container>
  );
}
