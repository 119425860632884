//Mui imports
import { Dashboard, AdminPanelSettings, Assessment, GridOn, DirectionsCar } from "@mui/icons-material";

// ----------------------------------------------------------------------

const navConfigSuper = [
  {
    title: "Dashboard",
    path: "/dashboard/app",
    icon: <Dashboard />,
  },
  {
    title: "Präqualifizierung",
    path: "/dashboard/evaluation",
    icon: <AdminPanelSettings />,
  },
  {
    title: "Bewertung",
    path: "/dashboard/Assessment",
    icon: <Assessment />,
    disabled: true,
  },
  {
    title: "Stammdaten",
    path: "/dashboard/stammdaten",
    icon: <GridOn />,
  },
  {
    title: "Begehungen",
    path: "/dashboard/walker",
    icon: <DirectionsCar />,
  },
  {
    title: "Admin Panel",
    path: "/dashboard/supervisor",
    icon: <AdminPanelSettings />,
  },
];

export default navConfigSuper;
