import { createSlice } from "@reduxjs/toolkit";

const assessmentSlice = createSlice({
  name: "assessment",
  initialState: [],
  reducers: {
    assessmentAdded(state, action) {
      return action.payload;
    },
    assessmentSpecificAdded(state, action) {
      const assessment = action.payload;
      if (state.length === 0) {
        console.log([action.payload]);
        return [action.payload];
      } else {
        const assessmentIndex = state.findIndex((item) => item.id === assessment.id);
        if (assessmentIndex > -1) {
          state[assessmentIndex] = assessment;
        } else {
          state.push(assessment);
        }
      }
    },

    assessmentSend(state, action) {
      const assessment = action.payload;
      const assessmentIndex = state.findIndex((item) => item.id === assessment.id);
      state[assessmentIndex] = assessment;
    },

    assessmentItemImageUpdate(state, action) {
      const image = action.payload;

      console.log(image);

      const assessmentId = image.prequalificationItem[0].assessment[0].id;

      const updatedAssessmentIndex = state.findIndex((item) => item.id === assessmentId);
      const assessmentItemIndex = state[updatedAssessmentIndex].prequalificationItem.findIndex(
        (item) => item.id === image.prequalificationItem[0].id
      );
      const imageIndex = state[updatedAssessmentIndex].prequalificationItem[assessmentItemIndex].image.findIndex(
        (item) => item.id === image.id
      );

      delete image.assessment;
      delete image.prequalification;

      state[updatedAssessmentIndex].prequalificationItem[assessmentItemIndex].image[imageIndex] = image;
    },

    assessmentItemUpdate(state, action) {
      const preqItem = action.payload;
      const assessmentId = preqItem.assessment[0].id;
      const updatedAssessmentIndex = state.findIndex((item) => item.id === assessmentId);
      const toChange = state[updatedAssessmentIndex].prequalificationItem.findIndex((item) => item.id === preqItem.id);
      state[updatedAssessmentIndex].prequalificationItem[toChange].status = preqItem.status;
      state[updatedAssessmentIndex].prequalificationItem[toChange].comment = preqItem.comment;
    },

    assessmentPreqItemUpdate(state, action) {
      const preqItem = action.payload;
      const assessmentId = preqItem.assessment[0].id;
      const updatedAssessmentIndex = state.findIndex((item) => item.id === assessmentId);
      const toChange = state[updatedAssessmentIndex].prequalificationItem.findIndex((item) => item.id === preqItem.id);
      state[updatedAssessmentIndex].prequalificationItem[toChange] = preqItem;
    },
    assessmentMoveImage(state, action) {
      const image = action.payload.payload;
      const destinationId = action.payload.destinationId;
      const preqItemId = action.payload.preqItemId;
      const assessmentId = image.prequalificationItem[0].assessment[0].id;

      const usedAssessmentIndex = state.findIndex((item) => item.id === assessmentId);
      const toRemoveIndex = state[usedAssessmentIndex].prequalificationItem.findIndex((item) => item.id === preqItemId);

      const toAddIndex = state[usedAssessmentIndex].prequalificationItem.findIndex((item) => item.id === destinationId);

      state[usedAssessmentIndex].prequalificationItem[toRemoveIndex].image = state[
        usedAssessmentIndex
      ].prequalificationItem[toRemoveIndex].image.filter((item) => item.id !== image.id);

      delete image.assessment;
      delete image.prequalification;

      state[usedAssessmentIndex].prequalificationItem[toAddIndex].image.push(image);
    },
    assessmentFileAdd(state, action) {
      const id = action.payload.preqItemId;
      const file = action.payload.response;
      const assessmentId = action.payload.assessmentId;

      console.log("redux", action.payload);

      const preqIndex = state.findIndex((item) => item.id === assessmentId);

      const toChange = state[preqIndex].prequalificationItem.findIndex((item) => item.id === id);

      state[preqIndex].prequalificationItem[toChange].image.length === 0
        ? (state[preqIndex].prequalificationItem[toChange].image = [file])
        : state[preqIndex].prequalificationItem[toChange].image.push(file);
    },
    assessmentFileDelete(state, action) {
      const file = action.payload.file;
      const assessmentId = file.prequalificationId;

      const preqIndex = state.findIndex((item) => item.id === assessmentId);

      const toDelete = state[preqIndex].prequalificationItem.findIndex((spec) =>
        spec?.image?.find((item) => item.id === file.id)
      );
      state[preqIndex].prequalificationItem[toDelete].image = state[preqIndex].prequalificationItem[
        toDelete
      ].image.filter((image) => image.id !== file.id);
    },
    assessmentItemImageNameUpdate(state, action) {
      const imageObject = action.payload;
      const prequalificationItem = imageObject.prequalificationItem[0];
      const assessment = prequalificationItem.assessment[0];
      const assessmentIndex = state.findIndex((preq) => preq.id === assessment.id);
      const prequalificationItemIndex = state[assessmentIndex].prequalificationItem.findIndex(
        (preqItem) => preqItem.id === prequalificationItem.id
      );
      const imageIndex = state[assessmentIndex].prequalificationItem[prequalificationItemIndex].image.findIndex(
        (image) => image.id === imageObject.id
      );

      state[assessmentIndex].prequalificationItem[prequalificationItemIndex].image[imageIndex].imageBaseName =
        imageObject.imageBaseName;
    },
    logoutAssessment(state, action) {
      return assessmentSlice.getInitialState();
    },
  },
});

export const {
  assessmentAdded,
  assessmentSend,
  assessmentSpecificAdded,
  assessmentMoveImage,
  assessmentItemImageUpdate,
  assessmentItemUpdate,
  assessmentFileDelete,
  assessmentFileAdd,
  assessmentItemImageNameUpdate,
  assessmentPreqItemUpdate,
  logoutAssessment,
} = assessmentSlice.actions;
export default assessmentSlice.reducer;
