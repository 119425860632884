import React from "react";

//mock data import

import { getCompany } from "api";

//MUI Imports
import { Typography, Card, Button, Box, Divider } from "@mui/material";
import { GridToolbarQuickFilter } from "@mui/x-data-grid";

//hooks
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// mock
import { companyColumns } from "layouts/DataGridLayouts";
import { CreateCompanyModal } from "pages/modals";
import { CustomContainer, CustomDataGrid } from "components";

const Company = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = React.useState(false);

  const account = useSelector((state) => state.account);
  const companies = useSelector((state) => (state.company ? state.company : []));

  React.useEffect(() => {
    dispatch(getCompany(account?.id));
  }, [account?.id, dispatch]);

  const handleClick = (event) => {
    navigate("/dashboard/facility", { state: event.row });
  };

  const extendetCompanyColumns = [
    {
      field: "detailPage",
      headerName: "Details",
      flex: 0.15,
      editable: false,
      renderCell: ({ row }) => {
        return (
          <Button
            sx={{ display: "flex", flex: 1, alignContent: "center", justifyContent: "center" }}
            variant="contained"
            onClick={() => navigate("/dashboard/facility", { state: row })}
          >
            Auswählen
          </Button>
        );
      },
    },
    ...companyColumns,
  ];

  return (
    <CustomContainer>
      <Card
        sx={{
          display: "flex",
          p: 2,
          boxShadow: 2,
          mb: 5,
        }}
      >
        <Typography variant="h4">Unternehmen</Typography>
      </Card>
      <Card sx={{ display: "flex", flexDirection: "column" }}>
        <CustomDataGrid
          rows={companies}
          columns={extendetCompanyColumns}
          initialPageSize={100}
          onRowDoubleClick={handleClick}
          components={{
            Toolbar: (props) => (
              <>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    p: 2,
                    backgroundColor: "background.default",
                    borderRadius: 1,
                    gap: 2, // Adds consistent spacing between elements
                  }}
                >
                  <GridToolbarQuickFilter
                    showQuickFilter
                    {...props.toolbar}
                    sx={{
                      "& .MuiInputBase-root": {
                        borderRadius: 2,
                        border: "1px solid",
                        borderColor: "divider",
                        backgroundColor: "background.paper",
                        padding: "6px 12px",
                        minWidth: 200,
                        transition: "all 0.2s ease-in-out",
                      },
                      "& .MuiInputBase-input": {
                        fontSize: "0.95rem",
                        fontWeight: 500,
                        color: "text.primary",
                        "::placeholder": {
                          color: "text.secondary",
                          opacity: 0.8,
                        },
                      },
                    }}
                  />
                  <Button
                    onClick={() => {
                      setIsOpen(true);
                    }}
                    variant="contained"
                    sx={{
                      textTransform: "none",
                      padding: "6px 16px", // Matches the height of the input
                      height: "40px", // Forces a consistent height with input
                      minWidth: "auto", // Prevents unnecessary width expansion
                      lineHeight: "1.5", // Ensures proper text alignment
                    }}
                  >
                    Neues Unternehmen anlegen
                  </Button>
                </Box>
                <Divider sx={{ mb: 1 }} />
              </>
            ),
          }}
          componentsProps={{
            toolbar: {
              showQuickFilter: true,
            },
          }}
        />
      </Card>
      <CreateCompanyModal isOpen={isOpen} onRequestClose={() => setIsOpen(false)} />
    </CustomContainer>
  );
};

export default Company;
