import React from "react";

import { Box, Button, Card, Typography, Divider } from "@mui/material";

import { CustomUpload, DisplayFiles, CustomDataGrid, DefinedTabs, CustomSnackbarAlert } from "components";
import { ShowFileModal } from "pages/modals";

import { UploadAssessmentFile, getAssessments, DeleteFile, sendAssessmentData } from "api";
import { useDispatch, useSelector } from "react-redux";
import { assessmentColumns } from "layouts/DataGridLayouts";

import { uniqueId } from "lodash";
import { filesize } from "filesize";
import { closeInsolvency } from "api/changes";
import { AssessmentType, StatusType } from "assets/type.ts";
import RemoveSpecialistPage from "./assessmentPages/removeSpecialist.js";

const AssessmentTab = ({ selectedFacility }) => {
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(getAssessments(selectedFacility.id));
  }, [dispatch, selectedFacility.id]);

  const [selectedAssessment, setSelectedAssessment] = React.useState({ type: AssessmentType.FIRSTMONITORING });
  const [selectedFile, setSelectedFile] = React.useState({});
  const [openModal, setModalOpen] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [activeTab, setActiveTab] = React.useState(0);
  const [clicked, setClicked] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [open, setOpen] = React.useState(false);

  //TODO: SORT Preqitems

  const assessment = useSelector((state) => state.assessment);
  const selectedAssessmentState = useSelector((state) =>
    state.assessment.find((item) => item.id === selectedAssessment.id)
  );

  const handleUpload = (files, prequalificationItem) => {
    const uploadedFiles = files?.map((file) => ({
      file,
      id: uniqueId(),
      name: file.name,
      readableSize: filesize(file.size),
      preview: URL.createObjectURL(file),
      progress: 0,
      type: file.type,
      uploaded: false,
      error: false,
      url: null,
      prequalificationItem: prequalificationItem.id,
      assessmentId: selectedAssessment.id,
    }));

    uploadedFiles.forEach((item) => processUpload(item, prequalificationItem));
  };

  const processUpload = (uploadedFile, item) => {
    const data = new FormData();

    data.append("file", uploadedFile.file);
    data.append("name", uploadedFile.name);
    data.append("prequalificationItemId", item.id);
    data.append("prequalificationId", selectedAssessment.id);

    dispatch(UploadAssessmentFile(uploadedFile, selectedAssessment.id));
    setSuccess(true);
  };

  const handleDelete = (item) => {
    if (selectedAssessment.status === StatusType.ACCEPTED) return;
    dispatch(DeleteFile(item, { assessmentId: selectedAssessment?.id })).then((response) => {
      console.log("response", response);
      setClicked(true);
      setOpen(true);
      setSuccess(response.error ? false : true);
      setMessage(response.message);
      setClicked(false);
    });
  };

  const handleClicked = (item) => {
    console.log(item);
    setSelectedFile(item);
    setModalOpen(true);
  };

  const sendAssessment = () => {
    setClicked(true);
    dispatch(sendAssessmentData(selectedAssessment?.id, selectedAssessment?.type)).then((response) => {
      if (response.error) {
        setSuccess(false);
        setMessage(response.message || "Irgendwas ist schiefgelaufen...");
      } else {
        setSuccess(true);
        setMessage(response.message);
      }
      setOpen(true);
      setClicked(false);
    });
  };

  const closeInsolvencyFunc = () => {
    setClicked(true);
    closeInsolvency(selectedAssessment?.id, selectedFacility?.id);
    setClicked(false);
  };

  const downloadZertificate = (item) => {
    setClicked(true);
    window.open(item.imageUrl, "_blank");
    setClicked(false);
  };

  const handleChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const excludedTypes = [
    AssessmentType.REMOVECONTACTPERSON,
    AssessmentType.OPENINSOLVENCY,
    AssessmentType.REMOVESPECIALIST,
  ];

  // Main return logic for rendering the component
  if (!assessment.some((item) => item.active)) {
    return (
      <Card sx={{ p: 2, mt: 2 }}>
        <Divider textAlign="left" sx={{ my: 2 }}>
          <Typography fontWeight="bold">Überwachungen / Änderungen</Typography>
        </Divider>
        <Typography sx={{ mb: 2 }}>
          {assessment.length === 0
            ? "Sie haben noch keine Änderung beantragt und es steht noch keine Überwachung an."
            : "Sie haben keine Aktiven Überwachungen oder Änderungsanträge."}
        </Typography>
      </Card>
    );
  } else {
    return (
      <Box>
        <Card
          sx={{
            backgroundColor: "transparent",
            mt: 2,
            p: 2,
          }}
        >
          <Divider textAlign="left" sx={{ my: 2 }}>
            <Typography fontWeight={"bold"}>Überwachung / Anträge</Typography>
          </Divider>
          <Card>
            <CustomDataGrid
              rows={assessment}
              columns={assessmentColumns}
              checkboxSelection
              disableSelectionOnClick
              onSelectionModelChange={(rows) => {
                console.log(rows);
                if (rows.length === 0) {
                  if (selectedAssessment) {
                    setSelectedAssessment({ type: AssessmentType.FIRSTMONITORING });
                  }
                  return;
                }
                if (rows.length === 2) rows.shift(); // only one can be selected at a time so it doesn't crash
                const actionListObject = assessment?.filter((item) => item.id.includes(rows));
                setSelectedAssessment(actionListObject[0]);
              }}
              initialPageSize={10}
            />
          </Card>
        </Card>
        {selectedAssessment && (
          <Card
            sx={{
              backgroundColor: "transparent",
              mt: 2,
              p: 2,
            }}
          >
            {selectedAssessment?.status === StatusType.ACCEPTED &&
              selectedAssessmentState?.images?.map((item) => (
                <Card sx={{ display: "flex", flexDirection: "column", p: 2, mt: 2, mb: 2, flex: 1 }} key={item.id}>
                  <Typography variant="h4">Zertifikat</Typography>
                  <DisplayFiles file={item} />
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                      mt: 1,
                    }}
                  >
                    <Button variant="contained" disabled={clicked} onClick={() => downloadZertificate(item)}>
                      Download
                    </Button>
                  </Box>
                </Card>
              ))}
            {selectedAssessment?.type === AssessmentType.OPENINSOLVENCY && (
              <Box>
                <Typography>Möchten Sie das Insolvenzverfahren auflösen?</Typography>
                <Button variant="contained" disabled={clicked} onClick={() => closeInsolvencyFunc()}>
                  Jetzt auflösen
                </Button>
                {JSON.stringify(selectedAssessment)}
                {/* <ContactPersonComponent
                  isCollapsible={false}
                  gender={selectedAssessment?.facility[0]?.main_contact_person?.gender}
                  firstName={selectedAssessment?.facility[0]?.main_contact_person?.first_name}
                  lastName={selectedAssessment?.facility[0]?.main_contact_person?.last_name}
                  phoneNumber={selectedAssessment?.facility[0]?.main_contact_person?.phone_number}
                                  <Typography>{`Faxnummer: ${assessment?.facility[0]?.contact_person[0]?.fax_number}`}</Typography>
                  email={selectedAssessment?.facility[0]?.main_contact_person?.email}
                /> */}
              </Box>
            )}
            {selectedAssessment?.type === AssessmentType.REMOVEPROVIDINGFIELDS && (
              <Box>
                <Divider textAlign="left" sx={{ my: 2 }}>
                  <Typography fontWeight={"bold"}>Wegfallende Vorraussetzungen</Typography>
                </Divider>
                <CustomDataGrid
                  rows={selectedAssessment?.prequalificationItem}
                  columns={[{ field: "description", flex: 1, headerName: "Vorraussetzung" }]}
                />
              </Box>
            )}
            {selectedAssessment.type === AssessmentType.REMOVESPECIALIST && (
              <RemoveSpecialistPage
                selectedAssessmentState={selectedAssessmentState}
                handleUpload={handleUpload}
                handleDelete={handleDelete}
                handleClicked={handleClicked}
              />
            )}

            {!excludedTypes.includes(selectedAssessment?.type) && (
              <>
                <DefinedTabs value={activeTab} handleChange={handleChange} />
                {selectedAssessment?.status !== StatusType.ACCEPTED && (
                  <>
                    {selectedAssessmentState?.prequalificationItem?.map((item) => (
                      <>
                        {item.status !== StatusType.ACCEPTED && activeTab === 0 ? (
                          <CustomUpload
                            key={item.id}
                            titel={item.titel}
                            description={item.description}
                            specialist={item.specialist}
                            status={item.status}
                            preqStatus={
                              selectedAssessment?.type !== AssessmentType.REMOVEPROVIDINGFIELDS
                                ? selectedAssessment?.status
                                : StatusType.ACCEPTED
                            } //I dont want
                            files={item.image}
                            comment={item.comment}
                            providingFields={item.providing_fields}
                            handleUpload={(file) => handleUpload(file, item)}
                            handleClicked={(item) => handleClicked(item)}
                            handleDelete={handleDelete}
                          />
                        ) : null}
                        {item.status === StatusType.ACCEPTED && activeTab === 1 ? (
                          <CustomUpload
                            key={item.id}
                            titel={item.titel}
                            description={item.description}
                            specialist={item.specialist}
                            status={item.status}
                            preqStatus={selectedAssessment.status}
                            files={item.image}
                            comment={item.comment}
                            providingFields={item.providing_fields}
                            handleUpload={(file) => handleUpload(file, item)}
                            handleClicked={(item) => handleClicked(item)}
                            handleDelete={handleDelete}
                          />
                        ) : null}
                      </>
                    ))}
                  </>
                )}
              </>
            )}
          </Card>
        )}
        {selectedAssessment.type !== AssessmentType.REMOVEPROVIDINGFIELDS && (
          <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 1, mb: 1 }}>
            <Button
              variant="contained"
              disabled={
                selectedAssessment?.status === StatusType.ACCEPTED ||
                selectedAssessment?.status === StatusType.REJECTED ||
                clicked
              }
              onClick={() => sendAssessment()}
            >
              {selectedAssessment.status === StatusType.PENDING ? "Dokumente Nachreichen" : "Absenden"}
            </Button>
          </Box>
        )}
        <CustomSnackbarAlert open={open} setOpen={setOpen} severity={success ? "success" : "error"} message={message} />
        {<ShowFileModal isOpen={openModal} onRequestClose={() => setModalOpen(false)} file={selectedFile} />}
      </Box>
    );
  }
};

export default AssessmentTab;
