import { CheckCircle } from "@mui/icons-material";
import { Box, Chip, Typography } from "@mui/material";
import { StatusType } from "../../assets/type.ts";

const statusColors = {
  [StatusType.CREATED]: "warning",
  [StatusType.PENDING]: "warning",
  [StatusType.COMPLETED]: "success",
  [StatusType.REJECTED]: "error",
  [StatusType.ACCEPTED]: "primary",
  [StatusType.DOCUMENT_REVIEW]: "info",
  [StatusType.IN_PROGRESS]: "info",
  [StatusType.UNDER_REVIEW]: "secondary",
  [StatusType.MONITORING]: "secondary",
  [StatusType.INSOLVENCY]: "error",
};

const statusLabels = {
  [StatusType.CREATED]: "In Bearbeitung",
  [StatusType.PENDING]: "In Bearbeitung",
  [StatusType.COMPLETED]: "Erfolgreich",
  [StatusType.REJECTED]: "Abgelehnt",
  [StatusType.ACCEPTED]: "Antrag Akzeptiert",
  [StatusType.DOCUMENT_REVIEW]: "Auftrag zur Dokumentenprüfung",
  [StatusType.IN_PROGRESS]: "Dokumentenprüfung",
  [StatusType.UNDER_REVIEW]: "Überprüfung",
  [StatusType.MONITORING]: "Überwachung",
  [StatusType.INSOLVENCY]: "Insolvenz",
};

const renderStatusCell = ({ row }) => {
  const label = statusLabels[row.status] || "In Bearbeitung";
  const color = statusColors[row.status] || "warning";

  return <Chip label={label} color={color} size="medium" />;
};

const facilityColumns = [
  {
    field: "main_house",
    headerName: "Haupthaus",
    editable: false,
    flex: 0.1,
    renderCell: ({ row }) => {
      return (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
            color: row.main_house ? "success.main" : "error.main",
          }}
        >
          {row.main_house ? (
            <>
              <CheckCircle fontSize="small" />
              <Typography fontWeight={500}>Ja</Typography>
            </>
          ) : (
            <></>
          )}
        </Box>
      );
    },
  },
  {
    field: "name",
    headerName: "Betriebsstätte",
    editable: false,
    flex: 0.2,
  },
  {
    field: "ik_number",
    headerName: "IK-Nummer",
    editable: false,
    flex: 0.2,
  },
  {
    field: "fullAddress",
    headerName: "Adresse",
    editable: false,
    flex: 0.4,
    valueGetter: (params) =>
      `${params.row.street || ""} ${params.row.street_number || ""}, ${params.row.postcode || ""} ${
        params.row.city || ""
      }`,
  },
  {
    field: "status",
    headerName: "Status",
    editable: false,
    flex: 0.3,
    renderCell: renderStatusCell,
  },
];

export default facilityColumns;
