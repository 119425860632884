import { renderCellWithTooltip } from "../../components/CustomDataGrid";

const companyColumns = [
  {
    field: "name",
    headerName: "Unternehmen",
    flex: 0.35,
    renderCell: (params) => {
      return renderCellWithTooltip({ value: params.row.name });
    },
  },
  {
    field: "ik_number",
    headerName: "IK-Nummer",
    flex: 0.15,
    editable: false,
  },
  {
    field: "fullAddress",
    headerName: "Adresse",
    flex: 0.35,
    renderCell: (params) => {
      return renderCellWithTooltip({
        value: `${params.row.street || ""} ${params.row.street_number || ""}, ${params.row.postcode || ""} ${
          params.row.city || ""
        }`,
      });
    },
  },
];

export default companyColumns;
