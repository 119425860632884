import React from "react";
import { Card, Box, Typography, Tabs, Tab, Button, Divider, Checkbox, Tooltip } from "@mui/material";
import { CustomTabPanel, CustomDataGrid, CustomLoadingIndicator, CustomSnackbarAlert } from "components";
import { specialistColumns } from "layouts/DataGridLayouts";

import { dynamicSpecialistFieldValue } from "utils/dynamicSpecialistFieldValue";
import { GetProvidingFields } from "api";
import { useDispatch, useSelector } from "react-redux";
import { InfoOutlined } from "@mui/icons-material";
import { useApprovalFlow } from "hooks/useApprovalFlow";
import { ConfirmationModal } from "pages/modals";
import { AssessmentType } from "assets/type.ts";

const EditProvidingFieldsTab = ({ selectedFacility }) => {
  const dispatch = useDispatch();
  const {
    sendRequest,
    confirmRequest,
    responseData,
    setResponseData,
    isLoading,
    openSnackbar,
    setOpenSnackbar,
    message,
    modalOpen,
    setModalOpen,
  } = useApprovalFlow();
  // const [value, setValue] = React.useState(0);
  const [selectedTabIndex, setSelectedTabIndex] = React.useState(0);
  const [selectedSpecialist, setSelectedSpecialist] = React.useState(null);
  const [providing_fields, setProvidingFields] = React.useState([]);
  const [providingFieldsDefault, setProvidingFieldsDefault] = React.useState([]);
  const [clicked, setClicked] = React.useState(false);

  const criteriaCatalog = useSelector((state) => state.criteriaCatalog);
  const specialistList = useSelector((state) => state.specialist);

  const sendChangeProvidingFields = async (type) => {
    // setClicked(true);
    const filterSelectedProvidingFields = providing_fields.filter(
      (item) => item.in_store_selected || item.field_sales_selected
    );

    console.log("filterSelectedProvidingFields", filterSelectedProvidingFields);

    let providingFields = filterSelectedProvidingFields.map((item) => {
      return {
        code: item.code,
        in_store: item.in_store_selected,
        field_sales: item.field_sales_selected,
        description: item.description,
        criteriaCatalogVersion: item.criteriaCatalogVersion, //old version
        versionOfficial: item.versionOfficial, //new version
      };
    });

    console.log("type", type);
    await sendRequest(type, {
      facilityId: selectedFacility.id,
      specialistId: selectedSpecialist.id,
      providingFields: providingFields,
    });

    setClicked(false);

    // dispatch(changeFields(selectedFacility.id, selectedSpecialist.id, providingFields, true)).then(() =>
    //   setClicked(false)
    // );
  };

  const handleProvidingAction = (from, row) => {
    const element = providing_fields.findIndex((item) => item.code.substring(0, 3) === row.code.substring(0, 3));
    // console.log(providing_fields);
    // const elementSpec = selectedSpecialist?.providing_fields?.findIndex((item) => item.code === row.code);

    if (from) {
      providing_fields[element].in_store_selected = !providing_fields[element].in_store_selected;

      // setSelectedSpecialist((prevState) => {
      //   const updatedFields = [...prevState.providing_fields];
      //   updatedFields[elementSpec] = {
      //     ...updatedFields[elementSpec],
      //     in_store: !updatedFields[elementSpec].in_store,
      //   };
      //   return {
      //     ...prevState,
      //     providing_fields: updatedFields,
      //   };
      // });
    } else {
      providing_fields[element].field_sales_selected = !providing_fields[element].field_sales_selected;
      // setSelectedSpecialist((prevState) => {
      //   const updatedFields = [...prevState.providing_fields];
      //   updatedFields[elementSpec] = {
      //     ...updatedFields[elementSpec],
      //     field_sales: !updatedFields[elementSpec].field_sales,
      //   };
      //   return {
      //     ...prevState,
      //     providing_fields: updatedFields,
      //   };
      // });
    }
  };

  const handleProvidingFieldsChanges = (rows, select) => {
    const changes = providingFieldsDefault.map((original) => {
      providing_fields.find((item) => item.id === original.id);
      return {
        id: original.id,
        in_store_selected: select ? !original.in_store_selected : original.in_store_selected,
        field_sales_selected: select ? !original.field_sales_selected : original.field_sales_selected,
      };
    });

    const currentRow = changes.find((item) => item.id === rows[0]);
    const currentProvidingField = providing_fields.find((item) => item.id === rows[0]);
    const defaultProvidingField = providingFieldsDefault.find((item) => item.id === rows[0]);

    currentProvidingField.in_store_selected = !currentRow.in_store_selected
      ? defaultProvidingField.in_store_selected
      : currentProvidingField.in_store_selected;

    currentProvidingField.field_sales_selected = !currentRow.field_sales_selected
      ? defaultProvidingField.field_sales_selected
      : currentProvidingField.field_sales_selected;
  };

  const providingFieldsColumns = [
    {
      field: "in_store_allowed",
      headerName: "Geschäftslokal",
      flex: 0.1,
      editable: false,
      default: false,
      renderCell: ({ row }) => {
        if (row.in_store_allowed) {
          return (
            <Checkbox
              disabled={row?.catalogItem_A?.length > 0 && row.catalogItem_B.some((item) => item.code !== row.code)}
              // disabled={row.replacedBy.length > 0}
              checked={row.in_store_selected}
              onClick={() => handleProvidingAction(true, row)}
            />
          );
        } else return <></>;
      },
    },
    {
      field: "field_sales_allowed",
      headerName: "Außendienst",
      flex: 0.1,
      editable: false,
      default: false,
      renderCell: ({ row }) => {
        if (row.field_sales_allowed) {
          return (
            <Checkbox
              disabled={row?.catalogItem_A?.length > 0 && row.catalogItem_B.some((item) => item.code !== row.code)}
              checked={row.field_sales_selected}
              onClick={() => handleProvidingAction(false, row)}
            />
          );
        } else return <></>;
      },
    },
    {
      field: "code",
      headerName: "Versorgungsbereiche",
      flex: 0.2,
      editable: false,
      renderCell: ({ row }) => {
        // Check if we have any valid catalogItem_A or catalogItem_B
        const hasCatalogItemB = row?.catalogItem_B?.length > 0;
        const hasCatalogItemA = row?.catalogItem_A?.length > 0;
        // Determine the tooltip content based on the conditions
        const tooltipContent =
          hasCatalogItemB && row.catalogItem_B.some((item) => item.code !== row.code)
            ? `Ersetzt durch: ${row.catalogItem_B.map((item) => item.code).join(", ")}`
            : hasCatalogItemA &&
              row.catalogItem_A.some((item) =>
                item.criteriaCatalog.some(
                  (criteriaItem) => criteriaItem.versionOfficial === selectedSpecialist.latest_catalog_version
                )
              )
            ? `Ersetzt: ${row.catalogItem_A.map((item) => item.code).join(", ")}`
            : null;

        // Determine the background color based on the catalog item conditions
        const backgroundColor = tooltipContent
          ? hasCatalogItemB
            ? "error.main"
            : hasCatalogItemA
            ? "success.main"
            : "transparent"
          : ""; // Default background if neither condition is met

        // Render the cell with the appropriate background and tooltip
        return (
          <Box
            sx={{
              display: "flex",
              flex: 1,
              p: 1,
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              backgroundColor: backgroundColor,
            }}
          >
            <Typography>{row.code}</Typography>
            {tooltipContent && (
              <Tooltip
                disableFocusListener
                disableTouchListener
                arrow
                title={<Typography variant="body1">{tooltipContent}</Typography>}
              >
                <InfoOutlined sx={{ mr: 3, scale: "1" }} />
              </Tooltip>
            )}
          </Box>
        );
      },
    },
    {
      field: "description",
      headerName: "Bezeichnung",
      flex: 0.5,
      editable: false,
    },
  ];

  return (
    <>
      <Box sx={{ borderBottom: 1, borderColor: "divider", display: "flex", flex: 1, width: "100%" }}>
        <Tabs
          variant="fullWidth"
          sx={{ width: "100%" }}
          selectionFollowsFocus
          value={selectedTabIndex}
          onChange={(e, selectedTabIndex) => {
            setSelectedTabIndex(selectedTabIndex);
            setProvidingFields([]);
            setProvidingFieldsDefault([]);
          }}
        >
          <Tab label="Erweiterung der Versorgungsbereiche" />
          <Tab label="Wegfall Versorgungsbereiche" />
        </Tabs>
      </Box>
      <CustomTabPanel value={selectedTabIndex} index={0}>
        <Card sx={{ p: 3, mt: 2 }}>
          <Box sx={{ display: "flex", flexDirection: "column", mt: 4, mb: 2, flex: 1 }}>
            <Typography sx={{ flex: 0.35 }} variant="body2">
              Zuerst Fachliche Leitung und anhand dessen berechtigungen nur erlaubte Versorgungsbereiche anzeigen
            </Typography>
            <Divider textAlign="left" sx={{ my: 2, textTransform: "none" }}>
              <Typography fontWeight={"bold"}>Fachliche Leitung auswählen</Typography>
            </Divider>
            <CustomDataGrid
              rows={selectedFacility.specialist}
              columns={specialistColumns}
              initialPageSize={25}
              checkboxSelection
              onSelectionModelChange={(rows) => {
                if (rows.length === 0) {
                  if (selectedSpecialist) {
                    setSelectedSpecialist({});
                  }
                  return;
                }
                const selected = specialistList.filter((item) => item.id === rows[0]);

                setSelectedSpecialist(selected[0]);
                dispatch(GetProvidingFields(rows[0])).then(() => {
                  setProvidingFields(dynamicSpecialistFieldValue(criteriaCatalog, selected[0], "rework"));
                  if (providingFieldsDefault.length === 0) {
                    setProvidingFieldsDefault(dynamicSpecialistFieldValue(criteriaCatalog, selected[0]));
                  }
                });
              }}
            />
            <Divider textAlign="left" sx={{ my: 2 }}>
              <Typography fontWeight={"bold"}>Versorgungsbereich auswählen</Typography>
            </Divider>
            <CustomDataGrid
              rows={providing_fields}
              columns={providingFieldsColumns}
              onSelectionModelChange={(rows) => {
                handleProvidingFieldsChanges(rows, true);
              }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              mt: 1,
            }}
          >
            <Button
              sx={{ my: 2 }}
              variant="contained"
              disabled={clicked}
              onClick={() => sendChangeProvidingFields(AssessmentType.EXTENDPROVIDINGFIELDS)}
            >
              Absenden
            </Button>
          </Box>
        </Card>
      </CustomTabPanel>
      <CustomTabPanel value={selectedTabIndex} index={1}>
        <Card sx={{ p: 3, mt: 2 }}>
          <Box sx={{ display: "flex", flexDirection: "row", mt: 2, flex: 1 }}>
            <Typography variant="body2">
              Um einen Versorgunsbereich zu entfernen wählen sie diesen bitte unten aus.
            </Typography>
          </Box>
          <Divider textAlign="left" sx={{ my: 2, textTransform: "none" }}>
            <Typography fontWeight={"bold"}>Fachliche Leitung auswählen</Typography>
          </Divider>
          <CustomDataGrid
            rows={specialistList}
            columns={specialistColumns}
            initialPageSize={25}
            checkboxSelection
            onSelectionModelChange={(rows) => {
              if (rows.length === 0) {
                if (selectedSpecialist) {
                  setSelectedSpecialist({});
                }
                return;
              }
              const selected = specialistList.filter((item) => item.id === rows[0]);

              setSelectedSpecialist(selected[0]);
              dispatch(GetProvidingFields(rows[0])).then(() => {
                setProvidingFields(dynamicSpecialistFieldValue(criteriaCatalog, selected[0]));
                if (providingFieldsDefault.length === 0) {
                  setProvidingFieldsDefault(dynamicSpecialistFieldValue(criteriaCatalog, selected[0]));
                }
              });
            }}
          />
          <Divider textAlign="left" sx={{ mt: 2, mb: 2 }}>
            <Typography fontWeight={"bold"}>Versorgungsbereich auswählen</Typography>
          </Divider>
          <CustomDataGrid
            rows={providing_fields}
            columns={providingFieldsColumns}
            onSelectionModelChange={(rows) => {
              handleProvidingFieldsChanges(rows, false);
            }}
          />
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              mt: 1,
            }}
          >
            <Button
              sx={{ mt: 2, mb: 2 }}
              variant="contained"
              disabled={clicked}
              onClick={() => sendChangeProvidingFields(AssessmentType.REMOVEPROVIDINGFIELDS)}
            >
              Absenden
            </Button>
          </Box>
        </Card>
      </CustomTabPanel>
      {modalOpen && (
        <ConfirmationModal
          isOpen={modalOpen}
          onRequestClose={() => setModalOpen(false)}
          responseData={responseData}
          setResponseData={setResponseData}
          confirmRequest={() => {
            console.log("responseData in tab", responseData);
            confirmRequest();
          }}
        />
      )}
      <CustomLoadingIndicator open={isLoading} message={"Einen Moment gedult. Änderung wird bearbeitet."} />
      <CustomSnackbarAlert
        open={openSnackbar}
        setOpen={() => {
          setOpenSnackbar(false);
        }}
        severity={message?.severity}
        message={message?.message}
      />
    </>
  );
};
export default EditProvidingFieldsTab;
