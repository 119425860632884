//REWORK GET VALID CRITERIA CATALOG, THAN CHECK WHICH CRITERIA CATALOG THE SPECIALIST IS USING

import moment from "moment";

export const parseVersion = (version) => version.split(".").map(Number);

export const compareVersions = (v1, v2) => {
  const [major1, minor1, patch1] = parseVersion(v1);
  const [major2, minor2, patch2] = parseVersion(v2);

  if (major1 !== major2) return major1 - major2;
  if (minor1 !== minor2) return minor1 - minor2;
  return patch1 - patch2;
};

export function dynamicSpecialistFieldValue(criteriaCatalog, selectedSpecialist, mode = "viewOnly") {
  console.log("selectedSpecialist", selectedSpecialist);
  let criteriaCatalogVersionIndex;

  // Determine the criteria catalog version index based on the mode
  if (process.env.REACT_APP_FIXED_CATALOG_VERSION === "true") {
    criteriaCatalogVersionIndex = criteriaCatalog.findIndex(
      (item) => item.version === process.env.REACT_APP_CATALOG_VERSION
    );
  } else if (selectedSpecialist.latest_catalog_version === undefined) {
    criteriaCatalogVersionIndex = criteriaCatalog.findIndex((item) => moment().isBefore(item.expires));
  } else {
    criteriaCatalogVersionIndex = criteriaCatalog.findIndex(
      (item) => item.versionOfficial === selectedSpecialist.latest_catalog_version
    );
  }

  if (criteriaCatalogVersionIndex !== -1) {
    const currentCatalogItem = criteriaCatalog[criteriaCatalogVersionIndex].catalogItem;

    // Filter the items based on the qualifications
    const qualifications = selectedSpecialist?.qualification || "";
    const filteredKatalog = currentCatalogItem.filter((item) => {
      const occupationArray = item.specialist_short_forms.split(",");
      return occupationArray.find(
        (shortForm) =>
          shortForm.trim() === qualifications.trim() || shortForm.trim() === qualifications.replace(/[()]/g, "").trim()
      );
    });

    // For "rework" mode, include newer fields
    let extendedKatalog = [];
    if (mode === "rework") {
      const newerCatalogItems = criteriaCatalog
        .filter(
          (item) =>
            compareVersions(item.versionOfficial, selectedSpecialist.latest_catalog_version) > 0 &&
            moment().isBefore(item.expires)
        )
        .flatMap((catalog) => catalog.catalogItem);

      // Add only new items to the extendedKatalog
      extendedKatalog = newerCatalogItems.filter((newItem) => {
        return !filteredKatalog.some((existingItem) => existingItem.code === newItem.code);
      });

      extendedKatalog = extendedKatalog.filter((item) => {
        const occupationArray = item.specialist_short_forms.split(",");
        return occupationArray.find(
          (shortForm) =>
            shortForm.trim() === qualifications.trim() ||
            shortForm.trim() === qualifications.replace(/[()]/g, "").trim()
        );
      });
    }

    // Merge filteredKatalog and extendedKatalog
    const finalKatalog = [...filteredKatalog, ...extendedKatalog];

    // Create a new array with additional properties
    const modifiedKatalog = finalKatalog.map((item) => {
      const newItem = { ...item }; // Create a copy of the item
      const providingFieldIndex = selectedSpecialist.providing_fields.findIndex(
        (provItem) => provItem.code === item.code && (!selectedSpecialist.active || provItem.active)
      );

      newItem.criteriaCatalogVersion = criteriaCatalog[criteriaCatalogVersionIndex]?.version;
      newItem.versionOfficial = criteriaCatalog[criteriaCatalogVersionIndex]?.versionOfficial;
      newItem.in_store_selected = selectedSpecialist.providing_fields[providingFieldIndex]?.in_store;
      newItem.field_sales_selected = selectedSpecialist.providing_fields[providingFieldIndex]?.field_sales;
      return newItem;
    });

    return modifiedKatalog.sort((a, b) => a.code.localeCompare(b.code));
  }

  return [];
}

//01A15,05A5,05B5,05C,05E,08B10,17A16,17B16
