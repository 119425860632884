import React from "react";
//mui import
import { Box, Typography, Button } from "@mui/material";

//component import
import { CModal } from "components";

const SendPrequalificationRequest = ({ isOpen, onRequestClose, handlePrequalification, row }) => {
  return (
    <CModal
      modalLabel="Sie stellen einen Antrag auf Präqualifizierung."
      open={isOpen}
      onRequestClose={onRequestClose}
      style={{ width: "70%", transform: "translate(20%, 15%)" }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          p: 2,
          m: 2,
        }}
      >
        <Typography fontWeight={"bold"} fontSize={18}>
          Der Präqualifizierungsantrag wird mit der Antragsannahme durch die DGP kostenpflichtig.
        </Typography>
        <Box
          sx={{
            display: "flex",
            width: "50%",
            justifyContent: "space-between",
            mt: 5,
          }}
        >
          <Button
            variant="contained"
            onClick={() => {
              handlePrequalification(row);
              onRequestClose();
            }}
          >
            Fortfahren
          </Button>
          <Button variant="contained" onClick={() => onRequestClose()}>
            Abbrechen
          </Button>
        </Box>
      </Box>
    </CModal>
  );
};

export default SendPrequalificationRequest;
