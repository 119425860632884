import { useState, useRef, useEffect } from "react";
import { Box, Typography, IconButton, Fade, Paper } from "@mui/material";
import { Home, ExpandMore, ExpandLess, LocationOn } from "@mui/icons-material";

const FloatingInfo = ({ selectedFacility, openFacilityModal }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const contentRef = useRef(null);
  const nameRef = useRef(null);
  const [contentHeight, setContentHeight] = useState(0);
  const [nameWidth, setNameWidth] = useState(0);

  const hasDifferentBillingAddress =
    selectedFacility.billing_city !== selectedFacility.city ||
    selectedFacility.billing_street !== selectedFacility.street ||
    selectedFacility.billing_street_number !== selectedFacility.street_number ||
    selectedFacility.billing_postcode !== selectedFacility.postcode;

  // Measure name width when facility changes
  useEffect(() => {
    if (nameRef.current) {
      // Add a small buffer for better appearance
      setNameWidth(Math.min(nameRef.current.scrollWidth + 20, 300));
    }
  }, [selectedFacility.name]);

  // Measure content height when component mounts or content changes
  useEffect(() => {
    if (contentRef.current) {
      setContentHeight(contentRef.current.scrollHeight);
    }
  }, [selectedFacility, isExpanded]);

  // Calculate the component width based on state
  const getComponentWidth = () => {
    if (isExpanded) {
      return 380; // Fixed width when expanded
    } else {
      // Dynamic width when collapsed (icon width + name width + expand button + padding)
      // base width = 40 (home button) + nameWidth + 40 (expand button) + 32 (padding 16px on each side)
      return Math.max(112 + nameWidth, 180); // Minimum width of 180px
    }
  };

  return (
    <Paper
      elevation={6}
      sx={{
        position: "fixed",
        bottom: 16,
        right: 16,
        width: getComponentWidth(),
        backgroundColor: "background.paper",
        borderRadius: 2,
        p: 2,
        zIndex: 1000,
        transition: "all 0.3s cubic-bezier(0.4, 0, 0.2, 1)",
        mr: 3,
        overflow: "hidden",
        border: "1px solid",
        borderColor: "divider",
        maxWidth: "calc(100vw - 48px)", // Prevent overflow on smaller screens
      }}
    >
      {/* Top Section with Buttons */}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          mb: isExpanded ? 2 : 0,
          width: "100%", // Ensure full width
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: 1.5, flexShrink: 1 }}>
          {/* Home Button */}
          <IconButton
            onClick={openFacilityModal}
            size="small"
            sx={{
              backgroundColor: "primary.main",
              color: "white",
              "&:hover": { backgroundColor: "primary.dark" },
              transition: "all 0.2s ease",
              transform: isExpanded ? "scale(1)" : "scale(0.95)",
              flexShrink: 0,
            }}
          >
            <Home fontSize="small" />
          </IconButton>

          {/* Facility name when not expanded */}
          {!isExpanded && (
            <Fade in={!isExpanded}>
              <Box sx={{ display: "flex", alignItems: "center", width: "100%", overflow: "hidden" }}>
                <LocationOn fontSize="small" color="primary" sx={{ mr: 0.5, opacity: 0.8, flexShrink: 0 }} />
                {/* Hidden element to measure text width */}
                <Typography
                  ref={nameRef}
                  variant="subtitle2"
                  sx={{
                    position: "absolute",
                    visibility: "hidden",
                    whiteSpace: "nowrap",
                  }}
                >
                  {selectedFacility.name}
                </Typography>
                {/* Actual visible text */}
                <Typography
                  variant="subtitle2"
                  fontWeight="bold"
                  color="primary.dark"
                  sx={{
                    display: "inline-block",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {selectedFacility.name}
                </Typography>
              </Box>
            </Fade>
          )}
        </Box>

        {/* Minimize/Expand Button */}
        <IconButton
          onClick={() => setIsExpanded(!isExpanded)}
          size="small"
          sx={{
            backgroundColor: isExpanded ? "action.hover" : "primary.light",
            color: isExpanded ? "text.secondary" : "primary.contrastText",
            transition: "all 0.2s ease",
            "&:hover": {
              backgroundColor: isExpanded ? "action.selected" : "primary.main",
            },
            flexShrink: 0,
            ml: 1,
          }}
        >
          {isExpanded ? <ExpandLess fontSize="small" /> : <ExpandMore fontSize="small" />}
        </IconButton>
      </Box>

      {/* Info Content with height animation */}
      <Box
        ref={contentRef}
        sx={{
          height: isExpanded ? contentHeight : 0,
          opacity: isExpanded ? 1 : 0,
          transform: `translateY(${isExpanded ? 0 : -8}px)`,
          transition: "height 0.3s cubic-bezier(0.4, 0, 0.2, 1), opacity 0.25s ease-in-out, transform 0.3s ease",
          mt: isExpanded ? 1 : 0,
          overflow: "hidden",
          borderTop: isExpanded ? "1px solid" : "none",
          borderColor: "divider",
          pt: isExpanded ? 2 : 0,
          width: "100%", // Ensure full width
        }}
      >
        <Typography
          variant="subtitle1"
          fontWeight="bold"
          color="primary.dark"
          sx={{
            mb: 1,
            wordBreak: "break-word", // Allow long facility names to wrap
          }}
        >
          {selectedFacility.name}
        </Typography>

        <Box sx={{ display: "flex", flexDirection: "column", gap: 0.75 }}>
          <Typography variant="body2" color="text.secondary" sx={{ wordBreak: "break-word" }}>
            <strong>IK:</strong> {selectedFacility.ik_number}
          </Typography>

          {selectedFacility.extraIKNumbers && (
            <Typography variant="body2" color="text.secondary" sx={{ wordBreak: "break-word" }}>
              <strong>Extra IKs:</strong> {selectedFacility.extraIKNumbers}
            </Typography>
          )}

          <Typography variant="body2" color="text.secondary" sx={{ wordBreak: "break-word" }}>
            <strong>Addresse:</strong> {selectedFacility.street} {selectedFacility.street_number},{" "}
            {selectedFacility.postcode} {selectedFacility.city}
          </Typography>

          {hasDifferentBillingAddress && (
            <Typography variant="body2" color="text.secondary" sx={{ wordBreak: "break-word" }}>
              <strong>Rechnungsadresse:</strong> {selectedFacility.billing_street}{" "}
              {selectedFacility.billing_street_number}, {selectedFacility.billing_postcode}{" "}
              {selectedFacility.billing_city}
            </Typography>
          )}
        </Box>
      </Box>
    </Paper>
  );
};

export default FloatingInfo;
