import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { jwtDecode } from "jwt-decode";

import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Divider,
  InputAdornment,
  IconButton,
  Checkbox,
  Alert,
  Snackbar,
  Card,
  Container,
  Typography,
  Box,
  Link,
  TextField,
  CssBaseline,
  Button,
} from "@mui/material";

import {
  Person,
  BusinessCenter,
  Handshake,
  CreditCard,
  Password,
  LocalPhone,
  Mail,
  AccountBalance,
  Shuffle,
  Business,
  Visibility,
  VisibilityOff,
  AddBusiness,
  Fax,
} from "@mui/icons-material";
import { Step, StepLabel, Stepper } from "@mui/material";

import { isValidIBAN, isValidBIC } from "ibantools";

//REGEX

import { getCompany, getSpecificFacility } from "api";
import { acceptChangeOwner } from "api/changes";

import { companyColumns } from "layouts/DataGridLayouts";
import EmailRegex from "utils/emailRegex";
import { ColorlibStepIconRoot, ColorlibConnector, CustomDataGrid, ExtendableTextField, LabelName } from "components";

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {"Copyright © "}
      <Link color="inherit" href="https://www.dgp-gmbh.de">
        DGP
      </Link>{" "}
      {new Date().getFullYear()}
    </Typography>
  );
}

function StepIcon(props) {
  const { active, completed, className } = props;

  const icons = {
    1: <Person />,
    2: <BusinessCenter />,
    3: <Handshake />,
    4: <CreditCard />,
    5: <Password />,
  };

  return (
    <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}

function overwriteStepIcon(props) {
  const { active, completed, className } = props;

  const icons = {
    1: <BusinessCenter />,
    2: <CreditCard />,
    3: <AddBusiness />,
    4: <Person />,
  };

  return (
    <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}

export default function OverwriteFacility() {
  const steps = [
    "Benutzerdaten",
    "Unternehmensdaten",
    "Vereinbarung",
    "Bankverbindung einrichten",
    "Passwort festlegen",
  ];
  const overwriteSteps = ["Unternehmensdaten", "Bankverbindung", "Übernahme", "Kontaktperson"];
  const { token } = useParams();
  const dispatch = useDispatch();

  const extendetCompanyColumns = [...companyColumns];

  const facility = useSelector((state) => state.facility);
  const companies = useSelector((state) => state.company);

  //Button Values

  const [buttonLeft, setButtonLeft] = React.useState("Zurück");
  const [buttonRight, setButtonRight] = React.useState("Weiter");

  const [open, setOpen] = React.useState(false);
  const [openSelect, setOpenSelect] = React.useState(false);
  const [activeStep, setActiveStep] = React.useState(0);
  const [overwriteActiveStep, setOverwriteActiveStep] = React.useState(0);

  //PAGE UserData(0)
  const [gender, setGender] = React.useState(0);
  const [first_name, setFirstName] = React.useState("");
  const [last_name, setLastName] = React.useState("");
  const [phone_number, setPhoneNumber] = React.useState("");
  const [faxNumber, setFaxNumber] = React.useState("");
  const [email, setEmail] = React.useState("");

  //PAGE CompanyData(1)

  const [ik_number, setIKNumber] = React.useState(facility[0]?.ik_number);
  const [company_name, setCompanyName] = React.useState(facility[0]?.name);
  const [extra, setExtra] = React.useState(facility[0]?.extra);
  const [street, setStreet] = React.useState(facility[0]?.street);
  const [post_code, setPostCode] = React.useState(facility[0]?.postcode);
  const [city, setCity] = React.useState(facility[0]?.city);
  const [street_number, setStreetNumber] = React.useState(facility[0]?.street_number);

  //PAGE Vereinbarungen(2)
  const [lastSchrift, setLastSchrift] = React.useState(false);
  const [acceptDataSecurity, setAcceptDataSecurity] = React.useState(false);
  const [certification, setCertification] = React.useState(false);
  const [authorized, setAuthorized] = React.useState(false);

  //PAGE Payment(3)
  const [creditInstituteName, setCreditInstituteName] = React.useState("");
  const [IBAN, setIBAN] = React.useState("");
  const ibanRegex = /^[A-Z]{2}\d{2}[A-Z0-9]{1,30}$/;
  const [IBANError, setIBANError] = React.useState(false);
  const [BIC, setBIC] = React.useState("");
  const bicRegex = /^[A-Z]{6}[A-Z2-9]{2}[A-NP-Z0-9]{3}$/;
  const [BICError, setBICError] = React.useState(false);
  const [differentBillingAddress, setDifferentBillingAddress] = React.useState(
    facility[0]?.billing_street_number ? true : false
  );
  const [billing_email, setBillingEmail] = React.useState(facility[0]?.billing_email);
  const [billing_street, setBillingStreet] = React.useState(facility[0]?.billing_street);
  const [billing_post_code, setBillingPostCode] = React.useState(facility[0]?.billing_postcode);
  const [billing_city, setBillingCity] = React.useState(facility[0]?.billing_city);
  const [billing_street_number, setBillingStreetNumber] = React.useState(facility[0]?.billing_street_number);

  //PAGE Passwort(4)
  const [showPassword, setShowPassword] = React.useState(false);
  const [showPassword2, setShowPassword2] = React.useState(false);
  const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
  const [password, setPassword] = React.useState("");
  const [passwordError, setPasswordError] = React.useState(false);
  const [password2, setPassword2] = React.useState("");
  const [password2Error, setPassword2Error] = React.useState(false);
  const [unequalPasswordError, setUnequalPasswordError] = React.useState(false);

  //
  const [textFields, setTextFields] = React.useState([{ id: 1, value: "" }]);

  const [sendetInformations, setSendetInformations] = React.useState({});
  // const [scope, setScope] = React.useState(facility[0]?.scope ? facility[0]?.scope : 1);
  const [slave, setSlave] = React.useState(false);
  const [indipendent, setIndipendent] = React.useState(false);
  const [selected, setSelected] = React.useState();

  const [contactPersonFromSelected, setContactPersonFromSelected] = React.useState(false);
  const [oldContactPerson, setOldContactPerson] = React.useState(false);
  const [newContactPerson, setNewContactPerson] = React.useState(false);

  React.useEffect(() => {
    if (token === undefined) return;

    const decodedToken = jwtDecode(token);
    if (Object.keys(decodedToken).length === 0) return;

    dispatch(getSpecificFacility(decodedToken.facilityId));
    setSendetInformations(decodedToken);
    if (decodedToken.accountId) {
      //check if account already excist
      dispatch(getCompany(decodedToken.accountId));
    }
    setEmail(decodedToken.email);
  }, [dispatch, token]);

  const handleClick = (value) => {
    if (!sendetInformations.accountId) {
      switch (activeStep) {
        case 0: {
          if (value) {
            if (!!first_name && !!last_name && EmailRegex(email) && !!phone_number) {
              //Activate NextButton
              setButtonLeft("Zurück");
              setActiveStep(activeStep + 1);
            }
          }
          break;
        }
        case 1: {
          if (!value) {
            //Error Message
            setActiveStep(activeStep - 1);
            setButtonLeft("Zum Login zurückkehren");
          } else {
            if (!!ik_number && !!company_name && !!street && !!street_number && !!post_code && !!city) {
              setActiveStep(activeStep + 1);
            }
          }
          break;
        }
        case 2: {
          if (!value) {
            setActiveStep(activeStep - 1);
          } else {
            if (!lastSchrift || !acceptDataSecurity || !certification || !authorized) {
              setOpenSelect(true);
            } else {
              setActiveStep(activeStep + 1);
            }
          }
          break;
        }
        case 3: {
          if (!value) {
            setActiveStep(activeStep - 1);
          } else {
            //CHECK IBAN BIC
            const isBICValid = isValidBIC(BIC.replace(/\s+/g, "").trim());
            const isIBANValid = isValidIBAN(IBAN.replace(/\s+/g, "").trim());
            if (isBICValid && isIBANValid) {
              setActiveStep(activeStep + 1);
              setButtonRight("Registrierung abschliessen");
            } else setOpen(true);
          }
          break;
        }
        case 4: {
          if (!value) {
            setActiveStep(activeStep - 1);
            setButtonRight("Weiter");
          } else {
            if (passwordRegex.test(password) && passwordRegex.test(password2) && !unequalPasswordError) {
              //Fertigstellen CREATE ACCOUNT - CREATE COMPANY - CREATE FACILITY
              console.log("hier?");
              approveAcception();
            } else {
              console.log("dort");
              return "";
            } //RETURN ERROR
          }
          break;
        }
        default:
          break;
      }
    } else {
      switch (overwriteActiveStep) {
        case 0: {
          if (value) {
            if (!!ik_number && !!company_name && !!street && !!street_number && !!post_code && !!city) {
              setOverwriteActiveStep(overwriteActiveStep + 1);
            }
          }
          break;
        }
        case 1: {
          if (!value) {
            setOverwriteActiveStep(overwriteActiveStep - 1);
          } else {
            //CHECK IBAN BIC
            // const isBICValid = isValidBIC(BIC);
            // const isIBANValid = isValidIBAN(IBAN);
            // if (isBICValid && isIBANValid) {
            setOverwriteActiveStep(overwriteActiveStep + 1);
            //}
            //else setOpen(true);
          }
          break;
        }
        case 2: {
          if (!value) {
            setOverwriteActiveStep(overwriteActiveStep - 1);
          } else {
            if ((!indipendent && !slave) || (slave && !selected)) {
              setOpenSelect(true);
            } else {
              setOverwriteActiveStep(overwriteActiveStep + 1);
              setButtonRight("Abschließen");
            }
          }
          break;
        }
        case 3: {
          if (!value) {
            setOverwriteActiveStep(overwriteActiveStep - 1);
            setButtonRight("Weiter");
          } else {
            if (
              newContactPerson &&
              (!!gender || !!first_name || !!last_name || !!phone_number || !!setFaxNumber || !!email)
            ) {
              setOpenSelect(true);
            } else {
              approveAcception();
            }
          }
          break;
        }
        default: {
          break;
        }
      }
    }
  };

  const approveAcception = () => {
    const Account = {
      firstName: first_name,
      lastName: last_name,
      email: email,
      password: password,
      gender: gender,
    };
    const Company = {
      name: company_name,
      ik_number: ik_number,
      extraIKNumbers: textFields.map((field) => field.value).join(","),
      street: street,
      street_number: street_number,
      extra_information: extra,
      postcode: post_code,
      city: city,
      creditInstituteName: creditInstituteName,
      BIC: BIC.replace(/\s+/g, "").trim(),
      IBAN: IBAN.replace(/\s+/g, "").trim(),
      phone_number: phone_number,
      fax_number: faxNumber,
      email: email,
      gender: gender,
      differentBillingAddress: differentBillingAddress,
      billing_city: billing_city,
      billing_email: billing_email,
      billing_post_code: billing_post_code,
      billing_street: billing_street,
      billing_street_number: billing_street_number,
    };

    const Informations = {
      slave: slave,
      indipendent: indipendent,
      parentId: selected?.id,
      contactPersonFromSelected: contactPersonFromSelected,
      oldContactPerson: oldContactPerson,
      newContactPerson: newContactPerson,
      facilityToChangeId: sendetInformations?.facilityId,
      newOwnerAccountId: sendetInformations?.accountId,
    };

    acceptChangeOwner(Account, Company, Informations);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleBankDetails = () => {
    if (activeStep !== 1) return false;
    return activeStep === 1 && (IBAN === "" || BIC === "");
  };

  return (
    <Container component="main" maxWidth="md">
      <CssBaseline />
      <Card
        sx={{
          mt: 8,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          p: 10,
          boxShadow: 3,
        }}
        // onKeyDown={(event) => {
        //   console.log(event.key);
        //   // if (event.key === "Enter") handleClick(true);
        // }}
      >
        {!sendetInformations.accountId ? (
          <Box sx={{ mb: 5 }}>
            <Stepper activeStep={activeStep} alternativeLabel connector={<ColorlibConnector />}>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel StepIconComponent={StepIcon}>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            {activeStep === 0 && (
              <>
                <Divider />
                <Box
                  sx={{
                    mt: 5,
                    width: "100%",
                    display: "flex",
                    flex: 1,
                    flexDirection: "column",
                    alignContent: "flex-start",
                  }}
                >
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Anrede</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={gender}
                      label="Age"
                      onChange={(e) => setGender(e.target.value)}
                    >
                      <MenuItem value={0}>Herr</MenuItem>
                      <MenuItem value={1}>Frau</MenuItem>
                      <MenuItem value={2}>* (divers)</MenuItem>
                    </Select>
                  </FormControl>
                  <Box sx={{ display: "flex", flexDirection: "row", flex: 1, justifyContent: "space-between" }}>
                    <Box sx={{ flex: 0.5, mt: 2 }}>
                      <TextField
                        sx={{ width: "80%" }}
                        label="Vorname"
                        value={first_name}
                        onChange={(e) => setFirstName(e.target.value)}
                      />
                    </Box>
                    <Box sx={{ flex: 0.5, mt: 2 }}>
                      <TextField
                        sx={{ width: "80%" }}
                        label="Nachname"
                        value={last_name}
                        onChange={(e) => setLastName(e.target.value)}
                      />
                    </Box>
                  </Box>
                  <TextField
                    sx={{ mt: 2 }}
                    label="Telefon"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <LocalPhone />
                        </InputAdornment>
                      ),
                    }}
                    value={phone_number}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                  />
                  <TextField
                    sx={{ mt: 2 }}
                    label="Fax"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Fax />
                        </InputAdornment>
                      ),
                    }}
                    value={faxNumber}
                    onChange={(e) => setFaxNumber(e.target.value)}
                  />

                  <TextField
                    sx={{ mt: 2 }}
                    label="E-Mail-Adresse"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Mail />
                        </InputAdornment>
                      ),
                    }}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Box>
              </>
            )}
            {activeStep === 1 && (
              <>
                <Divider />
                <Box
                  sx={{
                    mt: 5,
                    width: "100%",
                    display: "flex",
                    flex: 1,
                    flexDirection: "column",
                    alignContent: "flex-start",
                  }}
                >
                  <Box sx={{ display: "flex", flexDirection: "row", flex: 1, justifyContent: "space-between" }}>
                    <Box sx={{ flex: 0.5, mt: 2, mr: 0.04 }}>
                      <TextField
                        sx={{ mt: 2, width: "100%" }}
                        label="IK-Nummer"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <AccountBalance />
                            </InputAdornment>
                          ),
                        }}
                        value={ik_number}
                        onChange={(e) => setIKNumber(e.target.value)}
                      />
                    </Box>
                    <Box sx={{ flex: 0.5, mt: 2 }}>
                      <Button
                        sx={{ mt: 2, minHeight: 56, width: "100%" }}
                        variant="contained"
                        startIcon={<Shuffle />}
                        onClick={() => {
                          const randomValue = `t${Math.floor(Math.random() * 1000000000)}`;
                          setIKNumber(randomValue);
                        }}
                      >
                        Temporäre IK-Nummer
                      </Button>
                    </Box>
                  </Box>
                  <ExtendableTextField textFields={textFields} setTextFields={setTextFields} />
                  <TextField
                    sx={{ mt: 2 }}
                    label="Name der Firma"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Business />
                        </InputAdornment>
                      ),
                    }}
                    value={company_name}
                    onChange={(e) => setCompanyName(e.target.value)}
                  />
                  <TextField sx={{ mt: 2 }} label="Zusatz" value={extra} onChange={(e) => setExtra(e.target.value)} />
                  <Box sx={{ display: "flex", flex: 1, justifyContent: "space-between" }}>
                    <TextField
                      sx={{ mt: 2, display: "flex", flex: 0.6 }}
                      label="Straße"
                      value={street}
                      onChange={(e) => setStreet(e.target.value)}
                    />
                    <TextField
                      sx={{ mt: 2, display: "flex", flex: 0.3 }}
                      label="Hausnummer"
                      value={street_number}
                      onChange={(e) => setStreetNumber(e.target.value)}
                    />
                  </Box>
                  <Box sx={{ display: "flex", flexDirection: "row", flex: 1, justifyContent: "space-between" }}>
                    <TextField
                      sx={{ mt: 2, display: "flex", flex: 0.3 }}
                      label="Postleitzahl"
                      value={post_code}
                      onChange={(e) => setPostCode(e.target.value)}
                    />
                    <TextField
                      sx={{ mt: 2, display: "flex", flex: 0.6 }}
                      label="Ort"
                      value={city}
                      onChange={(e) => setCity(e.target.value)}
                    />
                  </Box>
                </Box>
              </>
            )}
            {activeStep === 2 && (
              <>
                <Divider />
                <Box sx={{ mt: 5 }}>
                  <Box sx={{ display: "flex", flexDirection: "row", mb: 2, alignItems: "center" }}>
                    <Checkbox
                      name="lastschrift"
                      color="primary"
                      checked={lastSchrift}
                      onChange={() => setLastSchrift(!lastSchrift)}
                    />
                    <Typography>Ich stimme dem Lastschrifteinzugsverfahren zu.</Typography>
                  </Box>
                  <Box sx={{ display: "flex", flexDirection: "row", mb: 2, alignItems: "center" }}>
                    <Checkbox
                      color="primary"
                      checked={acceptDataSecurity}
                      onClick={() => setAcceptDataSecurity(!acceptDataSecurity)}
                    />
                    <Typography>Ich akzeptiere die</Typography>
                    <Link
                      href="https://www.dgp-gmbh.de/datenschutz/"
                      target="_blank"
                      rel="noopener noreferrer"
                      sx={{ ml: 1 }}
                    >
                      Datenschutzerklärung.
                    </Link>
                  </Box>
                  <Box sx={{ display: "flex", flexDirection: "row", mb: 2, alignItems: "center" }}>
                    <Checkbox
                      color="primary"
                      checked={certification}
                      onClick={() => setCertification(!certification)}
                    />
                    <Typography>Ich akzeptiere die</Typography>
                    <Link
                      href="https://www.dgp-gmbh.de/zertifizierungsvereinbarung/"
                      target="_blank"
                      rel="noopener noreferrer"
                      sx={{ ml: 1 }}
                    >
                      Zertifizierungsvereinbarung.
                    </Link>
                  </Box>
                  <Box sx={{ display: "flex", flexDirection: "row", mb: 2, alignItems: "center" }}>
                    <Checkbox color="primary" checked={authorized} onClick={() => setAuthorized(!authorized)} />
                    <Typography>
                      {" "}
                      Ich bestätige, dass ich zum Zeitpunkt der Antragstellung bevollmächtigt bin.
                    </Typography>
                  </Box>
                </Box>
              </>
            )}

            {activeStep === 3 && (
              <>
                <Divider />
                <Box sx={{ mt: 5, display: "flex", flexDirection: "column" }}>
                  <TextField
                    label="Name des Kreditinstitutes (Optional)"
                    value={creditInstituteName}
                    onChange={(e) => setCreditInstituteName(e.target.value)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Business />
                        </InputAdornment>
                      ),
                    }}
                  />
                  <Box sx={{ display: "flex", flexDirection: "row", flex: 1, justifyContent: "space-between" }}>
                    <Box sx={{ flex: 0.5, mt: 2 }}>
                      <TextField
                        sx={{ width: "90%" }}
                        label="IBAN"
                        value={IBAN}
                        onChange={(event) => {
                          const inputValue = event.target.value;
                          const trimmedValue = inputValue.replace(/\s+/g, "").trim();
                          setIBAN(inputValue.toUpperCase());

                          // Validate the IBAN against the regex pattern
                          setIBANError(!isValidIBAN(trimmedValue));
                        }}
                        error={IBANError}
                        helperText={IBANError ? "Bitte geben Sie eine gültige IBAN ein" : ""}
                        InputProps={{
                          inputProps: {
                            pattern: ibanRegex, // Assign the regex pattern
                          },
                        }}
                      />
                    </Box>
                    <Box sx={{ flex: 0.5, mt: 2 }}>
                      <TextField
                        label="BIC"
                        sx={{ width: "90%" }}
                        value={BIC}
                        onChange={(event) => {
                          const inputValue = event.target.value;
                          const trimmedValue = inputValue.replace(/\s+/g, "").trim();
                          setBIC(inputValue.toUpperCase());

                          // Validate the BIC against the regex pattern
                          setBICError(!isValidBIC(trimmedValue));
                        }}
                        error={BICError}
                        helperText={BICError ? "Bitte geben Sie eine gültige BIC ein" : ""}
                        InputProps={{
                          inputProps: {
                            pattern: bicRegex, // Assign the regex pattern
                          },
                        }}
                      />
                    </Box>
                  </Box>
                  <Box sx={{ display: "flex", flex: 1, alignItems: "center", mt: 2 }}>
                    <Checkbox
                      color="primary"
                      checked={differentBillingAddress}
                      onClick={() => setDifferentBillingAddress(!differentBillingAddress)}
                    />
                    <Typography>Von der Betriebsanschrift abweichende Rechnungsadresse</Typography>
                  </Box>
                  {differentBillingAddress === true && (
                    <>
                      <Divider textAlign="left" sx={{ my: 2 }}>
                        <Typography fontWeight={"bold"}>Rechnungsaddresse</Typography>
                      </Divider>
                      <Box sx={{ display: "flex", flex: 1, justifyContent: "space-between" }}>
                        <TextField
                          sx={{ mt: 2, display: "flex", flex: 0.6 }}
                          label="Rechnungs-Email"
                          value={billing_email}
                          onChange={(e) => setBillingEmail(e.target.value)}
                        />
                      </Box>
                      <Box sx={{ display: "flex", flex: 1, justifyContent: "space-between" }}>
                        <TextField
                          sx={{ mt: 2, display: "flex", flex: 0.6 }}
                          label="Straße"
                          value={billing_street}
                          onChange={(e) => setBillingStreet(e.target.value)}
                        />
                        <TextField
                          sx={{ mt: 2, display: "flex", flex: 0.3 }}
                          label="Hausnummer"
                          value={billing_street_number}
                          onChange={(e) => setBillingStreetNumber(e.target.value)}
                        />
                      </Box>
                      <Box sx={{ display: "flex", flexDirection: "row", flex: 1, justifyContent: "space-between" }}>
                        <TextField
                          sx={{ mt: 2, display: "flex", flex: 0.3 }}
                          label="Postleitzahl"
                          value={billing_post_code}
                          onChange={(e) => setBillingPostCode(e.target.value)}
                        />
                        <TextField
                          sx={{ mt: 2, display: "flex", flex: 0.6 }}
                          label="Ort"
                          value={billing_city}
                          onChange={(e) => setBillingCity(e.target.value)}
                        />
                      </Box>
                    </>
                  )}
                </Box>
              </>
            )}
            {activeStep === 4 && (
              <>
                <Divider />

                <Box sx={{ display: "flex", flexDirection: "column", mt: 5 }}>
                  <TextField
                    label="Password"
                    type={showPassword ? "text" : "password"}
                    value={password}
                    onChange={(event) => {
                      const inputValue = event.target.value;
                      setPassword(inputValue);

                      // Validate the password against the regex pattern
                      setPasswordError(!passwordRegex.test(inputValue));
                      setUnequalPasswordError(inputValue !== password2);
                    }}
                    error={passwordError}
                    helperText={
                      passwordError
                        ? "Password must contain at least 8 characters, one uppercase, one lowercase, one number, and one special character"
                        : unequalPasswordError
                        ? "Die Passwörter sind unterschiedlich"
                        : ""
                    }
                    InputProps={{
                      inputProps: {
                        pattern: passwordRegex, // Assign the regex pattern
                      },
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setShowPassword(!showPassword)}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <TextField
                    label="Password"
                    type={showPassword2 ? "text" : "password"}
                    value={password2}
                    sx={{ mt: 3 }}
                    onChange={(event) => {
                      const inputValue = event.target.value;
                      setPassword2(inputValue);
                      // Validate the password against the regex pattern
                      setPassword2Error(!passwordRegex.test(inputValue));
                      setUnequalPasswordError(password !== inputValue);
                    }}
                    error={password2Error}
                    helperText={
                      password2Error
                        ? "Password must contain at least 8 characters, one uppercase, one lowercase, one number, and one special character"
                        : unequalPasswordError
                        ? "Die Passwörter sind unterschiedlich"
                        : ""
                    }
                    InputProps={{
                      inputProps: {
                        pattern: passwordRegex, // Assign the regex pattern
                      },
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setShowPassword2(!showPassword2)}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword2 ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
              </>
            )}
          </Box>
        ) : (
          <Box sx={{ mb: 5 }}>
            <Stepper activeStep={overwriteActiveStep} alternativeLabel connector={<ColorlibConnector />}>
              {overwriteSteps.map((label) => (
                <Step key={label}>
                  <StepLabel StepIconComponent={overwriteStepIcon}>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            {overwriteActiveStep === 0 && (
              <>
                <Divider />
                <Box
                  sx={{
                    mt: 5,
                    width: "100%",
                    display: "flex",
                    flex: 1,
                    flexDirection: "column",
                    alignContent: "flex-start",
                  }}
                >
                  <Box sx={{ display: "flex", flexDirection: "row", flex: 1, justifyContent: "space-between" }}>
                    <Box sx={{ flex: 0.5, mt: 2, mr: 0.04 }}>
                      <TextField
                        sx={{ mt: 2, width: "100%" }}
                        label="IK-Nummer"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <AccountBalance />
                            </InputAdornment>
                          ),
                        }}
                        value={ik_number}
                        onChange={(e) => setIKNumber(e.target.value)}
                      />
                    </Box>
                    <Box sx={{ flex: 0.5, mt: 2 }}>
                      <Button
                        sx={{ mt: 2, minHeight: 56, width: "100%" }}
                        variant="contained"
                        startIcon={<Shuffle />}
                        onClick={() => {
                          const randomValue = `t${Math.floor(Math.random() * 1000000000)}`;
                          setIKNumber(randomValue);
                        }}
                      >
                        Temporäre IK-Nummer
                      </Button>
                    </Box>
                  </Box>
                  <ExtendableTextField textFields={textFields} setTextFields={setTextFields} />
                  <TextField
                    sx={{ mt: 2 }}
                    label="Name der Firma"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Business />
                        </InputAdornment>
                      ),
                    }}
                    value={company_name}
                    onChange={(e) => setCompanyName(e.target.value)}
                  />
                  <TextField sx={{ mt: 2 }} label="Zusatz" value={extra} onChange={(e) => setExtra(e.target.value)} />
                  <Box sx={{ display: "flex", flex: 1, justifyContent: "space-between" }}>
                    <TextField
                      sx={{ mt: 2, display: "flex", flex: 0.6 }}
                      label="Straße"
                      value={street}
                      onChange={(e) => setStreet(e.target.value)}
                    />
                    <TextField
                      sx={{ mt: 2, display: "flex", flex: 0.3 }}
                      label="Hausnummer"
                      value={street_number}
                      onChange={(e) => setStreetNumber(e.target.value)}
                    />
                  </Box>
                  <Box sx={{ display: "flex", flexDirection: "row", flex: 1, justifyContent: "space-between" }}>
                    <TextField
                      sx={{ mt: 2, display: "flex", flex: 0.3 }}
                      label="Postleitzahl"
                      value={post_code}
                      onChange={(e) => setPostCode(e.target.value)}
                    />
                    <TextField
                      sx={{ mt: 2, display: "flex", flex: 0.6 }}
                      label="Ort"
                      value={city}
                      onChange={(e) => setCity(e.target.value)}
                    />
                  </Box>
                </Box>
              </>
            )}
            {overwriteActiveStep === 1 && (
              <>
                <Divider />
                <Box sx={{ mt: 5, display: "flex", flexDirection: "column" }}>
                  <Box sx={{ display: "flex", flexDirection: "row", flex: 1, justifyContent: "space-between" }}>
                    <Box sx={{ flex: 0.5, mt: 2 }}>
                      <TextField
                        sx={{ width: "90%" }}
                        label="IBAN"
                        value={IBAN}
                        onChange={(event) => {
                          const inputValue = event.target.value;
                          setIBAN(inputValue.toUpperCase());

                          // Validate the IBAN against the regex pattern
                          setIBANError(!isValidIBAN(inputValue));
                        }}
                        error={IBANError}
                        helperText={IBANError ? "Bitte geben Sie eine gültige IBAN ein" : ""}
                        InputProps={{
                          inputProps: {
                            pattern: ibanRegex, // Assign the regex pattern
                          },
                        }}
                      />
                    </Box>
                    <Box sx={{ flex: 0.5, mt: 2 }}>
                      <TextField
                        label="BIC"
                        sx={{ width: "90%" }}
                        value={BIC}
                        onChange={(event) => {
                          const inputValue = event.target.value;
                          setBIC(inputValue.toUpperCase());

                          // Validate the BIC against the regex pattern
                          setBICError(!isValidBIC(inputValue));
                        }}
                        error={BICError}
                        helperText={BICError ? "Bitte geben Sie eine gültige BIC ein" : ""}
                        InputProps={{
                          inputProps: {
                            pattern: bicRegex, // Assign the regex pattern
                          },
                        }}
                      />
                    </Box>
                  </Box>
                  <Box sx={{ display: "flex", flex: 1, alignItems: "center", mt: 2 }}>
                    <Checkbox
                      color="primary"
                      checked={differentBillingAddress}
                      onClick={() => setDifferentBillingAddress(!differentBillingAddress)}
                    />
                    <Typography>Von der Betriebsanschrift abweichende Rechnungsadresse</Typography>
                  </Box>
                  {differentBillingAddress === true && (
                    <>
                      <Divider textAlign="left" sx={{ my: 2 }}>
                        <Typography fontWeight={"bold"}>Rechnungsaddresse</Typography>
                      </Divider>
                      <Box sx={{ display: "flex", flex: 1, justifyContent: "space-between" }}>
                        <TextField
                          sx={{ mt: 2, display: "flex", flex: 0.6 }}
                          label="Rechnungs-Email"
                          value={billing_email}
                          onChange={(e) => setBillingEmail(e.target.value)}
                        />
                      </Box>
                      <Box sx={{ display: "flex", flex: 1, justifyContent: "space-between" }}>
                        <TextField
                          sx={{ mt: 2, display: "flex", flex: 0.6 }}
                          label="Straße"
                          value={billing_street}
                          onChange={(e) => setBillingStreet(e.target.value)}
                        />
                        <TextField
                          sx={{ mt: 2, display: "flex", flex: 0.3 }}
                          label="Hausnummer"
                          value={billing_street_number}
                          onChange={(e) => setBillingStreetNumber(e.target.value)}
                        />
                      </Box>
                      <Box sx={{ display: "flex", flexDirection: "row", flex: 1, justifyContent: "space-between" }}>
                        <TextField
                          sx={{ mt: 2, display: "flex", flex: 0.3 }}
                          label="Postleitzahl"
                          value={billing_post_code}
                          onChange={(e) => setBillingPostCode(e.target.value)}
                        />
                        <TextField
                          sx={{ mt: 2, display: "flex", flex: 0.6 }}
                          label="Ort"
                          value={billing_city}
                          onChange={(e) => setBillingCity(e.target.value)}
                        />
                      </Box>
                    </>
                  )}
                </Box>
              </>
            )}
            {overwriteActiveStep === 2 && (
              <>
                <Divider />
                <Box sx={{ mt: 5, display: "flex", flexDirection: "column" }}>
                  <Box sx={{ display: "flex", flexDirection: "row", flex: 1, alignItems: "center" }}>
                    <Checkbox
                      checked={indipendent}
                      onClick={() => {
                        if (slave === true) {
                          setSlave(false);
                        }
                        setIndipendent(!indipendent);
                      }}
                    />
                    <Typography>
                      {sendetInformations?.isMainHouse
                        ? "Die Betriebsstätte ist ein Haupthaus, möchten Sie dies so beibehalten?"
                        : "Die Betriebsstätte ist derzeit einer anderen Untergeordnet, möchten Sie diese Eigenständig machen?"}
                    </Typography>
                  </Box>
                  <Box sx={{ display: "flex", flexDirection: "row", flex: 1, alignItems: "center" }}>
                    <Checkbox
                      checked={slave}
                      onClick={() => {
                        if (indipendent === true) {
                          setIndipendent(false);
                        }
                        setSlave(!slave);
                      }}
                    />
                    <Typography>
                      {sendetInformations?.isMainHouse
                        ? "Soll die Betriebsstätte einem Ihrer Unternehmen untergeordnet werden?"
                        : "Möchten Sie die Betriebsstelle einer Ihrer Unternehmen unterordnen?"}
                    </Typography>
                  </Box>
                </Box>
                {slave && (
                  <CustomDataGrid
                    rows={companies}
                    columns={extendetCompanyColumns}
                    initialPageSize={100}
                    checkboxSelection
                    onSelectionModelChange={(rows) => {
                      if (rows.length === 0) {
                        if (selected) {
                          setSelected(null);
                        }
                        return;
                      }
                      const actionListObject = companies?.filter((item) => item.id.includes(rows));
                      setSelected(actionListObject[0]);
                    }}
                  />
                )}
              </>
            )}
            {overwriteActiveStep === 3 && (
              <>
                <Divider />
                <Box sx={{ mt: 5, display: "flex", flexDirection: "column" }}>
                  <Box sx={{ display: "flex", flexDirection: "row", flex: 1, alignItems: "center" }}>
                    <Checkbox
                      checked={contactPersonFromSelected}
                      onClick={() => {
                        if (oldContactPerson || newContactPerson) {
                          setOldContactPerson(false);
                          setNewContactPerson(false);
                        }
                        setContactPersonFromSelected(!contactPersonFromSelected);
                      }}
                    />
                    <Typography> Möchten Sie die Kontakperson des Ausgewählten Unternehmens übernehmen?</Typography>
                  </Box>
                  <Box sx={{ display: "flex", flexDirection: "row", flex: 1, alignItems: "center" }}>
                    <Checkbox
                      checked={oldContactPerson}
                      onClick={() => {
                        if (newContactPerson || contactPersonFromSelected) {
                          setNewContactPerson(false);
                          setContactPersonFromSelected(false);
                        }
                        setOldContactPerson(!oldContactPerson);
                      }}
                    />
                    <Typography> Möchten Sie die Kontaktperson des Unternehmens übernehmen?</Typography>
                  </Box>
                  <Box sx={{ display: "flex", flexDirection: "row", flex: 1, alignItems: "center" }}>
                    <Checkbox
                      checked={newContactPerson}
                      onClick={() => {
                        if (oldContactPerson || contactPersonFromSelected) {
                          setOldContactPerson(false);
                          setContactPersonFromSelected(false);
                        }
                        setNewContactPerson(!newContactPerson);
                      }}
                    />
                    <Typography>Möchten Sie eine Neue Kontakperson für dieses Unternehmen anlegen?</Typography>
                  </Box>
                </Box>
                {newContactPerson && (
                  <>
                    <Card sx={{ p: 3, mt: 2 }}>
                      <Divider textAlign="left" sx={{ mt: 2, mb: 2 }}>
                        <Typography fontWeight={"bold"}>Daten</Typography>
                      </Divider>
                      <Box sx={{ display: "flex", flexDirection: "row", mt: 2, mb: 2, flex: 1 }}>
                        <LabelName titel="Anrede" />
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={gender}
                          onChange={(e) => setGender(e.target.value)}
                          sx={{ flex: 0.75 }}
                        >
                          <MenuItem value={0}>Herr</MenuItem>
                          <MenuItem value={1}>Frau</MenuItem>
                          <MenuItem value={2}>* (divers)</MenuItem>
                        </Select>
                      </Box>
                      <Box sx={{ display: "flex", flexDirection: "row", mt: 2, mb: 2, flex: 1 }}>
                        <LabelName titel="Vorname" />
                        <TextField
                          sx={{ flex: 0.75 }}
                          label="Name"
                          value={first_name}
                          onChange={(e) => setFirstName(e.target.value)}
                        />
                      </Box>
                      <Box sx={{ display: "flex", flexDirection: "row", mt: 2, mb: 2, flex: 1 }}>
                        <LabelName titel="Nachname" />
                        <TextField
                          sx={{ flex: 0.75 }}
                          label="Nachname"
                          value={last_name}
                          onChange={(e) => setLastName(e.target.value)}
                        />
                      </Box>
                      <Box sx={{ display: "flex", flex: 1, justifyContent: "space-between", mt: 2, mb: 2 }}>
                        <LabelName titel="Straße, Hausnummer" />
                        <TextField
                          sx={{ display: "flex", flex: 0.5 }}
                          label="Straße"
                          value={street}
                          onChange={(e) => setStreet(e.target.value)}
                        />
                        <TextField
                          sx={{ display: "flex", flex: 0.25 }}
                          label="Hausnummer"
                          value={street_number}
                          onChange={(e) => setStreetNumber(e.target.value)}
                        />
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          flex: 1,
                          justifyContent: "space-between",
                          mt: 2,
                          mb: 2,
                        }}
                      >
                        <LabelName titel="Adresse" />
                        <TextField
                          sx={{ display: "flex", flex: 0.25 }}
                          label="Postleitzahl"
                          value={post_code}
                          onChange={(e) => setPostCode(e.target.value)}
                        />
                        <TextField
                          sx={{ display: "flex", flex: 0.5 }}
                          label="Ort"
                          value={city}
                          onChange={(e) => setCity(e.target.value)}
                        />
                      </Box>
                      <Box sx={{ display: "flex", flexDirection: "row", mt: 2, mb: 2, flex: 1 }}>
                        <LabelName titel="Telefonnummer" />
                        <TextField
                          sx={{ flex: 0.75 }}
                          label="Telefonnummer"
                          value={phone_number}
                          onChange={(e) => setPhoneNumber(e.target.value)}
                        />
                      </Box>
                      <Box sx={{ display: "flex", flexDirection: "row", mt: 2, mb: 2, flex: 1 }}>
                        <LabelName titel="Fax Nummer" />
                        <TextField
                          sx={{ flex: 0.75 }}
                          label="Faxnummer"
                          value={faxNumber}
                          onChange={(e) => setFaxNumber(e.target.value)}
                        />
                      </Box>

                      <Box sx={{ display: "flex", flexDirection: "row", mt: 2, mb: 2, flex: 1 }}>
                        <LabelName titel="Email" />
                        <TextField
                          sx={{ flex: 0.75 }}
                          label="email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </Box>
                    </Card>
                  </>
                )}
              </>
            )}
          </Box>
        )}
        <Box sx={{ display: "flex", flex: 1, justifyContent: "space-between" }}>
          <Button sx={{ flex: 0.3 }} size="small" variant="contained" onClick={() => handleClick(false)}>
            {buttonLeft}
          </Button>
          <Button
            sx={{ flex: 0.3, textTransform: "none" }}
            size="small"
            variant="contained"
            primary
            disabled={handleBankDetails()}
            onClick={() => handleClick(true)}
          >
            {buttonRight}
          </Button>
        </Box>
      </Card>
      <Copyright sx={{ mt: 5 }} />
      <Snackbar open={open} autoHideDuration={2000} onClose={() => setOpen(false)}>
        <Alert variant="filled" severity="error">
          Bitte füllen Sie die vorgegebenen Felder aus!
        </Alert>
      </Snackbar>

      <Snackbar open={openSelect} autoHideDuration={2000} onClose={() => setOpen(false)}>
        <Alert variant="filled" severity="error">
          Bitte bestätigen Sie alle Felder um fortzufahren.
        </Alert>
      </Snackbar>
    </Container>
  );
}
