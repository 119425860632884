import React from "react";
import { Container } from "@mui/material";

const CustomContainer = (props) => {
  const { children, ...other } = props;

  return (
    <Container component={"main"} maxWidth={"xl"} {...other}>
      {children}
    </Container>
  );
};

export default CustomContainer;
