import React from "react";
import { Tabs, Tab } from "@mui/material";
import { Check, PriorityHigh } from "@mui/icons-material";

export default function DefinedTabs({
  value,
  handleChange,
  FirstTabLabel = "zu erbringene Nachweise",
  SecondTabLabel = "Akzeptiert",
}) {
  return (
    <Tabs variant="fullWidth" sx={{ width: "100%" }} selectionFollowsFocus value={value} onChange={handleChange}>
      <Tab icon={<PriorityHigh />} label={FirstTabLabel} sx={{ textTransform: "none" }} />

      <Tab icon={<Check />} label={SecondTabLabel} />
    </Tabs>
  );
}
