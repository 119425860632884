import { Backdrop, Box, CircularProgress, Typography } from "@mui/material";
import React from "react";

/**
 *
 * @param {boolean} open - Loading Indicator open state
 * @param {string} message - String message to display
 * @description Custom Loading Indicator component
 * @returns {JSX.Element}
 */
const CustomLoadingIndicator = ({ open = false, message = "Einen Moment bitte..." }) => {
  return (
    <Backdrop sx={(theme) => ({ color: "#fff", zIndex: theme.zIndex.drawer + 1 })} open={open}>
      <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
        <CircularProgress color="inherit" />
        <Typography>{message}</Typography>
      </Box>
    </Backdrop>
  );
};

export default CustomLoadingIndicator;
