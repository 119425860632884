import React from "react";
import { useLocation } from "react-router";
import { Card, Button, Box, Checkbox, Divider, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import { StatusType } from "assets/type.ts";
import { GetProvidingFields } from "api";

import { specialistColumns } from "layouts/DataGridLayouts";
import { dynamicSpecialistFieldValue } from "utils/dynamicSpecialistFieldValue";
import { CustomDataGrid } from "components";

//mock data

const SpecialistTab = ({ selectedSpecialist, setSelectedSpecialist, setModalIsOpen }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [providing_fields, setProvidingFields] = React.useState([]);

  const specialistList = useSelector((state) => state.specialist);
  const criteriaCatalog = useSelector((state) => state.criteriaCatalog);
  const facility = useSelector((state) => state.facility.filter((item) => item.id === location.state.id));

  console.log(
    "prequalification",
    facility[0]?.prequalification,
    specialistList.length === 0 ||
      !!facility[0]?.prequalification.find(
        (item) => item.status === StatusType.ACCEPTED || item.status === StatusType.REJECTED
      )
  );

  const providingFieldsColumns = [
    {
      field: "in_store",
      headerName: "Geschäftslokal",
      flex: 0.15,
      editable: false,
      default: false,
      renderCell: ({ row }) => {
        if (row.in_store_allowed) {
          return <Checkbox checked={row.in_store_selected} />;
        } else return <></>;
      },
    },
    {
      field: "field_sales",
      headerName: "Außendienst",
      flex: 0.15,
      editable: false,
      default: false,
      renderCell: ({ row }) => {
        if (row.field_sales_allowed) {
          return <Checkbox checked={row.field_sales_selected} />;
        } else return <></>;
      },
    },
    {
      field: "code",
      headerName: "Versorgungsbereiche",
      flex: 0.2,
      editable: false,
    },
    {
      field: "description",
      headerName: "Bezeichnung",
      flex: 0.4,
      editable: false,
    },
  ];

  const handleSpecialistSelection = (rows) => {
    const equalSpecialist = specialistList?.find((item) => item.id === rows[0]);
    if (rows.length === 0) {
      if (selectedSpecialist) {
        setSelectedSpecialist(null);
        setProvidingFields([]);
      }
      return;
    }
    dispatch(GetProvidingFields(equalSpecialist.id)).then(
      setProvidingFields(dynamicSpecialistFieldValue(criteriaCatalog, equalSpecialist))
    );
    setSelectedSpecialist(equalSpecialist);
  };

  // onRowDoubleClick={() => {
  //   setOpen(!open);
  // }}
  // onRowClick={(params) => {
  //   dispatch(GetProvidingFields(params.row.id)).then(
  //     setProvidingFields(dynamicSpecialistFieldValue(criteriaCatalog, params.row))
  //   );
  //   setSelectedSpecialist(params.row);
  // }}

  if (specialistList.length === 0) {
    return (
      <Card sx={{ p: 2, mt: 2 }}>
        <Divider textAlign="left" sx={{ my: 2 }}>
          <Typography fontWeight={"bold"}>Fachliche Leitung</Typography>
        </Divider>
        <Typography>
          Sie haben noch keine Fachliche Leitung erstellt. Bitte Klicken Sie auf den Knopf um eine Fachliche Leitung zu
          erstellen und mit Ihrem Präqualifizierungsvorgang weiterzumachen.
        </Typography>
        <Button sx={{ p: 1, m: 1, textTransform: "none" }} variant="contained" onClick={() => setModalIsOpen(true)}>
          Fachliche Leitung anlegen.
        </Button>
      </Card>
    );
  } else {
    return (
      <Box
        sx={{
          flexDirection: "column",
          mt: 2,
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>
          <Button sx={{ p: 1, textTransform: "none" }} variant="contained" onClick={() => setModalIsOpen(true)}>
            Fachliche Leitung anlegen.
          </Button>
        </Box>
        <Card sx={{ backgroundColor: "transparent", p: 2, mt: 2 }}>
          <Divider textAlign="left" sx={{ my: 2 }}>
            <Typography fontWeight={"bold"}>Fachliche Leitung</Typography>
          </Divider>
          <Card>
            <CustomDataGrid
              initialState={{
                sorting: {
                  sortModel: [{ field: "active", sort: "desc" }],
                },
              }}
              rows={specialistList}
              columns={specialistColumns}
              initialPageSize={25}
              checkboxSelection
              disableSelectionOnClick
              onSelectionModelChange={(rows) => handleSpecialistSelection(rows)}
              // onRowDoubleClick={() => {
              //   setOpen(!open);
              // }}
              // onRowClick={(params) => {
              //   dispatch(GetProvidingFields(params.row.id)).then(
              //     setProvidingFields(dynamicSpecialistFieldValue(criteriaCatalog, params.row))
              //   );
              //   setSelectedSpecialist(params.row);
              // }}
            />
          </Card>
        </Card>
        {selectedSpecialist && (
          <Card sx={{ backgroundColor: "transparent", p: 2, mt: 2 }}>
            <Divider textAlign="left" sx={{ my: 2 }}>
              <Typography fontWeight={"bold"}>Versorgungsbereiche</Typography>
            </Divider>
            <Card>
              <CustomDataGrid
                rows={providing_fields}
                columns={providingFieldsColumns}

                // checkboxSelection={checkboxSelection}
                // onSelectionModelChange={(rows) => setSelectedFields(rows)}
              />
            </Card>
          </Card>
        )}
      </Box>
    );
  }
};

export default SpecialistTab;
