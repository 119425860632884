import { createSlice } from "@reduxjs/toolkit";

const walkerSlice = createSlice({
  name: "walker",
  initialState: [],
  reducers: {
    walkerAdded(state, action) {
      return action.payload;
    },
    logoutWalker(state, action) {
      return walkerSlice.getInitialState();
    },
  },
});

export const { walkerAdded, logoutWalker } = walkerSlice.actions;
export default walkerSlice.reducer;
