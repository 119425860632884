import React from "react";
import { useRoutes, Navigate } from "react-router";

//layouts

import DashboardLayout from "./layouts/dashboard";
import SimpleLayout from "./layouts/simple";

import { useSelector } from "react-redux";

//Page Import
import Dashboard from "./pages/Dashboard";
import LoginPage from "./pages/SignIn";
import SignUp from "./pages/SignUp";
import ForgotPW from "./pages/ForgotPW";
import Page404 from "./pages/Page404";
import Company from "./pages/Company";
import Facility from "./pages/Facility";
import Details from "./pages/details/Details";
import PrequalificationList from "./pages/PrequalificationList";
import Prequalification from "./pages/prequalification";
import InventoryList from "./pages/inventoryList";
import UploadCriteriaCatalog from "./pages/uploadCriteriaCatalog";
import WalkerPage from "./pages/walkerPage";
import WalkerUpload from "./pages/walkerUpload";
import ShowFile from "./pages/showFile";
import EvaluationPage from "./pages/evaluation/evaluationPage";
import AssessmentPage from "./pages/AssessmentPage/AssessmentPage";
import OverwriteFacility from "./pages/overwriteFacility";
import SuperVisorPage from "./pages/SupervisorPage/superVisorPage";
import BaseData from "./pages/baseData";
import ValidateEmail from "./pages/validateEmail";
import PasswordReset from "./pages/passwordReset";
import LogoutPage from "./pages/logout";
// -----------------------------------------------------------------

export default function Router() {
  const account = useSelector((state) => state.account);

  const routes = useRoutes([
    {
      path: "/",
      element: <LoginPage />,
    },
    {
      path: "/reset",
      element: <LogoutPage />,
    },
    {
      path: "/register",
      element: <SignUp />,
    },
    {
      path: "/forgottenPW",
      element: <ForgotPW />,
    },
    { path: "/showFile", element: <ShowFile /> },
    {
      path: "/kriterienkatalog",
      element: <UploadCriteriaCatalog />,
    },
    {
      path: "/validate/:token",
      element: <ValidateEmail />,
    },
    {
      path: "/reset-password/:token",
      element: <PasswordReset />,
    },
    {
      path: "/dashboard",
      element: <DashboardLayout />,
      children: [
        {
          element:
            account.superAdmin || account.admin ? (
              <Navigate to="/dashboard/app" />
            ) : account.walker ? (
              <Navigate to="/dashboard/walker" />
            ) : (
              <Navigate to="/dashboard/company" />
            ),
          index: true,
        },
        { path: "app", element: <Dashboard /> },
        { path: "company", element: <Company /> },
        { path: "facility", element: <Facility /> },
        { path: "details", element: <Details /> },
        { path: "PrequalificationList", element: <PrequalificationList /> },
        { path: "Prequalification", element: <Prequalification /> },
        { path: "inventoryList", element: <InventoryList /> },
        { path: "walker", element: <WalkerPage /> },
        { path: "walkerUpload", element: <WalkerUpload /> },
        { path: "evaluation", element: <EvaluationPage /> },
        { path: "assessment", element: <AssessmentPage /> },
        { path: "supervisor", element: <SuperVisorPage /> },
        { path: "stammdaten", element: <BaseData /> },
      ],
    },
    {
      path: "/overwriteFacility/:token",
      element: <OverwriteFacility />,
    },
    {
      element: <SimpleLayout />,
      children: [{ path: "404", element: <Page404 /> }],
    },
    {
      path: "*",
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
