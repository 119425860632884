import React from "react";
import { AddCircle, RemoveCircle } from "@mui/icons-material";
import { Box, IconButton, InputAdornment, TextField } from "@mui/material";

const ExtendableTextField = ({ textFields, setTextFields }) => {
  const handleAddTextField = () => {
    setTextFields([...textFields, { id: textFields.length + 1, value: "" }]);
  };

  const handleRemoveTextField = (id) => {
    setTextFields(textFields.filter((field) => field.id !== id));
  };

  const handleInputChange = (id, value) => {
    setTextFields(textFields.map((field) => (field.id === id ? { ...field, value } : field)));
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      {textFields.map((field, index) => (
        <TextField
          key={field.id}
          sx={{ mt: 2 }}
          label={`IK Nummer ${field.id + 1}`}
          value={field.value}
          onChange={(e) => handleInputChange(field.id, e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                {index === 0 ? (
                  <IconButton onClick={handleAddTextField}>
                    <AddCircle />
                  </IconButton>
                ) : (
                  <IconButton onClick={() => handleRemoveTextField(field.id)}>
                    <RemoveCircle />
                  </IconButton>
                )}
              </InputAdornment>
            ),
          }}
        />
      ))}
    </Box>
  );
};

export default ExtendableTextField;
