import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Typography, Card, Box, Divider } from "@mui/material";
import { GridToolbarQuickFilter } from "@mui/x-data-grid";

import { FacilityList } from "api";
import { companyColumns } from "layouts/DataGridLayouts";
import CompanyFacilityOverview from "../reuseablePages/companyFacilityOverview";
import { CustomDataGrid } from "components";

const SVFacilityCard = () => {
  const dispatch = useDispatch();

  const [selectedFacility, setSelectedFacility] = React.useState({});

  useEffect(() => {
    dispatch(FacilityList());
  }, [dispatch]);

  const facility = useSelector((state) => (state.facility ? state.facility : []));

  return (
    <Box sx={{ my: 1 }}>
      <Card className="facility-card" sx={{ display: "flex", flexDirection: "column", p: 2 }}>
        <Divider textAlign="left" sx={{ display: "flex", alignItems: "center", mb: 2 }}>
          <Typography variant="h5">Betriebsstätten</Typography>
        </Divider>
        <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
          <CustomDataGrid
            rows={facility}
            columns={companyColumns}
            initialPageSize={10}
            checkboxSelection
            components={{
              Toolbar: (props) => (
                <>
                  <Box sx={{ display: "flex", flex: 1, p: 1, justifyContent: "space-between", alignItems: "center" }}>
                    <GridToolbarQuickFilter showQuickFilter {...props.toolbar} />
                  </Box>
                  <Divider sx={{ mt: 1, mb: 1 }} />
                </>
              ),
            }}
            componentsProps={{
              toolbar: {
                showQuickFilter: true,
              },
            }}
            onSelectionModelChange={(rows) => {
              console.log(rows);
              if (rows.length === 0) {
                if (selectedFacility) {
                  setSelectedFacility(null);
                }
                return;
              }
              const actionListObject = facility?.find((item) => item.id === rows[0]);
              setSelectedFacility(actionListObject);
            }}
          />
          {selectedFacility && <CompanyFacilityOverview object={selectedFacility} type={1} />}
        </Box>
      </Card>
    </Box>
  );
};

export default SVFacilityCard;
