import { renderCellWithTooltip } from "../../components/CustomDataGrid";

const occupationColumns = [
  {
    field: "description",
    headerName: "Beschreibung",
    flex: 1,
    editable: false,
  },
  {
    field: "short_form",
    headerName: "Kurzform",
    flex: 0.25,
    editable: false,
  },
  {
    field: "certificate",
    headerName: "Vorraussetzungen",
    flex: 1,
    editable: false,
  },
];

const occupationShortFrontColumns = [
  {
    field: "short_form",
    headerName: "Kurzform",
    flex: 0.4,
    editable: false,
  },
  {
    field: "description",
    headerName: "Beschreibung",
    flex: 0.8,
    renderCell: renderCellWithTooltip,
  },

  {
    field: "certificate",
    headerName: "Vorraussetzungen",
    flex: 0.8,
    editable: false,
  },
];

export { occupationColumns, occupationShortFrontColumns };
