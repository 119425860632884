import { combineReducers } from "redux";
import account from "./reducers/account";
import accountList from "./reducers/accountList";
import company from "./reducers/company";
import facility from "./reducers/facility";
import specialist from "./reducers/specialist";
import providingFields from "./reducers/providingFields";
import prequalification from "./reducers/prequalification";
import actionList from "./reducers/actionList";
import admin from "./reducers/admin";
import history from "./reducers/history";
import walker from "./reducers/walker";
import inspection from "./reducers/inspection";
import assessment from "./reducers/assessment";
import criteriaCatalog from "./reducers/criteriaCatalog";
import occupation from "./reducers/occupation";
import requirements from "./reducers/requirements";
import token from "./reducers/token";
import xml from "./reducers/xml";

export default combineReducers({
  account,
  accountList,
  company,
  facility,
  specialist,
  providingFields,
  prequalification,
  actionList,
  admin,
  history,
  walker,
  inspection,
  assessment,
  criteriaCatalog,
  occupation,
  requirements,
  token,
  xml,
});
